import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { GiftcardService } from "../../service/giftcard.service";
import { Router, NavigationEnd } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: 'app-giftcard-home-top-part',
  templateUrl: './giftcard-home-top-part.component.html',
  styleUrls: ['./giftcard-home-top-part.component.scss'],
})

export class GiftcardHomeTopPartComponent implements OnInit {
  allCategories: any = [];
  progress: boolean = true;
  allGiftcards = [];
  categoryInput;
  selectedCategory;
  allSubCategories: any = [];
  subCategoryInput;
  selectedSubCategory;
  isLoading: boolean;
  isGiftcardDropdownOpen: boolean = false;
  filteredGiftCardList = [];
  giftInput = new UntypedFormControl();
  selectedGift;
  filterGiftList = false;
  inputToShow;
  dropDownPopOpen: boolean = false;
  popularGiftCards = [];

  @ViewChild('popularListing', { static: false }) private popularListing: ElementRef;
  //error variables
  chooseCategoryError;
  constructor(
    private router: Router,
    public giftcardService: GiftcardService,
    private renderer: Renderer2,
    public dataService: DataService,
  ) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.getGiftcards().then((res) => {
      this.getPopularGiftCards().then((res) => {
        this.getCategories();
      });
    });
    this.giftInput.valueChanges.subscribe((value) => {
      if (this.selectedGift && this.selectedGift.attributes.title !== value) {
        this.selectedGift = undefined;
      }
    });

  }
  getCategories() {
    this.giftcardService.getCategories().then((res: any) => {
      this.allCategories = res.data;
      this.progress = false;
    });
  }

  navigateToListing(id) {
    this.router.navigate(['giftcards', 'listing'], { queryParams: { 'category': id } });
  }

  getGiftcards() {
    return new Promise((resolve) => {
      let internalSub = setInterval(() => {
        if (!this.giftcardService.progress) {
          this.allGiftcards = this.giftcardService.allGiftcards;
          this.filterGiftCards();
          clearInterval(internalSub);
          resolve('success');
        }
      }, 100);

      this.allGiftcards = this.giftcardService.allGiftcards;
      // this.giftcardService.getGiftcards(1, 500).then((res: any) => {
      //   this.allGiftcards = res.data;
      // this.allGiftcards.forEach(obj => {
      //   obj.attributes.min_price = Number(obj.attributes.min_price).toLocaleString('en-IN');
      // });
      // });
    });
  }

  getPopularGiftCards() {
    return new Promise((resolve) => {
      this.popularGiftCards = [];
      this.giftcardService.getGiftcards(1, 500, true).then((res: any) => {
        this.popularGiftCards = res.data;
        resolve('success');
      });
    });
  }

  setSelectedCategory(where) {
    this.chooseCategoryError = '';
    if (!this.selectedCategory || this.selectedCategory.id != where.id) {
      this.selectedCategory = where;
      this.getSubCategories();
      this.giftInput.setValue('');
      this.selectedGift = undefined;
    }
    this.categoryInput = where.attributes.name;
    this.filterGiftCards();
  }

  getSubCategories() {
    this.subCategoryInput = '';
    this.selectedSubCategory = undefined;
    this.allSubCategories = [];
    if (this.selectedCategory && this.selectedCategory.id) {
      this.allSubCategories = this.selectedCategory.attributes.sub_categories ? this.selectedCategory.attributes.sub_categories : [];
      // this.isLoading = true;
      // this.giftcardService.getSubCategories(this.selectedCategory.id).then((res: any) => {
      //   this.allSubCategories = res.data;
      //   this.isLoading = false;
      // });
    }
  }

  setSelectedSubcategory(where) {
    if (!this.selectedSubCategory || this.selectedSubCategory.id != where.id) {
      this.selectedSubCategory = where;
      this.giftInput.setValue('');
      this.selectedGift = undefined;
    }
    this.subCategoryInput = where.name;
    this.filterGiftCards();
  }

  setSelectedGift(where) {
    if (!this.selectedGift || this.selectedGift.id != where.id) {
      this.selectedGift = where;
    }
    this.giftInput.setValue(where.attributes.title);
    this.filterGiftList = false;
  }

  openCategoryDrop() {
    if (window.innerWidth < 576) {
      this.inputToShow = 'categoryDrop';
      this.dropDownPopOpen = true;
      this.addModalOpen();
    }
  }

  openSubCategoryDrop() {
    if (this.selectedCategory && this.selectedCategory.id) {
      if (window.innerWidth < 576) {
        this.inputToShow = 'subCategoryDrop';
        this.dropDownPopOpen = true;
        this.addModalOpen();
      }
    } else {
      // this.dataService.triggerToastr('error','Choose category.');
      this.chooseCategoryError = 'Choose category.';

    }
  }

  getGiftCategories(giftcard) {
    let categories = []
    if (giftcard.attributes.categories.length) {
      giftcard.attributes.categories.forEach(element => {
        if (element.name && !element.parent_id) {
          categories.push(element.id)
        }
      });
    }
    return categories;
  }

  getGiftSubCategories(giftcard) {
    let subCategories = []
    if (giftcard.attributes.categories.length) {
      giftcard.attributes.categories.forEach(element => {
        if (element.name && element.parent_id) {
          subCategories.push(element.id)
        }
      });
    }
    return subCategories;
  }

  filterGiftCards() {
    if (this.selectedCategory && this.selectedCategory.id) {
      this.filteredGiftCardList = this.allGiftcards.filter((each) => this.getGiftCategories(each).find((e) => e == this.selectedCategory.id))
      if (this.selectedSubCategory && this.selectedSubCategory.id) {
        this.filteredGiftCardList = this.allGiftcards.filter((each) => this.getGiftSubCategories(each).find((e) => e == this.selectedSubCategory.id))
      }
    } else {
      this.filteredGiftCardList = this.allGiftcards;
    }
  }

  closeGiftDrop() {
    this.isGiftcardDropdownOpen = false;
  }

  openGiftDrop() {
    if (window.innerWidth < 576) {
      this.inputToShow = 'giftDrop';
      this.dropDownPopOpen = true;
      this.addModalOpen();
    } else {
      this.isGiftcardDropdownOpen = true;
    }
  }

  giftInputChange() {
    this.filterGiftList = true;
    console.log('here')
  }

  addModalOpen() {
    if (window.innerWidth < 576) {
      this.renderer.addClass(document.body, 'modal-open');
    }
  }

  removeModalOpen() {
    this.renderer.removeClass(document.body, 'modal-open');
    if (window.innerWidth < 576) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  search() {
    if (this.selectedGift && this.selectedGift.id) {
      this.router.navigate(['giftcards', 'details', this.selectedGift.id]);
    } else {
      if (this.selectedCategory && this.selectedCategory.id) {
        this.router.navigate(['giftcards', 'listing'], {
          queryParams: {
            'category': (this.selectedCategory && this.selectedCategory.id) ? this.selectedCategory.id : '',
            'subCategory': (this.selectedSubCategory && this.selectedSubCategory.id) ? this.selectedSubCategory.id : ''
          }
        });
      } else {
        // this.dataService.triggerToastr('error','Choose category.');
        this.chooseCategoryError = 'Choose category.';
      }
    }
  }

  previous() {
    this.popularListing.nativeElement.scrollTo({ left: (this.popularListing.nativeElement.scrollLeft - this.popularListing.nativeElement.offsetWidth), behavior: 'smooth' });
  }

  next() {
    this.popularListing.nativeElement.scrollTo({ left: (this.popularListing.nativeElement.scrollLeft + this.popularListing.nativeElement.offsetWidth), behavior: 'smooth' });
  }

  seeAll(){
    this.router.navigate(['giftcards','listing']);
  }

}
