<div *ngIf="!progress">
    <div *ngIf="!bookings.length" class="no-rooms">
        No bookings found !
      </div>
    <div class="nat-geo-item d-block d-sm-none" *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }" (click)="openBookingDetails(booking.attributes.booking_unique_id)">
        <div class="d-flex justify-content-between align-items-center pointer">
            <div class="d-flex justify-content-start align-items-center">
                <div class="mar-right-15">
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17.5" r="17.5" fill="#C2A63F"/>
                        <path d="M21.9996 10.6V9H13.9996V10.6H11.5996V25H16.3996V23.4H19.5996V25H24.3996V10.6H21.9996ZM17.1996 21H15.5996V19.4H17.1996V21ZM17.1996 17.8H15.5996V16.2H17.1996V17.8ZM17.1996 14.6H15.5996V13H17.1996V14.6ZM20.3996 21H18.7996V19.4H20.3996V21ZM20.3996 17.8H18.7996V16.2H20.3996V17.8ZM20.3996 14.6H18.7996V13H20.3996V14.6Z" fill="white"/>
                    </svg> 
                </div>
                <div class="">
                    <div class="nat-geo-item-title">{{booking.attributes.booking_data?.booking_request_hash?.restaurant_name}}</div>
                    <div class="nat-geo-item-details">
                        <span class="from-to">
                            {{booking.attributes.booking_data?.booking_request_hash?.booking_date | date: 'dd MMM yyyy'}}
                        </span>
                        <span> - </span>
                        <span class="from-to">
                            {{booking.attributes.booking_data?.booking_request_hash?.booking_time?.slice(0,2) + ':' + booking.attributes.booking_data?.booking_request_hash?.booking_time?.slice(2,4) | time24to12}}
                        </span>
                    </div>
                </div>
          </div>
          <div class="status-text">
            <div>
                <app-status-label class="status-text" [status]="getStatus(booking.attributes.booking_data?.booking_request_hash?.booking_date, booking.attributes.status)"></app-status-label>
            </div>
          </div>
        </div>
    </div>
    <div class="nat-geo-item d-none d-sm-block" *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }">
    <div class="bg">
        <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-start align-items-center w80">
                <div class="mar-right-15">
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17.5" r="17.5" fill="#C2A63F"/>
                        <path d="M21.9996 10.6V9H13.9996V10.6H11.5996V25H16.3996V23.4H19.5996V25H24.3996V10.6H21.9996ZM17.1996 21H15.5996V19.4H17.1996V21ZM17.1996 17.8H15.5996V16.2H17.1996V17.8ZM17.1996 14.6H15.5996V13H17.1996V14.6ZM20.3996 21H18.7996V19.4H20.3996V21ZM20.3996 17.8H18.7996V16.2H20.3996V17.8ZM20.3996 14.6H18.7996V13H20.3996V14.6Z" fill="white"/>
                    </svg>    
                </div>
                <div>
                    <div>
                        <span class="hotel_name">
                            {{booking.attributes.booking_data?.booking_request_hash?.restaurant_name}}
                        </span>
                    </div>
                    <div>
                        <span class="from-to">
                            {{booking.attributes.booking_data?.booking_request_hash?.restaurant_address}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="status-text">
                <div>
                    <app-status-label class="status-text" [status]="getStatus(booking.attributes.booking_data?.booking_request_hash?.booking_date, booking.attributes.status)"></app-status-label>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="bg">
        <div class="d-flex justify-content-between">
            <div class="booking-id-detail">
                <span>Booking ID : </span>
                <span class="id">{{booking.attributes?.booking_unique_id}}</span>
                <span class="mar-left-5 mar-right-5">
                    <svg width="1" height="22" viewBox="0 0 1 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="1" height="22" fill="#E1E1E1"/>
                    </svg>
                </span>
                <span class="fnt-16">
                    Powered by 
                    <img default="./assets/default_image.png" src="./assets/dineoutlogo.png" style="width: inherit; height: 20px;">
                </span>
            </div>
            <div>
                <span *ngIf="booking.attributes.status === 'booking_confirmed' || booking.attributes.status === 'booking_in_progress'" class="download-ticket pointer" (click)="cancelOpt(booking.attributes?.booking_unique_id)">Cancel Booking</span>
                <span class="mar-left-5 mar-right-5" *ngIf="booking.attributes.status === 'booking_confirmed' || booking.attributes.status === 'booking_in_progress'">
                    <svg width="1" height="22" viewBox="0 0 1 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="1" height="22" fill="#E1E1E1"/>
                    </svg>
                </span>
                <span class="cancel-ticket pointer" (click)="openBookingDetails(booking.attributes.booking_unique_id)">
                    View Details
                </span>
                <span>
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 10.5L5.5 6L1.5 1.5" stroke="#C2A63F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>                        
                </span>
            </div>
        </div>
    </div>
    <hr>
    <div class="adfasdas">
        <div class="desktop-body d-flex align-items-center justify-content-between" *ngIf="booking.attributes.booking_data?.booking_request_hash?.offer_text">
            <div class="check-in-out">
                Offer
            </div>
            <div class="check-in-out-txt">
                {{booking.attributes.booking_data?.booking_request_hash?.offer_text}}
            </div>
        </div>
        <hr>
        <div class="desktop-body d-flex align-items-center justify-content-between">
            <div class="check-in-out">
                Number of people
            </div>
            <div class="check-in-out-txt">
                {{booking.attributes.booking_data?.booking_request_hash?.people}}
            </div>
        </div>
        <hr>
        <div class="desktop-body d-flex align-items-center justify-content-between">
            <div class="check-in-out">
                Date
            </div>
            <div class="check-in-out-txt">
                {{booking.attributes.booking_data?.booking_request_hash?.booking_date | date: 'EEEE, dd MMM yyyy'}}
            </div>
        </div>
        <hr>
        <div class="desktop-body d-flex align-items-center justify-content-between">
            <div class="check-in-out">
                Time
            </div>
            <div class="check-in-out-txt">
                {{booking.attributes.booking_data?.booking_request_hash?.booking_time?.slice(0,2) + ':' + booking.attributes.booking_data?.booking_request_hash?.booking_time?.slice(2,4) | time24to12}}
            </div>
        </div>
    </div>
    </div>
    <div class="d-flex justify-content-center w100 my-5">
        <pagination-controls *ngIf="bookings.length>10" responsive="true" (pageChange)="pageNo = $event; moveUp();"></pagination-controls>
    </div>
</div>
<div class="d-flex justify-content-center w100 my-5">
    <div class="dot-flashing" *ngIf="progress"></div>
</div>

<!-- <app-loader *ngIf="progress"></app-loader> -->

  