import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, APP_INITIALIZER, LOCALE_ID, ErrorHandler } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { SignupComponent } from "./pages/signin/signup/signup.component";
import { OtpComponent } from "./pages/signin/otp/otp.component";
import { AuthInterceptorService } from "./services/login/auth-interceptor.service";
import { SharedModule } from "./shared/shared.module";
import { AngularReactDatesModule } from "angular-react-dates";
// import { PaymentComponent } from './shared/components/payment/payment.component';
// import { ModalModule, PopoverModule, BsDropdownModule } from 'ngx-bootstrap';
import { DataService } from "./services/common/data/data.service";
import { NgOtpInputModule } from "ng-otp-input";
import { Error404Component } from "./pages/error404/error404.component";
import { Error500Component } from "./pages/error500/error500.component";
import { BookingFailedComponent } from "./pages/booking-failed/booking-failed.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { DataSharingService } from "./services/data-sharing.service";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { RouterExtService } from "./services/util/router-ext.service";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { MatSidenavModule } from "@angular/material/sidenav";
import { FlightsHomeComponent } from "src/app/components/flights-home/flights-home.component";
import { HotelsHomeComponent } from "src/app/components/hotels-home/hotels-home.component";
import { ToursHomeComponent } from "src/app/components/tours-home/tours-home.component";
import { NatgeoHomeContainerComponent } from "src/app/nat-geo/pages/home/home-container/natgeo-home-container.component";
import { GiftcardHomeContainerComponent } from "src/app/giftcards/home/home-container/giftcard-home-container.component";
import { CharitableGivingHomeComponent } from "src/app/charitable-giving/home/charitable-giving-home.component";
import { MyAccountComponent } from "./pages/my-account/my-account.component";
import { RoutesService } from "src/app/routes.service";
import { RouterModule } from "@angular/router";
import { AuthTokenComponent } from "./pages/auth-token/auth-token.component";
import { ToastrModule } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { PointsTransferModule } from "./points-transfer/points-transfer.module";
import localeIn from "@angular/common/locales/en-IN";
import { registerLocaleData } from "@angular/common";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
export function initSettings(settings: RoutesService) {
  return () => settings.loadSettings();
}
registerLocaleData(localeIn);

declare var Razorpay: any;
@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    OtpComponent,
    // PaymentComponent,
    Error404Component,
    Error500Component,
    BookingFailedComponent,
    HomepageComponent,
    FlightsHomeComponent,
    HotelsHomeComponent,
    ToursHomeComponent,
    NatgeoHomeContainerComponent,
    GiftcardHomeContainerComponent,
    CharitableGivingHomeComponent,
    MyAccountComponent,
    AuthTokenComponent,
  ],
  imports: [
    // RouterModule.forRoot([]),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    NgOtpInputModule,
    AngularReactDatesModule.forRoot(),
    MatSidenavModule,
    BsDropdownModule.forRoot(),
    // ModalModule.forRoot(),
    // PopoverModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: true }),
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
      // disableTimeOut: true
    }),
    PointsTransferModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    DataService,
    DataSharingService,
    RouterExtService,
    // RoutesService,
    {
      provide: APP_INITIALIZER,
      useFactory: initSettings,
      deps: [RoutesService],
      multi: true,
    },
    {
      provide: "Razorpay",
      useValue: Razorpay,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: "env", useValue: environment },
    { provide: LOCALE_ID, useValue: "en-IN" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
