import { Component, OnInit, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { SavedTravllerService } from 'src/app/services/common/saved-traveller/saved-travller.service';
import * as moment from "moment";
import { UntypedFormGroup, UntypedFormControl, FormArray, Validators } from '@angular/forms';
import { FlightsService } from 'src/app/flights/service/flights.service';
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from "@angular/core";
import { PaymentHomeService } from 'src/app/payment/payment-home.service';
import { appConstants } from 'src/app/app.constants';// "./../../../app.constants";
import {HotelApiService} from 'src/app/hotel/services/hotel-api/hotel-api.service';
import { Router } from "@angular/router";
import { DataService } from 'src/app/services/common/data/data.service';

import {
  NgbDateAdapter,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = "/";

  fromModel(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value) {
      console.log(value,'qwertyui')
      let date = value.split(this.DELIMITER);
      result = {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return result;
  }

  toModel(date: NgbDateStruct): string {
    let result: string = null;
    if (date) {
      result =
        date.day + this.DELIMITER + date.month + this.DELIMITER + date.year;
    }
    return result;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "/";

  parse(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value) {
      let date = value.split(this.DELIMITER);
      result = {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return result;
  }

  format(date: NgbDateStruct): string {
    let result: string = null;
    if (date) {
      result =
        date.day + this.DELIMITER + date.month + this.DELIMITER + date.year;
    }
    return result;
  }
}

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class MyAccountComponent implements OnInit {
  basicProfile;
  savedTravellers;
  editWindow =[];
  enquiryForm;
  enquiryFormPrimary;
  titles=["Mr.","Mrs.","Ms."];
  genders=['Male', 'Female'];
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobilePattern = /^\d{10}$/;
  progress :boolean = false;
  submitted :boolean = false;
  nationalityList;
  addNewTravellerFlag : boolean = false;
  addNewCardFlag : boolean = false;
  addPrimaryFlag : boolean = false;
  @ViewChildren("d_o_b_a") dobAdults;
  @ViewChildren("p_i_d_a") pidAdults;
  @ViewChildren("p_e_d_a") pedAdults;
  @ViewChildren("d_o_b_primary") dobPrimary;
  savedCards = [];
  cardDetailsForm;
  progress2:boolean = true;
  cardIcon;
  bookingdetailsprogress : boolean = false;
  savedcardprogress : boolean = false;
  maxDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  // minDateChildren = {
  //   year: new Date().getFullYear() - 12,
  //   month: new Date().getMonth() + 1,
  //   day: new Date().getDate()
  // };

  // minDateInfant = {
  //   year: new Date().getFullYear() - 2,
  //   month: new Date().getMonth() + 1,
  //   day: new Date().getDate()
  // };

  bookingInfo;

  constructor(
    private traveller: SavedTravllerService,
    private cdRef: ChangeDetectorRef,
    public flightS: FlightsService,
    private paymentService: PaymentHomeService,
    private hotelApiService : HotelApiService,
    public router: Router,
    private data: DataService
  ) {
    this.initEnquiryForm();
  }

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const loginDetails = JSON.parse(localStorage.getItem('login_details'));
    this.basicProfile = loginDetails;
    this.getTravellers();
    this.getSavedCards();
    this.getNational();
    this.getAbstractDetails();
    this.enquiryFormPrimary = new UntypedFormGroup({
      first_name: new UntypedFormControl("",[Validators.required, Validators.minLength(2),Validators.maxLength(50),]),
      last_name: new UntypedFormControl("",[Validators.required, Validators.minLength(2),Validators.maxLength(50),]),
      gender: new UntypedFormControl(""),
      date_of_birth: new UntypedFormControl(""),
    });
  }

  getAbstractDetails(){
    this.bookingdetailsprogress = true;
    this.traveller.getUserBookingInfo().then( res => {
      console.log(res);
      this.bookingdetailsprogress = false;
      this.bookingInfo = res['booking_information'];
    })
  }

  initEnquiryForm(){
    this.enquiryForm = new UntypedFormGroup({
      title:new UntypedFormControl("",[Validators.required]),
      first_name: new UntypedFormControl("",[Validators.required, Validators.minLength(2),Validators.maxLength(50)]),
      last_name: new UntypedFormControl("",[Validators.required, Validators.minLength(2),Validators.maxLength(50)]),
      gender: new UntypedFormControl(""),
      mobile_number: new UntypedFormControl("",[Validators.minLength(10),Validators.maxLength(12),Validators.pattern(this.mobilePattern)]),
      email: new UntypedFormControl("", [Validators.email, Validators.pattern(this.emailPattern)]),
      date_of_birth: new UntypedFormControl(""),
      nationality: new UntypedFormControl(""),
      passport_number: new UntypedFormControl(""),
      passport_exp_date: new UntypedFormControl(""),
      passport_issue_date: new UntypedFormControl(""),
    });
  }

  initCardForm(){
    this.cardDetailsForm = new UntypedFormGroup({
      ccnum: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(19),
      ]),
      ccname: new UntypedFormControl("", [Validators.required]),
      expiry_month: new UntypedFormControl("", [Validators.required]),
      expiry_year: new UntypedFormControl("", Validators.required),
      ccvv: new UntypedFormControl("", Validators.required),
      save_later: new UntypedFormControl(true),
      card_mode: new UntypedFormControl(""),
      card_type: new UntypedFormControl(""),
      payment_type: new UntypedFormControl(""),
      bank_code: new UntypedFormControl(""),
    });
  }

  deleteCard(token){
    this.progress = true;
    this.hotelApiService.deleteUserCard(token).subscribe( res =>{
      this.progress = false;
      console.log(res);
      this.getSavedCards();
    })
  }
  openBookingHistory() {
    this.router.navigate(["booking/home"]);
  }
  toggleAddPrimary(){
    this.addPrimaryFlag = !this.addPrimaryFlag;
    if(this.addPrimaryFlag){
      this.initEditPrimary();
    }
  }
  getSavedCards(){
    this.savedcardprogress = true;
    // this.traveller.getUserCards().then( resp => {
    //   this.savedcardprogress = false;
    //   if (resp["status"] == 1) {
    //     this.savedCards = Object.values(resp["user_cards"]);
    //     console.log(this.savedCards);
    //   } else {
    //     this.savedCards = [];
    //   }
    // })
    this.progress2 = true;
  }
  getTravellers() {
    this.progress = true;
    this.traveller.getTravellerDetails().then(travellers => {
      this.savedTravellers = travellers['guest_list'];
      this.editWindow = new Array(this.savedTravellers.length).fill(false);
      this.savedTravellers.forEach(traveller => {
        this.initEdit(traveller);
      });
      this.progress = false;
      console.log(this.savedTravellers, 'qwertyuio')
    });
  }

  getAge(dob){
    // console.log(moment().diff(moment(dob), 'years'), 'asdfghjk');
    return moment().diff(moment(dob), 'years');
  }

  onSubmitCard(){
    this.submitted = true;
    this.cardDetailsForm.controls["ccnum"].setValue(
      this.parseCardNumber(this.cardDetailsForm.controls["ccnum"].value)
    );
    console.log(this.cardDetailsForm)
    if (this.cardDetailsForm.valid) {
      this.progress = true;
      this.submitted = false;
      this.hotelApiService.saveUserCard(this.cardDetailsForm.value, this.basicProfile.email).subscribe(res => {
        console.log(res);
        this.submitted = false;
        this.progress = false;
        this.addNewCardFlag = false;
        this.getSavedCards();
        if(res['status']){
          this.data.triggerToastr('success','Card Saved');
        }else{
          this.data.triggerToastr('error',"Card couldn't be saved");
        }
      })
    }
  }

  onSubmitPrimary(){
    this.progress=true;
    this.submitted = true;
    console.log(this.enquiryFormPrimary);

    if(!this.enquiryFormPrimary.valid) {
      this.progress=false;
      return;
    }

    // this.enquiryFormPrimary.controls['date_of_birth'].setValue( this.enquiryFormPrimary.value.date_of_birth.day+'/'+this.enquiryFormPrimary.value.date_of_birth.month+'/'+this.enquiryFormPrimary.value.date_of_birth.year)
    let sendthis = { ...this.enquiryFormPrimary.value, 'dob' : this.enquiryFormPrimary.value.date_of_birth}
    console.log(sendthis);

    this.traveller.editPrimaryUser(sendthis).then( res => {
      this.progress=false;
      this.addPrimaryFlag = false;
      this.submitted = false;
      if(res['user']){
        this.data.triggerToastr('success','Details saved.')
        let save_this = {
          mobile: res['user'].mobile_number,
          email: res['user'].email,
          first_name: res['user'].first_name,
          last_name: res['user'].last_name,
          date_of_birth: res['user'].dob,
          gender: res['user'].gender,
        }
        localStorage.setItem('login_details',JSON.stringify(save_this));
        this.basicProfile  = JSON.parse(localStorage.getItem('login_details'));
      }
    else
      this.data.triggerToastr('error','Could not save details.')
    })
  }

  onSubmit(index){
    this.progress=true;
    this.submitted = true;
    console.log(this.enquiryForm);

    if(!this.enquiryForm.valid) {
      this.progress=false;
      return;
    }
    if(index!= null){
      this.enquiryForm.value = { ...this.enquiryForm.value, 'id': this.savedTravellers[index].id}
    }
    this.addTraveller(this.enquiryForm.value, index);
  }

  addTitle(title){
    this.enquiryForm.controls['title'].setValue(title);
  }

  addGender(gender){
    this.enquiryForm.controls['gender'].setValue(gender);
    this.enquiryFormPrimary.controls['gender'].setValue(gender);
  }

  addNation(nation){
    this.enquiryForm.controls['nationality'].setValue(nation);
  }

  editPrimaryUser(){

  }

  addTraveller(traveller, index){
    this.submitted = false;
    console.log(traveller,'abcd')
    let a = { guests : []};
    a.guests.push(traveller);
    this.traveller.addTravellerDetails(a).then(res=> {
      this.progress = false;
      if(index!=null)
        this.editWindow[index]=false;
      this.addNewTravellerFlag = false;
      if(res['guests_added'] && res['guests_added'].length)
        this.data.triggerToastr('success','Traveller details saved.')
      else
        this.data.triggerToastr('error','Could not save traveller.')
      this.getTravellers();
    })
  }

  getNational() {
    this.flightS.getNationalities().then(nationality => {
      this.nationalityList = nationality;
    });
  }

  addNewTraveller(){
    this.addNewTravellerFlag = true;
    window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: "smooth" });
    this.initEnquiryForm();
  }

  addNewCard(){
    this.addNewCardFlag = true;
    window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: "smooth" });
    this.initCardForm();
  }

  closeAddTraveller(){
    this.addNewTravellerFlag = false;
  }

  closeAddCard(){
    this.addNewCardFlag = false;
  }

  initEditPrimary(){
    if(this.basicProfile.first_name)
      this.enquiryFormPrimary.controls['first_name'].setValue(this.basicProfile.first_name);
    if(this.basicProfile.last_name)
        this.enquiryFormPrimary.controls['last_name'].setValue(this.basicProfile.last_name);
    if(this.basicProfile.date_of_birth)
      this.enquiryFormPrimary.controls['date_of_birth'].setValue(moment(this.basicProfile.date_of_birth).format('DD/MM/YYYY'));
    if(this.basicProfile.gender)
      this.enquiryFormPrimary.controls['gender'].setValue(this.basicProfile.gender);
  }


  initEdit(traveller){
    if(traveller.title)
    this.enquiryForm.controls['title'].setValue(traveller.title);
    if(traveller.first_name)
      this.enquiryForm.controls['first_name'].setValue(traveller.first_name);
    if(traveller.last_name)
      this.enquiryForm.controls['last_name'].setValue(traveller.last_name);
    if(traveller.gender)
      this.enquiryForm.controls['gender'].setValue(traveller.gender);
    if(traveller.mobile_number)
      this.enquiryForm.controls['mobile_number'].setValue(traveller.mobile_number);
    if(traveller.email)
    this.enquiryForm.controls['email'].setValue(traveller.email);
    if(traveller.date_of_birth){
      // let a = {
      //   year: parseInt(traveller.date_of_birth.split('-')[0]),
      //   month: parseInt(traveller.date_of_birth.split('-')[1]),
      //   day: parseInt(traveller.date_of_birth.split('-')[2])
      // };
      let a = parseInt(traveller.date_of_birth.split('-')[2])+'/'+parseInt(traveller.date_of_birth.split('-')[1])+'/'+ parseInt(traveller.date_of_birth.split('-')[0])
      this.enquiryForm.controls['date_of_birth'].setValue(a);
    }else{
      this.enquiryForm.controls['date_of_birth'].setValue(null);
    }
    if(traveller.passport_number)
      this.enquiryForm.controls['passport_number'].setValue(traveller.passport_number);
    else{
      this.enquiryForm.controls['passport_number'].setValue('');
    }
    if(traveller.nationality)
      this.enquiryForm.controls['nationality'].setValue(traveller.nationality);
      console.log(traveller)
    if(traveller.passport_issue_date){
      // let a = {
      //   year: parseInt(traveller.passport_issue_date.split('-')[0]),
      //   month: parseInt(traveller.passport_issue_date.split('-')[1]),
      //   day: parseInt(traveller.passport_issue_date.split('-')[2])
      // };
      let a = moment(traveller.passport_issue_date).format('DD/MM/YYYY');
      this.enquiryForm.controls['passport_issue_date'].setValue(a);
    }else{
      this.enquiryForm.controls['passport_issue_date'].setValue(null);
    }
    if(traveller.passport_exp_date){
      let a = moment(traveller.passport_exp_date).format('DD/MM/YYYY');
      this.enquiryForm.controls['passport_exp_date'].setValue(a);
    }else{
      this.enquiryForm.controls['passport_exp_date'].setValue(null);
    }
  }

  removeLetter1(str) {
    // str.value = str.value.replace(/\D/g, "");
    console.log(str.value);
    if (str.value.length == 7) {
      //with extra '-'
      this.paymentService
        .getCardDetails(this.parseCardNumber(this.parseCardNumber(str.value)))
        .subscribe((resp) => {
          console.log(resp, "--------");
          this.cardDetailsForm.controls["card_mode"].setValue(
            resp["cardCategory"]
          );
          this.cardDetailsForm.controls["card_type"].setValue(resp["cardType"]);
          this.cardDetailsForm.controls["payment_type"].setValue(
            resp["cardCategory"]
          );
          this.cardDetailsForm.controls["bank_code"].setValue(
            resp["cardCategory"] == "UNKNOWN" ? "CC" : resp["cardCategory"]
          );

          this.cardIcon = this.getCardLink(resp["cardType"]);

          console.log(this.cardIcon, "***");
        });
    }
  }

  parseCardNumber(cardnumber) {
    console.log(cardnumber);
    return cardnumber.replace(/\s/g, "");
  }

  getCardLink(cardType) {
    console.log(appConstants.cardIconsMapping[cardType]);
    // return './../../../../assets/' + (appConstants.cardIconsMapping[cardType] ? appConstants.cardIconsMapping[cardType] : appConstants.cardIconsMapping['Unknown']);
    return appConstants.cardIconsMapping[cardType]
      ? "./../../../../assets/" + appConstants.cardIconsMapping[cardType]
      : './../../../../assets/credit.png';
  }

  toggleEditWindow(i, traveller){
    this.editWindow[i] = !this.editWindow[i];
    if(this.editWindow[i])
      this.initEdit(traveller);
    for(var j=0;j<this.editWindow.length; j++){
      if(j!=i){
        this.editWindow[j] = false;
      }
    }
    console.log(this.editWindow)
  }

  removeLetter(str) {
    str.value = str.value.replace(/\D/g, "");
  }

  detectChanges() {
    ;
    this.cdRef.detectChanges();
  }

  toggleCalendar(key) {
    console.log(this[key])
    this[key]._results[0].toggle();
  }

  range = (from, to) => [...Array(to + 1).keys()].slice(from);


}
