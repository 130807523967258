import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GiftingService } from 'src/app/gifting/services/gifting/gifting.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { PaymentHomeService } from 'src/app/payment/payment-home.service';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import { appConstants } from 'src/app/app.constants';
import swal from "sweetalert2";
import * as moment from 'moment';

@Component({
  selector: 'app-gifting-history-details',
  templateUrl: './gifting-history-details.component.html',
  styleUrls: ['./gifting-history-details.component.scss']
})
export class GiftingHistoryDetailsComponent implements OnInit {
  progress: boolean;
  bookingId: string;
  bookingData;
  cardIcon;
  constructor(
    private route: ActivatedRoute,
    private giftingService: GiftingService,
    public dialog: MatDialog,
    public login: LoginService,
    private router: Router,
    private paymentService: PaymentHomeService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.progress = true;
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.bookingId = this.route.snapshot.queryParamMap.get("id");
          this.giftingService.getBookingDetailsById(this.bookingId).then(resp => {
            this.bookingData = resp['data'];
            if (this.bookingData.attributes.card_number) {
              this.paymentService
                .getCardDetails(this.bookingData.attributes.card_number.slice(0, 6))
                .subscribe(resp => {
                  this.cardIcon = this.getCardLink(resp["cardType"]);
                });
            }
            this.progress = false;
          });
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          };
        });
      });
  }

  back() {
    history.back();
  }

  convertMyWay() {
    let title = document.title;
    document.title = this.bookingId;
    window.print();
    document.title = title;
  }

  getCardLink(cardType) {
    return appConstants.cardIconsMapping[cardType]
      ? "assets/" + appConstants.cardIconsMapping[cardType]
      : null;
  }

  getTotalProductsPrice() {
    let total = 0;
    this.bookingData.attributes.booking_data.data.forEach(element => {
      total = total + element.attributes.amount
    });
    return total
  }

  getAddress() {
    return this.bookingData.attributes.booking_data.data[0].attributes.address + this.bookingData.attributes.booking_data.data[0].attributes.pin_code + ', ' + this.bookingData.attributes.booking_data.data[0].attributes.pin_code_region.district_name + ', ' + this.bookingData.attributes.booking_data.data[0].attributes.pin_code_region.state;
  }

  checkIfUpcoming() {
    return moment(this.bookingData.attributes.booking_data.data[0].attributes.delivery_date).isAfter(moment())
  }


  cancelBooking() {
    let cancellationMessage = '<p>' + '</p>';
    // <span class="icon-gifting mar-right-5 fnt-14"></span>
    swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this order?',
        html: cancellationMessage,
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reason...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        preConfirm: (input) => {
          if (!input) {
            swal.showValidationMessage("Please enter reason for cancellation.");
            return false;
          } else {
            return input;
          }
        }
      })
      .then(result => {
        if (result.value) {
          this.progress = true;
          this.giftingService.cancelBookingRequest(this.bookingData.attributes.booking_unique_id, result.value).then((resp: any) => {
            if (resp['status'] === "success") {
              swal.fire({
                imageUrl: "assets/modal-info.png",
                imageHeight: 60,
                title: "Cancellation Requested",
                html: '<p>' + 'Cancellation request has been sent to <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            } else {
              swal.fire({
                imageUrl: "assets/modal-error.png",
                imageHeight: 60,
                title: "Cancellation Request Failed",
                html: '<p>' + 'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            }
          });
        }
      });


  }

}
