<div class="container">
  <h1 class="list-heading sp-heading gold center mb-60">Browse by Theme</h1>

  <div class="theme-container row justify-content-center">
    <a (click)="openTheme(tripTypes['Family'])" class="collection col-lg-4 col-md-6">
      <img
        src="https://www.nationalgeographic.com/content/dam/expeditions/destinations/africa/family-journeys/morocco/hero-morocco-fam-journey.adapt.1900.1.jpg"
        alt="" class="img-fluid">
      <div class="collection-title d-flex align-items-center">
        <span>
          National Geographic Family
          Journeys <a class="sp-link arrow-link"> </a>
        </span>
      </div>
    </a>


    <a (click)="openTheme(tripTypes['Signature Land'])" class="collection col-lg-4 col-md-6">
      <img
        src="https://www.nationalgeographic.com/content/dam/expeditions/destinations/africa/land/Southern-Africa-Safari-by-Private-Jet/Hero-southern-africa-by-private-air.ngsversion.1524084726615.adapt.1900.1.jpg"
        alt="" class="img-fluid">
      <div class=" collection-title d-flex align-items-center">
        <span>
          National Geographic Signature
          Land Expeditions <a class="sp-link arrow-link"></a>
        </span>
      </div>
    </a>

    <a (click)="openTheme(tripTypes['Journeys'])" class="collection col-lg-4 col-md-6">
      <img
        src="https://www.nationalgeographic.com/content/dam/expeditions/destinations/north-america/journeys/Discover-American-Canyonlands/discover-american-canyonlands.ngsversion.1526852027732.adapt.1900.1.jpg"
        alt="" class="img-fluid">
      <div class=" collection-title d-flex align-items-center">
        <span>
          National Geographic Journeys <a class="sp-link arrow-link"></a>
        </span>
      </div>
    </a>
  </div>
</div>
