import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment'
import { ApiService } from './../../../services/common/api/api.service';
import { HttpParams } from "@angular/common/http";
import { HotelUtilService } from './../hotel-util/hotel-util.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { hotelType } from './../../hotel.constants';

@Injectable({
  providedIn: 'root'
})
export class HotelApiService {
  stagingUrl: string = environment.v2ApiBs;
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'X-API-Client':  'test_client',
  //     'Content-Type': 'application/json',
  //     'Accept':'*/*',
  //   })
  // }
  constructor(private api: ApiService, private hotelUtilService : HotelUtilService, private http: HttpClient) {}

  getAutocompleteResult(queryString) {
    let params = new HttpParams();
    if(queryString){
      params = params.append('search',queryString);
    }
    if(parseInt(localStorage.getItem('hotelType')) === hotelType.luxurystaycation){
      return this.api.get(this.stagingUrl + "/luxury_staycations/autocomplete", {params : params})
    }else{
      return this.api.get(this.stagingUrl + "/hotels/autocomplete", {params : params})
    }
  }

  getHotelListingPriceAvailability(destinationId, checkInOutStartDate, checkInOutEndDate, rooms){
    let url = this.stagingUrl + "/luxury_staycations/hotel_availability";
    if(parseInt(localStorage.getItem('hotelType')) === hotelType.luxurystaycation){
      url = this.stagingUrl + "/luxury_staycations/hotel_availability";
      if(localStorage.getItem('isCountry') === 'yes'){
        let body = {
          rooms: rooms, //[{"adults": 2, "children": 1}],
          check_in: checkInOutStartDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
          check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
          country_id: destinationId
        }
        return this.api.post(url,body)
      }else{
        let body = {
          rooms: rooms, //[{"adults": 2, "children": 1}],
          check_in: checkInOutStartDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
          check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
          destination_id: destinationId
        }
        return this.http.post(url,body)
      }
    }else{
      url = this.stagingUrl + "/hotels/hotel_availability";
      if(localStorage.getItem('isLocality') === 'yes'){
        let body = {
          rooms: rooms, //[{"adults": 2, "children": 1}],
          check_in: checkInOutStartDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
          check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
          locality_id: destinationId
        }
        return this.http.post(url,body)
      }else{
        let body = {
          rooms: rooms, //[{"adults": 2, "children": 1}],
          check_in: checkInOutStartDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
          check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
          destination_id: destinationId
        }
        return this.http.post(url,body)
      }
    }
  }

  getHotelListDetails(destinationId){
    let url = this.stagingUrl + "/luxury_staycations/hotel_list_details?destination_id="+destinationId;
    if(parseInt(localStorage.getItem('hotelType')) === hotelType.luxurystaycation){
      if(localStorage.getItem('isCountry') === 'yes'){
        url = this.stagingUrl + "/luxury_staycations/hotel_list_details?country_id="+destinationId;
      }else{
        url = this.stagingUrl + "/luxury_staycations/hotel_list_details?destination_id="+destinationId;
      }
    }else{
      if(localStorage.getItem('isLocality') === 'yes'){
        url = this.stagingUrl + "/hotels/hotel_list_details?locality_id="+destinationId;
      }else{
        url = this.stagingUrl + "/hotels/hotel_list_details?destination_id="+destinationId;
      }
    }
    return this.api.get(url)
  }
    
  async getCurrencyExchangeRates(){
    let url = this.stagingUrl + "/exchange_rates";
    const api = await this.http.get(url).toPromise();
    return api;
  }

  getHotelDetails(hotelId : number){
    let url = this.stagingUrl + "/luxury_staycations/hotel_details?hotel_id="+hotelId;
    if(parseInt(localStorage.getItem('hotelType')) === hotelType.luxurystaycation){
      url = this.stagingUrl + "/luxury_staycations/hotel_details?hotel_id="+hotelId;
    }else{
      url = this.stagingUrl + "/hotels/hotel_details?hotel_id="+hotelId;
    }
    return this.api.get(url)
  }

  createBooking(initiationDetails){
    let url = this.stagingUrl + "/luxury_staycations/create_booking";
    let body = {};
    if(parseInt(localStorage.getItem('hotelType')) === hotelType.luxurystaycation){
      url = this.stagingUrl + "/luxury_staycations/create_booking";
      body = {
        rooms: initiationDetails.rooms, //[{"adults": 2, "children": 1}],
        hotel_id: initiationDetails.hotelId,
        check_in: initiationDetails.checkInDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
        check_out: initiationDetails.checkOutDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
        "hotel_currency": localStorage.getItem('hotel_currency'),
        // first_name: 'TEST',//+ initiationDetails.travellerDetailForm.first_name.replace(/^\s+|\s+$/g, ''),
        // last_name: 'TEST',// + initiationDetails.travellerDetailForm.last_name.replace(/^\s+|\s+$/g, ''),
        // customer_country: "IN",
        // email: initiationDetails.travellerDetailForm.email,
        // mobile_no : initiationDetails.travellerDetailForm.mobile
      }
    }else{
      url = this.stagingUrl + "/hotels/create_booking";
      body = {
        rooms: initiationDetails.rooms, //[{"adults": 2, "children": 1}],
        hotel_id: initiationDetails.hotelId,
        check_in: initiationDetails.checkInDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
        check_out: initiationDetails.checkOutDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
        "hotel_currency": "INR",
        // first_name: initiationDetails.travellerDetailForm.primaryTravellerDetails[0].first_name.replace(/^\s+|\s+$/g, ''),
        // last_name: initiationDetails.travellerDetailForm.primaryTravellerDetails[0].last_name.replace(/^\s+|\s+$/g, ''),
        // title : initiationDetails.travellerDetailForm.primaryTravellerDetails[0].title.replace(/^\s+|\s+$/g, ''),
        // mobile_no : initiationDetails.travellerDetailForm.mobile,
        // email: initiationDetails.travellerDetailForm.email,
        total_price : initiationDetails.total_price,
        // provider : initiationDetails.provider
      }
    }
    return this.api.post(url,body)
  }


  getHotelRoomDetails(hotelId : number){
    let url = this.stagingUrl + "/luxury_staycations/hotel_room_details?hotel_id="+hotelId;
    if(parseInt(localStorage.getItem('hotelType')) === hotelType.luxurystaycation){
      url = this.stagingUrl + "/luxury_staycations/hotel_room_details?hotel_id="+hotelId;
    }else{
      url = this.stagingUrl + "/hotels/hotel_room_details?hotel_id="+hotelId;
    }
    return this.api.get(url)
  }

  getHotelRoomsAvailability(hotelId, checkInOutStartDate, checkInOutEndDate, rooms){
    let url = this.stagingUrl + "/luxury_staycations/hotel_room_availability";
    if(parseInt(localStorage.getItem('hotelType')) === hotelType.luxurystaycation){
      url = this.stagingUrl + "/luxury_staycations/hotel_room_availability";
    }else{
      url = this.stagingUrl + "/hotels/hotel_room_availability";
    }
    let body = {
      rooms: rooms, //[{"adults": 2, "children": 1}],
      check_in: checkInOutStartDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
      check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
      hotel_id: hotelId
    }
    return this.api.post(url,body)
  }  

  getHotelTnc(hotelId, roomId, rate){
    console.log(hotelId, roomId, rate, '!!!!!!!!!!!!!!' );
    let url = this.stagingUrl + "/luxury_staycations/room_policy?hotel_id="+hotelId+"&room_id="+roomId+"&rate_id="+rate.type_id;
    if(rate.offer_id){
      url = this.stagingUrl + "/luxury_staycations/room_policy?hotel_id="+hotelId+"&room_id="+roomId+"&offer_id="+rate.offer_id;
    }
    return this.api.get(url)
  }

  initiateBooking(initiationDetails){
    let url = this.stagingUrl + "/luxury_staycations/initiate_booking";
    let body = {};
    if(parseInt(localStorage.getItem('hotelType')) === hotelType.luxurystaycation){
      url = this.stagingUrl + "/luxury_staycations/initiate_booking";
      body = {
        // rooms: initiationDetails.rooms, //[{"adults": 2, "children": 1}],
        hotel_id: initiationDetails.hotelId,
        check_in: initiationDetails.checkInDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
        check_out: initiationDetails.checkOutDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
        "hotel_currency": localStorage.getItem('hotel_currency'),
        first_name: environment.envName == 'prod' ? initiationDetails.travellerDetailForm.first_name.replace(/^\s+|\s+$/g, '') : 'TEST',//+ initiationDetails.travellerDetailForm.first_name.replace(/^\s+|\s+$/g, ''),
        last_name: environment.envName == 'prod' ? initiationDetails.travellerDetailForm.last_name.replace(/^\s+|\s+$/g, '') : 'TEST',// + initiationDetails.travellerDetailForm.last_name.replace(/^\s+|\s+$/g, ''),
        customer_country: "IN",
        email: initiationDetails.travellerDetailForm.email,
        mobile_no : initiationDetails.travellerDetailForm.mobile,
        booking_id : initiationDetails.bookingId
      }
    }else{
      url = this.stagingUrl + "/hotels/initiate_booking";
      body = {
        rooms: initiationDetails.rooms, //[{"adults": 2, "children": 1}],
        // hotel_id: initiationDetails.hotelId,
        // check_in: initiationDetails.checkInDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
        // check_out: initiationDetails.checkOutDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
        // "hotel_currency": "INR",
        first_name: initiationDetails.travellerDetailForm.primaryTravellerDetails[0].first_name.replace(/^\s+|\s+$/g, ''),
        last_name: initiationDetails.travellerDetailForm.primaryTravellerDetails[0].last_name.replace(/^\s+|\s+$/g, ''),
        title : initiationDetails.travellerDetailForm.primaryTravellerDetails[0].title.replace(/^\s+|\s+$/g, ''),
        mobile_no : initiationDetails.travellerDetailForm.mobile,
        email: initiationDetails.travellerDetailForm.email,
        total_price : initiationDetails.total_price,
        provider : initiationDetails.provider,
        booking_id : initiationDetails.bookingId
      }
    }
    return this.api.post(url,body)
  }

  payForFlight(params){
    return this.api
      .post(
        this.stagingUrl + `/payments/create_payment_request`,
        params
      )
      .toPromise();
  }

  convertGbptoInr(){
    return this.getCurrencyExchangeRates()
  }
  
  getHotelBookingDetails(bookingId : string){
    let url = this.stagingUrl + "/users/bookings/booking_details?booking_unique_id="+bookingId;
    // if(parseInt(localStorage.getItem('hotelType')) === hotelType.luxurystaycation){
    //   url = this.stagingUrl + "/luxury_staycations/hotel_room_details?hotel_id="+hotelId;
    // }else{
    //   url = this.stagingUrl + "/hotels/hotel_room_details?hotel_id="+hotelId;
    // }
    return this.api.get(url)
  }

  getHotelBookinghistory(){
    let url = this.stagingUrl +"/bookings?type=HotelBooking";
    return this.api.get(url)
  }

  checkCancellationCharges(bookingId : string){
    let url = this.stagingUrl + '/hotels/cancellation_charges?booking_id='+bookingId;
    return this.api.get(url)
  }

  cancelHotelBooking(bookingId : string){
    let url = this.stagingUrl + '/hotels/cancel_booking';
    let body = {
      "booking_id": bookingId
    }
    return this.api.post(url,body)
  }

  getCardDetails(number){
    let url = this.stagingUrl + '/payments/get_card_details?card_num='+number;
    return this.api.get(url)
  }

  saveUserCard(cardDetails, userMailId){
    let url = this.stagingUrl + "/payments/save_user_card";
    let body = {
      email : userMailId,
      nick_name  : cardDetails.ccname,
      card_mode : cardDetails.card_mode,
      card_type : cardDetails.card_type,
      name_on_card : cardDetails.ccname,
      card_number : cardDetails.ccnum,
      exp_mon : cardDetails.expiry_month,
      exp_yr : cardDetails.expiry_year,
    }
    return this.api.post(url,body)
  }

  deleteUserCard(token){
    let url = this.stagingUrl + "/payments/delete_user_card";
    let body = {
      token : token,
    }
    return this.api.post(url,body)
  }

  // getUserCards(){
  //   let url = this.stagingUrl + '/payments/get_user_cards';
  //   return this.api.get(url)
  // }

  getBookingsById(id) {
    return this.api
      .get(this.stagingUrl + `/bookings/${id}`)
      .toPromise();
  }
  
}
