import { Component, OnInit } from '@angular/core';
import { GiftcardService } from './../../giftcards/service/giftcard.service';
import { Router, NavigationEnd } from "@angular/router";
import { LoginService } from './../../services/login/login.service';
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import { MatDialog } from "@angular/material/dialog";
import swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: 'app-giftcard-booking-history',
  templateUrl: './giftcard-booking-history.component.html',
  styleUrls: ['./giftcard-booking-history.component.scss']
})
export class GiftcardBookingHistoryComponent implements OnInit {
  bookingDetails: any;
  totalQuantity: number = 0;
  selfGift = {
    status: false,
    amount: 0,
    count: 0,
    displayName: null
  };
  guestGift = {
    status: false,
    amount: 0,
    count: 0,
    displayName: null
  };
  progress: boolean = true;
  allGiftcards = [];

  constructor(public giftcardService: GiftcardService, private router: Router, public dialog: MatDialog, public login: LoginService,
    public data: DataService,
  ) {

  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.giftcardService.getAllBookings().then(res => {
            this.bookingDetails = res;
            this.bookingDetails.data.forEach((obj, key) => {
              obj.selfGift = {
                status: false,
                amount: 0,
                displayName: null
              }
              obj.guestGift = {
                status: false,
                amount: 0,
                displayName: null
              }
              this.calcTotalQuantity(obj);
            });
            this.getGiftcards().then((res) => {
              this.progress = false;
            });
          });
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          };
        });
      });


  }

  resendCoupons(bookingId) {
    this.giftcardService.resendGiftcards(bookingId).then(res => {
      console.log(res);
      if (res['status'] === 'success') {
        this.data.triggerToastr('success','Giftcard codes sent to given mobile and email.');
      } else {
        this.data.triggerToastr('error','Unable to resend codes.');
      }
    })
  }
  calcTotalQuantity(cartItemsArr) {
    cartItemsArr.totalQuantity = 0;
    cartItemsArr.attributes.details.attributes.gift_card_orders.forEach(obj => {
      cartItemsArr.totalQuantity = cartItemsArr.totalQuantity + parseInt(obj.attributes.quantity);
      if (obj.attributes.recipient_type == "self") {
        this.selfGift.status = true;
        this.selfGift.count += obj.attributes.quantity;
        this.selfGift.amount = this.selfGift.amount + (parseInt(obj.attributes.denomination) * parseInt(obj.attributes.quantity));
      }
      else {
        this.guestGift.status = true;
        this.guestGift.count += obj.attributes.quantity;
        this.guestGift.amount = this.guestGift.amount + (parseInt(obj.attributes.denomination) * parseInt(obj.attributes.quantity));
      }
    });
  }
  back() {
    this.router.navigate([localStorage.getItem('app-type')]);
  }

  openBookingDetails(bookingId) {
    this.router.navigate([`giftcards`, 'booking-details', bookingId]);
  }

  getGiftcards() {
    return new Promise((resolve) => {
      // this.giftcardService.getGiftcards(1, 500).then((res: any) => {
      let internalSub = setInterval(() => {
        if (!this.giftcardService.progress) {
          this.allGiftcards = this.giftcardService.allGiftcards;
          clearInterval(internalSub);
          resolve('success');
        }
      }, 1000);

      // });
    });
  }

  getImageUrl(cardId) {
    if (this.allGiftcards && this.allGiftcards.length) {
      for (let each of this.allGiftcards) {
        if (each.id == cardId) {
          return each.attributes.image_url;
        }
      }
    }
    return '';
  }

  getMerchantTitle(cardId) {
    if (this.allGiftcards && this.allGiftcards.length) {
      for (let each of this.allGiftcards) {
        if (each.id == cardId) {
          return each.attributes.merchant.name;
        }
      }
    }
    return '';
  }

  getMerchantTitles(order) {
    const titles = [];
    if (order.attributes.details.attributes.gift_card_orders && order.attributes.details.attributes.gift_card_orders.length) {
      for (let each of order.attributes.details.attributes.gift_card_orders) {
        const title = this.getMerchantTitle(each.attributes.gift_card_id);
        if (title && !titles.find((each) => each == title)) {
          titles.push(title);
        }
      }
    }
    return titles.join(', ');
  }

  getCardName(cardId) {
    if (this.allGiftcards && this.allGiftcards.length) {
      for (let each of this.allGiftcards) {
        if (each.id == cardId) {
          return each.attributes.title;
        }
      }
    }
    return '';
  }

  cancelBooking(booking) {
    // let cancellationMessage = '';
    swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this order?',
        // html: cancellationMessage,
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reason...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        preConfirm: (input) => {
          if (!input) {
            swal.showValidationMessage("Please enter reason for cancellation.");
            return false;
          } else {
            return input;
          }
        }
      })
      .then(result => {
        if (result.value) {
          this.progress = true;
          this.giftcardService.cancelBookingRequest(booking.attributes.booking_unique_id, result.value).then((resp: any) => {
            if (resp['status'] === "success") {
              swal.fire({
                imageUrl: "assets/modal-info.png",
                imageHeight: 60,
                title: "Cancellation Requested",
                html: '<p>' + 'Cancellation request has been sent to <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            } else {
              swal.fire({
                imageUrl: "assets/modal-error.png",
                imageHeight: 60,
                title: "Cancellation Request Failed",
                html: '<p>' + 'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            }
          });
        }
      });


  }

}
