import { Component, OnInit } from "@angular/core";
import { PointsTransferService } from "../points-transfer.service";
import { Router } from "@angular/router";
import { DataService } from "src/app/services/common/data/data.service";
import { appConstants } from "src/app/app.constants";

@Component({
  selector: "app-points-transfer-listing-page",
  templateUrl: "./listing-page.component.html",
  styleUrls: ["./listing-page.component.scss"],
})
export class ListingPageComponent implements OnInit {
  public providers: any[];
  public showLoader: boolean = true;
  public appConstants = appConstants;
  public howItWorksData = [
    {
      name:'Pick Your Preferred Partner',
      description:'Select from our curated list of trusted partners to transfer your points.'
    },
    {
      name:'Seamlessly Link Your Account',
      description:'Connect your partner account effortlessly to kickstart the magic.'
    },
    {
      name:'Transfer Your Points',
      description:'Effortlessly move your points to your chosen partner account and unlock endless possibilities!'
    },
  ]
  constructor(
    private ptsService: PointsTransferService,
    private router: Router,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    this.getPointsProviders();
  }

  public getBurnPoints(conversionRatio: number): number {
    return Math.floor(1 / conversionRatio);
  }

  public navigateToProviderDetails(providerId: string): void {
    this.router.navigate(["points-transfer", "details", providerId]);
  }

  private getPointsProviders(): void {
    this.showLoader = true;
    this.ptsService.getProviders().subscribe(
      (res: any) => {
        this.showLoader = false;
        this.providers = res?.data;
      },
      (err: any) => {
        this.showLoader = true;
        this.dataService.triggerToastr(
          "error",
          "Points transfer not available. Please try again later."
        );
      }
    );
  }


}
