import { Component, OnInit } from '@angular/core';
import { GiftingService } from 'src/app/gifting/services/gifting/gifting.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import swal from "sweetalert2";
import * as moment from 'moment';

@Component({
  selector: 'app-gifting-history',
  templateUrl: './gifting-history.component.html',
  styleUrls: ['./gifting-history.component.scss']
})
export class GiftingHistoryComponent implements OnInit {
  bookings = [];
  pageNo: number = 1;
  itemsPerPage = 10;
  progress: boolean = true;

  constructor(
    private giftingService: GiftingService,
    public dialog: MatDialog,
    public login: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.giftingService.getBookings().then(resp => {
            this.bookings = resp['data'];
            this.progress = false;
          })
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          };
        });
      });
  }

  openBookingDetails(bookingId) {
    this.router.navigate(['/booking/gifting'], { queryParams: { 'id': bookingId } });
  }

  moveUp() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  checkIfUpcoming(date) {
    return moment(date).isAfter(moment())
  }

  cancelBooking(booking) {
    let cancellationMessage = '<p>' + '</p>';
    // <span class="icon-gifting mar-right-5 fnt-14"></span>
    swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this order?',
        html: cancellationMessage,
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reason...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        preConfirm: (input) => {
          if (!input) {
            swal.showValidationMessage("Please enter reason for cancellation.");
            return false;
          } else {
            return input;
          }
        }
      })
      .then(result => {
        if (result.value) {
          this.progress = true;
          this.giftingService.cancelBookingRequest(booking.attributes.booking_unique_id, result.value).then((resp: any) => {
            if (resp['status'] === "success") {
              swal.fire({
                imageUrl: "assets/modal-info.png",
                imageHeight: 60,
                title: "Cancellation Requested",
                html: '<p>' + 'Cancellation request has been sent to <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            } else {
              swal.fire({
                imageUrl: "assets/modal-error.png",
                imageHeight: 60,
                title: "Cancellation Request Failed",
                html: '<p>' + 'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            }
          });
        }
      });


  }


}
