import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import {LoginService} from 'src/app/services/login/login.service';
import { DataService } from "src/app/services/common/data/data.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sbic-login',
  templateUrl: './sbic-login.component.html',
  styleUrls: ['./sbic-login.component.scss']
})
export class SbicLoginComponent implements OnInit {
  name = 'Set iframe source';
  url: string = environment.sbiUrl+"/creditcards/app/msw/user-registration?channel=poshvine&hs_token=";
  urlSafe: SafeResourceUrl;
  formBody: SafeHtml;
  progress: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<SbicLoginComponent>,
    public sanitizer: DomSanitizer,
    public loginService: LoginService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.loginService.getToken().subscribe((res: any) =>{
      console.log(res);
      this.progress = false;
      if(res.success){
        localStorage.setItem('token', res.token2);
        // this.data = res.token;
        console.log(this.url+encodeURIComponent(res.token));

        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + encodeURIComponent(res.token));
        this.formBody = this.sanitizer.bypassSecurityTrustHtml(res.form_body);
      }else{
        this.dataService.triggerToastr('success','Something went wrong. Please try again later.')
      }
    })
  }

  closeClicked(){
    this.loginService.sbiValidate().subscribe( (res:any) => {
      console.log(res);
      // location.reload();
      if(res.success){
        this.dataService.triggerToastr('success',"You are logged in.");
        localStorage.setItem('auth_token', res.token);
        this.dialogRef.close('true');
        this.dataService.triggerRefreshHeader();
      }else{
        this.dataService.triggerToastr('error',"Unable to login. Please try again.");
        this.dialogRef.close('redirect');
      }
    })
  }
}
