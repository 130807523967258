import { Component, OnInit } from "@angular/core";
import { NatGeoService } from "src/app/nat-geo/services/nat-geo/nat-geo.service";
import { ActivatedRoute } from "@angular/router";
import { LoginService } from "./../../services/login/login.service";
import { MatDialog } from "@angular/material/dialog";
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import { PaymentHomeService } from "./../../payment/payment-home.service"; // '../payment-home.service'
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: "app-nat-geo-history-details",
  templateUrl: "./nat-geo-history-details.component.html",
  styleUrls: ["./nat-geo-history-details.component.scss"],
})
export class NatGeoHistoryDetailsComponent implements OnInit {
  bookingId;
  booking;
  progress: boolean = true;
  bookingDetails;
  paymentDetails;
  constructor(
    public natGeoS: NatGeoService,
    public route: ActivatedRoute,
    public login: LoginService,
    public dialog: MatDialog,
    public payment: PaymentHomeService,
    public data: DataService,
  ) {}

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.progress = true;
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          // this.bookingId = this.route.snapshot.queryParamMap.get("id");
          this.route.paramMap.subscribe(paramMap => {
            this.bookingId = paramMap.get('booking_id');
          });
          this.payment.getPaymentDetails(this.bookingId).then( (res: any) => {
            this.paymentDetails = res.data;
          });
          this.natGeoS
            .getBookingDetails(this.bookingId)
            .then((booking: any) => {
              // this.booking = booking.data.attributes.booking_data;
              this.booking = booking.data.attributes.details.attributes;
              this.bookingDetails = booking.data;

              this.progress = false;
            });
        } else {
          this.dialog
            .open(SbicLoginComponent, {
              width: "450px",
              height: "70vh",
              disableClose: true
            })
            .afterClosed()
            .subscribe((res) => {
              if (res === "true") {
                this.ngOnInit();
              } else if (res == "redirect") {
                history.back();
              }
            });
        }
      },
      (error)=>{
        this.dialog
        .open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
          disableClose: true
        })
        .afterClosed()
        .subscribe((res) => {
          if (res === "true") {
            this.ngOnInit();
          } else if (res == "redirect") {
            history.back();
          }
        });
      });
  }

  getAmountPaidBy(type) {
    if (this.paymentDetails &&
      this.paymentDetails.attributes &&
      this.paymentDetails.attributes.transactions &&
      this.paymentDetails.attributes.transactions.data &&
      this.paymentDetails.attributes.transactions.data.length) {
      for (const each of this.paymentDetails.attributes.transactions.data) {
        if (each.attributes.txn_type == type) {
          return each.attributes.amount ? each.attributes.amount : 0;
        }
      }
    }
    return 0;
  }

  convertMyWay() {
    let title = document.title;
    document.title = this.bookingId;
    window.print();
    document.title = title;
  }

  resendPaymentLink() {
    this.progress = true;
    this.payment.resendPaymentLink(this.bookingId).subscribe((res) => {
      this.progress = false;
      if (res["status"] == "success") {
        this.data.triggerToastr('success',res["msg"]);
      } else {
        this.data.triggerToastr('error',res["msg"]);
      }
    });
  }

  back() {
    history.back();
  }
}
