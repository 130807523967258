import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-theme-part',
  templateUrl: './home-theme-part.component.html',
  styleUrls: ['./home-theme-part.component.scss']
})
export class HomeThemePartComponent implements OnInit {

  @Input()
  filters;

  tripTypes = {};

  constructor(public router : Router) { }

  ngOnInit() {
    if(this.filters && this.filters.data && this.filters.data.trip_types) {
      const trips = this.filters.data.trip_types;
      if(trips) {
        this.tripTypes = trips.reduce((acc,curr)=>{ return {...acc, [curr['trip_type']] : curr['id']}},{});
      }
    }
  }

  openTheme(theme){
    localStorage.setItem("nat-geo-params",JSON.stringify({
      // destination:"",
      theme: theme,
      // month:"",
      name:"All Destinations",
      page:"home"
    }))
    this.router.navigate(['/nat-geo/listing']);
  }

}
