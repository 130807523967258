import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import { MatDialog } from "@angular/material/dialog";

import { Router } from "@angular/router";
import { LoginService } from "src/app/services/login/login.service";
import { environment } from 'src/environments/environment';
import { DataService } from "../common/data/data.service";

@Injectable({
  providedIn: "root"
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(public dialog: MatDialog, private router: Router, public auth: LoginService, public dataService: DataService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let storage = localStorage;

    if (
      storage.getItem("currentUser")
    ) {
      request = request.clone({
        setHeaders: {
          // uid: storage.getItem("uid"),
          "x-client-id": environment.clientId,
          'X-Project-Id': environment.projectId,
          "Authorization": `Bearer ${storage.getItem("auth_token") ? storage.getItem("auth_token") : ''}`,
          // client: storage.getItem("client"),
          // "X-API-Client": "sbic",
          "X-API-Client-Token": "1234",
          // "Content-Type": "application/json",
          Accept: "*/*"
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          // "X-API-Client": "sbic",
          "x-client-id": environment.clientId,
          'X-Project-Id': environment.projectId,
          "Authorization": `Bearer ${storage.getItem("auth_token") ? storage.getItem("auth_token") : ''}`,
          "X-API-Client-Token": "1234",

          // "X-API-Client-Token": "12345",
          // "Content-Type": "application/json",
          Accept: "*/*"
        }
      });
    }
    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {
          console.log(response);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          if (error["status"] != 200 && error["status"] != 401 && error["status"] != 404 && error["status"] != 422) {
            this.dataService.triggerToastr('error',"Unable to login. Please try again.");
            this.router.navigate(["/"]);
            // swal
            //   .fire({
            //     imageUrl: "assets/modal-error.png",
            //     imageHeight: 60,
            //     title: "Oops...",
            //     text: error.error.msg,
            //     showCancelButton: true,
            //     confirmButtonText: "Retry",
            //     cancelButtonText: "Go Back",
            //     // imageUrl: "assets/info.svg",
            //     imageWidth: 100
            //     // imageHeight: 200
            //   })
            //   .then(result => {
            //     if (result.value) {
            //       location.reload();
            //     } else if (
            //       /* Read more about handling dismissals below */
            //       result.dismiss === swal.DismissReason.cancel
            //     ) {
            //       history.back();
            //     }
            //   });
          } else if (error["status"] == 401) {
            this.auth.logout();
            if (error.url != (environment.v2ApiUrl + '/user')) {
              this.dialog.open(SbicLoginComponent, {
                width: "450px",
                height: "70vh",
                disableClose: true
              });
            }

          }
        }
      )
    );
  }
}
