<div *ngIf="!progress">
  <div class="container mar-bot-20 to-show1">
    <div class="d-none d-sm-block mar-bot-20">
      <div class="mar-bot-3 pointer d-flex align-items-center" (click)="back()">
        <div>
          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM13 5.25L1 5.25V6.75L13 6.75V5.25Z"
              fill="#C2A63F" />
          </svg>
        </div>
        <div class="mar-top-2">
          <span class="mar-left-5 fnt-16" style="color: var(--primary);">
            Back
          </span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="d-none d-sm-block pad-20" style="border-bottom: 1px solid var(--primary);">
        <div class="d-flex justify-content-between align-items-center">
          <div class="flex flex-column  pad-top-10 pad-bot-10">
            <!-- <img src="assets/nat-geo-logo.png"> -->
            <div>
              <span class="ft-wt-400 fnt-18">{{booking.nat_geo_trip.title}}</span>
            </div>
            <!-- <span class="pad-left-8 pad-right-8">|</span> -->
            <div>
              <!-- <span>From <span class="rupee-symbol">₹</span> {{booking.nat_geo_trip.inr_price_category.split(' -')[0]| indianCurrency}} </span> -->
              <!-- <span class="pad-left-8 pad-right-8">|</span> -->
              <span class="fnt-16 f300">{{booking.nat_geo_trip.duration}} days</span>
            </div>
          </div>
          <div>
            <app-status-label class="status-text"
            [status]="bookingDetails.attributes.status">
            </app-status-label>
            <!-- <span class="enquiry-status">{{bookingDetails.attributes.status | removeUnderscore}}</span> -->
          </div>
        </div>


        <div class="pad-bot-28 flex justify-content-between">
          <img height="110" width="200" default="./assets/default_image.png" src="{{booking.nat_geo_trip.lead_image_url}}">
          <div *ngIf="bookingDetails.attributes.status == 'booking_confirmed'" class="print-button"
            (click)="convertMyWay()"> <button class="btn fnt-16" style="color: var(--accenttwo);">
              <span class="icon-print"></span>
              Print </button>
          </div>
        </div>
      </div>
      <div class="pad-top-10 pad-bot-10 bg d-block d-sm-none">
        <section class="align-items-center d-flex justify-content-center pad-bot-10 top-bar w100">
          <div class="absolute back-arr" (click)="back()">
            <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.469669 5.46967ZM17 5.25L1 5.25V6.75L17 6.75V5.25Z"
                fill="#C2A63F" />
            </svg>
          </div>
          <div class="f500 fnt-16 gold">Booking Details</div>
        </section>
        <hr>
        <div class="pad-bot-10 flex align-center" style="border-bottom: 1px solid var(--primary);">
          <div class="col-8 pad-top-5">
            <div class="f500 fnt-16 pad-bot-5">{{booking.nat_geo_trip.title}}</div>
            <div class="fnt-12">
              <span>{{booking.nat_geo_trip.duration}} days </span>
              <span>| Starting from <span class="rupee-symbol">₹</span>
                {{booking.nat_geo_trip.inr_price_category.split(' -')[0]| indianCurrency}}</span>
            </div>
          </div>
          <div class="col-4">
            <app-status-label class="status-text flex" [status]="bookingDetails.attributes.status"></app-status-label>
          </div>
        </div>
      </div>

      <div class="booking-confirmed-container">
        <!-- <div class="booking-top-bar flex bg">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 0C8.064 0 0 8.064 0 18C0 27.936 8.064 36 18 36C27.936 36 36 27.936 36 18C36 8.064 27.936 0 18 0ZM14.4 27L5.4 18L7.938 15.462L14.4 21.906L28.062 8.244L30.6 10.8L14.4 27Z"
              fill="#27AE60" />
          </svg>

          <div class="pad-left-8">
            <div class="fnt-18 ft-wt-400">We have received your Enquiry</div>
            <div class="fnt-16">Thankyou for your enquiry. We will get back to you
              through Email and Phone soon.</div>
          </div>
        </div> -->
        <div>

          <div class="trip-details-bar flex justify-content-between pad-top-10 pad-bot-10 pad-left-20 pad-right-20">
            <span class="fnt-16 d-none d-sm-block"> Booking Details</span>
            <span class="fnt-16">
              <div class="d-flex flex-column">
                <span>
                  Booking ID: <span class="ft-wt-400">{{bookingDetails.attributes.unique_id}}</span>
                </span>
                <span class="fnt-12 accenttwo">Booked at: {{bookingDetails.attributes?.confirmed_at | date: 'dd MMM yyyy, hh:mm a'}}</span>
              </div>

            </span>

            <span *ngIf="bookingDetails.attributes.status == 'booking_confirmed'" class="print-button d-sm-none"
              (click)="convertMyWay()"> <span class="fnt-16">
                <span class="icon-print"></span>
                Print </span>
            </span>
          </div>
          <!-- <hr> -->
          <div class="trip-details-body">
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Date</span><span class="fnt-16">{{booking.depart_date}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Group Size</span><span class="fnt-16">{{booking.group_size}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Activity Type</span><span class="fnt-16">{{booking.trip_label}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Activity Level</span><span class="fnt-16">{{booking.nat_geo_trip.activity_level}}</span>
            </div>
          </div>
          <div *ngIf="bookingDetails.attributes.status == 'payment_link_sent'"
            class="bg pad-top-10 pad-bot-10 pad-left-20 pad-right-20 fnt-16" style="border-radius: 0 0 8px 8px;">
            <span class="color-grey">
              Payment link has been sent to you, check you email {{booking.user_pack.email}} or your mobile {{booking.user_pack.mobile}}
            </span>
            <br>
            <span>or</span>
            <br>
            <span class="bluify pointer" (click)="resendPaymentLink()">Resend payment link</span>
          </div>
          <div class="bg fare-breakup flex align-center pad-left-15 fnt-16" *ngIf="bookingDetails.attributes.status == 'booking_confirmed'">Fare Breakup</div>

          <div class="trip-details-body" *ngIf="bookingDetails.attributes.status == 'booking_confirmed'">
            <div class="sidebox">
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Base Fare</span><span class="fnt-16"><span class="rupee-symbol mar-right-3">₹</span>{{booking?.fare_breakup?.base| indianCurrency}}</span>
              </div>
              <hr>
              <div class="pad-top-15">
                <div class="desktop-labels mar-bot-8 font-16 d-flex align-items-center justify-content-between">
                  <div>
                    Tax
                  </div>
                  <div class="ft-wt-400 font-14">
                    <span class="rupee-symbol mar-right-3">+ ₹</span><span>{{booking?.fare_breakup?.taxes| indianCurrency}}</span>
                  </div>
                </div>
              </div>
              <hr>

              <div class="pad-top-15">
                <div class="desktop-labels mar-bot-8 font-16 d-flex align-items-center justify-content-between">
                  <div>
                    Convenience Fees<br><span class="fnt-12">(inclusive of GST)</span>
                  </div>
                  <div class="ft-wt-400 font-14">
                    <span
                      class="rupee-symbol mar-right-3">₹</span><span>{{booking?.fare_breakup?.convenience_fee| indianCurrency}}</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="pad-top-15">
                <div class="desktop-labels mar-bot-8 font-16 d-flex align-items-center justify-content-between">
                  <div class="ft-wt-500">
                    Total Amount
                  </div>
                  <div class="ft-wt-500 font-16">
                    <span class="rupee-symbol mar-right-3">₹</span><span>{{booking?.fare_breakup?.price| indianCurrency}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="bg fare-breakup flex align-center pad-left-15 fnt-16" *ngIf="bookingDetails.attributes.status == 'booking_confirmed'">Payment Breakup</div>

          <div class="trip-details-body" *ngIf="bookingDetails.attributes.status == 'booking_confirmed'">
            <div class="sidebox">
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Amount Paid By Card</span>
                <span class="fnt-16">
                  <ng-container *ngIf="getAmountPaidBy('payment_gateway')">
                    <span class="rupee-symbol mar-right-2">₹</span>
                    <span>
                        {{getAmountPaidBy('payment_gateway') | indianCurrency}}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="!getAmountPaidBy('payment_gateway')">
                      NIL
                  </ng-container>
                </span>
              </div>
              <hr>
              <div class="pad-top-15">
                <div class="desktop-labels mar-bot-8 font-16 d-flex align-items-center justify-content-between">
                  <div>
                    Amount Paid by Reward Points
                  </div>
                  <div class="ft-wt-400 font-14">
                   <span>
                    <ng-container *ngIf="getAmountPaidBy('points')">
                      <span class="rupee-symbol mar-right-2">₹</span>
                      <span>
                          {{getAmountPaidBy('points') | indianCurrency}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="!getAmountPaidBy('points')">
                        NIL
                    </ng-container>
                   </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div  class="container mar-bot-20 to-hide1">
    <div style="border:1px solid var(--primary); border-radius:8px">
      <div class="checkout-message-top d-flex align-items-center justify-content-between header-bg mar-bot-25">
        <img class="img-fluid rewards-hub-header-logo pad-right-8" src="assets/sbic-images/logo.png" style="width: 200px;">
      </div>
      <div class="pad-left-15 pad-right-15">

        <div class="d-none d-sm-block">

          <div class="d-flex justify-content-between align-items-center">
            <div class="flex flex-column  pad-top-10 pad-bot-10">
              <!-- <img src="assets/nat-geo-logo.png"> -->
              <div>
                <span class="ft-wt-400 fnt-18">{{booking.nat_geo_trip.title}}</span>
              </div>
              <!-- <span class="pad-left-8 pad-right-8">|</span> -->
              <div>
                <!-- <span>From <span class="rupee-symbol">₹</span> {{booking.nat_geo_trip.inr_price_category.split(' -')[0]| indianCurrency}} </span> -->
                <!-- <span class="pad-left-8 pad-right-8">|</span> -->
                <span class="fnt-16">{{booking.nat_geo_trip.duration}} days</span>
              </div>
            </div>
            <div>
              <app-status-label class="status-text"
                [status]="bookingDetails.attributes.status">
              </app-status-label>
              <!-- <span class="enquiry-status">{{bookingDetails.attributes.status | removeUnderscore}}</span> -->
            </div>
          </div>


          <div class="pad-bot-28 flex justify-content-between">
            <img height="110" width="200" default="./assets/default_image.png" src="{{booking.nat_geo_trip.lead_image_url}}">

          </div>
        </div>
        <div class="pad-top-10 pad-bot-10 bg d-block d-sm-none">
          <div class=" flex align-center">
            <section class="align-items-center d-flex justify-content-center pad-bot-10 top-bar w100">
              <div class="absolute back-arr" (click)="back()">
                <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.469669 5.46967ZM17 5.25L1 5.25V6.75L17 6.75V5.25Z"
                    fill="#C2A63F" />
                </svg>
              </div>
              <div class="f500 fnt-16 gold">Booking Details</div>
            </section>
            <hr>
            <div class="col-8 pad-top-5">
              <div class="f500 fnt-16 pad-bot-5">{{booking.nat_geo_trip.title}}</div>
              <div class="fnt-12">
                <span>{{booking.nat_geo_trip.duration}} days </span>
                <span>| Starting from <span class="rupee-symbol">₹</span>
                  {{booking.nat_geo_trip.inr_price_category.split(' -')[0]| indianCurrency| indianCurrency}}</span>
              </div>
            </div>
            <div class="col-4">
              <app-status-label class="status-text flex" [status]="bookingDetails.attributes.status"></app-status-label>
            </div>
          </div>
        </div>

        <div class="booking-confirmed-container">
          <!-- <div class="booking-top-bar flex bg">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 0C8.064 0 0 8.064 0 18C0 27.936 8.064 36 18 36C27.936 36 36 27.936 36 18C36 8.064 27.936 0 18 0ZM14.4 27L5.4 18L7.938 15.462L14.4 21.906L28.062 8.244L30.6 10.8L14.4 27Z"
              fill="#27AE60" />
          </svg>

          <div class="pad-left-8">
            <div class="fnt-18 ft-wt-400">We have received your Enquiry</div>
            <div class="fnt-16">Thankyou for your enquiry. We will get back to you
              through Email and Phone soon.</div>
          </div>
        </div> -->
          <div>

            <div class="trip-details-bar flex justify-content-between pad-top-10 pad-bot-10 pad-left-20 pad-right-20">
              <span class="fnt-16 d-none d-sm-block"> Booking Details</span>
              <span class="fnt-16">
                <div class="d-flex flex-column">
                  <span>
                    Booking ID: <span class="ft-wt-400">{{bookingDetails.attributes.unique_id}}</span>
                  </span>
                  <span class="fnt-12 accenttwo">Booked at: {{bookingDetails.attributes?.confirmed_at | date: 'dd MMM yyyy, hh:mm a'}}</span>
                </div>
              </span>

           
          </div>
          <!-- <hr> -->
          <div class="trip-details-body">
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Date</span><span class="fnt-16">{{booking.depart_date}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Group Size</span><span class="fnt-16">{{booking.group_size}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Activity Type</span><span class="fnt-16">{{booking.trip_label}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Activity Level</span><span class="fnt-16">{{booking.nat_geo_trip.activity_level}}</span>
            </div>
          </div>
          <div *ngIf="bookingDetails.attributes.status == 'payment_link_sent'"
            class="bg pad-top-10 pad-bot-10 pad-left-20 pad-right-20 fnt-16" style="border-radius: 0 0 8px 8px;">
            <span class="color-grey">
              Payment link has been sent to you, check you email {{booking.user_pack.email}} or your mobile {{booking.user_pack.mobile}}
            </span>
            <br>
            <span>or</span>
            <br>
            <span class="bluify pointer" (click)="resendPaymentLink()">Resend payment link</span>
          </div>
          <div class="bg fare-breakup flex align-center pad-left-15 fnt-16" *ngIf="bookingDetails.attributes.status == 'booking_confirmed'">Fare Breakup</div>
          <div class="trip-details-body" *ngIf="bookingDetails.attributes.status == 'booking_confirmed'">
            <div class="sidebox">
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Base Fare</span><span class="fnt-16"><span class="rupee-symbol mar-right-3">₹</span>{{booking?.fare_breakup?.base| indianCurrency}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Group Size</span><span class="fnt-16">{{booking.group_size}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Activity Type</span><span class="fnt-16">{{booking.trip_label}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Activity Level</span><span class="fnt-16">{{booking.nat_geo_trip.activity_level}}</span>
              </div>
            </div>
            <div *ngIf="bookingDetails.attributes.status == 'payment_link_sent'"
              class="bg pad-top-10 pad-bot-10 pad-left-20 pad-right-20 fnt-16" style="border-radius: 0 0 8px 8px;">
              <span class="color-grey">
                Payment link has been sent to you, check you email {{booking.user_pack.email}} or your mobile {{booking.user_pack.mobile}}
              </span>
              <br>
              <span>or</span>
              <br>
              <span class="bluify pointer" (click)="resendPaymentLink()">Resend payment link</span>
            </div>
          </div>
          <div class="bg fare-breakup flex align-center pad-left-15 fnt-16">Payment Breakup</div>

          <div class="trip-details-body" *ngIf="bookingDetails.attributes.status == 'booking_confirmed'">
            <div class="sidebox">
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Amount Paid by Card</span>
                <span class="fnt-16">
                  <ng-container *ngIf="getAmountPaidBy('payment_gateway')">
                    <span class="rupee-symbol mar-right-2">₹</span>
                    <span>
                        {{getAmountPaidBy('payment_gateway') | indianCurrency}}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="!getAmountPaidBy('payment_gateway')">
                      NIL
                  </ng-container>
                </span>
              </div>
              <hr>
              <div class="pad-top-15">
                <div class="desktop-labels mar-bot-8 font-16 d-flex align-items-center justify-content-between">
                  <div>
                    Amount Paid by Reward Points
                  </div>
                  <div class="ft-wt-400 font-14">
                    <span>
                      <ng-container *ngIf="getAmountPaidBy('points')">
                        <span class="rupee-symbol mar-right-2">₹</span>
                        <span>
                            {{getAmountPaidBy('points') | indianCurrency}}
                        </span>
                      </ng-container>
                      <ng-container *ngIf="!getAmountPaidBy('points')">
                          NIL
                      </ng-container>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</div>
<app-loader *ngIf="progress"></app-loader>