import { Component, OnInit } from '@angular/core';
import { GiftingService } from 'src/app/gifting/services/gifting/gifting.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import { DocsappService } from 'src/app/docsapp/services/docsapp/docsapp.service';

@Component({
  selector: 'app-docsapp-history',
  templateUrl: './docsapp-history.component.html',
  styleUrls: ['./docsapp-history.component.scss']
})
export class DocsappHistoryComponent implements OnInit {
  bookings = [];
  pageNo: number = 1;
  itemsPerPage = 10;
  progress: boolean = true;

  constructor(
    private docsappService: DocsappService,
    public dialog: MatDialog,
    public login: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.docsappService.getBookings().then(resp => {
            this.bookings = resp['data'];
            this.progress = false;
          })
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      });
  }

  openBookingDetails(bookingId) {
    this.router.navigate(['/booking/doctor-consultation'], { queryParams: { 'id': bookingId } });
  }

  moveUp() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

}
