<div class="container mt-5 pt-5">
    <div class="row justify-content-center align-items-center mt-5 pt-5">
        <div class="col-12 text-center align-content-center justify-content-center d-flex">
            <div class="text-center">
                <img src="/assets/error.svg" class="img-fluid my-4">
                <h4 class="text-danger">Oops! Booking Failed </h4>
                <p class="text-muted my-3">The Booking couldn’t be made. Please retry.</p>
                <hr>
                <div class="text-left">
                    <p class="text-muted my-2">For any further queries</p>
                    <div class="row mt-3 mb-1">
                        <div class="col-5">
                            <p class="fnt-12"><span class="icon-call mr-1"></span> 1800 212 7078 </p>
                        </div>
                        <div class="col-7 text-right">
                            <p class="fnt-12"><span class="icon-mail mr-1"></span> aurumrewards@poshvine.com </p>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="text-left mt-3">
                    <a href="/" class="btn-link fnt-14">
                         Go to Home </a>
                </div>

            </div>
        </div>
    </div>
</div>