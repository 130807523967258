<div class="page container" *ngIf="!progress">
    <div class="to-show1">
        <div class="d-none d-sm-block">
            <div class="blueify mar-bot-10 d-flex justify-content-start align-items-center pointer">
                <div class="mar-top-4" (click)="back()">
                    <span class="icon-left-arrow"></span>
                </div>
                <div class="mar-left-10" (click)="back()">
                    Back
                </div>
            </div>
            <div class="checkout-message-top d-flex align-items-center justify-content-between mar-bot-15">
                <div class="w100">
                    <div class="d-none d-sm-block booking-id-detail">
                        <div class="d-flex justify-content-between">
                            <div class="to-name">
                                {{bookingData.attributes.booking_data.user_pack.name | titlecase}}
                            </div>
                            <div>
                                <app-status-label class="status-text" [status]="bookingData.attributes.status"
                                    [date]="bookingData.attributes.booking_data.booking_for=='consultation' ? bookingData.attributes.created_at:''">
                                </app-status-label>
                                <div class="print-button d-none d-sm-block pointer ml-auto" (click)="convertMyWay()">
                                    <div class="fnt-14" style="float: right;">
                                        <span class="icon-print"></span>
                                        Print </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="d-block d-sm-none">
            <section class="top-bar w100 d-flex align-items-center justify-content-between bg">
                <div class="d-flex align-items-center">
                    <div class="back-arr mar-right-20" (click)="back()">
                        <span class="icon-left-arrow" style="vertical-align: -7px;"></span>
                    </div>

                    <div class="to-name">
                        {{bookingData.attributes.booking_data.user_pack.name | titlecase}}
                    </div>
                </div>
                <div>
                    <app-status-label class="status-text" [status]="bookingData.attributes.status"
                        [date]="bookingData.attributes.booking_data.booking_for=='consultation' ? bookingData.attributes.created_at:''">
                    </app-status-label>
                </div>
            </section>

            <hr>

            <div class="booking-details-bck d-flex justify-content-between align-items-center"
                style="background: var(--background);">
                <div>
                    <span class="booking-id">
                        Order ID :
                    </span>
                    <span class="booking-id-txt">
                        {{bookingData?.attributes.booking_unique_id}}
                    </span>
                </div>
            </div>
            <hr>

        </div>
        <div class="booking-details-card booking-confirmed-container">
            <div class="d-block d-sm-none">
                <div class="d-flex align-items-center justify-content-between booking-details-bck">
                    <div class="booking-details-txt">
                        Details
                    </div>
                </div>
            </div>
            <div class="d-none d-sm-block">
                <div class="d-flex align-items-center justify-content-between booking-details-bck">
                    <div>
                        <span class="booking-id">
                            Details
                        </span>
                    </div>
                    <div>
                        <span class="booking-id">
                            Order ID:
                        </span>
                        <span class="booking-id-txt">
                            {{bookingData.attributes.booking_unique_id}}
                        </span>
                    </div>

                </div>
            </div>


            <!-- responsive -->

            <hr>

            <div class="detail each-strip">
                <!-- <div class="d-flex align-items-center justify-content-between field mar-bot-10">
                    <div class="name">Name</div>
                    <div class="value">
                        {{bookingData.attributes.booking_data.user_pack.name | titlecase}}
                    </div>
                </div>
                <hr> -->

                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Email Address</div>
                    <div class="value">
                        {{bookingData.attributes.booking_data.user_pack.email}}
                    </div>
                </div>
                <hr>
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Mobile Number</div>
                    <div class="value">
                        {{bookingData.attributes.booking_data.user_pack.mobile}}
                    </div>
                </div>
                <hr>
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Gender</div>
                    <div class="value">
                        {{bookingData.attributes.booking_data.user_pack.gender | titlecase}}
                    </div>
                </div>
                <hr>
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Date of birth</div>
                    <div class="value">
                        {{bookingData.attributes.booking_data.user_pack.date_of_birth | date: 'MMM dd, yyyy'}}
                    </div>
                </div>
                <hr>
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Order For</div>
                    <div class="value">
                        {{bookingData.attributes.booking_data.booking_for | titlecase}}
                    </div>
                </div>

            </div>

            <ng-container *ngIf="bookingData.attributes.booking_data.booking_for == 'membership'">
                <hr>

                <div class="pay-header each-strip">Fare Breakup</div>

                <hr>

                <div class="payment-detail each-strip">


                    <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                        <div class="name">Amount Paid</div>
                        <div class="value"><span class="rupee-symbol">₹</span>
                            {{bookingData.attributes.amount| indianCurrency}}</div>
                    </div>


                </div>
                <hr>

                <div class="pay-header each-strip">Payment Breakup</div>

                <hr>

                <div class="payment-detail each-strip">
                    <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                        <div class="name"><span>Amount paid by card
                                {{bookingData?.attributes?.card_number | creditCardMask}}</span><img *ngIf="cardIcon"
                                height="16" src="{{cardIcon}}"></div>
                        <div class="value"><span class="rupee-symbol">₹</span>
                            {{bookingData.attributes.amount| indianCurrency}}</div>
                    </div>

                    <hr>

                    <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10">
                        <div class="name">Amount paid by Reward Points</div>
                        <div class="value">NIL</div>
                    </div>



                </div>
            </ng-container>

        </div>
    </div>



    <div class="booking-confirmed-container to-hide1" style="border: 1px solid var(--primary);border-radius: 10px;">
        <div class="checkout-message-top each-strip d-flex align-items-center justify-content-between header-bg">
            <img class="img-fluid rewards-hub-header-logo pad-right-8" src="assets/sbic-images/logo.png" style="width: 200px;">
        </div>
        <div class="checkout-message-top each-strip d-flex align-items-center justify-content-between">
            <div class="d-flex w-100">
                <div>
                    <img src="assets/large-green-tick.png" alt="tick">
                </div>
                <div class="mar-left-20">
                    <div class="congrats-text">Your order has been placed successfully!</div>
                    <div class="email-text">Please check your email and mobile number for order confirmation and
                        detailed delivery information.
                    </div>
                </div>
                <div class="d-none d-sm-block booking-id-detail ml-auto text-right">
                    <div>
                        Order ID : <span class="id">{{bookingData?.attributes.booking_unique_id}}</span>
                    </div>
                </div>

            </div>

        </div>

        <hr>

        <div class="pay-header each-strip">Details</div>

        <hr>

        <div class="d-block d-sm-none mob-booking-id each-strip">
            Order ID : <span class="id">{{bookingData?.attributes.booking_unique_id}}</span>
        </div>

        <hr class="d-block d-sm-none">

        <div class="detail each-strip">
            <div class="d-flex align-items-center justify-content-between field mar-bot-10">
                <div class="name">Name</div>
                <div class="value">
                    {{bookingData.attributes.booking_data.user_pack.name | titlecase}}
                </div>
            </div>
            <hr>
            <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                <div class="name">Email Address</div>
                <div class="value">
                    {{bookingData.attributes.booking_data.user_pack.email}}
                </div>
            </div>
            <hr>
            <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                <div class="name">Mobile Number</div>
                <div class="value">
                    {{bookingData.attributes.booking_data.user_pack.mobile}}
                </div>
            </div>
            <hr>
            <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                <div class="name">Gender</div>
                <div class="value">
                    {{bookingData.attributes.booking_data.user_pack.gender | titlecase}}
                </div>
            </div>
            <hr>
            <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                <div class="name">Date of birth</div>
                <div class="value">
                    {{bookingData.attributes.booking_data.user_pack.date_of_birth | date: 'MMM dd, yyyy'}}
                </div>
            </div>
            <hr>
            <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                <div class="name">Order For</div>
                <div class="value">
                    {{bookingData.attributes.booking_data.booking_for | titlecase}}
                </div>
            </div>

        </div>
        <ng-container *ngIf="bookingData.attributes.booking_data.booking_for == 'membership'">

            <hr>

            <div class="pay-header each-strip">Fare Breakup</div>

            <hr>

            <div class="payment-detail each-strip">


                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name">Amount Paid</div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.amount| indianCurrency}}</div>
                </div>


            </div>

            <hr>

            <div class="pay-header each-strip">Payment Breakup</div>

            <hr>

            <div class="payment-detail each-strip">
                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name"><span>Amount paid by card
                            {{bookingData?.attributes?.card_number | creditCardMask}}</span><img *ngIf="cardIcon"
                            height="16" src="{{cardIcon}}"></div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.amount| indianCurrency}}</div>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10">
                    <div class="name">Amount paid by Reward Points</div>
                    <div class="value">NIL</div>
                </div>



            </div>


        </ng-container>


    </div>

</div>

<app-loader *ngIf="progress"></app-loader>