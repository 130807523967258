import { appConstants } from "src/app/app.constants";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { PointsTransferService } from "./../points-transfer.service";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-tours-experiences-modal",
  templateUrl: "./tours-experiences.component.html",
  styleUrls: ["./tours-experiences.component.scss"],
})
export class ToursExperienceComponent {

  public appConstants = appConstants;
  public errorMsg = "";

  constructor(
    private activeModal: NgbActiveModal,
    public router: Router
  ) {
  }
  public navigate(navLink: string): void {
    this.activeModal.dismiss();
    this.router.navigate([navLink])
  }
}
