<div class="page container" *ngIf="!progress">
    <div class="to-show1">
        <div class="d-none d-lg-block">
            <div class="blueify mar-bot-10 d-flex justify-content-start align-items-center pointer">
                <div class="mar-top-4" (click)="back()">
                    <span class="icon-left-arrow"></span>
                </div>
                <div class="mar-left-10" (click)="back()">
                    Back
                </div>
            </div>
            <div class="checkout-message-top d-flex align-items-center justify-content-between mar-bot-15">
                <div class="w100">
                    <div class="d-none d-lg-block booking-id-detail">
                        <div class="d-flex justify-content-between">
                            <div class="to-name">
                                {{'To, ' + bookingData.attributes.booking_data.data[0].attributes.display_first_name + ' ' + bookingData.attributes.booking_data.data[0].attributes.display_last_name}}
                            </div>
                            <div>
                                <app-status-label class="status-text" [status]="bookingData.attributes.status"
                                    [date]="bookingData.attributes.booking_data.data[0].attributes.delivery_date">
                                </app-status-label>
                                <div class="print-button d-none d-lg-block pointer ml-auto" (click)="convertMyWay()">
                                    <div class="fnt-14" style="float: right;">
                                        <span class="icon-print"></span>
                                        Print </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap each-strip product-container ">
                        <div class="col-12 col-lg-6 d-flex align-items-center mar-bot-20 each-product"
                            *ngFor="let each of bookingData.attributes.booking_data.data">
                            <div class="product-image">
                                <img loading="lazy" default="assets/gifting/default-image.png"
                                    [src]="each.attributes.images.image_link_1" alt="">
                            </div>
                            <div class="product-details ">
                                <div class="product-name">{{each.attributes.product_name}}</div>
                                <div class="product-amount"><span class="rupee-symbol mar-right-2">₹</span>
                                    {{each.attributes.amount | indianCurrency}}</div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="d-block d-lg-none">
            <section class="top-bar w100 d-flex align-items-center justify-content-between bg">
                <div class="d-flex align-items-center">
                    <div class="back-arr mar-right-20" (click)="back()">
                        <span class="icon-left-arrow" style="vertical-align: -7px;"></span>
                    </div>

                    <div class="to-name">
                        {{'To, ' + bookingData.attributes.booking_data.data[0].attributes.display_first_name + ' ' + bookingData.attributes.booking_data.data[0].attributes.display_last_name}}
                    </div>
                </div>
                <div>
                    <app-status-label class="status-text" [status]="bookingData.attributes.status"
                        [date]="bookingData.attributes.booking_data.data[0].attributes.delivery_date">
                    </app-status-label>
                </div>
            </section>

            <hr>

            <div class="booking-details-bck d-flex justify-content-between align-items-center"
                style="background: var(--background);">
                <div>
                    <span class="booking-id">
                        Order ID :
                    </span>
                    <span class="booking-id-txt">
                        {{bookingData?.attributes.unique_id}}
                    </span>
                </div>
            </div>
            <hr>

            <div class="d-flex flex-wrap each-strip product-container ">
                <div class="col-12 col-lg-6 d-flex align-items-center mar-bot-20 each-product"
                    *ngFor="let each of bookingData.attributes.booking_data.data">
                    <div class="product-image">
                        <img loading="lazy" default="assets/gifting/default-image.png"
                            [src]="each.attributes.images.image_link_1" alt="">
                    </div>
                    <div class="product-details ">
                        <div class="product-name">{{each.attributes.product_name}}</div>
                        <div class="product-amount"><span class="rupee-symbol mar-right-2">₹</span>
                            {{each.attributes.amount | indianCurrency}}</div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        <div class="booking-details-card booking-confirmed-container">
            <div class="d-block d-lg-none">
                <div class="d-flex align-items-center justify-content-between booking-details-bck">
                    <div class="booking-details-txt">
                        Order Details
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-block">
                <div class="d-flex align-items-center justify-content-between booking-details-bck">
                    <div>
                        <span class="booking-id">
                            Delivery Details
                        </span>
                    </div>
                    <div>
                        <span class="booking-id">
                            Order ID:
                        </span>
                        <span class="booking-id-txt">
                            {{bookingData.attributes.booking_unique_id}}
                        </span>
                    </div>

                </div>
            </div>


            <!-- responsive -->

            <hr>

            <div class="detail each-strip">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10">
                    <div class="name">Recepient's Name</div>
                    <div class="value">
                        {{bookingData.attributes.booking_data.data[0].attributes.display_first_name + ' ' + bookingData.attributes.booking_data.data[0].attributes.display_last_name}}
                    </div>
                </div>
                <hr>
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Delivery Date & Time</div>
                    <div class="value">
                        {{bookingData.attributes.booking_data.data[0].attributes.delivery_date | date: 'MMM dd, yyyy'}}
                        <span>|</span>
                        {{bookingData.attributes.booking_data.data[0].attributes.delivery_time}}
                    </div>
                </div>
                <hr>
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Delivery Address</div>
                    <div class="value">{{getAddress()}}</div>
                </div>
                <hr>
                <div class="field mar-top-10">
                    <div class="name">Gift’s Message</div>
                    <div class="sub-detail">{{bookingData.attributes.booking_data.data[0].attributes.gift_message}}
                    </div>
                </div>
            </div>

            <hr>

            <div class="pay-header each-strip">Fare Breakup</div>

            <hr>

            <div class="payment-detail each-strip">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Total Product Price</div>
                    <div class="value">
                        <span class="rupee-symbol">₹</span>
                        {{getTotalProductsPrice() | indianCurrency}}</div>
                </div>
                <hr>

                <ng-container *ngIf="bookingData.attributes.booking_data.data[0].attributes.shipping_fee">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Shipping</div>
                        <div class="value">
                            <span class="mar-right-2">+</span>
                            <span class="rupee-symbol">₹</span>
                            {{bookingData.attributes.booking_data.data[0].attributes.shipping_fee| indianCurrency}}
                        </div>
                    </div>
                    <hr>
                </ng-container>


                <ng-container *ngIf="bookingData?.fare_breakup?.convenience_fee">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Convenience Fee</div>
                        <div class="value">
                            <span class="mar-right-2">+</span>
                            <span class="rupee-symbol">₹</span>
                            {{bookingData.fare_breakup.convenience_fee| indianCurrency}}</div>
                    </div>
                    <hr>
                </ng-container>


                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name">Total Amount</div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.gross_amount| indianCurrency}}</div>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10 discount"
                    *ngIf="bookingData.attributes.gross_amount-bookingData.attributes.amount>0">
                    <div class="name">Discount</div>
                    <div class="value">
                        <span class="mar-right-2">-</span>
                        <span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.gross_amount-bookingData.attributes.amount| indianCurrency}}</div>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name">Amount Paid</div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.amount| indianCurrency}}</div>
                </div>


            </div>

            <hr>

            <div class="pay-header each-strip">Payment Breakup</div>

            <hr>

            <div class="payment-detail each-strip">
                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name"><span>Amount paid by card
                            {{bookingData?.attributes?.card_number | creditCardMask}}</span><img *ngIf="cardIcon"
                            height="16" src="{{cardIcon}}"></div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.amount| indianCurrency}}</div>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10">
                    <div class="name">Amount paid by Reward Points</div>
                    <div class="value">NIL</div>
                </div>



            </div>

            <ng-container *ngIf="bookingData.attributes.status=='booking_confirmed' && checkIfUpcoming()">
                <hr>

                <div class="pay-header each-strip">Cancellation</div>

                <hr>

                <div class="each-strip booking-details cancel-strip"
                    *ngIf="bookingData.attributes.status=='booking_confirmed' && checkIfUpcoming()">
                    <button mat-button class="primary-btn cancellation-button" (click)="cancelBooking()">Request
                        Cancellation
                    </button>
                </div>
            </ng-container>

        </div>
    </div>



    <div class="booking-confirmed-container to-hide1" style="border: 1px solid var(--primary);border-radius: 10px;">
        <div class="checkout-message-top each-strip d-flex align-items-center justify-content-between header-bg">
            <img class="img-fluid rewards-hub-header-logo pad-right-8" src="assets/sbic-images/logo.png"
                style="width: 200px;">
        </div>
        <div class="checkout-message-top each-strip d-flex align-items-center justify-content-between">
            <div class="d-flex w-100">
                <div>
                    <img src="assets/large-green-tick.png" alt="tick">
                </div>
                <div class="mar-left-20">
                    <div class="congrats-text">Your order has been placed successfully!</div>
                    <div class="email-text">Please check your email and mobile number for order confirmation and
                        detailed delivery information.
                    </div>
                </div>
                <div class="d-none d-lg-block booking-id-detail ml-auto text-right">
                    <div>
                        Booking ID : <span class="id">{{bookingData?.attributes.booking_unique_id}}</span>
                    </div>
                </div>

            </div>

        </div>

        <hr>

        <div class="d-flex flex-wrap each-strip product-container ">
            <div class="col-12 col-lg-6 d-flex align-items-center mar-bot-20"
                *ngFor="let each of bookingData.attributes.booking_data.data">
                <div class="product-image">
                    <img loading="lazy" default="assets/gifting/default-image.png"
                        [src]="each.attributes.images.image_link_1" alt="">
                </div>
                <div class="product-details ">
                    <div class="product-name">{{each.attributes.product_name}}</div>
                    <div class="product-amount"><span class="rupee-symbol mar-right-2">₹</span>
                        {{each.attributes.amount | indianCurrency}}</div>
                </div>
            </div>
        </div>

        <hr>

        <div class="pay-header each-strip">Delivery Details</div>

        <hr>

        <div class="d-block d-lg-none mob-booking-id each-strip">
            Booking ID : <span class="id">{{bookingData?.attributes.booking_unique_id}}</span>
        </div>

        <hr class="d-block d-lg-none">

        <div class="detail each-strip">
            <div class="d-flex align-items-center justify-content-between field mar-bot-10">
                <div class="name">Recepient's Name</div>
                <div class="value">
                    {{bookingData.attributes.booking_data.data[0].attributes.display_first_name + ' ' + bookingData.attributes.booking_data.data[0].attributes.display_last_name}}
                </div>
            </div>
            <hr>
            <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                <div class="name">Delivery Date & Time</div>
                <div class="value">
                    {{bookingData.attributes.booking_data.data[0].attributes.delivery_date | date: 'MMM dd, yyyy'}}
                    <span>|</span>
                    {{bookingData.attributes.booking_data.data[0].attributes.delivery_time}}
                </div>
            </div>
            <hr>
            <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                <div class="name">Delivery Address</div>
                <div class="value">{{getAddress()}}</div>
            </div>
            <hr>
            <div class="field mar-top-10">
                <div class="name">Gift’s Message</div>
                <div class="sub-detail">{{bookingData.attributes.booking_data.data[0].attributes.gift_message}}
                </div>
            </div>
        </div>

        <hr>

        <div class="pay-header each-strip">Fare Breakup</div>

        <hr>

        <div class="payment-detail each-strip">
            <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                <div class="name">Total Product Price</div>
                <div class="value">
                    <span class="rupee-symbol">₹</span>
                    {{getTotalProductsPrice() | indianCurrency}}</div>
            </div>
            <hr>

            <ng-container *ngIf="bookingData.attributes.booking_data.data[0].attributes.shipping_fee">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Shipping</div>
                    <div class="value">
                        <span class="mar-right-2">+</span>
                        <span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.booking_data.data[0].attributes.shipping_fee| indianCurrency}}</div>
                </div>
                <hr>
            </ng-container>


            <!-- <ng-container *ngIf="bookingData?.fare_breakup?.convenience_fee">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Convenience Fee</div>
                    <div class="value">
                        <span class="mar-right-2">+</span>
                        <span class="rupee-symbol">₹</span>
                        {{bookingData.fare_breakup.convenience_fee| indianCurrency}}</div>
                </div>
                <hr>
            </ng-container> -->


            <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                <div class="name">Total Amount</div>
                <div class="value"><span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.gross_amount| indianCurrency}}</div>
            </div>

            <hr>

            <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10 discount"
                *ngIf="bookingData.attributes.gross_amount-bookingData.attributes.amount>0">
                <div class="name">Discount</div>
                <div class="value">
                    <span class="mar-right-2">-</span>
                    <span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.gross_amount-bookingData.attributes.amount| indianCurrency}}</div>
            </div>

            <hr>

            <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                <div class="name">Amount Paid</div>
                <div class="value"><span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.amount| indianCurrency}}</div>
            </div>


        </div>

        <hr>

        <div class="pay-header each-strip">Payment Breakup</div>

        <hr>

        <div class="payment-detail each-strip">
            <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                <div class="name"><span>Amount paid by card
                        {{bookingData?.attributes?.card_number | creditCardMask}}</span><img *ngIf="cardIcon"
                        height="16" src="{{cardIcon}}"></div>
                <div class="value"><span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.amount| indianCurrency}}</div>
            </div>

            <hr>

            <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10">
                <div class="name">Amount paid by Reward Points</div>
                <div class="value">NIL</div>
            </div>



        </div>





    </div>

</div>

<app-loader *ngIf="progress"></app-loader>