import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from "../../../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class SavedTravllerService {
  apiUrl: string = environment.v2ApiBs;
  constructor(public api: ApiService) { }

  addTravellerDetails(traveller){
    return this.api.post(this.apiUrl+"/user_guest_lists",traveller).toPromise();
  }

  getTravellerDetails(){
    ;
    return this.api.get(this.apiUrl+"/user_guest_lists").toPromise();
  }

  deleteTraveller(ids){
    return this.api.delete("users/delete_user_guests",ids).toPromise();
  }

  // getUserCards(){
  //   return this.api.get(this.apiUrl+'api/v1/payments/get_user_cards').toPromise()
  // }

  getUserBookingInfo(){
    return this.api.get(this.apiUrl+'users/booking_information').toPromise()
  }

  saveUserCard(cardDetails){
    return this.api.post(this.apiUrl+"api/v1/payments/save_user_card",cardDetails).toPromise()
  }

  editPrimaryUser(userDetails){
    return this.api.post(this.apiUrl+"users/edit",userDetails).toPromise()
  }

}
