import { Component, OnInit } from '@angular/core';
import {ConciergeServicesService} from 'src/app/concierge-services/service/concierge-services.service';
import { Router } from '@angular/router';
import swal from "sweetalert2";

@Component({
  selector: 'app-concierge-services-history',
  templateUrl: './concierge-services-history.component.html',
  styleUrls: ['./concierge-services-history.component.scss']
})
export class ConciergeServicesHistoryComponent implements OnInit {
  bookings;
  progress;

  constructor(private conciergeServicesService:ConciergeServicesService, public router:Router) { }

  ngOnInit() {
    this.progress = true;
    this.conciergeServicesService.bookingList().then((bookings:any)=>{
      this.progress = false;
      this.bookings = bookings.data
    })
  }

  openBookingDetails(bookingId){
    this.router.navigate([`booking`, 'concierge-services'], { queryParams: { 'id': bookingId}});
  }

  cancelBooking(booking) {
    let cancellationMessage = '<p>' + '</p>';
    // <span class="icon-gifting mar-right-5 fnt-14"></span>
    swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this booking?',
        html: cancellationMessage,
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reason...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        preConfirm: (input) => {
          if (!input) {
            swal.showValidationMessage("Please enter reason for cancellation.");
            return false;
          } else {
            return input;
          }
        }
      })
      .then(result => {
        if (result.value) {
          this.progress = true;
          this.conciergeServicesService.cancelBookingRequest(booking.attributes.booking_unique_id, result.value).then((resp: any) => {
            if (resp['status'] === "success") {
              swal.fire({
                imageUrl: "assets/modal-info.png",
                imageHeight: 60,
                title: "Cancellation Requested",
                html: '<p>'+'You will soon receive the status of your cancellation request through email or call from the SBI Card Concierge team. '+'</p>',
              }).then(res => {
                this.ngOnInit();
              })
            } else {
              swal.fire({
                imageUrl: "assets/modal-error.png",
                imageHeight: 60,
                title: "Cancellation Request Failed",
                html: '<p>' + 'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            }
          });
        }
      });


  }
}
