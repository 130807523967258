

<div class="d-block homepage" [ngClass]="{'isMobile' : isMobile }">
    <div *ngIf="!isMobile" style="min-height: 400px;">
        <app-flights-home *ngIf="selectedApp.id===1"></app-flights-home>
        <app-hotels-home *ngIf="selectedApp.id===2"></app-hotels-home>
        <app-tours-home *ngIf="selectedApp.id===3"></app-tours-home>
        <app-giftcard-home-container *ngIf="selectedApp.id===4"></app-giftcard-home-container>
        <app-charitable-giving-home *ngIf="selectedApp.id===5"></app-charitable-giving-home>
        <app-events-concerts-home *ngIf="selectedApp.id===7"></app-events-concerts-home>
        <app-natgeo-home-container *ngIf="selectedApp.id===9"></app-natgeo-home-container>
        <app-points-transfer-listing-page *ngIf="selectedApp.id===24"></app-points-transfer-listing-page>
    </div>
    <div class="home-top-container relative d-block d-sm-none  dark3" *ngIf="isMobile">
        <div class="bg-overlay w100 absolute"></div>
        <div class="banner-content-contain relative">
          <div class="banner-text-contain">
            <!-- <p class="home-page-heading d-flex align-items-center justify-content-center f500 fnt-18"> -->
                <h1 class="home-page-heading sp-heading gold mb-20">
                    Fuel Your Passions
               </h1>
               <p class="sp-text mb-60">
                    Make every journey or occasion rewarding with AURUM Rewards
                </p>
          </div>
        </div>
    </div>
    <div class="d-block d-sm-none" *ngIf="isMobile">
        <!-- <section class="your-points d-flex align-items-center justify-content-between" *ngIf="datalogin.isAuthenticated()">
            <div class="fnt-12 accenttwo">
                Your Reward Points
            </div>
            <div class="fnt-14">
                <span class="icon-star" style="color: var(--primary);"></span> <span class="rupee-symbol">{{'15000' | indianCurrency}}</span>
            </div>
        </section> -->
        <section class="home-page-search d-flex align-items-center">
            <div class="category d-flex flex-column align-items-center" (click)="onAppChange('Flights')">
                <div class="icon-flight icon-size"></div>
                <div class="category-text d-flex justify-content-center align-items-center">
                    Flights
                </div>
            </div>
            <div class="category d-flex flex-column align-items-center" (click)="onAppChange('Hotels')">
                <div class="icon-hotels icon-size"></div>
                <div class="category-text d-flex justify-content-center align-items-center">
                    Hotels
                </div>
            </div>
            <div class="category d-flex flex-column align-items-center" (click)="onAppChange('Gift Cards')">
              <div class="icon-gift-card icon-size"></div>
              <div class="category-text d-flex justify-content-center align-items-center">
                  Gift Cards
              </div>
          </div>
        </section>
        <section class="home-page-search d-flex align-items-center">
            <div class="category d-flex flex-column align-items-center position-relative" (click)="onAppChange('Points Transfer')">
              <div class="icon-size">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                  <path d="M18.111 0.390565C17.5903 -0.130184 16.746 -0.130189 16.2253 0.390553C15.7045 0.911296 15.7045 1.75559 16.2252 2.27634L17.2775 3.32858H9.83416C4.67905 3.32858 0.5 7.50763 0.5 12.6627V13.9962C0.5 14.7326 1.09701 15.3296 1.83345 15.3296C2.5699 15.3296 3.1669 14.7326 3.1669 13.9962V12.6627C3.1669 8.98052 6.15194 5.99549 9.83416 5.99549H17.2836L16.2252 7.05386C15.7045 7.57461 15.7045 8.4189 16.2253 8.93964C16.746 9.46038 17.5903 9.46038 18.111 8.93963L20.9712 6.07941C21.7523 5.2983 21.7523 4.03187 20.9712 3.25076L18.111 0.390565Z" fill="#C2A63F"/>
                  <path d="M15.1657 22.002C20.3209 22.002 24.4999 17.8229 24.4999 12.6678V11.3344C24.4999 10.5979 23.9029 10.0009 23.1665 10.0009C22.43 10.0009 21.833 10.5979 21.833 11.3344V12.6678C21.833 16.35 18.848 19.3351 15.1657 19.3351H7.71614L8.77466 18.2765C9.2954 17.7558 9.29539 16.9115 8.77464 16.3907C8.25389 15.87 7.4096 15.87 6.88886 16.3908L4.0287 19.251C3.24759 20.0321 3.2476 21.2985 4.0287 22.0796L6.88886 24.9398C7.4096 25.4606 8.2539 25.4606 8.77464 24.9398C9.29539 24.4191 9.2954 23.5748 8.77466 23.054L7.72259 22.002H15.1657Z" fill="#C2A63F"/>
                </svg>
              </div>
              <span class="new-span position-absolute ">NEW</span>
              <div class="category-text d-flex justify-content-center align-items-center">
                Points Transfer
              </div>
          </div>
            <div class="category d-flex flex-column align-items-center" (click)="onAppChange('Philanthropy')">
                <div class="icon-charity icon-size"></div>
                <div class="category-text d-flex justify-content-center align-items-center">
                    Philanthropy
                </div>
            </div>
            <div class="category d-flex flex-column align-items-center disable-hover" (click)="navigateViaModal()">
                <div class="icon-size">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 32 33" fill="none">
                    <path d="M16 21.8333C20.4187 21.8333 24 15.5853 24 11.1667C24 9.04494 23.1571 7.01011 21.6569 5.50982C20.1566 4.00953 18.1217 3.16667 16 3.16667M16 21.8333C11.5813 21.8333 8 15.5853 8 11.1667C8 9.04494 8.84286 7.01011 10.3431 5.50982C11.8434 4.00953 13.8783 3.16667 16 3.16667M16 21.8333C15.2928 21.8333 14.6145 20.85 14.1144 19.0997C13.6143 17.3493 13.3333 14.9754 13.3333 12.5C13.3333 10.0247 13.6143 7.65068 14.1144 5.90034C14.6145 4.15 15.2928 3.16667 16 3.16667M16 21.8333C16.7072 21.8333 17.3855 20.85 17.8856 19.0997C18.3857 17.3493 18.6667 14.9754 18.6667 12.5C18.6667 10.0247 18.3857 7.65068 17.8856 5.90034C17.3855 4.15 16.7072 3.16667 16 3.16667M13.3333 27.1667C13.3333 26.8131 13.4738 26.4739 13.7239 26.2239C13.9739 25.9738 14.313 25.8333 14.6667 25.8333H17.3333C17.687 25.8333 18.0261 25.9738 18.2761 26.2239C18.5262 26.4739 18.6667 26.8131 18.6667 27.1667V28.5C18.6667 28.8536 18.5262 29.1928 18.2761 29.4428C18.0261 29.6929 17.687 29.8333 17.3333 29.8333H14.6667C14.313 29.8333 13.9739 29.6929 13.7239 29.4428C13.4738 29.1928 13.3333 28.8536 13.3333 28.5V27.1667Z" stroke="#C2A63F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="category-text d-flex justify-content-center align-items-center">
                  Tours & Experiences
                </div>
            </div>
        </section>
    </div>

    <div *ngIf="!hidePageBottom" class="d-block d-sm-none sp-section  dark3">
        <div class="block-sec d-flex flex-column justify-content-center align-items-center"  *ngIf="selectedApp.id!==9">
            <div class="block-sec-heading pad-bot-40">
                <h1 class="sp-heading gold mb-40">Live a Rewarding Life ...</h1>
            </div>
            <div class="d-none d-sm-block">
                <div class="d-flex justify-content-center mobile-view mar-bot-45 flex-wrap">
                    <div class="col-12 col-md-4 col-lg-4 each-col">
                        <div class="mem-text active" id="mem-text-1">
                            <div class="mem-logos mb-20">
                                <img src="./assets/sbic-images/hotel-icon.png" alt="" class="brand-logo">
                            </div>
                            <h4 class="sp-heading gold mb-10">An Extensive Rewards Catalog</h4>
                            <p class="sp-text mb-40">Redeem your AURUM Rewards Points from a wide variety of options from flights and hotel bookings to shopping and making charitable donations.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 each-col">
                        <div class="mem-text active" id="mem-text-2">
                            <div class="mem-logos mb-20">
                                <img src="./assets/sbic-images/safe.png" alt="" class="brand-logo">
                            </div>
                            <h4 class="sp-heading gold mb-10">Pay with Points, or Card, or Both</h4>
                            <p class="sp-text mb-40">Book the products/services you want directly and pay using your AURUM Credit Card, or Reward Points, or both. It's easy and convenient.</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 each-col">
                        <div class="mem-text active" id="mem-text-3">
                            <div class="mem-logos mb-20">
                                <img src="./assets/sbic-images/loan.png" alt="" class="brand-logo">
                            </div>
                            <h4 class="sp-heading gold mb-10">Maximize your Savings</h4>
                            <p class="sp-text mb-40">When you book with AURUM Rewards, you save money. And when you pay with Rewards Points, you save even more.</p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="d-block d-sm-none">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="section-content-center mem-text-section">
                        <div class="mem-text" id="mem-text-1" [ngClass]="{'active': membershipSlideCount == 0}">
                            <div class="mem-logos mb-20">
                                <img src="./assets/sbic-images/hotel-icon.png" alt="" class="brand-logo">
                            </div>
                            <h4 class="sp-heading gold mb-10">An Extensive Rewards Catalog
                            </h4>
                            <p class="sp-text mb-40">Redeem your AURUM Rewards Points from a wide variety of options from flights and hotel bookings to shopping and making charitable donations.
                            </p>
                        </div>
                        <div class="mem-text " id="mem-text-2" [ngClass]="{'active': membershipSlideCount == 1}">
                            <div class="mem-logos mb-20">
                                <img src="./assets/sbic-images/safe.png" alt="" class="brand-logo">
                            </div>
                            <h4 class="sp-heading gold mb-10">Pay with Points, or Card, or Both
                            </h4>
                            <p class="sp-text mb-40">Book the products/services you want directly and pay using your AURUM Credit Card, or Reward Points, or both. It's easy and convenient.
                            </p>
                        </div>

                        <div class="mem-text " id="mem-text-3" [ngClass]="{'active': membershipSlideCount == 2}">
                            <div class="mem-logos mb-20">
                                <img src="./assets/sbic-images/loan.png" alt="" class="brand-logo">
                            </div>
                            <h4 class="sp-heading gold mb-10">Maximize your Savings
                            </h4>
                            <p class="sp-text mb-40">When you book with AURUM Rewards, you save money. And when you pay with Rewards Points, you save even more.
                            </p>
                        </div>

                        <div role="tablist" class="membership-dots">
                            <a (click)="changeMembership(mI)" [ngClass]="{'active': membershipSlideCount == mI}"
                                [id]="mI + '-dot'" *ngFor="let each of Arr(3);let mI=index;"></a>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>




