<div class="home-top-container relative">
  <div class="mob-banner-contain w100 relative">
    <h1 class="sp-heading gold mb-20 text-center absolute">Redeem AURUM Reward Points on your love for Travel ! </h1>
  </div>
  <div class="home-search-bar container d-block d-sm-none">
    <div class="home-search-row pad-top-2">
      <div class="fnt-14 f500 accent mb-20">Whats your destination?</div>
      <!-- <div class="filter-head-outer d-flex align-center justify-center mar-top-13">
        <div class="filter-head-item d-flex align-items-center justify-content-center pointer"
          [ngClass]="{'active' : openWay == 1}" (click)="openWay = 1;">One way</div>
        <div class="filter-head-item d-flex align-items-center justify-content-center pointer"
          [ngClass]="{'active' : openWay == 2}" (click)="openWay = 2;">Round trip</div>
      </div> -->
      <mat-radio-group class='align-self-center' [(ngModel)]="openWay" color="accent">
        <mat-radio-button class="example-margin" (change)="radioChange($event)" [value]="1">
            <span [class]="openWay == 1 ? 'highlighted' : ''">
              One way
            </span>
        </mat-radio-button>
        <mat-radio-button class="example-margin mar-left-20" (change)="radioChange($event)" [value]="2">
            <span [class]="openWay == 2 ? '' : 'highlighted'">
              Round trip
            </span>
        </mat-radio-button>
    </mat-radio-group>
    </div>
    <div class=" search-contain relative">
      <div class="w100 view-input-box mar-top-30 relative form-field" (click)="dropDownPopOpen = true;inputToShow = 'originDrop'">
        <div class='w100 ft-wt-400 form-field__control'>
          <input id="exampleFieldRoom" type="text" class="form-field__input arrows relative" readonly #originAirport placeholder=" "
          [ngClass]="{'error' : originError}" [value]="fromTxt?fromTxt:''" autocomplete="off">
          <label for="exampleFieldRoom" class="form-field__label">Select Origin</label>
          <div class="form-field__bar"></div>
        </div>
      </div>
      <div *ngIf="originError">
        <span class="fnt-10 error-msg">{{originError}}</span>
      </div>
      <svg class="suffle-inputs absolute" (click)="shuffleInputs()" width="69" height="69" viewBox="0 0 69 69"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
            <circle cx="34.5" cy="32.5" r="19.5" fill="#252525" />
          </g>
          <path
            d="M30.3536 25.6464C30.1583 25.4512 29.8417 25.4512 29.6464 25.6464L26.4645 28.8284C26.2692 29.0237 26.2692 29.3403 26.4645 29.5355C26.6597 29.7308 26.9763 29.7308 27.1716 29.5355L30 26.7071L32.8284 29.5355C33.0237 29.7308 33.3403 29.7308 33.5355 29.5355C33.7308 29.3403 33.7308 29.0237 33.5355 28.8284L30.3536 25.6464ZM30.5 41V26H29.5V41H30.5Z"
            fill="#DADADA" />
          <path
            d="M39.6464 41.3536C39.8417 41.5488 40.1583 41.5488 40.3536 41.3536L43.5355 38.1716C43.7308 37.9763 43.7308 37.6597 43.5355 37.4645C43.3403 37.2692 43.0237 37.2692 42.8284 37.4645L40 40.2929L37.1716 37.4645C36.9763 37.2692 36.6597 37.2692 36.4645 37.4645C36.2692 37.6597 36.2692 37.9763 36.4645 38.1716L39.6464 41.3536ZM39.5 26V41H40.5V26H39.5Z"
            fill="#DADADA" />
          <defs>
            <filter id="filter0_d" x="0" y="0" width="69" height="69" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="7.5" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend mode="300" in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend mode="300" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
          </defs>
      </svg>
      <div class="w100 view-input-box mar-top-30 relative form-field" (click)="dropDownPopOpen = true;inputToShow = 'destinationDrop'">
        <div class='w100 ft-wt-400 form-field__control'>
          <input id="exampleFieldRoom1" type="text" class="form-field__input arrows relative" readonly #destinationAirport placeholder=" "
          [ngClass]="{'error' : destinationError}" [value]="toTxt?toTxt:''" autocomplete="off">
          <label for="exampleFieldRoom1" class="form-field__label">Select Destination</label>
          <div class="form-field__bar"></div>
        </div>
      </div>
      <div *ngIf="destinationError">
        <span class="fnt-10 error-msg">{{destinationError}}</span>
      </div>
      <div class="w100 view-input-box mar-top-30 relative form-field" (click)="dropDownPopOpen = true;inputToShow = 'dateDrop'">
        <div class='w100 ft-wt-400 form-field__control' *ngIf="openWay==1">
          <input id="exampleFieldDate1" type="text" class="form-field__input arrows relative" readonly placeholder=" "
          [ngClass]="{'error' : departureDateError}" [value]="flightSearchParams.depart_date ? (flightSearchParams.depart_date|date:'d MMM, y') : ''">
          <label for="exampleFieldDate1" class="form-field__label">Departure Date</label>
          <div class="form-field__bar"></div>
        </div>
        <div class='w100 ft-wt-400 form-field__control' *ngIf="openWay==2">
          <input id="exampleFieldDate2" type="text" class="form-field__input arrows relative" readonly placeholder=" "
          [ngClass]="{'error' : returnDateError}" [value]="flightSearchParams.depart_date && flightSearchParams.return_date ? (flightSearchParams.depart_date|date:'d MMM, y')+'  →  '+(flightSearchParams.return_date|date:'d MMM, y') : ''">
          <label for="exampleFieldDate2" class="form-field__label">Departure Date → Return Date</label>
          <div class="form-field__bar"></div>
        </div>
      </div>
      <div *ngIf="departureDateError">
        <span class="fnt-10 error-msg">{{departureDateError}}</span>
      </div>
      <div *ngIf="returnDateError">
        <span class="fnt-10 error-msg">{{returnDateError}}</span>
      </div>
      <div class="w100 view-input-box mar-top-30 relative form-field" (click)="dropDownPopOpen = true;inputToShow = 'travellerDrop'">
        <div class='w100 ft-wt-400 form-field__control'>
          <input id="exampleFieldRoom2" type="text" class="form-field__input arrows relative" readonly placeholder=" "
           [value]="(valueNum[0]+valueNum[1]+valueNum[2]) + ' Traveller(s), '+ travClass[selectedTravelClass]">
          <label for="exampleFieldRoom2" class="form-field__label">Travellers and Class</label>
          <div class="form-field__bar"></div>
        </div>

      </div>
      <!-- <div *ngIf="destinationErr" class="show invalid-feedback"> Looks like you've picked the same city.</div> -->
      <!-- <div *ngIf="(destinationErrNew && (((openWay==1) && !(this.flightSearchParams.depart_date &&
        this.flightSearchParams.from_id &&
        this.flightSearchParams.to_id)) || ((openWay==2) && !(this.flightSearchParams.depart_date &&
        this.flightSearchParams.from_id && this.flightSearchParams.return_date &&
        this.flightSearchParams.to_id)))) && !destinationErr" class="show invalid-feedback">All fields are required. Please fill all fields.
      </div> -->
      <div class="search-btn-contain mar-top-40">
        <button (click)="makeSearchQuery()" mat-button class="w100">Search Flights
          <span class="mar-left-10 loader btn-loader-default" *ngIf="clickedSearch"></span>
        </button>
      </div>

      <section class="all-mob-dropdowns" [ngClass]="{'active' : dropDownPopOpen}">
        <div class="close-bar flex align-center">
          <div class="close-icon-contain mar-top-15" (click)="dropDownPopOpen = false">
              <span class="icon-cancel fnt-24"></span>
          </div>
        </div>
        <div id="origin-drop" *ngIf="inputToShow == 'originDrop'">
          <div class="flex txt-box justify-center">
            <div class="img-box">
              <span class="icon-group fnt-18"></span>
            </div>
            <div class="fnt-16 pad-left-5 f500 pad-top-2">Select your origin</div>
          </div>

          <div class="input-contain mar-top-20">
            <input type="text" class="form-control fnt-14" #myInput2 (click)="myInput2.select()" [(ngModel)]="fromTxt" (input)="getAirports($event.target.value,'from')"
              placeholder="Enter City, Airport, Airline Code" autocomplete="off">
          </div>
          <div class="option-contain pad-top-10">
            <div *ngIf="airports?.from.length > 0 && !loading">
              <div *ngFor="let fromAirport of airports.from"
              (click)="setFlightSearchParams('from_id',fromAirport,originAirport);dropDownPopOpen = false"
              class="option flex">
              <div class="airport-contain">
                <div class="fnt-14 pad-bot-5">{{fromAirport.city.city_name}}<span
                    class="country-code">({{fromAirport.airport.airport_code}})</span></div>
                <div class="fnt-12 airport-name">{{fromAirport.airport.airport_name}}</div>
              </div>
              <div class="flex country-contain align-center">
                <span class="country-name fnt-14">{{fromAirport.airport.country}}</span>
                <img src="{{fromAirport.airport.flag_url}}" class="img-fluid country-img">
              </div>
              </div>
            </div>
            <div class="option flex fnt-14" *ngIf="loading">
              Loading...
            </div>
          </div>
        </div>

        <div id="origin-drop" *ngIf="inputToShow == 'destinationDrop'">
          <div class="flex txt-box justify-center">
            <div class="img-box">
              <span class="icon-group fnt-18"></span>
            </div>
            <div class="fnt-16 f500 pad-top-2">Select your destination</div>
          </div>
          <div class="input-contain mar-top-20">
            <input type="text" class="form-control fnt-14" #myInput3 (click)="myInput3.select()" [(ngModel)]="toTxt" (input)="getAirports($event.target.value,'to')"
              placeholder="Enter City, Airport, Airline Code" autocomplete="off">
          </div>
          <div class="option-contain pad-top-10">
            <div *ngIf="airports?.to.length > 0 && !loading">
              <div *ngFor="let toAirport of airports.to"
              (click)="setFlightSearchParams('to_id',toAirport,destinationAirport);dropDownPopOpen = false"
              class="option flex">
              <div class="airport-contain">
                <div class="fnt-14 pad-bot-5">{{toAirport.city.city_name}}<span
                    class="country-code mar-left-3">({{toAirport.airport.airport_code}})</span></div>
                <div class="fnt-12 airport-name">{{toAirport.airport.airport_name}}</div>
              </div>
              <div class="flex country-contain align-center">
                <span class="country-name fnt-14">{{toAirport.airport.country}}</span>
                <img src="{{toAirport.airport.flag_url}}" class="img-fluid country-img">
              </div>
              </div>
            </div>
            <div class="option flex fnt-14" *ngIf="loading">
              Loading...
            </div>
          </div>
        </div>

        <div id="origin-drop" *ngIf="inputToShow == 'dateDrop'">
          <div class="flex txt-box justify-center">
            <div class="img-box">
              <span class="icon-group-1 fnt-18"></span>
            </div>
            <div class="fnt-16 f500 pad-top-2 pad-left-5">Select travel dates</div>
          </div>
          <div class="input-contain mar-top-20" *ngIf="openWay==1">
            <input readonly #desktopDatepicker3 type="text" name="daterange3" placeholder="Departure Date" ngxDaterangepickerMd
            autoApply='false' [addHeight]="175" [singleDatePicker]="true" alwaysShowCalendars='true' [format]="'ddd, DD MMM, YYYY'"
            [minDate]="minDepartDate" (datesUpdated)="datesStartUpdated($event)" class="form-control fnt-14">
          </div>
          <div class="input-contain mar-top-20" *ngIf="openWay==2">
            <input #desktopDatepicker4 readonly type="text" name="daterange4" placeholder="Departure Date → Return date" ngxDaterangepickerMd
            autoApply='false' [addHeight]="175" [format]="'ddd, DD MMM, YYYY'"
            alwaysShowCalendars='true'
            [class]="showSearchError ? 'form-control' : 'form-control'"
            [minDate]="minDepartDate" (datesUpdated)="datesRoundUpdated($event)" class="form-control fnt-14">
          </div>

        </div>

        <div id="traveller-drop" *ngIf="inputToShow == 'travellerDrop'">
          <div class="flex txt-box justify-center">
            <div class="img-box">
              <span class="icon-group-size fnt-18"></span>
            </div>
            <div class="fnt-16 f500 pad-top-2 pad-left-5">Choose travellers and Class</div>
          </div>
          <div class="input-contain mar-top-20">
            <span class="form-control fnt-14 accenttwo">{{valueNum[0]+valueNum[1]+valueNum[2]}} Travellers,
              {{travClass[selectedTravelClass]}}</span>
          </div>
          <div class="traveller-contain pad-top-10">
            <div class="traveller-type-contain flex">
              <div class="traveller-type">
                <div class="fnt-14 pad-bot-5 f500">Adults</div>
                <div class="fnt-12 age-group">12 yrs & above</div>
              </div>
              <div class="flex range-contain align-center">
                <div class="inc-dec-btn d-flex align-items-center">
                  <div class="inc" (click)="decrement(0)">-</div>
                  <div class="value">{{valueNum[0] ? valueNum[0] : 0}}</div>
                  <div class="dec" (click)="increment(0)">+</div>
                </div>
              </div>
            </div>

            <div class="traveller-type-contain flex">
              <div class="traveller-type">
                <div class="fnt-14 pad-bot-5 f500">Children</div>
                <div class="fnt-12 age-group">Between 0 - 12 yrs</div>
              </div>
              <div class="flex range-contain align-center">
                <div class="inc-dec-btn d-flex align-items-center">
                  <div class="inc" (click)="decrement(1)">-</div>
                  <div class="value">{{valueNum[1] ? valueNum[1] : 0}}</div>
                  <div class="dec" (click)="increment(1)">+</div>
                </div>
              </div>
            </div>
            <div class="traveller-type-contain flex">
              <div class="traveller-type">
                <div class="fnt-14 pad-bot-5 f500">Infants</div>
                <div class="fnt-12 age-group">Under 2 yrs</div>
              </div>
              <div class="flex range-contain align-center">
                <div class="inc-dec-btn d-flex align-items-center">
                  <div class="inc" (click)="decrement(2)">-</div>
                  <div class="value">{{valueNum[2] ? valueNum[2] : 0}}</div>
                  <div class="dec" (click)="increment(2)">+</div>
                </div>
              </div>
            </div>
          </div>

          <div class="class-contain flex pad-top-20">
            <div class="small-tag">
              <div class="class-tag text-center" [ngClass]="{'active' : selectedTravelClass == 'E'}"
                (click)="selectedTravelClass = 'E'">Economy</div>
            </div>
            <div class="big-tag">
              <div class="class-tag text-center" [ngClass]="{'active' : selectedTravelClass == 'B'}"
                (click)="selectedTravelClass = 'B'">Business Class</div>
            </div>
            <div class="small-tag" *ngIf="isCleartrip">
              <div class="class-tag text-center" [ngClass]="{'active' : selectedTravelClass == 'F'}"
                (click)="selectedTravelClass = 'F'">First Class</div>
            </div>
            <div class="big-tag">
              <div class="class-tag text-center" [ngClass]="{'active' : selectedTravelClass == 'P'}"
                (click)="selectedTravelClass = 'P'">Premium Economy</div>
            </div>
          </div>
        </div>

      </section>
    </div>
  </div>
  <div class="home-search-bar container d-none d-sm-block rounded absolute">
    <div class="home-search-row pad-top-2 d-flex justify-content-between mar-bot-20 align-items-center">
      <!-- <div class="filter-head-outer d-flex align-center justify-center">
        <div class="filter-head-item filter-head-item-desktop d-flex align-items-center justify-content-center pointer"
          [ngClass]="{'active' : openWay == 1}" (click)="openWay = 1">One way</div>
        <div class="filter-head-item filter-head-item-desktop d-flex align-items-center justify-content-center pointer"
          [ngClass]="{'active' : openWay == 2}" (click)="openWay = 2">Round trip</div>
      </div> -->
      <mat-radio-group class='align-self-center' [(ngModel)]="openWay" color="accent">
        <mat-radio-button class="example-margin" (change)="radioChange($event)" [value]="1">
            <span [class]="openWay == 1 ? 'highlighted' : ''">
              One way
            </span>
        </mat-radio-button>
        <mat-radio-button class="example-margin mar-left-20" (change)="radioChange($event)" [value]="2">
            <span [class]="openWay == 2 ? '' : 'highlighted'">
              Round trip
            </span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class=" search-contain d-flex">
      <div class="w100 mar-right-10">
        <div class="form-field relative" dropdown #dropdown="bs-dropdown">
          <div class="form-field__control" dropdownToggle>
            <input  id="exampleFieldOrigin" type="text" class="form-field__input arrows text-truncate"
              [ngClass]="{'error' : originError}"
              [(ngModel)]="fromTxt" placeholder=" " autocomplete="off"
              type="text" #myInput (click)="myInput.select()" (input)="getAirports($event.target.value,'from');"/>
            <label for="exampleFieldOrigin" class="form-field__label">Origin</label>
            <div class="form-field__bar"></div>
          </div>
          <div class="dropdown-menu option-contain" *dropdownMenu>
            <div class="drop-inner" *ngIf="airports?.from.length > 0 && !loading">
              <div *ngFor="let fromAirport of airports.from"
                (click)="setFlightSearchParams('from_id',fromAirport,originAirport)"
                class="option flex">
                <div class="airport-contain">
                  <div class="fnt-14 pad-bot-5">{{fromAirport.city.city_name+" "}}<span
                      class="country-code">({{fromAirport.airport.airport_code}})</span></div>
                  <div class="fnt-12 airport-name">{{fromAirport.airport.airport_name}}</div>
                </div>
                <div class="flex country-contain align-center">
                  <span class="country-name fnt-14">{{fromAirport.airport.country}}</span>
                  <img src="{{fromAirport.airport.flag_url}}" class="img-fluid country-img">
                </div>
              </div>
            </div>
            <div class="drop-inner fnt-14" *ngIf="loading">
              Loading...
            </div>
          </div>
        </div>
        <div *ngIf="originError">
          <span class="fnt-10 error-msg">{{originError}}</span>
        </div>
      </div>

      <div class="w100 mar-left-10 mar-right-10">
        <div class="form-field relative" dropdown #dropdown1="bs-dropdown">
          <div class="form-field__control" dropdownToggle>
            <input  id="exampleFieldDestination" type="text" class="form-field__input arrows text-truncate"
            [ngClass]="{'error' : destinationError}"
            [(ngModel)]="toTxt" placeholder=" " autocomplete="off"
             type="text" #myInput1 (click)="myInput1.select()" (input)="getAirports($event.target.value,'to');  dropdown1.show();"/>
            <label for="exampleFieldDestination" class="form-field__label">Destination</label>
            <div class="form-field__bar"></div>
          </div>
          <div class="dropdown-menu option-contain" *dropdownMenu>
            <div class="drop-inner" *ngIf="airports?.to.length > 0 && !loading">
              <div *ngFor="let toAirport of airports.to"
                (click)="setFlightSearchParams('to_id',toAirport,destinationAirport)"
                class="option flex">
                <div class="airport-contain">
                  <div class="fnt-14 pad-bot-5">{{toAirport.city.city_name}}<span
                      class="country-code mar-left-3">({{toAirport.airport.airport_code}})</span></div>
                  <div class="fnt-12 airport-name">{{toAirport.airport.airport_name}}</div>
                </div>
                <div class="flex country-contain align-center">
                  <span class="country-name fnt-14">{{toAirport.airport.country}}</span>
                  <img src="{{toAirport.airport.flag_url}}" class="img-fluid country-img">
                </div>
              </div>
            </div>
            <div class="drop-inner fnt-14" *ngIf="loading">
              Loading...
            </div>
          </div>
        </div>
        <div *ngIf="destinationError">
          <span class="fnt-10 error-msg">{{destinationError}}</span>
        </div>
      </div>
      <div class="w100 mar-left-10 mar-right-10">
        <div class="form-field relative">
          <div class="form-field__control datepicker">
            <input  id="exampleFieldDDate" type="text" class="form-field__input arrows relative" 
              [ngClass]="{'error' : departureDateError}"
              placeholder=" " ngxDaterangepickerMd autoApply='false'
             [addHeight]="73" [singleDatePicker]="true" [minDate]="minDepartDate" (datesUpdated)="datesStartUpdated($event)"
             type="text" readonly #desktopDatepicker1 name="daterange1" [format]="'ddd, DD MMM, YYYY'"/>
            <label for="exampleFieldDDate" class="form-field__label">Departure Date</label>
            <div class="form-field__bar"></div>
          </div>
        </div>
        <div *ngIf="departureDateError">
          <span class="fnt-10 error-msg">{{departureDateError}}</span>
        </div>
      </div>
      <div *ngIf="openWay == 2" class="w100 mar-left-10 mar-right-10">
        <div class="form-field relative">
          <div class="form-field__control datepicker">
            <input  id="exampleFieldRDate" type="text" class="form-field__input arrows relative"
              [ngClass]="{'error' : returnDateError}" placeholder=" " ngxDaterangepickerMd autoApply='false'
             [addHeight]="73" [singleDatePicker]="true" [minDate]="minReturnDate" (datesUpdated)="datesEndUpdated($event)"
             type="text" readonly #desktopDatepicker2 name="daterange2" [format]="'ddd, DD MMM, YYYY'"/>
            <label for="exampleFieldRDate" class="form-field__label">Return Date</label>
            <div class="form-field__bar"></div>
          </div>
        </div>
        <div *ngIf="returnDateError">
          <span class="fnt-10 error-msg">{{returnDateError}}</span>
        </div>
      </div>
      <div class="w100 mar-left-10 travel-b">
        <div [insideClick]="true" class="form-field relative" dropdown>
          <div class="form-field__control" dropdownToggle>
            <input readonly id="exampleFieldTraveller" class="form-field__input arrows text-truncate input-placeholder fnt-14 travel-val"
            [ngClass]="{'error' : travellerDetailsError}" placeholder=" " [(ngModel)]="travellersTxt" #myInput5 (click)="myInput5.select()"/>
            <label for="exampleFieldTraveller" class="form-field__label">Travellers & Class</label>
            <div class="form-field__bar"></div>
          </div>
          <div class="dropdown-menu option-contain travel-drop" *dropdownMenu>
            <div class="drop-inner">
              <div class="traveller-contain pad-top-10">
                <div class="traveller-type-contain flex">
                  <div class="traveller-type">
                    <div *ngIf="valueNum[0]==1" class="fnt-14 pad-bot-5 f500">Adult</div>

                    <div *ngIf="valueNum[0]>1" class="fnt-14 pad-bot-5 f500">Adults</div>
                    <div class="fnt-12 age-group">12 years & above</div>
                  </div>
                  <div class="flex range-contain align-center">
                    <div class="inc-dec-btn d-flex align-items-center">
                      <div class="inc" (click)="decrement(0)">-</div>
                      <div class="value">{{valueNum[0] ? valueNum[0] : 0}}</div>
                      <div class="dec" (click)="increment(0)">+</div>
                    </div>
                  </div>
                </div>

                <div class="traveller-type-contain flex">
                  <div class="traveller-type">
                    <div *ngIf="valueNum[1]==1||valueNum[1]==0" class="fnt-14 pad-bot-5 f500">Child</div>

                    <div *ngIf="valueNum[1]>1" class="fnt-14 pad-bot-5 f500">Children</div>
                    <div class="fnt-12 age-group">Between 2 - 12 years</div>
                  </div>
                  <div class="flex range-contain align-center">
                    <div class="inc-dec-btn d-flex align-items-center">
                      <div class="inc" (click)="decrement(1)">-</div>
                      <div class="value">{{valueNum[1] ? valueNum[1] : 0}}</div>
                      <div class="dec" (click)="increment(1)">+</div>
                    </div>
                  </div>
                </div>
                <div class="traveller-type-contain flex">
                  <div class="traveller-type">
                    <div *ngIf="valueNum[2]==1|| valueNum[2]==0" class="fnt-14 pad-bot-5 f500">Infant</div>

                    <div *ngIf="valueNum[2]>1" class="fnt-14 pad-bot-5 f500">Infants</div>
                    <div class="fnt-12 age-group">Under 2 years</div>
                  </div>
                  <div class="flex range-contain align-center">
                    <div class="inc-dec-btn d-flex align-items-center">
                      <div class="inc" (click)="decrement(2)">-</div>
                      <div class="value">{{valueNum[2] ? valueNum[2] : 0}}</div>
                      <div class="dec" (click)="increment(2)">+</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="class-contain flex pad-top-10 pad-bot-10">
                <div class="small-tag">
                  <div class="class-tag text-center" [ngClass]="{'active' : selectedTravelClass == 'E'}"
                    (click)="changeTravelClass('E')">Economy</div>
                </div>
                <div class="big-tag">
                  <div class="class-tag text-center" [ngClass]="{'active' : selectedTravelClass == 'B'}"
                    (click)="changeTravelClass('B')">Business Class</div>
                </div>
                <div class="small-tag" *ngIf="isCleartrip">
                  <div class="class-tag text-center" [ngClass]="{'active' : selectedTravelClass == 'F'}"
                    (click)="changeTravelClass('F')">First Class</div>
                </div>
                <div class="big-tag">
                  <div class="class-tag text-center" [ngClass]="{'active' : selectedTravelClass == 'P'}"
                    (click)="changeTravelClass('P')">Premium Economy</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="travellerDetailsError">
          <span class="fnt-10 error-msg">{{travellerDetailsError}}</span>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="d-flex align-items-top justify-content-start">
        <div class="buy-for flex mar-top-25">
          <label class="filter-label">
            <input type="checkbox" (change)="changeNonStop($event)"><span class="checkmark mar-top-2"></span><span
              class="fnt-14 pad-left-10 mar-top-2">Non-stop flights
            </span>
          </label>
        </div>
      </div>

      <div class="mar-top-25 search-btn-contain-desktop">
        <button (click)="makeSearchQuery()" mat-button class="w100">Search Flights
          <span class="mar-left-10 loader btn-loader-default" *ngIf="clickedSearch"></span>
        </button>
      </div>
    </div>
  </div>

</div>

<div class="secondary-background d-none d-sm-block">
  <div class="row sp-section  dark3 ">
    <div class="container secondary-background">
      <h1 class="sp-heading gold center mb-60">Why you should book with AURUM Rewards</h1>
      <div class='row'>
        <div class='col-md-4 col-lg-4 col-12 text-left'>
          <img src="assets/sbic-images/air-insurance.png" class="mar-bot-20 img-fluid" style="height: 65px;">
          <h4 class="sp-heading gold mb-10 mar-top-10">Let us handle the searching</h4>
          <p class="f300 fnt-16 ft-wt-400">We scour all search results from all airlines via Yatra so you don’t
            have to.</p>
        </div>
        <div class='col-md-4 col-lg-4 col-12 text-left'>
          <img src="assets/sbic-images/flight-cancel.png" class="mar-bot-20 img-fluid" style="height: 65px;">
          <h4 class="sp-heading gold mb-10 mar-top-10">Get results that won’t drive you nuts</h4>
          <p class="f300 fnt-16">Easily see which flights work best with your schedule.</p>
        </div>
        <div class='col-md-4 col-lg-4 col-12 text-left'>
          <img src="assets/sbic-images/charges.png" class="mar-bot-20 img-fluid" style="height: 65px;">
          <h4 class="sp-heading gold mb-10 mar-top-10">Pay with points</h4>
          <p class="f300 fnt-16">Use your reward points to book flights, and maximize your savings when you travel.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-margin sp-section dark4 center">
    <div class="col-12 text-center">
      <h1 class="sp-heading gold center mb-60">Top Flight Routes</h1>
    </div>
    <div class="col-12 p-3">
      <div class="container p-3 rounded shadow">
        <div class="row no-margin">
          <div class="col-6 no-padding">
            <div class="d-flex align-items-center justify-content-between pad-top-10 pad-bot-10">
              <div class="align-center col-8 d-flex">
                <span class="fnt-16 ft-wt-400"> Delhi to Mumbai</span><span class="color-primary-grey">&nbsp;(DEL -
                  BOM)</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button type="button"
                  (click)="setSearch('New Delhi - Indira Gandhi Airport(DEL)','2ab2cc05-76c6-4a2e-965d-b51398943c20','Mumbai - Chatrapati Shivaji Airport(BOM)','f3619e96-9a6d-40e0-995b-aebdb58698a3')"
                  class="btn pad-left-20 pad-right-20 pad-top-10 pad-bot-10 btn-outline-primary fnt-14">Search flights</button>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between pad-top-10 pad-bot-10">
              <div class="align-center col-8 d-flex">
                <span class="fnt-16 ft-wt-400"> Mumbai to Delhi</span><span class="color-primary-grey">&nbsp;(BOM -
                  DEL)</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button type="button"
                  (click)="setSearch('Mumbai - Chatrapati Shivaji Airport(BOM)','f3619e96-9a6d-40e0-995b-aebdb58698a3','New Delhi - Indira Gandhi Airport(DEL)','2ab2cc05-76c6-4a2e-965d-b51398943c20')"
                  class="btn pad-left-20 pad-right-20 pad-top-10 pad-bot-10 btn-outline-primary fnt-14">Search flights</button>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between pad-top-10 pad-bot-10">
              <div class="align-center col-8 d-flex">
                <span class="fnt-16 ft-wt-400"> Delhi to Goa</span><span class="color-primary-grey">&nbsp;(DEL -
                  GOI)</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button type="button"
                  (click)="setSearch('New Delhi - Indira Gandhi Airport(DEL)','2ab2cc05-76c6-4a2e-965d-b51398943c20','Goa - Dabolim Airport(GOI)','ba617181-c056-4c5c-add9-aff375a9a73f')"
                  class="btn pad-left-20 pad-right-20 pad-top-10 pad-bot-10 btn-outline-primary fnt-14">Search flights</button>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-between pad-top-10 pad-bot-10">
              <div class="align-center col-8 d-flex">
                <span class="fnt-16 ft-wt-400"> Delhi to Bangalore</span><span class="color-primary-grey">&nbsp;(DEL -
                  BLR)</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button type="button"
                  (click)="setSearch('New Delhi - Indira Gandhi Airport(DEL)','2ab2cc05-76c6-4a2e-965d-b51398943c20','Bangalore - Kempegowda International Airport(BLR)','0a72dc49-7ef2-4db5-853c-197e0b3817d2')"
                  class="btn pad-left-20 pad-right-20 pad-top-10 pad-bot-10 btn-outline-primary fnt-14">Search flights</button>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between pad-top-10 pad-bot-10">
              <div class="align-center col-8 d-flex">
                <span class="fnt-16 ft-wt-400"> Mumbai to Goa</span><span class="color-primary-grey">&nbsp;(BOM -
                  GOI)</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button type="button"
                  (click)="setSearch('Mumbai - Chatrapati Shivaji Airport(BOM)','f3619e96-9a6d-40e0-995b-aebdb58698a3','Goa - Dabolim Airport(GOI)','ba617181-c056-4c5c-add9-aff375a9a73f')"
                  class="btn pad-left-20 pad-right-20 pad-top-10 pad-bot-10 btn-outline-primary fnt-14">Search flights</button>
              </div>
            </div>
          </div>
          <div class="col-6 no-padding">
            <div class="d-flex align-items-center justify-content-between pad-top-10 pad-bot-10">
              <div class="align-center col-8 d-flex">
                <span class="fnt-16 ft-wt-400"> Delhi to Patna</span><span class="color-primary-grey">&nbsp;(DEL -
                  PAT)</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button type="button" (click)="setSearch('New Delhi - Indira Gandhi Airport(DEL)','2ab2cc05-76c6-4a2e-965d-b51398943c20','Patna','1a117fe7-910a-489b-9878-cbdb2aa960a5')"
                  class="btn pad-left-20 pad-right-20 pad-top-10 pad-bot-10 btn-outline-primary fnt-14">Search flights</button>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between pad-top-10 pad-bot-10">
              <div class="align-center col-8 d-flex">
                <span class="fnt-16 ft-wt-400"> Delhi to Kolkata</span><span class="color-primary-grey">&nbsp;(DEL -
                  CCU)</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button type="button"
                  (click)="setSearch('New Delhi - Indira Gandhi Airport(DEL)','2ab2cc05-76c6-4a2e-965d-b51398943c20','Kolkata - Netaji Subhas Chandra Bose Airport(CCU)','39398c26-b323-4db8-9eda-b1295342ba90')"
                  class="btn pad-left-20 pad-right-20 pad-top-10 pad-bot-10 btn-outline-primary fnt-14">Search flights</button>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between pad-top-10 pad-bot-10">
              <div class="align-center col-8 d-flex">
                <span class="fnt-16 ft-wt-400"> Delhi to Pune </span><span class="color-primary-grey">&nbsp;(DEL -
                  PNQ)</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button type="button"
                  (click)="setSearch('New Delhi - Indira Gandhi Airport(DEL)','2ab2cc05-76c6-4a2e-965d-b51398943c20','Pune - Lohegaon(PNQ)','29287982-fe1a-4615-974d-ef160338e658')"
                  class="btn pad-left-20 pad-right-20 pad-top-10 pad-bot-10 btn-outline-primary fnt-14">Search flights</button>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between pad-top-10 pad-bot-10">
              <div class="align-center col-8 d-flex">
                <span class="fnt-16 ft-wt-400"> Kolkata to Delhi </span><span class="color-primary-grey">&nbsp;(CCU -
                  DEL)</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button type="button"
                  (click)="setSearch('Kolkata - Netaji Subhas Chandra Bose Airport(CCU)','39398c26-b323-4db8-9eda-b1295342ba90','New Delhi - Indira Gandhi Airport(DEL)','2ab2cc05-76c6-4a2e-965d-b51398943c20')"
                  class="btn pad-left-20 pad-right-20 pad-top-10 pad-bot-10 btn-outline-primary fnt-14">Search flights</button>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between pad-top-10 pad-bot-10">
              <div class="align-center col-8 d-flex">
                <span class="fnt-16 ft-wt-400"> Mumbai to Bangalore </span><span class="color-primary-grey">&nbsp;(BOM -
                  BLR)</span>
              </div>
              <div class="col-4 d-flex justify-content-end">
                <button type="button"
                  (click)="setSearch('Mumbai - Chatrapati Shivaji Airport(BOM)','f3619e96-9a6d-40e0-995b-aebdb58698a3','Bangalore - Kempegowda International Airport(BLR)','0a72dc49-7ef2-4db5-853c-197e0b3817d2')"
                  class="btn pad-left-20 pad-right-20 pad-top-10 pad-bot-10 btn-outline-primary fnt-14">Search flights</button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
