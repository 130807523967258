<div *ngIf="!mobileNewWindow" [insideClick]="true" class="dropdown1 form-field relative"  dropdown #dropdown="bs-dropdown">
  <div
  class='activity-select w100 d-flex align-items-center form-field__control'
  [class.whitebg]="background === true" dropdownToggle>
    <input id="exampleFieldOrigin" type="text" #destinationDropdown readonly (click)="openDestinationDrop(); inputToShow = 'destinationDrop'; generateShowText()"
      [(ngModel)]="showText" [formControl]='whereInput' placeholder=" " [ngClass]="{'error' : roomselectorError}"
      class='w100 bg pointer form-field__input arrows' [class.whitebg]="background === true">
      <label for="exampleFieldOrigin" class="form-field__label">Guests, Rooms</label>
      <div class="form-field__bar"></div>
  </div>
  <div class="dropdown-menu dropdown location-dropdown w100" *dropdownMenu id='pickerContainer'>
    <div class='show dropdown-menu w100'>
      <div class="scrollable-part">
        <div class="options" *ngFor="let room of rooms; index as roomindex;">
          <hr *ngIf="roomindex">
          <custom-dropdown-item [room]='room' [ageError]='isErrors[roomindex]' [roomIndex]='roomindex' [adults]='room.adults' [children]='room.children' (onChildAgeAdded)="onChildAgeAdded($event,roomindex)"  (onRoomChange)="onRoomChange($event,roomindex)" (onError)="onError($event)">
          </custom-dropdown-item>
        </div>
        
        <div class="add-room-buttom d-flex justify-content-start align-items-center" (click)="addAnotherRoom();">
          <span class="icon">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M11.9498 2.05025C10.6276 0.728134 8.86974 0 7 0C5.13026 0 3.37236 0.728134 2.05025 2.05025C0.728134 3.37236 0 5.13026 0 7C0 8.86974 0.728134 10.6276 2.05025 11.9498C3.37236 13.2719 5.13026 14 7 14C8.86974 14 10.6276 13.2719 11.9498 11.9498C13.2719 10.6276 14 8.86974 14 7C14 5.13026 13.2719 3.37236 11.9498 2.05025ZM7 12.9062C3.74332 12.9062 1.09375 10.2567 1.09375 7C1.09375 3.74332 3.74332 1.09375 7 1.09375C10.2567 1.09375 12.9062 3.74332 12.9062 7C12.9062 10.2567 10.2567 12.9062 7 12.9062ZM7.54688 6.45259H10.0078V7.54634H7.54688V10.0073H6.45312V7.54634H3.99219V6.45259H6.45312V3.99165H7.54688V6.45259Z" fill="#ffffff"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="14" height="14" fill="white"/>
              </clipPath>
              </defs>
            </svg>            
          </span>
          <span class="text">
            Add another room
          </span>
        </div>
      </div>
      <div class="done-div" *ngIf="disableDoneButton">
        <button mat-button class="done-button" (click)="closeDesktopDropdown()">Done</button>
          <!-- <button mat-button class="done-button" (click)="dropdown.hide();">Done</button> -->
      </div>
    </div>
  </div>
</div>


<div class="dropdown1" *ngIf="mobileNewWindow">
  <div
  [class]="isRoomDropdownOpen == true ? 'activity-select w100 d-flex align-items-center bg border-blue' : 'activity-select w100 d-flex align-items-center bg'"
  [class.whitebg]="background === true">
    <span class="pos-a icon-position mar-top-3 d-none d-sm-block" style="margin-left: -6px;">
      <span class="icon-group-size fnt-24"></span>
    </span>
    <input #destinationDropdown readonly (click)="openDestinationDrop(); inputToShow = 'destinationDrop'; generateShowText()"
      [(ngModel)]="showText" [formControl]='whereInput' placeholder="Guests, Rooms"
      class='w100 ft-wt-400 bg mar-left-45 avdafda pointer' [class.whitebg]="background === true">
    <i (click)="isRoomDropdownOpen=!isRoomDropdownOpen;isRoomDropdownOpen?'':destinationDropdown.focus()"
      [class]="isRoomDropdownOpen == true ? 'fa fa-angle-up downarrow pad-right-15' : 'fa fa-angle-down downarrow pad-right-15'" style="background : inherit;"></i>
  </div>
  <div class="dropdown location-dropdown">
    <div [class]="isRoomDropdownOpen == true ? 'show dropdown-menu w100' : 'dropdown-menu w100'">
      <div class="scrollable-part">
        <div class="options" *ngFor="let room of rooms; index as roomindex;">
          <hr *ngIf="roomindex">
          <custom-dropdown-item [room]='room' [ageError]='isErrors[roomindex]' [roomIndex]='roomindex' [adults]='room.adults' [children]='room.children' (onChildAgeAdded)="onChildAgeAdded($event,roomindex)"  (onRoomChange)="onRoomChange($event,roomindex)" (onError)="onError($event)">
          </custom-dropdown-item>
        </div>
        
        <div class="add-room-buttom d-flex justify-content-start align-items-center" (click)="addAnotherRoom()">
          <span class="icon">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M11.9498 2.05025C10.6276 0.728134 8.86974 0 7 0C5.13026 0 3.37236 0.728134 2.05025 2.05025C0.728134 3.37236 0 5.13026 0 7C0 8.86974 0.728134 10.6276 2.05025 11.9498C3.37236 13.2719 5.13026 14 7 14C8.86974 14 10.6276 13.2719 11.9498 11.9498C13.2719 10.6276 14 8.86974 14 7C14 5.13026 13.2719 3.37236 11.9498 2.05025ZM7 12.9062C3.74332 12.9062 1.09375 10.2567 1.09375 7C1.09375 3.74332 3.74332 1.09375 7 1.09375C10.2567 1.09375 12.9062 3.74332 12.9062 7C12.9062 10.2567 10.2567 12.9062 7 12.9062ZM7.54688 6.45259H10.0078V7.54634H7.54688V10.0073H6.45312V7.54634H3.99219V6.45259H6.45312V3.99165H7.54688V6.45259Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="14" height="14" fill="white"/>
              </clipPath>
              </defs>
            </svg>            
          </span>
          <span class="text">
            Add another room
          </span>
        </div>
      </div>
      <div class="done-div" *ngIf="disableDoneButton">
          <button mat-button class="done-button" (click)="closeDestinationDrop()">Done</button>
      </div>
    </div>
  </div>
</div>
