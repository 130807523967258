import { Component, OnInit } from '@angular/core';
import { OfferService } from 'src/app/offers/services/offer/offer.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';

@Component({
  selector: 'app-offers-history',
  templateUrl: './offers-history.component.html',
  styleUrls: ['./offers-history.component.scss']
})
export class OffersHistoryComponent implements OnInit {
  bookings = [];
  pageNo: number = 1;
  itemsPerPage = 10;
  progress: boolean = true;
  offerList = [];

  constructor(
    private offerService: OfferService,
    public dialog: MatDialog,
    public login: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.offerService.getBookings().then(resp => {
            this.bookings = resp['data'];
            this.getAllOffers();
          })
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          };
        });
      });
  }

  getAllOffers() {
    this.offerService.getAllOffers().then((res: any) => {
      if (res.data) {
        this.offerList = res.data;
      }
      this.progress = false;
    });
  }

  openBookingDetails(bookingId) {
    this.router.navigate(['/booking/offers'], { queryParams: { 'id': bookingId } });
  }

  moveUp() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }


  getOfferName(booking) {
    if (booking.attributes.booking_data && booking.attributes.booking_data.offer_id && this.offerList.length) {
      for (const each of this.offerList) {
        if (each.id == booking.attributes.booking_data.offer_id) {
          return each.attributes.title;
        }
      }
    }
    return '';
  }

  getMerchantName(booking){
    if (booking.attributes.booking_data && booking.attributes.booking_data.offer_id && this.offerList.length) {
      for (const each of this.offerList) {
        if (each.id == booking.attributes.booking_data.offer_id) {
          return each.attributes.merchant.name;
        }
      }
    }
    return '';
  }

}
