import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { PointsTransferDetailsComponent } from "./points-transfer-details.component";
import { PointsInputBoxModule } from "../points-input-box/points-input-box.module";
import { CommonModule } from "@angular/common";
import { XSubstituterPipe } from "../x-substituter.pipe";
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
  declarations: [
    PointsTransferDetailsComponent,
    XSubstituterPipe,
  ],
  imports: [PointsInputBoxModule, CommonModule, SharedModule],
  exports: [PointsTransferDetailsComponent],
})
export class PointsTransferDetailsModule {
  constructor() {}
}
