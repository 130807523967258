<div *ngIf="!progress">
    <div class="container mar-bot-20 to-show1">
        <div class="pad-top-60">
            <div class="d-none d-lg-block">
                <div class="blueify mar-bot-10 d-flex justify-content-start align-items-center pointer"
                    (click)="back()">
                    <div class="mar-top-4">
                        <span class="icon-left-arrow"></span>
                    </div>
                    <div class="mar-left-10">
                        Back
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mar-bot-20" style="padding: 10px 0;">
                    <div class="flex align-items-center pad-top-10 pad-bot-10 offer-container">
                        <div *ngIf="offerDetails?.attributes?.merchant?.logo?.url" class="logo-image">
                            <img [src]="offerDetails?.attributes?.merchant?.logo?.url" loading="lazy">
                        </div>
                        <div class="flex align-items-center flex-column">
                            <div class="w100 fnt-18 f500">{{offerDetails.attributes.title}}</div>
                            <div class="w100 merchant-name">{{'by ' + offerDetails.attributes.merchant.name}}</div>
                        </div>
                    </div>
                    <div>
                        <app-status-label class="status-text flex" [status]="bookingData?.attributes?.status">
                        </app-status-label>
                    </div>
                </div>
            </div>
            <div class="pad-top-10 pad-bot-10 bg d-block d-lg-none">
                <div class=" flex">
                    <div class="col-1 pad-top-16" (click)="back()">
                        <span class="icon-left-arrow"></span>
                    </div>
                    <div class="col-8 pad-top-14">
                        <div class="flex align-items-center pad-top-10 pad-bot-10 offer-container">
                            <div *ngIf="offerDetails?.attributes?.merchant?.logo?.url" class="logo-image">
                                <img [src]="offerDetails?.attributes?.merchant?.logo?.url" loading="lazy">
                            </div>
                            <div class="flex align-items-center flex-column">
                                <div class="w100 fnt-18 f500">{{offerDetails.attributes.title}}</div>
                                <div class="w100 merchant-name">{{'by ' + offerDetails.attributes.merchant.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 pad-top-14">
                        <app-status-label class="status-text flex" [status]="bookingData?.attributes?.status">
                        </app-status-label>
                    </div>
                </div>
            </div>

            <div class="booking-confirmed-container">
                <div>
                    <div
                        class="trip-details-bar flex justify-content-between pad-top-10 pad-bot-10 pad-left-20 pad-right-20">
                        <span class="fnt-16">Offer ID : <span
                                class="ft-wt-400">{{bookingData.attributes.booking_unique_id}}</span></span>
                        <!-- <span class="print-button" (click)="convertMyWay()"> <span class="fnt-14">
                                <span class="icon-print"></span>
                                Print </span>
                        </span> -->
                    </div>
                    <hr>
                    <div
                        class="trip-details-bar flex justify-content-between pad-top-10 pad-bot-10 pad-left-20 pad-right-20">
                        <span class="fnt-16">Offer Details</span>
                    </div>
                    <div class="trip-details-body">
                        <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                            <span class="color-grey">Coupon Code</span><span class="fnt-14">
                                <ng-container *ngIf="bookingData?.attributes?.booking_data?.coupon_code">
                                    {{bookingData?.attributes.booking_data.coupon_code}}
                                </ng-container>
                                <ng-container *ngIf="!(bookingData?.attributes?.booking_data?.coupon_code)">
                                    -
                                </ng-container>
                            </span>
                        </div>
                        <hr>
                        <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                            <span class="color-grey">Valid Till</span><span class="fnt-14">
                                <ng-container *ngIf="bookingData?.attributes?.booking_data?.valid_till">
                                    {{bookingData?.attributes.booking_data.valid_till | date:'MMM dd, yyyy'}}
                                </ng-container>
                                <ng-container *ngIf="!(bookingData?.attributes?.booking_data?.valid_till)">
                                    -
                                </ng-container>
                            </span>
                        </div>
                        <hr>
                        <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                            <span class="color-grey">Availed On</span><span class="fnt-14">
                                <ng-container *ngIf="bookingData?.attributes?.created_at">
                                    {{bookingData?.attributes.created_at | date:'MMM dd, yyyy'}}
                                </ng-container>
                                <ng-container *ngIf="!(bookingData?.attributes?.created_at)">
                                    -
                                </ng-container>
                            </span>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-loader *ngIf="progress"></app-loader>