import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {

  @Input() event;
  @Input() exchangeRates = [];
  @Input() selectedDate;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  getNoOfStars(rating) {
    return new Array(Math.round(rating));
  }

  getValue() {
    if (this.exchangeRates && this.exchangeRates.length) {
      const val = this.exchangeRates.find((each) => each.code.toLowerCase() === this.event.cost_range.range_currency_code.toLowerCase());
      if (val) {
        return this.event.cost_range.min_combined / val.rate;
      }
    } else {
      return this.event.cost_range.min_combined;
    }
    return this.event.cost_range.min_combined;
  }

  navigateToDetailPage() {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {
    //     date: this.selectedDate ? moment(this.selectedDate).format('YYYY-MM-DD') : ''
    //   }
    // };
    const path = this.event.event_desc.split(' ').join('-').split('/').join('-') + '-' + this.event.id
    this.router.navigate(['/events-concerts/event/' + path]);
  }

  getClasses() {
    let arr = []
    for (let each of Object.keys(this.event.classes)) {
      if (this.event.classes[each]) {
        arr.push(this.event.classes[each]);
      }
    }
    return arr.join(', ');
  }

  getRating() {
    return this.event.agregate_rating ? this.event.agregate_rating : 0;
  }

  getRatingCount() {
    return (this.event.user_rating_count ? this.event.user_rating_count : 0) + (this.event.critic_rating_count ? this.event.critic_rating_count : 0);
  }

}
