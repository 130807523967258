<!-- <div class="loader1 dot-spin" *ngIf="appType !== 'flight'"></div> -->
<div class="loader1">
  <img src="assets/spinner.svg">
</div>

<!-- <div class="loader-flight" *ngIf="appType === 'flight'">
  <img src="assets/flight-loading.svg">
</div> -->

<div class="overlay"></div>
