import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/common/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class GiftingService {
  baseUrl: string = environment.apiUrl;
  apiUrl: string = this.baseUrl + 'fnp';
  stagingUrl = environment.stagingUrl;

  constructor(private api: ApiService) { }

  getFilters() {
    return this.api.get(this.apiUrl + '/get_filters').toPromise();
  }

  getGiftListing(subCategoryId?: any) {
    return this.api.get(this.apiUrl + '/gift_card_listing' + (subCategoryId ? ('?product_sub_category_id=' + subCategoryId) : '')).toPromise();
  }

  getBestSellerGifts() {
    return this.api.get(this.apiUrl + '/gift_card_listing?best_sellers=true').toPromise();
  }

  addToCart(data) {
    return this.api.post(this.baseUrl + 'fnp_cart/add_items', data);
  }

  getCartDetails() {
    return this.api.get(this.baseUrl + 'fnp_cart/list_items');
  }

  clearCartItems() {
    return this.api.delete(this.apiUrl + 'fnp_cart/clear_cart_items');
  }

  getGiftCardDetails(id) {
    return this.api.get(this.apiUrl + '/gift_card_detail?id=' + id);
  }

  removeCartItem(cartItem){
    return this.api.delete(this.baseUrl + `fnp_cart/remove_items?product_id=${cartItem.product_id}&amount=${cartItem.amount}&recipient_type=${cartItem.recipient_type}`);
  }

  createBooking() {
    return this.api.get(this.apiUrl + '/create_booking');
  }

  getBookingDetailsById(id) {
    return this.api.get(this.stagingUrl + `users/bookings/booking_details?booking_unique_id=${id}`).toPromise();
  }

  getShippingDetails(pinCode) {
    return this.api.get(this.apiUrl + '/get_shipping_details?pin_code=' + pinCode).toPromise();
  }

  purchase(params) {
    return this.api.post(this.apiUrl + '/purchase',params).toPromise();
  }

  getBookings(){
    return this.api.get(this.stagingUrl + "users/bookings?booking_type=FnpBooking").toPromise()
  }

  cancelBookingRequest(bookingUniqueId, reason){
    return this.api.post(this.stagingUrl+'users/bookings/request_cancellation',{
      'booking_unique_id': bookingUniqueId,
      'reason': reason
    }).toPromise()
  }
  
}
