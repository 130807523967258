export const appConstants = {
    cardIconsMapping  : {
        VISA : 'visa.png',
        AMEX : 'amex-512.png',
        DINR : 'diners-club.png',
        MAST : 'master.png',
        RUPAY : 'RuPay.png',
        MASTERCARD : 'master.png',
        Unknown : 'credit.png',
    },
    apps:[
        { id: 1, name: 'Flights', avatar: 'icon-flight mar-right-10 mar-top-4', url: '/flights' },
        { id: 2, name: 'Hotels', avatar: 'icon-hotels mar-right-10 mar-top-4', url: '/hotels' },
        { id: 24, name: 'Points Transfer', url: '/points-transfer' },
        { id: 4, name: 'Gift Cards', avatar: 'icon-gift-card mar-right-10 mar-top-4', url: '/giftcards' },
        { id: 5, name: 'Philanthropy', avatar: 'icon-charity mar-right-10 mar-top-4', url: '/charitable-donations' },
        { id: 3, name: 'Experiences', avatar: 'icon-ballon mar-right-10 mar-top-4', url: '/tours-and-activities' },
        // { id: 6, name: 'Dining', avatar: 'icon-dining mar-right-10 mar-top-4', url: '/dining' },
        // { id: 7, name: 'Events & Concerts', avatar: 'icon-events mar-right-10 mar-top-4', url: '/events-concerts' },
        // { id: 8, name: 'Airport Concierge', avatar: 'icon-airportcon mar-right-10 mar-top-4', url: '/airport-concierge' },
        { id: 9, name: 'NAT GEO Tours', avatar: 'icon-national-geographic mar-right-10 mar-top-4', url: '/nat-geo' },
        // { id: 10, name: 'Gifting (Flowers, Cakes & more)', avatar: 'icon-gifting mar-right-10 mar-top-4', url: '/gifting' },
        // { id: 11, name: 'Golf', avatar: 'icon-golf mar-right-10 mar-top-4', url: '/golf' },
        // { id: 12, name: 'Experience Fashion', avatar: 'icon-fashion mar-right-10 mar-top-4', url: '/designers' },
        // { id: 13, name: 'Memberships & Subscriptions', avatar: 'icon-13 mar-right-10 mar-top-4', url: '' },
        // { id: 14, name: 'Bus', avatar: 'icon-bus mar-right-10 mar-top-4', url: '' },
        // { id: 15, name: 'Trains', avatar: 'icon-trains mar-right-10 mar-top-4', url: '' },
        // { id: 16, name: 'Online Doctor Consultations', avatar: 'icon-16 mar-right-10 mar-top-4', url: '/doctor-consultation' },
        // { id: 17, name: 'Health Check-Ups', avatar: 'icon-17 mar-right-10 mar-top-4', url: '' },
        // { id: 18, name: 'Luxury Vacations', avatar: 'icon-escapes mar-right-10 mar-top-4', url: '' },
        // { id: 19, name: 'Premium Electronics Store', avatar: 'icon-19 mar-right-10 mar-top-4', url: '' },
        // { id: 20, name: 'Spas & Salons Vouchers', avatar: 'icon-20 mar-right-10 mar-top-4', url: '/offers?id=spas-salons' },
        // { id: 21, name: 'Fitness & Mindfulness Benefits', avatar: 'icon-21 mar-right-10 mar-top-4', url: '' },
        // { id: 22, name: 'Nutrition Benefits', avatar: 'icon-22 mar-right-10 mar-top-4', url: '/offers?id=nutrition' },
        // { id: 23, name: 'Diet Plans', avatar: 'icon-23 mar-right-10 mar-top-4', url: '' },
    ],
    basicInfo: {
        email: { display: 'aurumrewards@poshvine.com', val: 'aurumrewards@poshvine.com'},
        phone: { display: '1800 212 7078', val: '18002127078'}
    },
    routesMapping: [
        { HotelBooking : "hotels" },
        { NatGeoTrack : "nat-geo" },
        { GolfEnquiry : "golf" },
        { FlightItinerary : "flights" },
        { GiftCardBooking : "giftcards" },
        { HeadoutBooking : "tours-and-activities" },
        { NgoDonation : "charitable-donations" },
        { FnpBooking : "gifting" },
        { DocsAppBooking : "doctor-consultation" },
        { EventBooking : "events-concerts" },
        { GacBooking : "airport-concierge" },
        { DesignerBooking : "designers" },
        { ConciergeRequest : "concierge-services" },
        { HealthPassRedemption : "offers" },
        { RestaurantBooking : "dining" },
    ],
    FALLBACK_IMAGE: 'https://images.poshvine.com/b/default-thumbnail-square.jpg',
    DATE_FORMAT: 'mediumDate',
    REWARD_POINTS_PROGRAM_NAME:'AURUM Reward points',
    POINTS_TRANSFER_MAX_REDEEMABLE_POINTS:20000,
    POINTS_TRANSFER_MIN_REDEEMABLE_POINTS:1000,
    POINTS_TRANSFER_MIN_REDEEMABLE_FACTOR:100
}
