import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error500',
  templateUrl: './error500.component.html',
  styleUrls: ['./error500.component.scss']
})
export class Error500Component implements OnInit {
  autoRedirectTime : number = 10;

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let internalSub = setInterval(() => {
      if (this.autoRedirectTime >=1) {
        this.autoRedirectTime -= 1;
      }else{
        window.location.href = '/';
      }
    }, 1000);
  }

}
