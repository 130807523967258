import { Injectable } from "@angular/core";
import { ApiService } from './../../../services/common/api/api.service';
import { environment } from '../../../../environments/environment';
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class NatGeoService {
  apiUrl: string = environment.v2ApiBs+'/nat_geos/';
  stagingUrl: string = environment.v2ApiBs;
  constructor(private api: ApiService) {}

  getHomePageFilters() {
    return new Promise((resolve, reject) => {
      this.api.get(this.apiUrl + "get_home_page_filters").subscribe(filters => {
        resolve(filters);
      });
    });
  }

  getBookings(){
    return new Promise((resolve, reject) => {
      this.api.get(this.stagingUrl + "/bookings?type=NatGeoTrack").subscribe(filters => {
        resolve(filters);
      });
    });
  }

  getBookingDetails(id){
    return this.api.get(this.stagingUrl + `/bookings/${id}`).toPromise();
  }

  getSearchResults(destinations=[], theme="", month="", length = "", intrests = []) {
    let params = new HttpParams();
    if (destinations) destinations.forEach(destination => {
      params = params.append("destinations[]", destination);
    });
    if (intrests) intrests.forEach(intrest => {
      params = params.append("interests[]", intrest);
    });
    return new Promise((resolve, reject) => {
      this.api
        .get(
          this.apiUrl +
            `search?sort_by_price=ASC&departure_month_values=${month}&nat_geo_trip_type_id=${theme}&nat_geo_trip_length_id=${length}`,
          { params: params }
          // {params:params}
        )
        .subscribe(searchResult => {
          resolve(searchResult);
        });
    });
  }

  getTripDetails(param) {
    return new Promise((resolve, reject) => {
      this.api
        .get(this.apiUrl + `${param.id}/get_details`)
        .subscribe(tripDetails => {
          resolve(tripDetails);
        });
    });
  }

  postTripEnquiries(params) {
    return new Promise((resolve, reject) => {
      this.api
        .post(this.apiUrl + `send_enquiry`, params)
        .subscribe(tripDetails => {
          resolve(tripDetails);
        });
    });
  }
}
