import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FlightsService } from "src/app/flights/service/flights.service";
import { Router } from "@angular/router";
import * as _moment from "moment";
import { ToastrService } from "ngx-toastr";
const moment = _moment;
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: "app-flights-home",
  templateUrl: "./flights-home.component.html",
  styleUrls: ["./flights-home.component.scss"],
})
export class FlightsHomeComponent implements OnInit {
  desktop;
  travClass = {
    E: "Economy",
    B: "Business Class",
    F: "First Class",
    P: "Premium Economy",
  };
  // @ViewChild("desktopDatepicker1", { static: false }) filtersRef: ElementRef;
  minDepartDate = moment().add();
  minReturnDate = moment();
  destinationErr = false;
  destinationErrNew = false;
  singleDatePickerProps = {
    date: new Date(),
    placeholder: "Select Date",
    showClearDates: true,
    withPortal: false,
    daySize: 35,
    numberOfMonths: 1,
    hideKeyboardShortcutsPanel: true,
    displayFormat: "MMM DD, YYYY",
  };
  airports = {
    from: [],
    to: [],
  };
  fromVal;
  toVal;
  selectedDateRange;
  // airports;
  airportList;
  openWay: number = 2;
  valueNum: any = [1, 0, 0];
  isFromDropdownOpen = false;
  isToDropdownOpen = false;
  isTncDropdownOpen = false;
  dropDownPopOpen: boolean = false;
  inputToShow: string;
  selectedTravelClass: string = "E";
  selectedDateRangeAdv: any;
  selectedDateRangeAdvReturn: any;
  dateRangePickerPropsReturn = {
    placeholder: "Return Date",
    showClearDates: true,
    withPortal: false,
    daySize: 35,
    displayFormat: "MMM DD, YYYY",
    hideKeyboardShortcutsPanel: true,
  };
  flightSearchParams: any = {
    nonstop: false,
    depart_date: "",
    return_date: "",
  };
  fromTxt: string;
  toTxt: string;
  travellersTxt: string;
  loading: boolean = false;
  isCleartrip: boolean = false;
  getAirportSubscriptions: any = {};
  topAirports = [];
  hidePageBottom: boolean = false;
  @ViewChild("dropdown", { static: false }) dropdown: ElementRef<HTMLElement>;
  @ViewChild("myInput1", { static: false }) myInput1: ElementRef<HTMLElement>;
  @ViewChild("desktopDatepicker1", { static: false })
  desktopDatepicker1: ElementRef<HTMLElement>;

  @ViewChild("desktopDatepicker2", { static: false })
  desktopDatepicker2: ElementRef<HTMLElement>;

  clickedSearch: boolean = false;
  //error variables
  originError;
  destinationError;
  departureDateError;
  returnDateError;
  travellerDetailsError;
  constructor(
    public flightS: FlightsService,
    public router: Router,
    public data: DataService,

  ) {}

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    localStorage.setItem("flights_merchant", "yatra");
    this.desktop = window.innerWidth > 768;
    // this.flightS.getAirports("").then(airport => {
    //   this.airportList = airport;
    // });
    localStorage.setItem("flights_merchant", "yatra");

    this.getTopAirports();
    this.travellersTxt =
      this.valueNum[0] +
      this.valueNum[1] +
      this.valueNum[2] +
      (this.valueNum[0] + this.valueNum[1] + this.valueNum[2] > 1
        ? " Travellers, "
        : " Traveller, ") +
      this.travClass[this.selectedTravelClass];
    // if(window.location.pathname.split('/')[1]){
    //   this.hidePageBottom = true;
    // }
  }
  radioChange(value){
    this.openWay = value;
  }
  triggerTwoWay() {
    let a = this.openWay;
    setTimeout(() => {
      this.openWay = this.openWay === 1 ? 2 : 1;
    }, 10);
    setTimeout(() => {
      this.openWay = this.openWay === 2 ? 1 : 2;
    }, 10);
  }
  onMerchantTypeChange(event: any, recipient_type) {
    // this.selectedRecipientType = recipient_type;
    localStorage.setItem("flights_merchant", recipient_type);
    if (recipient_type === "cleartrip") {
      this.isCleartrip = true;
    } else {
      this.isCleartrip = false;
    }
    // this.fromTxt = '';
    // this.toTxt  = '';
    // this.airports['from'] = [];
    // this.airports['to'] = [];

    console.log(recipient_type, "+++");
  }

  datesStartUpdated(event) {
    this.dropDownPopOpen = false;
    this.departureDateError = '';
    this.flightSearchParams.depart_date = event.startDate.format("YYYY-MM-DD");
    this.minReturnDate = moment(
      this.flightSearchParams.depart_date,
      "YYYY-MM-DD"
    );

    if (this.openWay == 2) {
      this.triggerTwoWay();
      if (!this.flightSearchParams.return_date) {
        setTimeout(() => this.desktopDatepicker2.nativeElement.click(), 10);
      }
    }
    if (this.openWay == 2 && this.flightSearchParams.return_date) {
      if (
        moment(this.flightSearchParams.depart_date, "YYYY-MM-DD").valueOf() >
        moment(this.flightSearchParams.return_date, "YYYY-MM-DD").valueOf()
      ) {
        this.resetReturnDate();
        this.desktopDatepicker2.nativeElement["value"] = "";
      }
    }
  }

  resetReturnDate() {
    this.flightSearchParams.return_date = "";
  }

  datesRoundUpdated(event) {
    this.dropDownPopOpen = false;
    this.departureDateError = '';
    this.flightSearchParams.depart_date = event.startDate.format("YYYY-MM-DD");
    // this.minReturnDate = moment(this.flightSearchParams.depart_date,"YYYY-MM-DD");
    this.flightSearchParams.return_date = event.endDate.format("YYYY-MM-DD");
    // this.minReturnDate = moment(this.flightSearchParams.depart_date,"YYYY-MM-DD");
  }

  datesEndUpdated(event) {
    this.flightSearchParams.return_date = event.startDate.format("YYYY-MM-DD");
    this.returnDateError = '';
  }

  openFromDrop() {
    this.isFromDropdownOpen = true;
  }

  closeFromDrop() {
    console.log("fromdropdown");
    // this.isFromDropdownOpen = false;
  }

  openToDrop() {
    this.isToDropdownOpen = true;
  }

  closeToDrop() {
    // this.isToDropdownOpen = false;
  }

  openTncDrop() {
    this.isTncDropdownOpen = true;
  }

  closeTncDrop() {
    // this.isTncDropdownOpen = false;
  }

  increment(i) {
    if (this.valueNum[0] + this.valueNum[1] > 8 && i != 2) {
      // this.data.triggerToastr('error',"No of travellers cannot be more than 9");
      this.travellerDetailsError = "No of travellers cannot be more than 9";
      return;
    }else{
      this.travellerDetailsError = "";
    }
    if (this.valueNum[i]) {
      this.valueNum[i] = this.valueNum[i] + 1;
      if (this.valueNum[0] < this.valueNum[2]) {
        // this.data.triggerToastr('error',"Infants cannot be more than adults");
        this.travellerDetailsError = "Infants cannot be more than adults";

        this.valueNum[2]--;
      }else{
        this.travellerDetailsError = "";
      }
    } else {
      this.valueNum[i] = 1;
      this.travellerDetailsError = "";
    }
    this.travellersTxt =
      this.valueNum[0] +
      this.valueNum[1] +
      this.valueNum[2] +
      (this.valueNum[0] + this.valueNum[1] + this.valueNum[2] > 1
        ? " Travellers, "
        : " Traveller, ") +
      this.travClass[this.selectedTravelClass];
  }

  decrement(i) {
    if (this.valueNum[0] + this.valueNum[1] > 9) {
      return;
    }
    if (this.valueNum[i]) {
      this.valueNum[i] = this.valueNum[i] - 1;
      if (this.valueNum[0] == 0) {
        this.valueNum[0] = 1;
        // this.data.triggerToastr('error',"Atleast one adult should be selected.");
        this.travellerDetailsError = "Atleast one adult should be selected.";
      }else{
        this.travellerDetailsError = "";
      }
      if (this.valueNum[0] < this.valueNum[2]) this.valueNum[2]--;
    } else {
      this.valueNum[i] = 0;
    }
    this.travellersTxt =
      this.valueNum[0] +
      this.valueNum[1] +
      this.valueNum[2] +
      (this.valueNum[0] + this.valueNum[1] + this.valueNum[2] > 1
        ? " Travellers, "
        : " Traveller, ") +
      this.travClass[this.selectedTravelClass];
  }
  changeTravelClass(class1) {
    this.selectedTravelClass = class1;
    this.travellersTxt =
      this.valueNum[0] +
      this.valueNum[1] +
      this.valueNum[2] +
      (this.valueNum[0] + this.valueNum[1] + this.valueNum[2] > 1
        ? " Travellers, "
        : " Traveller, ") +
      this.travClass[this.selectedTravelClass];
  }
  dateRangePickerProps = {
    placeholder: "Departure Date",
    showClearDates: true,
    withPortal: false,
    daySize: 35,
    displayFormat: "MMM DD, YYYY",
    hideKeyboardShortcutsPanel: true,
  };

  getAirports(query, key) {
    if (query != this.flightSearchParams[key + "_id_name"]) {
      this.flightSearchParams[key + "_id"] = null;
      this.flightSearchParams[key + "_id_name"] = null;
      this.flightSearchParams[key + "_id_country"] = null;
    }
    if (query.length) {
      // debuger
      this.loading = true;
      if (this.getAirportSubscriptions[key]) {
        this.getAirportSubscriptions[key].unsubscribe();
      }
      this.getAirportSubscriptions[key] = this.flightS
        .getAirports(query)
        .subscribe((airport) => {
          this.loading = false;
          console.log(airport, this.airports);
          this.airports[key] = airport;
        });
    } else {
      this.airports[key] = this.topAirports;
    }
  }

  // returnToTxt(){
  //   return this.toTxt: this.toTxt
  // }

  shuffleInputs() {
    let shuffle, shuffleParamsAirport, shuffleParamsName;
    shuffle = this.fromTxt;
    this.fromTxt = this.toTxt;
    this.toTxt = shuffle;
    shuffleParamsAirport = this.flightSearchParams["from_id"];
    this.flightSearchParams["from_id"] = this.flightSearchParams["to_id"];
    this.flightSearchParams["to_id"] = shuffleParamsAirport;
    shuffleParamsName = this.flightSearchParams["from_id_name"];
    this.flightSearchParams["from_id_name"] = this.flightSearchParams[
      "to_id_name"
    ];
    this.flightSearchParams["to_id_name"] = shuffleParamsName;
  }

  setFlightSearchParams(key, value, el) {
    this.destinationErr = false;
    this.flightSearchParams[key];
    this.flightSearchParams[key] = value.airport.id;
    if (
      this.flightSearchParams["from_id"] &&
      this.flightSearchParams["to_id"]
    ) {
      if (
        this.flightSearchParams["from_id"] == this.flightSearchParams["to_id"]
      ) {
        delete this.flightSearchParams[key];
        // this.data.triggerToastr('error',"Looks like you've picked the same city.");
        this.destinationError = "Looks like you've picked the same city.";
        this.destinationErr = true;
        return;
      }
    }
    if (key === "from_id") {
      this.originError = '';
      this.fromTxt =
        value.airport.airport_code +
        " - " +
        value.city.city_name +
        ", " +
        value.city.country;
      this.flightSearchParams[key + "_name"] =
        value.airport.airport_code +
        " - " +
        value.city.city_name +
        ", " +
        value.city.country;
      if (!this.toTxt) {
        setTimeout(() => this.myInput1.nativeElement.click(), 0);
      }
    }
    if (key === "to_id") {
      this.destinationError = '';
      this.toTxt =
        value.airport.airport_code +
        " - " +
        value.city.city_name +
        ", " +
        value.city.country;
      this.flightSearchParams[key + "_name"] =
        value.airport.airport_code +
        " - " +
        value.city.city_name +
        ", " +
        value.city.country;
      // debugger
      if (!this.flightSearchParams.depart_date) {
        setTimeout(() => this.desktopDatepicker1.nativeElement.click(), 10);
      }
    }

    this.flightSearchParams[key + "_country"] = value.city.country;
    el.value =
      value.airport.airport_code +
      " - " +
      value.city.city_name +
      ", " +
      value.city.country;
    if (
      this.flightSearchParams["from_id"] &&
      this.flightSearchParams["to_id"] &&
      this.flightSearchParams["to_id_country"] ==
        this.flightSearchParams["from_id_country"]
    ) {
      this.flightSearchParams["search_type"] = "domestic";
    }

    if (
      this.flightSearchParams["from_id"] &&
      this.flightSearchParams["to_id"] &&
      this.flightSearchParams["to_id_country"] !=
        this.flightSearchParams["from_id_country"]
    ) {
      this.flightSearchParams["search_type"] = "international";
    }

    console.log(
      this.flightSearchParams,
      this.selectedDateRangeAdv,
      console.log(el)
    );
  }

  changeNonStop(event) {
    this.flightSearchParams.nonstop = event.target.checked;
    console.log(this.flightSearchParams);
  }

  setSearch(sourseStop, sourceId, destStop, destId) {
    window.scrollTo(0, 100);
    console.log(sourseStop, sourceId, destStop, destId);
    this.flightSearchParams.from_id = sourceId;
    this.flightSearchParams.to_id = destId;
    // this.
    this.fromTxt = sourseStop;
    this.toTxt = destStop;
    this.flightSearchParams["search_type"] = "domestic";
    this.flightSearchParams["from_id_name"] = sourseStop;
    this.flightSearchParams["to_id_name"] = destStop;
    setTimeout(() => this.desktopDatepicker1.nativeElement.click(), 10);
  }

  makeSearchQuery() {
    if (!this.flightSearchParams.from_id) {
      // this.data.triggerToastr('error', "Select Origin.");
      this.originError = 'Select Origin';
      // this.data.triggerToastr('error',"Select Origin.");
      this.destinationErrNew = true;
      return;
    }
    if (!this.flightSearchParams.to_id) {
      // this.data.triggerToastr('error',"Select Destination.");
      this.destinationError = 'Select Destination';
      this.destinationErrNew = true;
      return;
    }

    if (!this.flightSearchParams.depart_date) {
      // this.data.triggerToastr('error',"Select Departure Date.");
      this.departureDateError = 'Select Departure Date';
      this.destinationErrNew = true;
      return;
    }

    if (this.openWay == 2 && !this.flightSearchParams.return_date) {
      // this.data.triggerToastr('error',"Select Return Date.");
      this.returnDateError = 'Select Return Date';
      this.destinationErrNew = true;
      return;
    }

    this.destinationErrNew = false;
    if (this.openWay == 1)
      this.flightSearchParams = {
        ...this.flightSearchParams,
        // depart_date: `${this.selectedDateRangeAdv.getFullYear()}-${this.selectedDateRangeAdv.getMonth() +
        //   1}-${this.selectedDateRangeAdv.getDate()}`,
        // return_date: `${this.selectedDateRangeAdvReturn.getFullYear()}-${this.selectedDateRangeAdvReturn.getMonth() +
        //   1}-${this.selectedDateRangeAdvReturn.getDate()}`,
        // return_date: `${this.selectedDateRangeAdv.end.getFullYear()}-${this.selectedDateRangeAdv.end.getMonth()+1}-${this.selectedDateRangeAdv.end.getDate()}`,
        adults: this.valueNum[0],
        children: this.valueNum[1],
        infants: this.valueNum[2],
        cabin_type: this.selectedTravelClass,
        supplier_name: localStorage.getItem("flights_merchant")
          ? localStorage.getItem("flights_merchant")
          : "cleartrip",
      };
    if (this.openWay == 2)
      this.flightSearchParams = {
        ...this.flightSearchParams,
        // depart_date: `${this.selectedDateRangeAdv.getFullYear()}-${this.selectedDateRangeAdv.getMonth() +
        //   1}-${this.selectedDateRangeAdv.getDate()}`,
        // return_date: `${this.selectedDateRangeAdvReturn.getFullYear()}-${this.selectedDateRangeAdvReturn.getMonth() +
        //   1}-${this.selectedDateRangeAdvReturn.getDate()}`,
        // return_date: `${this.selectedDateRangeAdv.end.getFullYear()}-${this.selectedDateRangeAdv.end.getMonth()+1}-${this.selectedDateRangeAdv.end.getDate()}`,
        adults: this.valueNum[0],
        children: this.valueNum[1],
        infants: this.valueNum[2],
        cabin_type: this.selectedTravelClass,
        supplier_name: localStorage.getItem("flights_merchant")
          ? localStorage.getItem("flights_merchant")
          : "cleartrip",
      };
    // debugger;
    this.clickedSearch = true;
    this.router.navigate(["flights/listing", this.flightSearchParams]);
    // location.reload();
  }

  getTopAirports() {
    this.flightS.getAirports("", true).subscribe((res: any) => {
      this.topAirports = res;
      if (this.topAirports && this.topAirports.length) {
        this.airports.from = this.topAirports;
        this.airports.to = this.topAirports;
      }
    });
  }
}
