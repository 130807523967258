import { Component, OnInit } from '@angular/core';
import { HotelApiService } from './../../hotel/services/hotel-api/hotel-api.service';
import * as _moment from 'moment';
import swal from 'sweetalert2';
import {Router} from "@angular/router"; 
import { LoginService } from './../../services/login/login.service';
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import { MatDialog } from "@angular/material/dialog";
const moment = _moment;
import {IndianCurrency} from 'src/app/shared/pipes/indian-currency.pipe';

@Component({
  selector: 'app-hotel-booking-history',
  templateUrl: './hotel-booking-history.component.html',
  styleUrls: ['./hotel-booking-history.component.scss'],
  providers: [ IndianCurrency ]
})
export class HotelBookingHistoryComponent implements OnInit {
  bookings=[];
  moment = moment;
  numDays;
  pageNo: number = 1;
  itemsPerPage = 10;
  progress : boolean = true;  

  constructor(private hotelApiService : HotelApiService, private router: Router, public dialog: MatDialog, public login: LoginService, private indianCurrency: IndianCurrency) { }

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
      if(result && result.id){
        this.hotelApiService.getHotelBookinghistory().subscribe( resp => {
          this.bookings = resp['data'];
          // this.numDays = moment(this.checkoutDate).diff(moment(this.checkinDate), 'days');
          this.progress = false;
          console.log(this.bookings);
          // this.bookings.map(booking => {
          //   booking.attributes.booking_data['room_name'] = Object.keys(booking['attributes']['booking_data']['room_details'][0])[0];
          //   booking.attributes.booking_data['room_count'] = parseInt(JSON.stringify(Object.values(booking['attributes']['booking_data']['room_details'][0])[0]));
          // });
        })
      }else{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
          disableClose: true
        }).afterClosed().subscribe( res=> {
          if(res == 'true'){
            this.ngOnInit();
          }else if(res =='redirect'){
            history.back();
          };
        });
      }
    },
    (error)=>{
      this.dialog.open(SbicLoginComponent, {
        width: "450px",
        height: "70vh",
        disableClose: true
      }).afterClosed().subscribe( res=> {
        if(res == 'true'){
          this.ngOnInit();
        }else if(res =='redirect'){
          history.back();
        };
      });
    });
  }
  
  cancelOpt(bookingData){
    this.progress = true;
    this.hotelApiService.checkCancellationCharges(bookingData.attributes.id).subscribe( resp => {
      this.progress = false;

      if(resp['status']==='success'){
        let cancellationMessage = '<p><span class="icon-hotels mar-right-5 fnt-14"></span>'+bookingData.attributes.details.attributes.hotel_details.name+'</p>'+
        '<p><span class="icon-group-1 mar-right-5 fnt-14"></span>'+moment(bookingData.attributes.details.attributes.from_date).format('DD MMM YYYY')+ '<span class="icon-right mar-right-5 mar-left-5 fnt-14"></span>'+moment(bookingData.attributes.details.attributes.to_date).format('DD MMM YYYY')+'</p>'+
        '<p><span class="icon-users mar-right-5 fnt-14"></span>'+bookingData.attributes.details.attributes.user_count.adults+(bookingData.attributes.details.attributes.user_count.adults >= 2 ? ' Adults, ' : ' Adult, ')+ bookingData.attributes.details.attributes.user_count.children+ (bookingData.attributes.details.attributes.user_count.children >= 2 ? ' Children' : ' Child') +'</p>'+
        // '<p><span class="icon-bed mar-right-5 fnt-14"></span>'+parseInt(JSON.stringify(Object.values(bookingData.attributes.details.attributes.rooms[0]['room_details'][0])[0]))+' '+Object.keys(bookingData.attributes.booking_data['room_details'][0])[0]+'</p>'+
        '<p style="padding-bottom: 5px !important; padding-top: 5px !important; font-weight:500 !important;"><span class="icon-check mar-right-3 fnt-14"></span> Refundable Amount : '+ '<span class="rupee-symbol">₹</span> ' +this.indianCurrency.transform(resp['refundable_amount'], null)+'</p>'+
        '<p class="ft-wt-400" style="text-decoration: underline;">Terms</p>';
        for(var i=0;i<resp['cancellation_policies'].length;i++){
          cancellationMessage+='<p class="w100 d-flex"><span style="width: 5%;display: inline-flex;margin-top: 3px;"><span class="icon-bullet mar-right-5" style="font-size: 8px;"></span></span> <span style="width: 94%;display: inline-block;">'+'<span>'+resp['cancellation_policies'][i]+'</span>'+'</span></p>';
        }
        swal.fire({
          imageUrl: "assets/modal-info.png",
          imageHeight: 60,
          title: 'Are you sure you want to cancel this booking?',
          html: cancellationMessage,
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel"
        }).then( result => {
          if (result.value) {
            console.log('proceed to cancel')
            this.progress = true;

            this.hotelApiService.cancelHotelBooking(bookingData.attributes.id).subscribe( resp => {
              console.log(resp);
              this.progress = false;

              if(resp['status'] === "cancelled"){
                swal.fire({
                  imageUrl: "assets/modal-info.png",
                  imageHeight: 60,
                  title: "Cancellation Successful",
                  html: '<p>'+resp['msg'][0]+'</p>'+
                  '<p> Refundable Amount : '+ '<span class="rupee-symbol">₹</span> ' +this.indianCurrency.transform(resp['refundable_amount'], null)+'</p>'+
                  '<br><p>Your refund amount will be credited to your Payment Account within 7 business days.</p>',
                  // showCancelButton: true,
                }).then( res => {
                  this.ngOnInit();
                })
              }else{
                swal.fire({
                  imageUrl: "assets/modal-error.png",
                  imageHeight: 60,
                  title: "Cancellation Failed",
                  html: '<p>'+resp['msg'][0]+'</p>',
                  // showCancelButton: true,
                }).then( res => {
                  this.ngOnInit();
                })
              }
            })
          }else if(result.dismiss === swal.DismissReason.cancel){
            console.log('close alert')
          }
        });
      }else{
        let cancellationMessage = '<p>'+resp['msg'][0]+'</p>';
        swal.fire({
          imageUrl: "assets/modal-error.png",
          imageHeight: 60,
          title: "Review Cancellation",
          html: cancellationMessage,
          showCancelButton: true,
        })
      }

    })
  }
  
  getStatus(date, cur_status){
    if(this.checkIfUpcoming(date) && cur_status === 'booking_confirmed'){
      return 'upcoming';
    }else if(this.checkIfCompleted(date) && cur_status === 'booking_confirmed'){ 
      return 'booking_completed';
    }else{
      return cur_status;
    }
  }

  checkIfUpcoming(date){
    return moment(date).isAfter(moment().startOf('day'))
  }

  checkIfCompleted(date){
    return moment(date).isBefore(moment().startOf('day'))
  }

  openBookingDetails(bookingId){
    this.router.navigate([`hotels`, 'booking-details'], { queryParams: { 'id': bookingId}});
  }

  moveUp(){
    window.scrollTo({top : 0, left : 0, behavior : 'smooth'});
  }
  
}
