<div *ngIf="!progress">
  <div *ngIf="!bookings.length" class="no-rooms">
    No bookings found !
  </div>
  <div class="d-block d-sm-none pad-left-15 pad-right-15">
    <div class="nat-geo-item pad-15" (click)="goToDetails(booking)" *ngFor="let booking of bookings">
      <div class="d-flex justify-content-between">
        <div>
          <div class="back-circle">
            <span class="icon-national-geographic mar-right-10 mar-top-4"></span>
          </div>
        </div>
  
        <!-- <img class="nat-geo-logo" src="assets/nat-geo-logo.png"> -->
        <!-- <div class="status-text"> -->
          <div>
            <app-status-label class="status-text"
            [status]="booking.attributes.status">
            </app-status-label>
            <!-- <span class="enquiry-status">{{booking.attributes.status | removeUnderscore}}</span> -->
          </div>
        <!-- </div> -->

      </div>
      <div class="d-flex">
        <!-- <img class="nat-geo-item-img" default="./assets/default_image.png"
          src="{{booking?.attributes.details?.attributes?.nat_geo_trip?.lead_image_url}}"> -->
  
        <div class="">
          <div class="nat-geo-item-title">{{booking?.attributes.details?.attributes?.nat_geo_trip?.title}}</div>
          <div class="nat-geo-item-details">
            <span>{{booking?.attributes.details?.attributes?.nat_geo_trip?.duration}} days</span>
            <span>| Starting from <span class="rupee-symbol">₹</span>
              {{booking?.attributes.details?.attributes?.nat_geo_trip?.inr_lowest_price}}</span>
          </div>
        </div>
      </div>
      <hr>
    </div>

  </div>
  <div class="nat-geo-item d-none d-sm-block"  *ngFor="let booking of bookings">
    <div class="bg" style="border-bottom: 1px solid var(--primary);">
      <div class="d-flex justify-content-between">
        <div class="flex align-items-center mb-10">
          <div class="back-circle">
            <span class="icon-national-geographic mar-right-10 mar-top-4"></span>
          </div> 
          <div class="nat-geo-item-title">
            {{booking?.attributes.details?.attributes?.nat_geo_trip?.title}}
          </div>
        </div>

        <!-- <img class="nat-geo-logo" src="assets/nat-geo-logo.png"> -->
        <!-- <div class="status-text"> -->
          <div>
            <app-status-label class="status-text"
              [status]="booking.attributes.status">
            </app-status-label>
            <!-- <span class="enquiry-status">{{booking.attributes.status | removeUnderscore}}</span> -->
          </div>
        <!-- </div> -->
      </div>

      <span class="fnt-16 f300">{{booking?.attributes.details?.attributes?.nat_geo_trip?.duration}} days</span>
      <span class="pad-left-8 pad-right-8">|</span>
      <span class="fnt-16 f300"><span class="trip-type-text">From </span><span
          class="rupee-symbol">₹</span><span
          >{{booking?.attributes.details?.attributes?.nat_geo_trip?.inr_lowest_price|indianCurrency}}</span></span>
    </div>
    <div class="desktop-neck d-flex justify-content-between bg">
      <div class="fnt-16">
        <span class="color-grey">Booking ID : </span>
        <span class="f500">{{booking.attributes.unique_id}}</span>
      </div>
      <span (click)="goToDetails(booking)" class="primary-color pointer fnt-14"><span
          class="pad-right-8">View Details</span><svg width="7" height="12" viewBox="0 0 7 12" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 10.5L5.5 6L1.5 1.5" stroke="#C2A63F" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </span>
    </div>


    <div class="desktop-body">

      <div class="pad-top-10 pad-bot-10 flex justify-content-between">
        <span class="color-grey fnt-16 f300">Date</span><span class="fnt-16 f500">{{booking.attributes.details?.attributes?.depart_date}}</span>
      </div>
      <hr>
      <div class="pad-top-10 pad-bot-10 flex justify-content-between">
        <span class="color-grey fnt-16 f300">Group Size</span><span class="fnt-16 f500">{{booking.attributes.details?.attributes?.group_size}}</span>
      </div>
      <hr>
      <div class="pad-top-10 pad-bot-10 flex justify-content-between">
        <span class="color-grey fnt-16 f300">Activity Type</span><span class="fnt-16 f500">{{booking.attributes.details?.attributes?.trip_label}}</span>
      </div>
      <hr>
      <div class="pad-top-10 pad-bot-10 flex justify-content-between">
        <span class="color-grey fnt-16 f300">Activity Level</span><span class="fnt-16 f500">{{booking.attributes.details?.attributes?.nat_geo_trip?.activity_level}}</span>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center w100 my-5">
  <div class="dot-flashing" *ngIf="progress"></div>
</div>
