import { DatePipe, KeyValue } from "@angular/common";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { appConstants } from "src/app/app.constants";
import { PointsTransferService } from "src/app/points-transfer/points-transfer.service";
import { DataService } from "src/app/services/common/data/data.service";
import { LoginService } from "src/app/services/login/login.service";
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";

@Component({
  selector: "app-points-transfer-history",
  templateUrl: "./points-transfer-history.component.html",
  styleUrls: ["./points-transfer-history.component.scss"],
})
export class PointsTransferHistoryComponent {
  public bookingsData: { [date: string]: any[] } = {};
  public progress: boolean = true;
  public appConstants = appConstants;
  public ObjectKeys = Object.keys;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public login: LoginService,
    private ptsService: PointsTransferService,
    private dataService: DataService
  ) {}

  public ngOnInit(): void {
    this.login
      .getUserDetails()
      // .toPromise()
      .subscribe(
        (result: any) => {
          if (result && result.id) {
            // get points transfer booking data
            this.getPointsTransferBookingData();
          } else {
            this.dialog
              .open(SbicLoginComponent, {
                width: "450px",
                height: "70vh",
                disableClose: true,
              })
              .afterClosed()
              .subscribe((res) => {
                if (res == "true") {
                  this.ngOnInit();
                } else if (res == "redirect") {
                  history.back();
                }
              });
          }
        },
        (error) => {
          this.dialog
            .open(SbicLoginComponent, {
              width: "450px",
              height: "70vh",
              disableClose: true,
            })
            .afterClosed()
            .subscribe((res) => {
              if (res == "true") {
                this.ngOnInit();
              } else if (res == "redirect") {
                history.back();
              }
            });
        }
      );
  }

  public navigateToBookingDetails(bookingId: string) {
    this.router.navigate([`points-transfer`, "booking-details"], {
      queryParams: { id: bookingId },
    });
  }

  public dateSorting = (
    akv: KeyValue<string, any>,
    bkv: KeyValue<string, any>
  ): number => {
    const a = akv?.key;
    const b = bkv?.key;
    if (a === "Today") {
      return -1;
    }
    if (b === "Today") {
      return 1;
    }
    if (a === "Yesterday") {
      return -1;
    }
    if (b === "Yesterday") {
      return 1;
    }
    return a > b ? -1 : b > a ? 1 : 0;
  };

  private getPointsTransferBookingData(): void {
    this.progress = true;
    this.ptsService.getBookingsData().subscribe(
      (res: any) => {
        this.progress = false;
        const bookingsData = {};
        res?.data?.map((bookingData: any) => {
          const dateString = this.getDateString(
            bookingData?.attributes?.created_at
          );
          bookingsData[dateString] = bookingsData[dateString]
            ? [...bookingsData[dateString], bookingData]
            : [bookingData];
        });
        this.bookingsData = bookingsData;
      },
      (err: any) => {
        this.progress = true;
        this.dataService.triggerToastr(
          "error",
          "Something went wrong. Please try again later."
        );
      }
    );
  }

  private getDateString(date: string) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const inpDate = new Date(date);
    if (
      inpDate.getFullYear() == today.getFullYear() &&
      inpDate.getMonth() == today.getMonth() &&
      inpDate.getDate() == today.getDate()
    )
      return "Today";
    else if (
      inpDate.getFullYear() == yesterday.getFullYear() &&
      inpDate.getMonth() == yesterday.getMonth() &&
      inpDate.getDate() == yesterday.getDate()
    )
      return "Yesterday";
    else {
      return new DatePipe("en-US").transform(inpDate, "MMM dd, yyyy");
    }
  }
}
