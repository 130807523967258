<div>
  <div class="container">
    <div class="history-top-bar">
      <div class="back-to-listing d-none d-lg-block">
        <!-- <i (click)="backToListing()" class="fa fa-arrow-left" aria-hidden="true"></i>
    Back -->
      </div>
      <div class="back-button d-block d-lg-none" (click)="backToListing()">
        <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.469669 5.46967ZM17 5.25L1 5.25V6.75L17 6.75V5.25Z"
            fill="#C2A63F" />
        </svg>
      </div>
      <div class="h100 booking-history gold">Booking History</div>
    </div>
    <div class="d-flex">
      <div class="tabs-1 col-12 no-padding">
        <span *ngFor="let app of apps;">
          <span *ngIf="app.url" (click)="makeActiveTab(app.id)" [class]="app.id==activeTab?'tab-item pointer active':'tab-item pointer'">
            <a class="tab-link">{{app.name}}</a>
          </span>
        </span>
        <!-- <span (click)="makeActiveTab(101)" [class]="activeTab === 101?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Concierge Services</a>
        </span>
        <span (click)="makeActiveTab(102)" [class]="activeTab === 102?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Offers</a>
        </span> -->

        <!-- <span (click)="activeTab=1" [class]="activeTab==1?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Flights</a>
        </span>
        <span (click)="activeTab=2" [class]="activeTab==2?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Hotels</a>
        </span>
        <span (click)="activeTab=3" [class]="activeTab==3?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Nat Geo Expeditions</a>
        </span>
        <span (click)="activeTab=5" [class]="activeTab==5?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Giftcards</a>
        </span>
        <span (click)="activeTab=6" [class]="activeTab==6?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Activities & Experiences</a>
        </span>
        <span (click)="activeTab=7" [class]="activeTab==7?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Charitable Donations</a>
        </span>
        <span (click)="activeTab=8" [class]="activeTab==8?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Golf</a>
        </span>
        <span (click)="activeTab=9" [class]="activeTab==9?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Experience Fashion</a>
        </span>
        <span (click)="activeTab=10" [class]="activeTab==10?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">Gifting</a>
        </span>
        <span (click)="activeTab=11" [class]="activeTab==11?'tab-item pointer active':'tab-item pointer'">
          <a class="tab-link">DocsApp</a>
        </span> -->

      </div>
    </div>
    <app-points-transfer-history *ngIf="activeTab===24"></app-points-transfer-history>
    <app-flights-history *ngIf="activeTab===1"></app-flights-history>
    <app-hotel-booking-history *ngIf="activeTab===2"></app-hotel-booking-history>
    <app-nat-geo-history *ngIf="activeTab===9"></app-nat-geo-history>
    <app-giftcard-booking-history *ngIf="activeTab===4"></app-giftcard-booking-history>
    <app-tours-history *ngIf="activeTab===3"></app-tours-history>
    <app-charity-history *ngIf="activeTab===5"></app-charity-history>
    <app-dining-history *ngIf="activeTab===6"></app-dining-history>
    <app-golf-history *ngIf="activeTab===11"></app-golf-history>
    <app-designer-history *ngIf="activeTab===12"></app-designer-history>
    <app-gifting-history *ngIf="activeTab===10"></app-gifting-history>
    <app-docsapp-history *ngIf="activeTab===16"></app-docsapp-history>

    <app-concierge-services-history *ngIf="activeTab===101"></app-concierge-services-history>
    <app-offers-history *ngIf="activeTab===102"></app-offers-history>
    <app-airport-con-history *ngIf="activeTab===8"></app-airport-con-history>
    <app-event-history *ngIf="activeTab===7"></app-event-history>

  </div>
</div>
