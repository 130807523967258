
<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over" class="d-block d-sm-none" style="width: 15em;position: fixed; background: var(--dark-blue);">
    <div class="logomain" (click)="navigateToHome()">
      <img class="img-fluid rewards-hub-header-logo pad-right-8" src="assets/sbic-images/logo.png" style="width: 200px;">
    </div>
    <div class="category d-flex align-items-center justify-content-between">
      <div>
        Categories
      </div>
    </div>
    <div class="app-list">
      <div class="app-list-item" *ngFor="let app of apps; let i = index;">
        <div *ngIf="i<5" (click)="onAppChange(app.url)" class="text-truncate">
          <span>
            <span [class]="app.avatar"></span>
          </span>
          <span>
            {{app.name}}
          </span>
        </div>
        <div *ngIf="i>=5 && appSideNavOpen" (click)="onAppChange(app.url)" class="text-truncate">
          <span>
            <span [class]="app.avatar"></span>
          </span>
          <span>
            {{app.name}}
          </span>
        </div>
      </div>
      <div class="app-list-item" (click)="toggleAppSideNavOpen()">
        <div *ngIf="!appSideNavOpen" class="d-flex align-items-center justify-content-between">
          <div>View All</div>
          <div>
            <i class='fa fa-angle-down downarrow'></i>
          </div>
        </div>
        <div *ngIf="appSideNavOpen" class="d-flex align-items-center justify-content-between">
          <div>View Less</div>
          <div>
            <i class='fa fa-angle-up downarrow'></i>
          </div>
        </div>
      </div>
    </div>
      <div>
        <div *ngIf="!data.isAuthenticated()" class="checkinout">
          <button mat-button class="primary-btn log-in" (click)="openDialog()">
            Login / Sign Up
          </button>
        </div>
        <div class="" *ngIf="data.isAuthenticated()">
          <div class="category d-flex align-items-center justify-content-between" (click)="toggleAccountSideNavOpen()">
            <div>
              My Account
            </div>
            <div>
              <i [class]="accountSideNavOpen == false ? 'fa fa-angle-up downarrow' : 'fa fa-angle-down downarrow'"></i>
            </div>
          </div>
          <div class="login-app-list" *ngIf="!accountSideNavOpen">
            <div class="app-list-item d-flex justify-content-start align-items-center" (click)="openBookingHistory()">
              <div class="icon-historyicon mar-top-5 mar-right-11 fnt-16"></div>
              <div>
                Booking History
              </div>
            </div>
            <div class="app-list-item d-flex justify-content-start align-items-center" (click)="openMyAccount()">
              <div class="icon-myaccount mar-top-5 mar-right-11 fnt-16"></div>
              <div>
                My Account
              </div>
            </div>
            <div class="app-list-item d-flex justify-content-start align-items-center" (click)="logout()">
              <div class="icon-logout mar-top-5 mar-right-11 fnt-16"></div>
              <div>
                Logout
              </div>
            </div>
          </div>
        </div>

        <!-- <button *ngIf="data.login" mat-button [matMenuTriggerFor]="menu">
          <svg class="pad-right-4" width="20" height="20" viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.0001 9.99996C12.5784 9.99996 14.6667 7.91162 14.6667 5.33329C14.6667 2.75496 12.5784 0.666626 10.0001 0.666626C7.42175 0.666626 5.33341 2.75496 5.33341 5.33329C5.33341 7.91162 7.42175 9.99996 10.0001 9.99996ZM10.0001 12.3333C6.88508 12.3333 0.666748 13.8966 0.666748 17V19.3333H19.3334V17C19.3334 13.8966 13.1151 12.3333 10.0001 12.3333Z"
              fill="white" />
          </svg>
          <i class='fa fa-angle-down'></i>
        </button> -->
      </div>
      <!-- <mat-list-item routerLink="1" routerLinkActive="active" >
        <mat-icon mat-list-icon>person</mat-icon>
        <div fxFlex="10"></div>
        <div class="sidenav-item">
          <h5 class="lead">About</h5>
        </div>
      </mat-list-item>

      <mat-list-item routerLink="2" routerLinkActive="active">
        <mat-icon mat-list-icon>settings</mat-icon>
        <div fxFlex="10"></div>
        <div class="sidenav-item">
          <h5 class="lead">Services</h5>
        </div>
      </mat-list-item>

      <mat-list-item routerLink="3" routerLinkActive="active">
        <mat-icon mat-list-icon>local_phone</mat-icon>
        <div fxFlex="10"></div>
        <div class="sidenav-item">
          <h5 class="lead">Contact</h5>
        </div>
      </mat-list-item> -->
    <!-- </mat-nav-list> -->
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header [sidenav]="sidenav" (headerUpdate)="headerUpdate($event)" style="display: block; position: relative; z-index : 999;"></app-header>
    <!-- <app-header></app-header> -->
    <div class="app" [ngClass]="{'post-login': isAuthenticated, 'is-home-page': !hidePageBottom}" >
      <router-outlet *ngIf="!login.logoutProgress"></router-outlet>
      <app-loader *ngIf="login.logoutProgress"></app-loader>
    </div>
    <app-footer class="app-footer1" [isAuthenticated]="isAuthenticated"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>