<div class="page container" *ngIf="!progress">
  <div class="row">
      <div class="col-0 col-sm-0 col-md-0 col-lg-3 details-block">
        <div class="d-none d-sm-block basic-profile">
          <div class="head-profile d-flex flex-column align-items-center justify-content-center">
            <div class="head-profile-icon mar-bot-20">
              <span class="icon-user"></span>
            </div>
            <div class="">
              <span class="fnt-16 ft-wt-400 font-grey">{{basicProfile.mobile}}</span>
            </div>
            <div class="mar-bot-20">
              <span class="fnt-16 ft-wt-400 font-grey">{{basicProfile.email}}</span>
            </div>
          </div>
          <hr>
          <div>
            <div class="d-flex align-items-center justify-content-between pad-10 pointer" (click)="openBookingHistory()">
              <span class="d-flex">
                <span class="icon-bookings mar-top-2 mar-right-5"></span><span>Bookings</span>
              </span>
              <span class="font-green">
                {{bookingInfo?.booking_count}}
              </span>
            </div>
            <div class="d-flex align-items-center justify-content-between pad-10 pointer" (click)="openBookingHistory()">
              <span class="d-flex">
                <span class="icon-enquiries mar-top-2 mar-right-5"></span><span>Enquiries</span>
              </span>
              <span class="font-green">
                {{bookingInfo?.enquiryies_count}}
              </span>
            </div>
            <div class="d-flex align-items-center justify-content-between pad-10">
              <span class="d-flex">
                <span class="icon-paidbycard mar-top-2 mar-right-5"></span><span>Paid by Card</span>
              </span>
              <span class="font-green">
                <span class="rupee-symbol">₹</span>{{bookingInfo?.paid_by_cards | indianCurrency}}
              </span>
            </div>
            <div class="d-flex align-items-center justify-content-between pad-10">
              <span class="d-flex">
                <span class="icon-paidbypoints mar-top-2 mar-right-5"></span><span>Paid by Points</span>
              </span>
              <span class="font-green">
                <span class="rupee-symbol">₹</span>{{bookingInfo?.paid_by_points | indianCurrency}}
              </span>
            </div>
          </div>
          <!-- <div class="d-flex flex-column align-items-center pad-top-10 pad-bot-10" style="text-align: center;">
            <div class="upper-part">
              <span class="icon-star ft-wt-400 mar-right-10 mar-top-3"></span>
              <span class="ft-wt-400">{{bookingInfo?.booking_count}} </span>
            </div>
            <div class="bottom-part">
             <span class="ft-wt-400">Number of Bookings</span>
            </div>
          </div>
          <hr>
          <div class="d-flex flex-column align-items-center pad-top-10 pad-bot-10" style="text-align: center;">
            <div class="upper-part">
              <span class="icon-search ft-wt-400 mar-right-10 mar-top-3"></span>
              <span class="ft-wt-400">{{bookingInfo?.enquiryies_count}} </span>
            </div>
            <div class="bottom-part">
             <span class="ft-wt-400">Number of Enquiries</span>
            </div>
          </div>
          <hr>
          <div class="d-flex flex-column align-items-center pad-top-10 pad-bot-10" style="text-align: center;">
            <div class="upper-part">
              <span class="ft-wt-400"><span class="rupee-symbol">₹</span>{{bookingInfo?.paid_by_cards | indianCurrency}} </span>
            </div>
            <div class="bottom-part">
             <span class="ft-wt-400">Total Spends</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-0 col-lg-9 details-block">
          <div class="basic-profile d-block d-sm-none">
            <div *ngIf="bookingdetailsprogress" class="d-flex align-items-center justify-content-center" style="height: 2em;">
              <div class="dot-flashing"></div>
            </div>
            <div *ngIf="!bookingdetailsprogress">
              <div>
                <div class="d-flex align-items-center justify-content-between pad-10">
                  <span class="d-flex">
                    <span class="icon-bookings mar-top-2 mar-right-5"></span><span>Bookings</span>
                  </span>
                  <span class="font-green">
                    {{bookingInfo?.booking_count}}
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-between pad-10">
                  <span class="d-flex">
                    <span class="icon-enquiries mar-top-2 mar-right-5"></span><span>Enquiries</span>
                  </span>
                  <span class="font-green">
                    {{bookingInfo?.enquiryies_count}}
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-between pad-10">
                  <span class="d-flex">
                    <span class="icon-paidbycard mar-top-2 mar-right-5"></span><span>Paid by Card</span>
                  </span>
                  <span class="font-green">
                    <span class="rupee-symbol">₹</span>{{bookingInfo?.paid_by_cards | indianCurrency}}
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-between pad-10">
                  <span class="d-flex">
                    <span class="icon-paidbypoints mar-top-2 mar-right-5"></span><span>Paid by Points</span>
                  </span>
                  <span class="font-green">
                    <span class="rupee-symbol">₹</span>{{bookingInfo?.paid_by_points | indianCurrency}}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="basic-profile">
              <div class="profile_heading d-flex align-items-center justify-content-between">
                  <h3>Account Details</h3>
                  <div class="add-traveller-but" *ngIf="!addPrimaryFlag" (click)="toggleAddPrimary()">
                      <div class="">
                          <span class=""></span>
                          <span>Edit</span>
                      </div>
                  </div>
              </div>
              <div class="d-flex align-items-center justify-content-start profile_detail">
                  <div class="profile_detail_head">
                      Email
                  </div>
                  <div class="profile_detail_value">
                      <span>{{basicProfile.email}}</span>
                  </div>
              </div>
              <hr>
              <div class="d-flex align-items-center justify-content-start profile_detail">
                  <div class="profile_detail_head">
                      Mobile
                  </div>
                  <div class="profile_detail_value">
                      <span>{{basicProfile.mobile}}</span>
                  </div>
              </div>
              <hr>
              <div class="d-flex align-items-center justify-content-start profile_detail">
                  <div class="profile_detail_head">
                      Name
                  </div>
                  <div class="profile_detail_value">
                      <span *ngIf="!basicProfile?.first_name" class="blueify ft-wt-400 pointer" (click)="toggleAddPrimary()">+ Add</span>
                      <span *ngIf="basicProfile?.first_name">{{basicProfile.first_name}} {{basicProfile.last_name}}</span>
                  </div>
              </div>
              <hr>
              <div class="d-flex align-items-center justify-content-start profile_detail">
                  <div class="profile_detail_head">
                      Gender
                  </div>
                  <div class="profile_detail_value">
                      <span *ngIf="!basicProfile?.gender" class="blueify ft-wt-400 pointer" (click)="toggleAddPrimary()">+ Add</span>
                      <span *ngIf="basicProfile?.gender">{{basicProfile?.gender}}</span>
                  </div>
              </div>
              <hr>
              <div class="d-flex align-items-center justify-content-start profile_detail">
                  <div class="profile_detail_head">
                      Date of Birth
                  </div>
                  <div class="profile_detail_value">
                      <span *ngIf="!basicProfile?.date_of_birth" class="blueify ft-wt-400 pointer" (click)="toggleAddPrimary()">+ Add</span>
                      <span *ngIf="basicProfile?.date_of_birth">{{basicProfile?.date_of_birth | date : 'MMM dd, yyyy'}}</span>
                  </div>
              </div>
              <div *ngIf="addPrimaryFlag">
                  <hr>
                  <form class="mobile-form" [formGroup]="enquiryFormPrimary" (ngSubmit)="onSubmitPrimary()">
                      <div class="d-flex align-items-center justify-content-between">
                          <div>
                          </div>
                          <div class="pad-right-15 pointer">
                              <span class="fnt-18 ft-wt-400 blueify" (click)="toggleAddPrimary()"><span class="icon-cancel"></span> </span>
                          </div>
                      </div>
                      <div class="form-section row no-margin">
                        <div class="col-md-12 col-lg-6">
                          <div class="enquiry-form-labels">First Name</div>
                          <div class="enquiry-form-control">
                            <input formControlName="first_name" type="text" required>
                          </div>
                          <div *ngIf="submitted && enquiryFormPrimary.controls.first_name.errors" class="invalid-feedback show">
                            <div *ngIf="enquiryFormPrimary.controls.first_name.errors">Please enter valid first name</div>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-6">
                          <div class="enquiry-form-labels">Last Name</div>
                          <div class="enquiry-form-control">
                            <input formControlName="last_name" type="text" required>
                          </div>
                          <div *ngIf="submitted && enquiryFormPrimary.controls.last_name.errors" class="invalid-feedback show">
                            <div *ngIf="enquiryFormPrimary.controls.last_name.errors">Please enter valid last name</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-section row no-margin">
                          <div class="col-md-12 col-lg-6 pointer" dropdown>
                              <div class="relative">
                                  <div class="enquiry-form-labels">Gender</div>
                                  <div class="enquiry-form-control" dropdownToggle>
                                    <input readonly class="padding-input" type="text" formControlName="gender">
                                    <span class="icon-down-open-mini side-icon"></span>
                                  </div>
                                  <div *dropdownMenu class="dropdown-menu">
                                      <div class="block loc-results">
                                      <div class="option-item ellipsis" *ngFor="let gender of genders">
                                          <div class="option-item-inner" (mousedown)="addGender(gender)">
                                          {{gender}}
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                              </div>
                              <div
                                *ngIf="enquiryFormPrimary.controls.gender.errors && submitted"
                                class="invalid-feedback show">
                                <!-- <div
                                  *ngIf="enquiryFormPrimary.controls.gender.errors.required">
                                  Gender is required</div> -->
                              </div>
                          </div>
                          <div class="col-md-12 col-lg-6">
                              <div class="enquiry-form-labels">Date of Birth</div>
                              <div class="enquiry-form-control pointer">
                                <input placeholder="DD/MM/YYYY" formControlName="date_of_birth" readonly
                                  (click)="toggleCalendar('dobPrimary')" [minDate]="{year: 1900, month: 1, day: 1}"
                                  [maxDate]="maxDate" type="text" ngbDatepicker
                                  #d_o_b_primary="ngbDatepicker" />
                              <span class="icon-down-open-mini side-icon"></span>
                              </div>
                              <div
                                *ngIf="enquiryFormPrimary.controls.date_of_birth.errors  && submitted"
                                class="invalid-feedback show">
                                <!-- <div
                                  *ngIf="enquiryFormPrimary.controls.date_of_birth.errors.required">
                                  Date of birth is required.</div> -->
                                <div
                                  *ngIf="!enquiryFormPrimary.controls.date_of_birth.errors.required">
                                  Date of birth is invalid</div>
                              </div>
                          </div>
                      </div>
                      <div class="w100 pad-top-15 pad-left-15 pad-bot-5 ">
                        <button class="primary-btn pad-right-34 pad-left-34 height-45" mat-button
                          (click)="onSubmitPrimary()"><span class="pad-right-34 pad-left-34">Save</span></button>
                      </div>
                  </form>
              </div>
          </div>
          <div class="basic-profile">
              <div class="profile_heading d-flex align-items-center justify-content-between mar-bot-10">
                  <h3>Saved Traveller(s)</h3>
                  <div class="add-traveller-but" (click)="addNewTraveller()">
                      <div class="">
                          <span class=""></span>
                          <span>Add Traveller</span>
                      </div>
                  </div>
              </div>
              <div *ngIf="!savedTravellers" class="pad-20">
                  <span>You have no traveller saved.</span>
              </div>
              <div *ngIf="savedTravellers && savedTravellers.length">
                  <div class="d-flex flex-column each-traveller" *ngFor="let traveller of savedTravellers; let i = index;">
                      <div class="d-flex align-items-center justify-content-between" (click)="toggleEditWindow(i, traveller)">
                          <div class="d-flex align-items-center justify-content-start">
                              <div class="profile-icon">
                                  <span class="icon-user"></span>
                              </div>
                              <div class="profile-detail">
                                  <div class="profile-detail-main">
                                      <div class="profile-detail-one text-truncate">
                                          <span class="ft-wt-400">{{traveller?.title}} </span>
                                          <span class="ft-wt-400">{{traveller?.first_name}} </span>
                                          <span class="ft-wt-400">{{traveller?.last_name}} </span>
                                          <span class="font-grey fnt-14" *ngIf="traveller?.gender || traveller.date_of_birth">
                                              (
                                              <span class="" *ngIf="traveller?.gender">{{traveller?.gender | titlecase}},</span>
                                              <span class="" *ngIf="traveller.date_of_birth">  {{getAge(traveller.date_of_birth) == 0 ? '< 1' : getAge(traveller.date_of_birth) }} {{getAge(traveller.date_of_birth) <=1 ? 'year' : 'years'}} </span>
                                              )
                                          </span>
                                      </div>
                                      <div class="d-none d-sm-block">
                                        <div class="profile-detail-two">
                                          <span class="font-grey">{{traveller?.mobile_number}}</span>
                                        </div>
                                      </div>
                                      <div class="d-none d-sm-block">
                                        <div class="profile-detail-three">
                                          <span class="font-grey">{{traveller?.email}}</span>
                                        </div>
                                      </div>

                                  </div>
                                  <div class="d-block d-sm-none">
                                    <div class="profile-detail-others text-truncate " *ngIf="traveller?.mobile_number || traveller?.email">
                                      <span class="font-grey"><span class="ft-wt-400">{{traveller?.mobile_number}} {{traveller?.email ? '|' :''}} {{traveller?.email}}</span></span>
                                    </div>
                                  </div>
                                  <div class="profile-detail-others text-truncate" *ngIf="traveller?.passport_number">
                                    <span class="font-grey"><span class="ft-wt-400">Passport number :</span> {{traveller?.passport_number}} <span *ngIf="traveller?.passport_exp_date" class="hide-mobile">(Expiry : {{traveller?.passport_exp_date | date: 'MMM dd, yyyy'}})</span></span>
                                  </div>
                                  <div class="d-block d-sm-none">
                                    <div class="profile-detail-others text-truncate " *ngIf="traveller?.passport_number">
                                      <span class="font-grey"><span class="ft-wt-400">Passport expiry : {{traveller?.passport_exp_date | date: 'MMM dd, yyyy'}}</span></span>
                                    </div>
                                  </div>
                              </div>
                          </div>
                          <div class="view-detail dont-hide">
                              <span class="ft-wt-400 blueify" *ngIf="!editWindow[i]">
                                <span class="icon-pencil"></span>
                              </span>
                              <span class="fnt-18 ft-wt-400 blueify" *ngIf="editWindow[i]"><span class="icon-cancel"></span> </span>
                          </div>
                      </div>
                      <div class="edit-traveller" *ngIf="editWindow[i]">
                          <form class="mobile-form" [formGroup]="enquiryForm" (ngSubmit)="onSubmit(i)">
                              <div class="form-section row no-margin">
                                  <div class="col-md-12 col-lg-2 pointer" dropdown>
                                      <div class="enquiry-form-labels">Title</div>
                                      <div class="enquiry-form-control" dropdownToggle>
                                        <input readonly class="padding-input" type="text" formControlName="title">
                                        <span class="icon-down-open-mini side-icon"></span>
                                      </div>
                                      <div *dropdownMenu class="dropdown-menu">
                                            <div class="block loc-results">
                                              <div class="option-item ellipsis" *ngFor="let title of titles">
                                                <div class="option-item-inner" (mousedown)="addTitle(title);">
                                                  {{title}}
                                                </div>
                                              </div>
                                            </div>
                                      </div>
                                      <div
                                        *ngIf="enquiryForm.controls.title.errors && submitted"
                                        class="invalid-feedback show">
                                        <div
                                          *ngIf="enquiryForm.controls.title.errors.required">
                                          Title is required</div>
                                      </div>
                                  </div>
                                <div class="col-md-12 col-lg-5">
                                  <div class="enquiry-form-labels">First Name</div>
                                  <div class="enquiry-form-control">
                                    <input formControlName="first_name" type="text" required>
                                  </div>
                                  <div *ngIf="submitted && enquiryForm.controls.first_name.errors" class="invalid-feedback">
                                    <div *ngIf="enquiryForm.controls.first_name.errors">Please enter valid first name</div>
                                  </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                  <div class="enquiry-form-labels">Last Name</div>
                                  <div class="enquiry-form-control">
                                    <input formControlName="last_name" type="text" required>
                                  </div>
                                  <div *ngIf="submitted && enquiryForm.controls.last_name.errors" class="invalid-feedback show">
                                    <div *ngIf="enquiryForm.controls.last_name.errors">Please enter valid last name</div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-section row no-margin">
                                  <div class="col-md-12 col-lg-2 pointer" dropdown>
                                      <div class="relative">
                                          <div class="enquiry-form-labels">Gender</div>
                                          <div class="enquiry-form-control" dropdownToggle>
                                          <input readonly class="padding-input" type="text" formControlName="gender">
                                          <span class="icon-down-open-mini side-icon"></span>
                                          </div>
                                          <div *dropdownMenu class="dropdown-menu">
                                              <div class="block loc-results">
                                              <div class="option-item ellipsis" *ngFor="let gender of genders">
                                                  <div class="option-item-inner" (mousedown)="addGender(gender)">
                                                  {{gender}}
                                                  </div>
                                              </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div
                                        *ngIf="enquiryForm.controls.gender.errors && submitted"
                                        class="invalid-feedback show">
                                        <!-- <div
                                          *ngIf="enquiryForm.controls.gender.errors.required">
                                          Gender is required</div> -->
                                      </div>
                                  </div>
                                <div class="col-md-12 col-lg-5">
                                  <div class="enquiry-form-labels">Mobile Number</div>
                                  <div class="enquiry-form-control"><input formControlName="mobile_number" type="tel" maxlength="10"
                                      (input)="removeLetter($event.target)"></div>
                                  <div *ngIf="submitted && enquiryForm.controls.mobile_number.errors" class="invalid-feedback show">
                                    <div *ngIf="enquiryForm.controls.mobile_number.errors">Mobile must be valid</div>
                                  </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                  <div class="enquiry-form-labels">Email Address</div>
                                  <div class="enquiry-form-control"><input formControlName="email" type="email"></div>
                                  <div *ngIf="submitted &&  enquiryForm.controls.email.errors" class="invalid-feedback show">
                                    <div *ngIf="enquiryForm.controls.email.errors.required">Email is required</div>
                                    <div *ngIf="enquiryForm.controls.email.errors.email || enquiryForm.controls.email.errors.pattern">
                                      Email
                                      must be a valid email address</div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-section row no-margin">
                                  <div class="col-md-12 col-lg-4">
                                      <div class="enquiry-form-labels">Date of Birth</div>
                                      <div class="enquiry-form-control pointer">
                                        <input placeholder="DD/MM/YYYY" formControlName="date_of_birth" readonly
                                          (click)="toggleCalendar('dobAdults')" [minDate]="{year: 1900, month: 1, day: 1}"
                                          [maxDate]="maxDate" type="text" ngbDatepicker
                                          #d_o_b_a="ngbDatepicker" />
                                          <span class="icon-down-open-mini side-icon"></span>
                                      </div>
                                      <div
                                        *ngIf="enquiryForm.controls.date_of_birth.errors  && submitted"
                                        class="invalid-feedback show">
                                        <!-- <div
                                          *ngIf="enquiryForm.controls.date_of_birth.errors.required">
                                          Date of birth is required.</div> -->
                                        <div
                                          *ngIf="!enquiryForm.controls.date_of_birth.errors.required">
                                          Date of birth is invalid</div>
                                      </div>
                                  </div>
                                  <div class="col-md-12 col-lg-4" dropdown>
                                  <div class="enquiry-form-labels">Nationality</div>
                                  <div class="enquiry-form-control">
                                      <div style="position: relative;">
                                      <div class="d-flex align-items-center" dropdownToggle>
                                          <input type="text"  #adultNationalityInput
                                          (input)="detectChanges();" class="text-truncate" [value]="enquiryForm.controls.nationality.value">
                                          <span class="pos-a mar-right-10" style="right: 0;">
                                          <i
                                              [class]="true ? 'fa fa-angle-up downarrow' : 'fa fa-angle-down downarrow'"></i>
                                          </span>
                                      </div>
                                      <div *dropdownMenu class="dropdown-menu">
                                          <div class="block loc-results"
                                              *ngIf="(nationalityList | filter: adultNationalityInput.value ) as result">
                                              <div class="option-item ellipsis" *ngFor="let nation of result">
                                              <div class="option-item-inner"
                                                  (mousedown)="addNation(nation)">
                                                  {{nation}}
                                              </div>
                                              </div>
                                              <div class="option-item ellipsis" *ngIf="result.length == 0">
                                              <div class="option-item-inner">
                                                  No results found
                                              </div>
                                              </div>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <div
                                      *ngIf="enquiryForm.controls.nationality.errors  && submitted"
                                      class="invalid-feedback show">
                                      <!-- <div
                                      *ngIf="enquiryForm.controls.nationality.errors.required">
                                      Nationality is required</div> -->
                                  </div>
                                  </div>
                                  <div class="col-md-12 col-lg-4">
                                      <div class="enquiry-form-labels">Passport Number</div>
                                      <div class="enquiry-form-control">
                                        <input type="text" formControlName="passport_number">
                                      </div>
                                      <div
                                        *ngIf="enquiryForm.controls.passport_number.errors && submitted"
                                        class="invalid-feedback show">
                                        <!-- <div
                                          *ngIf="enquiryForm.controls.passport_number.errors.required">
                                          Passport Number is required</div> -->
                                      </div>
                                    </div>
                              </div>
                              <div class="form-section row no-margin">
                                  <div class="col-md-12 col-lg-4">
                                      <div class="enquiry-form-labels">Passport Issue Date</div>
                                      <div class="enquiry-form-control pointer">
                                        <input placeholder="DD/MM/YYYY" formControlName="passport_issue_date" readonly
                                          (click)="toggleCalendar('pidAdults')" [minDate]="{year: 1900, month: 1, day: 1}"
                                          [maxDate]="maxDate" type="text" ngbDatepicker
                                          #p_i_d_a="ngbDatepicker" />
                                          <span class="icon-down-open-mini side-icon"></span>
                                      </div>
                                      <div
                                        *ngIf="enquiryForm.controls.passport_issue_date.errors  && submitted"
                                        class="invalid-feedback show">
                                        <!-- <div
                                          *ngIf="enquiryForm.controls.passport_issue_date.errors.required">
                                          Passport issue date is required</div> -->
                                      </div>
                                  </div>
                                  <div class="col-md-12 col-lg-4">
                                      <div>
                                        <div class="enquiry-form-labels">Passport Expiry Date</div>
                                        <div class="enquiry-form-control pointer">
                                          <input placeholder="DD/MM/YYYY" formControlName="passport_exp_date" readonly [minDate]="maxDate"
                                            (click)="toggleCalendar('pedAdults')" type="text"
                                            ngbDatepicker #p_e_d_a="ngbDatepicker" />
                                          <span class="icon-down-open-mini side-icon"></span>
                                        </div>
                                        <div
                                          *ngIf="enquiryForm.controls.passport_exp_date.errors  && submitted"
                                          class="invalid-feedback show">
                                          <!-- <div
                                            *ngIf="enquiryForm.controls.passport_exp_date.errors.required">
                                            Passport expiry date is required</div> -->
                                          <div
                                            *ngIf="enquiryForm.controls.passport_exp_date.errors.ngbDate">
                                            Passport is expired</div>
                                        </div>
                                      </div>
                                    </div>
                              </div>

                              <div class="w100 pad-top-15 pad-left-15 pad-bot-5 ">
                                <button class="primary-btn pad-right-34 pad-left-34 height-45" mat-button
                                  (click)="onSubmit(i)"><span class="pad-right-34 pad-left-34">Save</span></button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>

          </div>
          <div *ngIf="addNewTravellerFlag" class="basic-profile">
              <div class="profile_heading d-flex align-items-center justify-content-between mar-bot-10">
                  <h3>Add Traveller</h3>
                  <div class="" (click)="closeAddTraveller()">
                      <div class="blueify">
                          <span class="fnt-18 ft-wt-400 blueify"><span class="icon-cancel"></span> </span>
                      </div>
                  </div>
              </div>
              <div>
                  <form class="mobile-form" [formGroup]="enquiryForm" (ngSubmit)="onSubmit(null)">
                      <div class="form-section row no-margin">
                          <div class="col-md-12 col-lg-2 pointer" dropdown>
                              <div class="relative">
                                  <div class="enquiry-form-labels">Title</div>
                                  <div class="enquiry-form-control" dropdownToggle>
                                    <input readonly class="padding-input" type="text" formControlName="title">
                                    <span class="icon-down-open-mini side-icon"></span>
                                  </div>
                                  <div *dropdownMenu class="dropdown-menu">
                                        <div class="block loc-results">
                                          <div class="option-item ellipsis" *ngFor="let title of titles">
                                            <div class="option-item-inner" (mousedown)="addTitle(title);">
                                              {{title}}
                                            </div>
                                          </div>
                                        </div>
                                  </div>
                              </div>
                              <div
                                *ngIf="enquiryForm.controls.title.errors && submitted"
                                class="invalid-feedback show">
                                <div
                                  *ngIf="enquiryForm.controls.title.errors.required">
                                  Title is required</div>
                              </div>
                          </div>
                        <div class="col-md-12 col-lg-5">
                          <div class="enquiry-form-labels">First Name</div>
                          <div class="enquiry-form-control">
                            <input formControlName="first_name" type="text" required>
                          </div>
                          <div *ngIf="submitted && enquiryForm.controls.first_name.errors" class="invalid-feedback show">
                            <div *ngIf="enquiryForm.controls.first_name.errors">Please enter valid first name</div>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-5">
                          <div class="enquiry-form-labels">Last Name</div>
                          <div class="enquiry-form-control">
                            <input formControlName="last_name" type="text" required>
                          </div>
                          <div *ngIf="submitted && enquiryForm.controls.last_name.errors" class="invalid-feedback show">
                            <div *ngIf="enquiryForm.controls.last_name.errors">Please enter valid last name</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-section row no-margin">
                          <div class="col-md-12 col-lg-2 pointer" dropdown>
                              <div class="relative">
                                  <div class="enquiry-form-labels">Gender</div>
                                  <div class="enquiry-form-control" dropdownToggle>
                                    <input readonly class="padding-input" type="text" formControlName="gender">
                                    <span class="icon-down-open-mini side-icon"></span>
                                  </div>
                                  <div *dropdownMenu class="dropdown-menu">
                                      <div class="block loc-results">
                                      <div class="option-item ellipsis" *ngFor="let gender of genders">
                                          <div class="option-item-inner" (mousedown)="addGender(gender)">
                                          {{gender}}
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                              </div>
                              <div
                                *ngIf="enquiryForm.controls.gender.errors && submitted"
                                class="invalid-feedback show">
                                <div
                                  *ngIf="enquiryForm.controls.gender.errors.required">
                                  Gender is required</div>
                              </div>
                          </div>
                        <div class="col-md-12 col-lg-5">
                          <div class="enquiry-form-labels">Mobile Number</div>
                          <div class="enquiry-form-control"><input formControlName="mobile_number" type="tel" maxlength="10"
                              (input)="removeLetter($event.target)"></div>
                          <div *ngIf="submitted && enquiryForm.controls.mobile_number.errors" class="invalid-feedback show">
                            <div *ngIf="enquiryForm.controls.mobile_number.errors">Mobile must be valid</div>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-5">
                          <div class="enquiry-form-labels">Email Address</div>
                          <div class="enquiry-form-control"><input formControlName="email" type="email"></div>
                          <div *ngIf="submitted &&  enquiryForm.controls.email.errors" class="invalid-feedback show">
                            <div *ngIf="enquiryForm.controls.email.errors.required">Email is required</div>
                            <div *ngIf="enquiryForm.controls.email.errors.email || enquiryForm.controls.email.errors.pattern">
                              Email
                              must be a valid email address</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-section row no-margin">
                          <div class="col-md-12 col-lg-4">
                              <div class="enquiry-form-labels">Date of Birth</div>
                              <div class="enquiry-form-control pointer">
                                <input placeholder="DD/MM/YYYY" formControlName="date_of_birth" readonly
                                  (click)="toggleCalendar('dobAdults')" [minDate]="{year: 1900, month: 1, day: 1}"
                                  [maxDate]="maxDate" type="text" ngbDatepicker
                                  #d_o_b_a="ngbDatepicker" />
                                  <span class="icon-down-open-mini side-icon"></span>

                              </div>
                              <div
                                *ngIf="enquiryForm.controls.date_of_birth.errors  && submitted"
                                class="invalid-feedback show">
                                <div
                                  *ngIf="enquiryForm.controls.date_of_birth.errors.required">
                                  Date of birth is required.</div>
                                <div
                                  *ngIf="!enquiryForm.controls.date_of_birth.errors.required">
                                  Date of birth is invalid</div>
                              </div>
                          </div>
                          <div class="col-md-12 col-lg-4 pointer" dropdown>
                          <div class="enquiry-form-labels">Nationality</div>
                          <div class="enquiry-form-control">
                              <div style="position: relative;">
                              <div class="d-flex align-items-center" dropdownToggle>
                                  <input type="text"  #adultNationalityInput
                                  (input)="detectChanges();" class="text-truncate" [value]="enquiryForm.controls.nationality.value">
                                  <span class="icon-down-open-mini side-icon"></span>
                              </div>
                              <div *dropdownMenu class="dropdown-menu">
                                  <div class="block loc-results"
                                      *ngIf="(nationalityList | filter: adultNationalityInput.value ) as result">
                                      <div class="option-item ellipsis" *ngFor="let nation of result">
                                      <div class="option-item-inner"
                                          (mousedown)="addNation(nation)">
                                          {{nation}}
                                      </div>
                                      </div>
                                      <div class="option-item ellipsis" *ngIf="result.length == 0">
                                      <div class="option-item-inner">
                                          No results found
                                      </div>
                                      </div>
                                  </div>
                              </div>
                              </div>
                          </div>
                          <div
                              *ngIf="enquiryForm.controls.nationality.errors  && submitted"
                              class="invalid-feedback show">
                              <div
                              *ngIf="enquiryForm.controls.nationality.errors.required">
                              Nationality is required</div>
                          </div>
                          </div>
                          <div class="col-md-12 col-lg-4">
                              <div class="enquiry-form-labels">Passport Number</div>
                              <div class="enquiry-form-control">
                                <input type="text" formControlName="passport_number">
                              </div>
                              <div
                                *ngIf="enquiryForm.controls.passport_number.errors && submitted"
                                class="invalid-feedback show">
                                <div
                                  *ngIf="enquiryForm.controls.passport_number.errors.required">
                                  Passport Number is required</div>
                              </div>
                            </div>
                      </div>
                      <div class="form-section row no-margin">
                          <div class="col-md-12 col-lg-4">
                              <div class="enquiry-form-labels">Passport Issue Date</div>
                              <div class="enquiry-form-control pointer">
                                <input placeholder="DD/MM/YYYY" formControlName="passport_issue_date" readonly
                                  (click)="toggleCalendar('pidAdults')" [minDate]="{year: 1900, month: 1, day: 1}"
                                  [maxDate]="maxDate" type="text" ngbDatepicker
                                  #p_i_d_a="ngbDatepicker" />
                                  <span class="icon-down-open-mini side-icon"></span>
                              </div>
                              <div
                                *ngIf="enquiryForm.controls.passport_issue_date.errors  && submitted"
                                class="invalid-feedback show">
                                <div
                                  *ngIf="enquiryForm.controls.passport_issue_date.errors.required">
                                  Passport issue date is required</div>
                              </div>
                          </div>
                          <div class="col-md-12 col-lg-4">
                              <div>
                                <div class="enquiry-form-labels">Passport Expiry Date</div>
                                <div class="enquiry-form-control pointer">
                                  <input placeholder="DD/MM/YYYY" formControlName="passport_exp_date" readonly [minDate]="maxDate"
                                    (click)="toggleCalendar('pedAdults')" type="text"
                                    ngbDatepicker #p_e_d_a="ngbDatepicker" />
                                  <span class="icon-down-open-mini side-icon"></span>
                                </div>
                                <div
                                  *ngIf="enquiryForm.controls.passport_exp_date.errors  && submitted"
                                  class="invalid-feedback show">
                                  <div
                                    *ngIf="enquiryForm.controls.passport_exp_date.errors.required">
                                    Passport expiry date is required</div>
                                  <div
                                    *ngIf="enquiryForm.controls.passport_exp_date.errors.ngbDate">
                                    Passport is expired</div>
                                </div>
                              </div>
                            </div>
                      </div>

                      <div class="w100 pad-top-15 pad-left-15 pad-bot-5 ">
                        <button class="primary-btn pad-right-34 pad-left-34 height-45" mat-button
                          (click)="onSubmit(null)"><span class="pad-right-34 pad-left-34">Save</span></button>
                      </div>
                  </form>
              </div>
          </div>
          <div class="basic-profile">
            <div class="profile_heading d-flex align-items-center justify-content-between mar-bot-10">
                <h3>Saved Cards(s)</h3>
                <!-- <div class="add-traveller-but" (click)="addNewCard()">
                    <div class="">
                        <span class=""></span>
                        <span>Add Card</span>
                    </div>
                </div> -->
            </div>
            <div *ngIf="!savedcardprogress && !savedCards.length" class="pad-20">
                <span>You have no card saved.</span>
            </div>
            <div *ngIf="!savedcardprogress && savedCards && !savedCards.length" class="d-flex align-center justify-content-center pad-20">
              <span class="dot-flashing"></span>
            </div>
            <div>
              <div *ngIf="savedcardprogress" class="d-flex align-items-center justify-content-center" style="height: 2em;">
                <div class="dot-flashing"></div>
              </div>
              <div *ngIf="!savedcardprogress">
                <div  class="d-flex flex-column each-traveller" *ngFor="let savedCard of savedCards; let i = index;">
                  <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center justify-content-start">
                          <div class="card-icon">
                            <img [src]="getCardLink(savedCard.card_brand)" alt="icon" class="icon-img">
                          </div>
                          <div class="profile-detail">
                              <div class="profile-detail-main">
                                  <div class="profile-detail-one savedcard text-truncate">
                                      <span class="ft-wt-400">{{savedCard.card_no | creditCardMask:4}} </span>
                                  </div>
                                  <div class="profile-detail-two savedcard">
                                      <span class="font-grey">{{savedCard.card_type == 'CC' ? 'Credit Card' : 'Debit Card'}}</span>
                                  </div>
                                  <div class="profile-detail-three savedcard">
                                      <span class="font-grey">Valid till {{savedCard.expiry_month}}/{{savedCard.expiry_year}}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="view-detail dont-hide" (click)="deleteCard(savedCard.card_token)">
                        <span class="ft-wt-400 blueify fnt-16">
                          <span class="icon-trash-o"></span>
                        </span>
                      </div>
                  </div>
              </div >
              </div>

            </div>
          </div>
          <div *ngIf="addNewCardFlag" class="basic-profile">
            <div class="profile_heading d-flex align-items-center justify-content-between mar-bot-10">
                <h3>Add Card</h3>
                <div class="" (click)="closeAddCard()">
                    <div class="blueify">
                        <span class="fnt-18 ft-wt-400 blueify"><span class="icon-cancel"></span> </span>
                    </div>
                </div>
            </div>
            <div>
                <form class="mobile-form" [formGroup]="cardDetailsForm" (ngSubmit)="onSubmitCard()">
                    <div class="form-section row no-margin">
                      <div class="col-md-12 col-lg-6">
                        <div class="enquiry-form-labels">Card Number</div>
                        <div class="enquiry-form-control">
                          <input class="w85 card-number" type="tel" formControlName="ccnum" maxlength="19" minlength="14"
                          (input)="removeLetter1($event.target)" credit-card autocomplete="cc-number" required>
                          <img *ngIf="cardIcon" height="16" src="{{cardIcon}}">
                        </div>
                        <div *ngIf="submitted && cardDetailsForm.controls.ccnum.errors" class="invalid-feedback">
                          <div>Please enter valid Card Number</div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-6">
                        <div class="enquiry-form-labels">Name On Card</div>
                        <div class="enquiry-form-control">
                          <input type="text" formControlName="ccname" autocomplete="cc-name" required>
                        </div>
                        <div *ngIf="submitted && cardDetailsForm.controls.ccname.errors" class="invalid-feedback">
                          <div>Please enter name on card</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-section row no-margin">
                        <div class="col-md-12 col-lg-6">
                          <div class="enquiry-form-labels">Expiry date</div>
                          <div class="enquiry-form-control-1 d-flex justify-content-start align-items-center">
                            <select class="form-control fnt-16" formControlName="expiry_month" style="margin: 0 10px 0 0;" autocomplete="cc-exp">
                              <option label="MM"></option>
                              <option value="1">01</option>
                              <option value="2">02</option>
                              <option value="3">03</option>
                              <option value="4">04</option>
                              <option value="5">05</option>
                              <option value="6">06</option>
                              <option value="7">07</option>
                              <option value="8">08</option>
                              <option value="9">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                            <select class="form-control fnt-16 rounded" formControlName="expiry_year" autocomplete="cc-exp">
                              <option label="YYYY"></option>
                              <option *ngFor="let year of range(2020, 2100);" value="{{year}}">{{year}}</option>
                            </select>
                          </div>
                            <div
                              *ngIf="cardDetailsForm.controls.expiry_month.errors && submitted"
                              class="invalid-feedback show">
                              <div> Expiry date is required</div>
                            </div>
                        </div>
                      <div class="col-md-12 col-lg-6">
                        <div class="enquiry-form-labels">CVV</div>
                        <div class="enquiry-form-control">
                          <input class="w100" type="password" formControlName="ccvv" maxlength="4" minlength="3"
                            (input)="removeLetter($event.target)" autocomplete="cc-csc">
                          </div>
                        <div *ngIf="submitted && cardDetailsForm.controls.ccvv.errors" class="invalid-feedback show">
                          <div>Enter valid CVV</div>
                        </div>
                      </div>
                    </div>
                    <div class="w100 pad-top-15 pad-left-15 pad-bot-5 ">
                      <button class="primary-btn pad-right-34 pad-left-34 height-45" mat-button
                        (click)="onSubmitCard()"><span class="pad-right-34 pad-left-34">Save</span></button>
                    </div>
                </form>
            </div>
        </div>
      </div>
  </div>
</div>
<app-loader *ngIf="progress"></app-loader>