import { Component, OnInit, Input } from "@angular/core";
import { FlightsService } from "src/app/flights/service/flights.service";
import { ActivatedRoute, Router } from "@angular/router";
import swal from "sweetalert2";
import { HotelApiService } from  "src/app/hotel/services/hotel-api/hotel-api.service";
import { appConstants } from 'src/app/app.constants';
import { LoginService } from 'src/app/services/login/login.service';
import { MatDialog } from "@angular/material/dialog";
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import * as _moment from 'moment';
const moment = _moment;
import {IndianCurrency} from 'src/app/shared/pipes/indian-currency.pipe';
import {PaymentService} from 'src/app/shared/components/payment/payment.service';

@Component({
  selector: "app-flights-history-details",
  templateUrl: "./flights-history-details.component.html",
  styleUrls: ["./flights-history-details.component.scss"],
  providers: [ IndianCurrency ]
})
export class FlightsHistoryDetailsComponent implements OnInit {
  bookingId;
  booking;
  airlines;
  baggageType = {
    ADT: "ADULT",
    CHD: "CHILDREN",
    INF: "INFANTS"
  };
  travClass = {
    E: "Economy",
    B: "Business Class",
    F: "First Class",
    P: "Premium Economy"
  };
  params;
  flightDetailsNum;
  flightDetailsReturn;
  flightDetailsOnwReturn;
  userList;
  status;
  progress = true;
  cardIcon;
  paymentDetails;

  // objectKeys = Object.keys;
  constructor(
    public flightS: FlightsService,
    private route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public login: LoginService,
    private hotelApiService: HotelApiService,
    private indianCurrency: IndianCurrency,
    private paymentService: PaymentService
  ) { }

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
      if(result && result.id){
      this.bookingId = this.route.snapshot.queryParamMap.get("id");
      this.paymentService.getPaymentDetails(this.bookingId).then( (res: any) => {
        this.paymentDetails = res.data;
      });
    // localStorage.getItem('flight-history-airlines');
    // this.airlines = JSON.parse(localStorage.getItem("flight-history-airlines"));
    this.flightS.viewItenraryDetails(this.bookingId).then(booking => {
      this.progress = false;
      this.booking = booking;
      this.status = this.booking.flight_trip.booking_status;
      this.airlines = this.booking.flight_trip.airlines;
      this.changeStatus();
      // this.hotelApiService
      // .getCardDetails(this.booking.flight_trip.card_number.slice(0, 6))
      // .subscribe(resp => {
      //   this.cardIcon = this.getCardLink(resp["cardType"]);
      // });
      this.flightDetailsNum = Object.keys(
        this.booking.flight_trip.flights[0].segments
      );

      if (this.booking.flight_trip.flights[1])
        this.flightDetailsReturn = Object.keys(
          this.booking.flight_trip.flights[1].segments
        );
      this.flightDetailsOnwReturn = Object.keys(
        this.booking.flight_trip.flights[0].segments
      ).length;
      this.userList = Object.keys(this.booking.flight_trip.pax_info_list);

    });
  }else{
    this.dialog.open(SbicLoginComponent, {
      width: "450px",
      height: "70vh",
      disableClose: true
    }).afterClosed().subscribe( res=> {
        if(res == 'true'){
        this.ngOnInit();
      }else if(res =='redirect'){
        history.back();
      };
    });
  }
},
(error)=>{
  this.dialog.open(SbicLoginComponent, {
    width: "450px",
    height: "70vh",
  disableClose: true
  }).afterClosed().subscribe( res=> {
      if(res == 'true'){
      this.ngOnInit();
    }else if(res =='redirect'){
      history.back();
    };
  });
});
  }

  getAmountPaidBy(type) {
    if (this.paymentDetails &&
      this.paymentDetails.attributes &&
      this.paymentDetails.attributes.transactions &&
      this.paymentDetails.attributes.transactions.data &&
      this.paymentDetails.attributes.transactions.data.length) {
      for (const each of this.paymentDetails.attributes.transactions.data) {
        if (each.attributes.txn_type == type) {
          return each.attributes.amount ? each.attributes.amount : 0;
        }
      }
    }
    return 0;
  }

  getCardLink(cardType) {
    // return './../../../../assets/' + (appConstants.cardIconsMapping[cardType] ? appConstants.cardIconsMapping[cardType] : appConstants.cardIconsMapping['Unknown']);
    return appConstants.cardIconsMapping[cardType]
      ? "./../../../../assets/" + appConstants.cardIconsMapping[cardType]
      : null;
  }
  objectKeys(object) {
    return Object.keys(object);
  }

  layoverTime(departureTime, arrivalTime) {

    // getFlightTime(departureTime:any, arrivalTime:any){

    let flightDuration =
      new Date(arrivalTime.departure_date).getTime() -
      new Date(departureTime["arrival-date"]).getTime();
    return (
      Math.floor(flightDuration / 3600000) +
      "h " +
      Math.ceil((flightDuration % 3600000) / 60000) +
      "m"
    );
    // }
  }

  convertMyWay(){
    let title = document.title;
    document.title = this.bookingId;
    window.print();
    document.title = title;
  }

  getAirlineName(code) {
    return this.airlines.find(airline => airline.airline_code == code)?.airline_name ?? '';
  }

  getPNR(booking, index) {

    return booking.find(
      (segmentinfo: any) => segmentinfo.segment_index == index
    ).airline_pnr;
  }

  getTicket(booking, index) {
    return booking.find(
      (segmentinfo: any) => segmentinfo.segment_index == index
    ).ticket_number;
  }

  getStops(flight) {
    if (Object.keys(this.booking.flight_trip.flights[0].segments).length == 1) {
      return "Non-Stop";
    } else
      return (
        Object.keys(this.booking.flight_trip.flights[0].segments).length -
        1 +
        " Stops"
      );
  }

  getBaggage(flight, type, index) {

    // return 1;

    if (
      this.booking.flight_trip.flight_search.search_type == "international" &&
      this.booking.flight_trip.flight_search.return_date
    ) {
      index = 0;
    }
    if (
      Object.keys(this.booking.flight_trip["baggage-allowances"][index])
        .length === 0
    ) {
      return "Not Available";
    }
    if (
      this.booking.flight_trip["baggage-allowances"][index][0][
      flight.departure_airport + "_" + flight.arrival_airport
      ][type]
    ) {
      return this.booking.flight_trip["baggage-allowances"][index][0][
        flight.departure_airport + "_" + flight.arrival_airport
      ][type];
    } else return "Not Available";
  }

  getBaggageInfo(flight, index) {
    if (this.booking.flight_trip.flight_search.supplier_name == 'cleartrip') {
      if (
        this.booking.flight_trip.flight_search.search_type == "international" &&
        this.booking.flight_trip.flight_search.return_date
      ) {
        index = 0;
      }
      if (
        Object.keys(this.booking.flight_trip["baggage_information"][index]["BI"])
          .length === 0
      ) {
        return { ADT: { cib: "Not Available", cab: "Not Available" } };
      }

      let a = Object.keys(this.booking.flight_trip["baggage_information"][index]["BI"])
      return this.booking.flight_trip["baggage_information"][index]["BI"][a[0]]["bg"];
      // if (
      //   this.booking.flight_trip["baggage_information"][index]["BI"][
      //     flight.departure_airport +
      //       "_" +
      //       flight.arrival_airport +
      //       "_" +
      //       flight.departure_date.replace("T", "_").slice(0, 16)
      //   ]["bg"]["ADT"]["cib"]
      // ) {
      //   return this.booking.flight_trip["baggage_information"][index]["BI"][
      //     flight.departure_airport +
      //       "_" +
      //       flight.arrival_airport +
      //       "_" +
      //       flight.departure_date.replace("T", "_").slice(0, 16)
      //   ]["bg"];
      // } else return { ADT: { cib: "Not Available", cab: "Not Available" } };
    } else {
      let b = Object.keys(this.booking.flight_trip.flights[index]['segments'])
      if (this.booking.flight_trip.flights[index]['segments'][b[0]]['cab'] || this.booking.flight_trip.flights[index]['segments'][b[0]]['cib']) {
        return { ADT: { cib: this.booking.flight_trip.flights[index]['segments'][b[0]]['cib'], cab: this.booking.flight_trip.flights[index]['segments'][b[0]]['cab'] } }
      }
      return { ADT: { cib: "Not Available", cab: "Not Available" } };
      // let a = Object.keys(this.booking.flight_trip["baggage_information"][index]["BI"]);
    }

  }

  arrivalAirport() {
    return this.booking.flight_trip.flights[0].segments[
      Object.keys(this.booking.flight_trip.flights[0].segments).length
    ].arrival_airport;
  }

  inboundArrivalDate() {
    return this.booking.flight_trip.flights[0].segments[
      Object.keys(this.booking.flight_trip.flights[0].segments).length
    ].departure_date;
  }

  flightDuration(duration) {
    return (
      Math.floor(duration / 3600) +
      "h " +
      Math.ceil((duration % 3600) / 60) +
      "m"
    );
  }

  getAirlineImage(code) {
    if(code==69){
      code='UK';
    }
    if( code == 62){
      code = 'AI';
    }
    if( code == 63){
      code = 'UK';
    }
    return `https://ui.cltpstatic.com/images/logos/air-logos/${code}.png`;
  }

  goToHome() {
    this.router.navigate([`flights`]);
  }

  totalTravellers() {
    let search = this.booking.flight_trip.flight_search;
    return search.adults + search.children + search.infants;
  }

  back() {
    history.back();
  }

  changeStatus(){
    if(this.checkIfUpcoming() && this.status === 'booking_confirmed'){
      this.status = 'upcoming';
      console.log('jererererere')
    }else if(this.checkIfUpcoming() && this.status === 'booking_confirmed'){
      this.status = 'booking_completed';
    }
  }

  returnSegmentLength(flights) {
    return Object.keys(flights)[0];
  }

  checkIfCompleted(){
    return moment(this.booking.flight_trip.flights[0].segments[1].departure_date).isBefore(moment())
  }

  checkIfUpcoming(){
    return moment(this.booking.flight_trip.flights[0].segments[1].departure_date).isAfter(moment())
  }

  cancelBooking(booking) {
    let totalCharge = '';
    if(booking.flight_trip.flight_search.supplier_name === 'cleartrip'){
      totalCharge =
      booking.flight_trip.mini_rules.onward.total_cancellation_charges;
      if (booking.flight_trip.mini_rules.return.total_cancellation_charges) {
        totalCharge =
          totalCharge +
          booking.flight_trip.mini_rules.return.total_cancellation_charges;
      }
    }
    let cancellationMessage = '<p><span class="icon-flight mar-right-5 fnt-14"></span><span>'+booking.flight_trip.flights[0].segments[1].departure_city_name+ '<span class="icon-right mar-right-5 mar-left-5 fnt-14"></span>'+booking.flight_trip.flights[0].segments[this.flightDetailsOnwReturn].arrival_city_name +'</p>'+
        '<p><span class="icon-group-1 mar-right-5 fnt-14"></span> Departure Date: '+moment(booking.flight_trip.flight_search.depart_date).format('DD MMM, YYYY')+ //+moment(booking.flight_trip.flight_search.return_date).format('DD MMM YYYY')+'</p>'+
        (booking.flight_trip.flight_search.return_date ? '<p><span class="icon-group-1 mar-right-5 fnt-14"></span> Return Date: '+moment(booking.flight_trip.flight_search.return_date).format('DD MMM, YYYY') : '' )+
        '<p><span class="icon-users mar-right-5 fnt-14"></span>'+this.totalTravellers()+ ' traveller(s)' +
        "<p class='fnt-16' style='text-align:left; font-weight: 400 !important;'> Amount Paid: " +'<span class="rupee-symbol">₹</span> ' +this.indianCurrency.transform(booking.flight_trip.pricing_summary.total_fare, null) + "</p>" +
        (totalCharge ?  "<p class='fnt-16' style='text-align:left; font-weight: 400 !important;'> Total cancellation charges: " +'<span class="rupee-symbol">₹</span> ' +this.indianCurrency.transform(parseFloat(totalCharge),null) + "</p>" : '')+
        '<p> Note : Convenience Fee of <span class="rupee-symbol">₹</span> '+booking.flight_trip.pricing_summary.convenience_fee+ ' will not be refunded.</p>';

    let fire = swal.fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this booking?',
        html: cancellationMessage,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel"
    });
    if(booking.flight_trip.flight_search.supplier_name !== 'cleartrip'){
      fire = swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this booking?',
        html: cancellationMessage,
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reason...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        preConfirm: (input) => {
          if (!input) {
          swal.showValidationMessage("Please enter reason for cancellation.");
          return false;
          } else {
          return input;
          }
          },
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel"
      })
    }
    fire.then(result => {
        if (result.value) {
          this.progress = true;
          if(booking.flight_trip.flight_search.supplier_name === 'cleartrip'){
            this.flightS.cancelBooking(this.bookingId).then((resp: any) => {
              if (resp.status == 200) {
                // this.status = "booking_cancelled";
                let refundTxt = "<p> Refundable amount : " +
                  '<span class="rupee-symbol">₹</span> ' +
                  resp.cancel_response.refunded_amount +
                  "</p>";
                swal
                  .fire({
                    imageUrl: "assets/modal-info.png",
                    imageHeight: 60,
                    title: "Cancellation Successful",
                    html: refundTxt,
                    // showCancelButton: true,
                    // confirmButtonText: "OK",
                    // cancelButtonText: "Don't cancel"
                  }).then( res => {
                    this.ngOnInit();
                  })
              }else{
                swal.fire({
                  imageUrl: "assets/modal-error.png",
                  imageHeight: 60,
                  title: "Cancellation Failed",
                  // html: '<p>'+resp['msg'][0]+'</p>',
                  html: '<p>'+'In case of assistance contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>'+'</p>',
                  // showCancelButton: true,
                }).then( res => {
                  this.ngOnInit();
                })
              }
              this.progress = false;
            });
          }else{
            this.flightS.cancelRequestConcierge(this.bookingId, result.value).then((resp: any) => {
              if(resp['status'] === "success"){
                swal.fire({
                  // icon: "success",
                  imageUrl: "assets/modal-info.png",
                  imageHeight: 60,
                  title: "Cancellation Requested",
                  html: '<p>'+'Cancellation request has been sent to <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>'+'</p>',
                  // showCancelButton: true,
                }).then( res => {
                  this.ngOnInit();
                })
              }else{
                swal.fire({
                  imageUrl: "assets/modal-error.png",
                  imageHeight: 60,
                  title: "Cancellation Request Failed",
                  html: '<p>'+'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>'+'</p>',
                  // showCancelButton: true,
                }).then( res => {
                  this.ngOnInit();
                })
              }
            });
          }
        } else if (result.dismiss === swal.DismissReason.cancel) {
        }
      });


  }
}
