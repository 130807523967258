import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss']
})
export class ActivityCardComponent implements OnInit {

  @Input() activity;
  @Input() exchangeRates = [];

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  getNoOfStars(rating) {
    return new Array(Math.round(rating));
  }

  getValue() {
    if (this.exchangeRates && this.exchangeRates.length) {
      const val = this.exchangeRates.find((each) => each.code === this.activity.price.currencyCode);
      if(val) {
        return this.activity.price.minimumPrice.finalPrice / val.rate;
      }
    } else {
      return this.activity.price.minimumPrice.finalPrice;
    }
    return this.activity.price.minimumPrice.finalPrice;
  }

  getValueOriginal() {
    if (this.exchangeRates && this.exchangeRates.length) {
      const val = this.exchangeRates.find((each) => each.code === this.activity.price.currencyCode);
      if(val) {
        return this.activity.price.minimumPrice.originalPrice / val.rate;
      }
    } else {
      return this.activity.price.minimumPrice.originalPrice;
    }
    return this.activity.price.minimumPrice.originalPrice;
  }


  navigateToDetailPage() {
    const path = this.activity.name.split(' ').join('-').split('/').join('-') + '-' + this.activity.id
    this.router.navigate(['/tours-and-activities/activity/' + path]);
  }

}
