import { Component, Input, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: 'app-read-more',
  template: `<div class="position-relative app-read-more">
    <div #readMore class="line-clamp position-relative safari_only" [innerHTML]="description"></div>
    <div *ngIf="isLineClampEnabled && showReadMore" (click)="toggleLineClamp(false)" class='read-more text-brand font-12px position-absolute ft-wt-600'><span class="text-dark-grey"> ...</span>Read more</div>
    <div *ngIf="isLineClampEnabled && !showReadMore" (click)="toggleLineClamp(true)" class='read-more text-brand font-12px position-absolute ft-wt-600'>  Read less</div>
    </div>`,
  styles: [`.line-clamp{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }`,
  ``,
    `.read-more{
    right:0px;
    bottom:0px;
    text-decoration:underline;
    background: #252525;
    cursor:pointer;
  }`]
})
export class ReadMoreComponent {
  @Input() description: string = '';
  @ViewChild('readMore') private readMoreDiv: ElementRef;
  @Input() showReadMore:boolean = true;
  public isLineClampEnabled: boolean = false;
  public ngAfterViewInit():void {
    this.isLineClampEnabled = this.readMoreDiv.nativeElement.scrollHeight > this.readMoreDiv.nativeElement.clientHeight
  }
  public toggleLineClamp(enable:boolean):void{
    this.showReadMore = enable;
    if(!this.showReadMore){
      this.readMoreDiv.nativeElement.classList.remove('line-clamp');
    } else {
      this.readMoreDiv.nativeElement.classList.add('line-clamp');
    }
  }
}
