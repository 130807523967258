import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-booking-failed',
  templateUrl: './booking-failed.component.html',
  styleUrls: ['./booking-failed.component.scss']
})
export class BookingFailedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  }

}
