import { Component, OnInit, Input, OnChanges, ElementRef, HostListener, Output, EventEmitter, ViewChild, ContentChildren, QueryList , TemplateRef} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { OriginConnectionPosition, ConnectionPositionPair, OverlayConnectionPosition } from '@angular/cdk/overlay';
import { UntypedFormControl } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { element } from 'protractor';
import { DataService } from "src/app/services/common/data/data.service";

// Template Sections
@Component({
  selector: 'custom-dropdown-item',
  templateUrl: './custom-dropdown-item.component.html',
  styleUrls: ['./custom-dropdown-item.component.scss']
})
export class CustomDropdownItemsComponent{
  @ViewChild('content', { read: false, static: true }) content: any;
  @Input() value: any;
  @Input() width: string;
  @Input() height: string;
  @Input() room: Room;
  @Input() roomIndex: Room;
  @Input() children: string;
  // @Input() children_arr: string;
  @Input() adults: string;
  @Input() ageError: any;
  @Output() onRoomChange = new EventEmitter();
  @Output() onChildAgeAdded = new EventEmitter();
  @Output() onError = new EventEmitter();

  isOpen = true;
  isDestinationDropdownOpen  = false;
  ages = [1,2,3,4,5,6,7,8,9,10,11,12];
  selectedAge = '1 year';
  selectedAges = [];
  isChildAgeSelected = false;
  @ViewChild('navdrop') navdrop: ElementRef;

  editRoomDetail(modRoom) {
    // if(modRoom === 3){ //children
    //   if(!this.isChildAgeSelected){
    //     this.isChildAgeSelected = true;
    //   }else{
    //     this.showSearchError = true;
    //     this.onError.emit(true);
    //   }
    // }else{
    //   this.onRoomChange.emit(modRoom);
    // }
    this.onRoomChange.emit(modRoom);
  }

  deleteRoom(isOpen){
    // if(!isOpen){
      this.onRoomChange.emit(4); //delete
    // }
  }

  addAge(age, childnumber){
    console.log(age,'emited')
    this.onChildAgeAdded.emit([age,childnumber]);
  }

  constructor(public dataService: DataService) {
  }

  setAge(age){
    this.selectedAge = age + (age < 2 ? ' year' : 'years');
    this.isChildAgeSelected = false;
    this.onRoomChange.emit(3);
    this.onError.emit(false);
    this.isDestinationDropdownOpen = false;
  }
  openDestinationDrop(){
    this.isDestinationDropdownOpen = !this.isDestinationDropdownOpen;
  }

  closeDestinationDrop(){
    this.isDestinationDropdownOpen = false;
  }

}

@Component({
  selector: 'custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements OnInit, OnChanges {
  @ViewChild('matSelect', { read: false, static: true }) matSelect: ElementRef;
  @Output() valueChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
  @Output() openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() value: any;
  @Input() items: string[];
  @Input() placeholder: string;
  @Input() dropdownTitle: string;
  @Output() onRoomsChange = new EventEmitter();
  @Output() onMobileDropdownClose = new EventEmitter();
  @Input() background;
  @Input() preset;
  @Input() addDynamicContent: boolean = false;
  @Input() rooms: Room[];
  @Input() disableDoneButton: boolean = true;
  @Input() compareWith: Function;
  @Input() mobileNewWindow: boolean = false;
  defaultCompareWithFn: Function = function () { };
  isErrors = [[false,false,false],[false,false,false],[false,false,false],[false,false,false]];
  isMobile: boolean = false;
  public reference: HTMLElement;
  selectedIndex = -1;
  showText = '';
  inputToShow = '';
  isRoomDropdownOpen = false;
  whereInput = new UntypedFormControl();
  // @ViewChild('dropdown', { read: false, static: true }) pickerContainer: ElementRef;
  @ViewChild('widgetsContent', { read: ElementRef, static: true }) public widgetsContent: ElementRef<any>;

  @ContentChildren(CustomDropdownItemsComponent)
  ddItems:QueryList<CustomDropdownItemsComponent>;
  refRect : any;
  roomselectorError= '';
  constructor( private eleref: ElementRef, public toastr: ToastrService, public dataService: DataService) {

  }
  ngOnChanges() {
    this.refRect =  this.eleref.nativeElement.getBoundingClientRect();
    if(window.innerWidth < 576){
      this.isRoomDropdownOpen = true;
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }

    console.log(this.mobileNewWindow, 'mobileNewWindow');
  }
  private originPos: OriginConnectionPosition = {
    originX: 'start',
    originY: 'bottom'
  };
  private overlayPos: OverlayConnectionPosition = {
      overlayX: 'start',
      overlayY: 'top'
  };

  private position: ConnectionPositionPair = new ConnectionPositionPair(this.originPos, this.overlayPos, 0, 0);
  public positions: ConnectionPositionPair[] = [this.position];
  /**
   * For click outside of the calendar's container
   * @param event event object
   */
  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (!event.target) {
      return;
    }
    ;
    if (!this.eleref.nativeElement.contains(event.target)) {
      if(window.innerWidth > 576){
        // this.closeDestinationDrop();
      }
    }
  }

  openDestinationDrop(){
    this.isRoomDropdownOpen = true;
  }

  checkChildrenAgeFilled(rooms){
    for (let i=0; i < rooms.length; i++) {
      if(rooms[i].children){
        for (let j=0; j < rooms[i].children; j++) {
          console.log(rooms[i],rooms[i].children,j)
          if(rooms[i]['child'+j]<0){
            this.isErrors[i][j] = true;
            return false;
          }else{
            this.isErrors[i][j] = false;
          }
        }
      }
    }
    return true;
  }

  ifRoomsValid(){
    for (let i=0; i < this.isErrors.length; i++) {
        for (let j=0; j < this.isErrors[i].length; j++) {
          if(this.isErrors[i][j]){
            return false;
          }
        }
    }
    return true;
  }
  closeDestinationDrop(){
    console.log(this.rooms,'werty', this.checkChildrenAgeFilled(this.rooms))
    console.log(this.isErrors, this.ifRoomsValid())
    if(this.ifRoomsValid()){
      this.isRoomDropdownOpen = false;
      if(window.innerWidth < 576){
        console.log("gdsbdsljn")
        this.onMobileDropdownClose.emit(true);
      }
    }
  }

  onChildAgeAdded(age_arr,i){
    if(age_arr[0] === -1){
      this.rooms[i]['child'+(this.rooms[i].children-1)] = parseInt(age_arr[0]);
      this.rooms[i].children_arr[(this.rooms[i].children-1)] = age_arr[0];
      console.log('bbb', this.rooms[i].children_arr)
    }else{
      this.rooms[i]['child'+(age_arr[1]-1)] = parseInt(age_arr[0]);
      this.rooms[i].children_arr[(age_arr[1]-1)] = age_arr[0];
      console.log('aaa', this.rooms[i].children_arr)
      this.isErrors[i][(age_arr[1]-1)] = false;
    }
  }

  onRoomChange(myvar, i) {
    switch (myvar) {
      case modRoom.incrementAdult:
        if (this.rooms[i].adults + this.rooms[i].children < 4) {
          this.rooms[i].adults += 1;
        }else{
          this.toastr.warning("Can't add more than 4 guests in a room.")
        }
        break;
      case modRoom.decrementAdult:
          if (this.rooms[i].adults > 1) {
            this.rooms[i].adults -= 1;
          }else{
            this.toastr.warning("Aleast one adult in room in required.")
          }
          break;
      case modRoom.incrementChildren:
          if (this.rooms[i].adults + this.rooms[i].children < 4) {
            this.rooms[i].children += 1;
            this.onChildAgeAdded([-1,i],i);
          }else{
            this.toastr.warning("Can't add more than 4 guests in a room.")
          }
          break;
      case modRoom.decrementChildren:
          if (this.rooms[i].children > 0 ) {
            this.rooms[i].children -= 1;
            delete this.rooms[i]['child'+(this.rooms[i].children)];
            this.rooms[i].children_arr.splice((this.rooms[i].children), 1);
            this.isErrors[i][this.rooms[i].children] = false;
          }
          break;
      case modRoom.deleteRoom:
          if(this.rooms.length > 1 ){
            this.rooms.splice(i, 1);
          }else{
            this.toastr.warning("Select atleast 1 room.")
          }
          break;
    }
    this.value = this.rooms;
    this.onRoomsChange.emit(this.rooms);

    this.generateShowText();
  }

  generateShowText() {
    let noRooms = 0;
    let noChildren = 0;
    let noAdults = 0;
    if (this.rooms.length > 0) {
      noRooms = this.rooms.length;
      for (let i=0; i < this.rooms.length; i++) {
        noAdults += this.rooms[i].adults;
        noChildren += this.rooms[i].children;
      }
    }
    if (noRooms < 2 && noChildren === 0 && noAdults === 0) {
      this.showText = '';
    }else{
      this.showText =  noRooms + (noRooms > 1 ? ' Rooms. ' : ' Room. ') + noAdults +  (noAdults > 1 ? ' Adults. ':' Adult ');
      if(noChildren){
        this.showText =  noRooms + (noRooms > 1 ? ' Rooms. ' : ' Room. ') + noAdults +  (noAdults > 1 ? ' Adults. ':' Adult ') + noChildren + (noChildren > 1 ? ' Children. ' : ' Child. ');
      }
    }
    console.log(this.showText)
    return this.showText;
  }

  ngOnInit() {
    if(window.innerWidth < 576){
      this.isRoomDropdownOpen = true;
    }
    this.whereInput.setValue(this.rooms);
    if(this.preset){
      console.log("bkjb")
      this.generateShowText()
    }
    // this.generateShowText();
  }

  addAnotherRoom() {
    if(this.rooms.length < 4 ){
      this.rooms.push({
        adults : 1,
        children : 0,
        children_arr : []
      });
      this.generateShowText();
      this.roomselectorError = "";
    }else{
      // this.dataService.triggerToastr('error',"Cannot add more than 4 rooms.")
      this.roomselectorError = "Cannot add more than 4 rooms.";
    }
  }

  closeDesktopDropdown(){
    if(!this.checkChildrenAgeFilled(this.rooms)){
      // this.dataService.triggerToastr('error',"Enter the age of child.");
      this.roomselectorError = "Enter the age of child.";
    }else{
      this.roomselectorError = "";
      let pickerContainer = document.getElementById('pickerContainer');
      pickerContainer.classList.remove('show');// .nativeElement.removeClass('open');
    }
  }

}

export interface Room {
  adults: number;
  children: number;
  children_arr: number[];
}

export enum modRoom {
  decrementAdult,
  incrementAdult,
  decrementChildren,
  incrementChildren,
  deleteRoom
}
