<div class="sp-section dark4 center">
    <div class="row background404 w100">
        <div class="container text-white d-flex justify-content-start align-center row">
            <div class="col-md-8 col-sm-12 mb-40">
                <h1 class="sp-heading accenttwo mb-40">The page requested by you does not exist at this location. You can go through our site using the links above.</h1>
                <h4 class="sp-heading gold f300">Auto-redirecting to AURUM Rewards homepage in {{autoRedirectTime}} seconds...</h4>
                <!-- <h5>Unfortunately the page you were looking for is not looking for you.</h5>
                <a href="/" class="btn btn-outline-light my-3 d-flex align-items-center justify-content-around take-me-home">
                    <div class="icon-left-arrow mar-top-6"></div>
                    <div class="pl-2">Take me home</div>
                </a> -->
            </div>
            <div class="col-md-4 col-sm-12">
                <img src="/assets/404.png" class="w100">
            </div>
        </div>
    </div>
</div>