<div class="page container" *ngIf="!progress">
    <div class="d-none d-sm-block">
        <div class="blueify mar-bot-10 d-flex justify-content-start align-items-center pointer">
            <div class="mar-bot-3">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM13 5.25L1 5.25V6.75L13 6.75V5.25Z"
                        fill="#C2A63F" />
                </svg>
            </div>
            <div class="mar-left-10" (click)="back()">
                Back
            </div>
        </div>
    </div>
    <div class="to-show1">
        <div class="d-none d-sm-block">
            <div class="checkout-message-top d-flex align-items-center justify-content-between mar-bot-15">
                <div class="w100">
                    <div class="d-none d-sm-block booking-id-detail">
                        <div class="d-flex justify-content-between">
                            <div class="to-name">
                                {{bookingData.attributes.booking_data.event_details.event_desc}}
                                <div class="to-desc">
                                    {{bookingData.attributes.booking_data.event_details.venue_desc + ', ' + bookingData.attributes.booking_data.event_details.city_desc  + ', ' + bookingData.attributes.booking_data.event_details.country_desc}}
                                </div>

                            </div>
                            <div>
                                <app-status-label class="status-text" [status]="bookingData.attributes.status"
                                    [date]="bookingData.attributes?.booking_data?.seat_selection?.seats[0].date">
                                </app-status-label>
                                <div class="print-button d-none d-sm-block pointer ml-auto" (click)="convertMyWay()">
                                    <div class="fnt-14" style="float: right;">
                                        <span class="icon-print"></span>
                                        Print </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="d-block d-sm-none">
            <section class="top-bar w100 d-flex align-items-center justify-content-between bg">
                <div class="d-flex align-items-center">
                    <div class="back-arr mar-right-20" (click)="back()">
                        <span class="icon-left-arrow" style="vertical-align: -7px;"></span>
                    </div>

                    <div class="to-name">
                        {{bookingData.attributes.booking_data.event_details.event_desc}}
                    </div>
                    <!-- <div class="to-desc">
                        {{bookingData.attributes.booking_data.event_details.venue_desc + ', ' + bookingData.attributes.booking_data.event_details.city_desc  + ', ' + bookingData.attributes.booking_data.event_details.country_desc}}
                    </div> -->

                </div>
                <div>
                    <app-status-label class="status-text" [status]="bookingData.attributes.status"
                        [date]="bookingData.attributes?.booking_data?.seat_selection?.seats[0].date">
                    </app-status-label>
                </div>
            </section>

            <hr>

            <div class="booking-details-bck d-flex justify-content-between align-items-center"
                style="background: var(--background);">
                <div>
                    <span class="booking-id">
                        Booking ID :
                    </span>
                    <span class="booking-id-txt">
                        {{bookingData?.attributes.unique_id}}
                    </span>
                </div>
            </div>
            <hr>
        </div>
        <div class="booking-details-card booking-confirmed-container">
            <div class="d-block d-sm-none">
                <div class="d-flex align-items-center justify-content-between booking-details-bck">
                    <div class="booking-details-txt">
                        Booking Details
                    </div>
                </div>
            </div>
            <div class="d-none d-sm-block">
                <div class="d-flex align-items-center justify-content-between booking-details-bck">
                    <div>
                        <span class="booking-id">
                            Booking Details
                        </span>
                    </div>
                    <div>
                        <span class="booking-id">
                            Booking ID:
                        </span>
                        <span class="booking-id-txt">
                            {{bookingData.attributes.booking_unique_id}}
                        </span>
                    </div>

                </div>
            </div>


            <!-- responsive -->

            <hr>

            <div class="detail each-strip">
                <ng-container *ngIf="bookingData.attributes.booking_data.seat_selection?.seats[0].date">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10">
                        <div class="name">Date</div>
                        <div class="value">
                            {{bookingData.attributes.booking_data.seat_selection.seats[0].date | date:'EEEE, dd MMM yyyy'}}
                        </div>
                    </div>
                </ng-container>
                <hr>
                <ng-container *ngIf="bookingData?.attributes?.booking_data?.perf_details?.time_desc">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Time</div>
                        <div class="value">
                            {{bookingData?.attributes?.booking_data?.perf_details?.time_desc}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="getPeople()">
                    <hr>
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Number of people</div>
                        <div class="value">{{getPeople()}}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="getSeats()">
                    <hr>
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Seats</div>
                        <div class="value">{{getSeats()}}</div>
                    </div>
                </ng-container>

                <hr>

                <div class="field mar-top-10">
                    <div class="name">
                        {{'Booked by '+bookingData.attributes.booking_data.user_pack.first_name + ' ' + bookingData.attributes.booking_data.user_pack.last_name}}
                    </div>
                    <div class="sub-detail">
                        {{'Phone : ' + bookingData.attributes.booking_data.user_pack.mobile_no+' | Email : '+bookingData.attributes.booking_data.user_pack.email}}
                    </div>
                </div>
            </div>

            <ng-container *ngIf="bookingData?.attributes?.booking_data?.send_method?.send_methods?.length">
                <hr>
                <div class="pay-header each-strip">Tickets</div>
                <hr>
    
                <div class="detail each-strip">
                    <ng-container
                        *ngFor="let each of bookingData?.attributes?.booking_data?.send_method?.send_methods; let sI = index;">
                        <ng-container *ngIf="each?.self_print_voucher_url">
                            <div class="d-flex align-items-center justify-content-between field mar-bot-10"
                                [ngClass]="{'mar-top-10': sI>0}">
                                <div class="name">{{each.send_desc}}</div>
                                <div class="value">
                                    <a [href]="each.self_print_voucher_url" target="_blank">Click to download</a>
                                </div>
                            </div>
                            <hr *ngIf="sI<bookingData.attributes.booking_data.send_method.send_methods.length-1">
                        </ng-container>
                    </ng-container>
                </div>
    
            </ng-container>

            <hr>

            <div class="pay-header each-strip">Payment Details</div>

            <hr>

            <div class="payment-detail each-strip">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Base Fare</div>
                    <div class="value">
                        <span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.gross_amount | indianCurrency}}</div>
                </div>
                <hr>


                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name">Total Amount</div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.gross_amount| indianCurrency}}</div>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10 discount"
                    *ngIf="bookingData.attributes.gross_amount-bookingData.attributes.amount>0">
                    <div class="name">Discount</div>
                    <div class="value">
                        <span class="mar-right-2">-</span>
                        <span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.gross_amount-bookingData.attributes.amount| indianCurrency}}</div>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name">Amount Paid</div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.amount| indianCurrency}}</div>
                </div>


            </div>

            <hr>

            <div class="pay-header each-strip">Payment Breakup</div>

            <hr>

            <div class="payment-detail each-strip">
                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name"><span>Amount paid by card
                            {{bookingData?.attributes?.card_number | creditCardMask}}</span><img *ngIf="cardIcon"
                            height="16" src="{{cardIcon}}"></div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.amount| indianCurrency}}</div>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10">
                    <div class="name">Amount paid by Reward Points</div>
                    <div class="value">NIL</div>
                </div>



            </div>
            <!-- 
            <ng-container *ngIf="bookingData.attributes.status=='booking_confirmed' && checkIfUpcoming()">
                <hr>

                <div class="pay-header each-strip">Cancellation</div>

                <hr>

                <div class="each-strip booking-details cancel-strip"
                    *ngIf="bookingData.attributes.status=='booking_confirmed' && checkIfUpcoming()">
                    <button mat-button class="primary-btn cancellation-button" (click)="cancelBooking()">Request
                        Cancellation
                    </button>
                </div>
            </ng-container> -->

        </div>
    </div>

    <div class="booking-confirmed-container to-hide1" style="border: 1px solid var(--primary);border-radius: 10px;">
        <div class="checkout-message-top each-strip d-flex align-items-center justify-content-between header-bg">
            <img class="img-fluid rewards-hub-header-logo pad-right-8" src="assets/sbic-images/logo.png"
                style="width: 200px;">
        </div>
        <div class="checkout-message-top each-strip d-flex align-items-center justify-content-between">
            <div class="d-flex w-100">
                <div>
                    <img src="assets/large-green-tick.png" alt="tick">
                </div>
                <div class="mar-left-20">
                    <div class="congrats-text">Congratulations! Your booking is confirmed.</div>
                    <div class="email-text">
                        {{'We have sent you the booking details to '+ bookingData.attributes.booking_data.user_pack.email + ' and ' + bookingData.attributes.booking_data.user_pack.mobile_no + '.'}}
                    </div>
                </div>
                <div class="d-none d-sm-block booking-id-detail ml-auto text-right">
                    <div>
                        Booking ID : <span class="id">{{bookingData?.attributes.booking_unique_id}}</span>
                    </div>
                </div>

            </div>

        </div>

        <hr>


        <div class="pay-header each-strip">
            <div class="to-name">
                {{bookingData.attributes.booking_data.event_details.event_desc}}
            </div>
            <div class="to-desc">
                {{bookingData.attributes.booking_data.event_details.venue_desc + ', ' + bookingData.attributes.booking_data.event_details.city_desc  + ', ' + bookingData.attributes.booking_data.event_details.country_desc}}
            </div>
        </div>

        <hr>

        <div class="d-block d-sm-none mob-booking-id each-strip">
            Booking ID : <span class="id">{{bookingData?.attributes.booking_unique_id}}</span>
        </div>

        <hr class="d-block d-sm-none">

        <div class="detail each-strip">
            <ng-container *ngIf="bookingData.attributes.booking_data.seat_selection?.seats[0].date">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10">
                    <div class="name">Date</div>
                    <div class="value">
                        {{bookingData.attributes.booking_data.seat_selection.seats[0].date | date:'EEEE, dd MMM yyyy'}}
                    </div>
                </div>
            </ng-container>
            <hr>
            <ng-container *ngIf="bookingData?.attributes?.booking_data?.perf_details?.time_desc">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Time</div>
                    <div class="value">
                        {{bookingData?.attributes?.booking_data?.perf_details?.time_desc}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="getPeople()">
                <hr>
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Number of people</div>
                    <div class="value">{{getPeople()}}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="getSeats()">
                <hr>
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Seats</div>
                    <div class="value">{{getSeats()}}</div>
                </div>
            </ng-container>

            <hr>

            <div class="field mar-top-10">
                <div class="name">
                    {{'Booked by '+bookingData.attributes.booking_data.user_pack.first_name + ' ' + bookingData.attributes.booking_data.user_pack.last_name}}
                </div>
                <div class="sub-detail">
                    {{'Phone : ' + bookingData.attributes.booking_data.user_pack.mobile_no+' | Email : '+bookingData.attributes.booking_data.user_pack.email}}
                </div>
            </div>
        </div>
        
        <ng-container *ngIf="bookingData?.attributes?.booking_data?.send_method?.send_methods?.length">
            <hr>
            <div class="pay-header each-strip">Tickets</div>
            <hr>

            <div class="detail each-strip">
                <ng-container
                    *ngFor="let each of bookingData?.attributes?.booking_data?.send_method?.send_methods; let sI = index;">
                    <ng-container *ngIf="each?.self_print_voucher_url">
                        <div class="d-flex align-items-center justify-content-between field mar-bot-10"
                            [ngClass]="{'mar-top-10': sI>0}">
                            <div class="name">{{each.send_desc}}</div>
                            <div class="value">
                                <a [href]="each.self_print_voucher_url" target="_blank">Click to download</a>
                            </div>
                        </div>
                        <hr *ngIf="sI<bookingData.attributes.booking_data.send_method.send_methods.length-1">
                    </ng-container>
                </ng-container>
            </div>

        </ng-container>

        <hr>

        <div class="pay-header each-strip">Payment Details</div>

        <hr>

        <div class="payment-detail each-strip">
            <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                <div class="name">Base Fare</div>
                <div class="value">
                    <span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.gross_amount | indianCurrency}}</div>
            </div>
            <hr>


            <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                <div class="name">Total Amount</div>
                <div class="value"><span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.gross_amount| indianCurrency}}</div>
            </div>

            <hr>

            <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10 discount"
                *ngIf="bookingData.attributes.gross_amount-bookingData.attributes.amount>0">
                <div class="name">Discount</div>
                <div class="value">
                    <span class="mar-right-2">-</span>
                    <span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.gross_amount-bookingData.attributes.amount| indianCurrency}}</div>
            </div>

            <hr>

            <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                <div class="name">Amount Paid</div>
                <div class="value"><span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.amount| indianCurrency}}</div>
            </div>


        </div>
        <hr>

        <div class="pay-header each-strip">Payment Breakup</div>

        <hr>

        <div class="payment-detail each-strip">
            <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                <div class="name"><span>Amount paid by card
                        {{bookingData?.attributes?.card_number | creditCardMask}}</span><img *ngIf="cardIcon"
                        height="16" src="{{cardIcon}}"></div>
                <div class="value"><span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.amount| indianCurrency}}</div>
            </div>

            <hr>

            <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10">
                <div class="name">Amount paid by Reward Points</div>
                <div class="value">NIL</div>
            </div>



        </div>
    </div>
</div>

<app-loader *ngIf="progress"></app-loader>