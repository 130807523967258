import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from "src/app/services/common/data/data.service";
import { initDayOfWeek } from "ngx-bootstrap/chronos/units/day-of-week";
import { DataSharingService } from "src/app/services/data-sharing.service";
import { appConstants } from "src/app/app.constants";
import { LoginService } from 'src/app/services/login/login.service';
import { MatDialog } from "@angular/material/dialog";
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import * as moment from 'moment';
import { Observable } from 'rxjs/Rx';
import { Location } from "@angular/common";
import { ToursExperienceComponent } from 'src/app/points-transfer/tours-experiences/tours-experiences.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
})
export class HomepageComponent implements OnInit {
  moreOpen: boolean = false;
  apps = appConstants.apps;
  progress : boolean = false;
  isMobile : boolean;
  selectedApp;
  Arr = Array;
  membershipSlideCount = 0;
  membershipInterval;
  hidePageBottom: boolean = false;
  appInterval;

  public currentModule : Observable<object>;

  constructor(public router: Router,
    public login: LoginService,
    public dataService: DataService,
    public datalogin: DataSharingService,
    private location: Location,
    private modalService: NgbModal,
    public dialog: MatDialog) {
      this.isMobile = window.innerWidth < 576 ? true : false;
      this.currentModule = this.dataService.getCurrentModule();
    }

  ngOnInit() {
    this.currentModule.subscribe( current =>{
      console.log('getCurrentModule',current);
      if(current){
        this.selectedApp = current;
        // if(window.location.pathname.split('/')[1] != this.selectedApp.url.substring(1)){
        //   if(window.location.pathname.split('/').length < 3)
        //     this.location.go(this.selectedApp.url);
        //   else
        //     location.assign(this.selectedApp.url);
        // }

        if(window.location.pathname.split('/')[1])
          this.isMobile = false;
        else{
          this.isMobile = true;
        }
      }
      else{
        this.selectedApp = this.apps[0];
        if(window.innerWidth < 576){
          this.isMobile = true;
        }else{
          this.isMobile = false;
        }
      }
      console.log('this.selectedApp', this.selectedApp);
    });
    // this.appInterval = setInterval(()=>{
    //   console.log('this.dataService.currentApp',this.dataService.currentApp)
    //     if(this.dataService.currentApp){
    //       this.appInterval.clearInterval();
    //     }
    // },100);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.autoplayMembership();
    if(window.location.pathname.split('/')[1]){
      this.hidePageBottom = true;
    }
   }

   viewhome(){
     this.isMobile = true;
     this.dataService.setCurrentModule(null);
     history.back();
   }

   onAppChange(appName) {
    let selectedApp = this.apps[0];
    const indexMapper = {
      'NAT GEO Tours':7,
      'Hotels':1,
      'Flights':0,
      'Gift Cards':3,
      'Experiences':5,
      'Philanthropy':4,
      'Events & Concerts':0,
      'Points Transfer':2
    }
    selectedApp = this.apps[indexMapper[appName]];
    let prevurl = null;
    console.log(appName, selectedApp , this.apps[4]);

    localStorage.setItem('prevurl', prevurl);
    this.dataService.setCurrentModule(selectedApp);
    this.isMobile = false;
  }
  onCustomAppChange(url){
    if(url==='/concierge-services/eventManagement' || url==='/concierge-services/bookingManagement' || url==='/concierge-services/shoppingManagement' || url==='/concierge-services/elseManagement' ){
      this.progress = true;
      this.login
      .sbiValidate()
      .toPromise()
      .then((result:any) => {
        this.progress = false;
        if(result && result.id){
          this.router.navigateByUrl(url);
          // window.open(url);
        }else{
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe( res=> {
            if(res == 'true'){
              this.router.navigateByUrl(url);
              // window.open(url);
            }
          });
        }
      });
    }
    else
      this.router.navigateByUrl(url);
      // window.open(url);
  }

  navigateToSpecficHotelList() {
    localStorage.setItem('hotelType', JSON.stringify(0))

    const store = {
      date_from: moment().add(90, 'days').format('YYYY-MM-DD'),
      date_to: moment().add(91, 'days').format('YYYY-MM-DD'),
      rooms: JSON.stringify([{ "adults": 2, "children": 0, "children_arr": [-1, -1, -1, -1] }]),
      destination_id: 1514,
      destination_name: 'Paris',
      partner_id: '56'
    }
    localStorage.setItem('hotel_search', JSON.stringify(store));
    window.open('/hotels/hotellist/1514');
  }
  // onAppChange(appName) {
  //   console.log(appName);
  //   switch (appName) {
  //     case "Natgeo":
  //       window.open("/nat-geo/home");
  //       break;
  //     case "Hotels":
  //       window.open("/hotels");
  //       break;
  //     case "Flights":
  //       window.open("/flights");
  //       break;
  //     case "GiftCards":
  //       window.open("/giftcards");
  //       break;
  //     case "ToursActivities":
  //       window.open("/tours-and-activities");
  //       break;
  //     case "CharitableDonations":
  //       window.open("/charitable-donations");
  //     case "Golf":
  //       window.open("/golf");
  //       break;
  //     case "Designers":
  //       window.open("/designers");
  //       break;
  //     case "Gifting":
  //       window.open("/gifting");
  //       break;
  //     case "DocsApp":
  //       window.open("/docsapp");
  //       break;
  //     case "Concierge Services-Organise an Event":
  //       window.open("/concierge-services/eventManagement");
  //       break;
  //     case "Concierge Services-Booking Management":
  //       window.open("/concierge-services/bookingManagement");
  //       break;
  //     case "Concierge Services-Shopping Management":
  //       window.open("/concierge-services/shoppingManagement");
  //       break;
  //     case "Concierge Services-Else Management":
  //       window.open("/concierge-services/elseManagement");
  //       break;
  //   }
  // }

  onShown() {
    this.moreOpen = true;
    console.log(this.moreOpen);
  }

  onHidden() {
    // setTimeout(function() {
    this.moreOpen = false;
    // }, 100);
  }

  changeMembership(count) {
    clearInterval(this.membershipInterval);
    this.membershipSlideCount = count;
    this.autoplayMembership();
  }

  autoplayMembership() {
      this.membershipInterval = setInterval(() => {
          if (this.membershipSlideCount < 2) {
              this.membershipSlideCount += 1
          } else {
              this.membershipSlideCount = 0
          }
      }, 5000);
  }
  public navigateViaModal(){
    const modalRef = this.modalService.open(ToursExperienceComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "account-modal navmodal",
      backdropClass: "account-modal-backdrop",
      centered: false,
    });

    modalRef.result.then((result) => {

    });
  }

}
