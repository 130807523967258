import { Injectable } from '@angular/core';
import { ApiService } from "src/app/services/common/api/api.service";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiningService {
  apiUrl: string = environment.apiUrl; //'https://rewards-stage-api-customer.poshvine.com/api/v1/'
  baseUrl: string = environment.stagingUrl; //'https://rewards-stage-api-customer.poshvine.com/'

  constructor(private api: ApiService) { }

  getDining(city_name, top?){
    let url = this.apiUrl + 'dineout/search_restaurants?city_name='+city_name;
    if(top){
      url = this.apiUrl + 'dineout/search_restaurants?city_name='+city_name+'&top_restaurants=true';
    }
    return this.api.get(url);
  }

  getAutocompleteResult(query){
    return this.api.get(this.apiUrl + 'dineout/autocomplete?search='+query);
  }

  getBookingDetails(bookingId){
    return this.api.get(this.baseUrl + `/users/bookings/booking_details?booking_unique_id=${bookingId}`).toPromise();
  }

  getFilters(){
    return this.api.get(this.apiUrl + 'dineout/get_filters').toPromise();
  }

  generateBooking(bookingDetails){
    return this.api.post(this.apiUrl + `dineout/generate_booking`,bookingDetails).toPromise();
  }

  cancelBooking(bookingDetails){
    return this.api.post(this.apiUrl + `dineout/cancel_booking`,bookingDetails).toPromise();
  }

  getRestaurantDetails(restId){
    return this.api.get(this.apiUrl + 'dineout/restaurant_detail?rest_id='+restId).toPromise();
  }

  getRestaurantSlots(restId,timestamp){
    return this.api.get(this.apiUrl + 'dineout/get_restaurant_slot?rest_id='+restId+'&timestamp='+timestamp).toPromise();
  }

  getLocalities(city){
    return this.api.get(this.apiUrl + 'dineout/locality_area_tree?city_name='+city).toPromise();
  }

  createBooking(){
    return this.api.post(this.apiUrl + `dineout/create_booking`,null).toPromise();
  }

  updateBooking(bookingDetails){
    return this.api.post(this.apiUrl + `dineout/update_booking_amount`,bookingDetails).toPromise();
  }

  getBookinghistory(){
    let url = this.baseUrl + "/users/bookings?booking_type=RestaurantBooking";
    return this.api.get(url).toPromise();
  }
}
