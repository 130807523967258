import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocsappService } from 'src/app/docsapp/services/docsapp/docsapp.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { PaymentHomeService } from 'src/app/payment/payment-home.service';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import { appConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-docsapp-history-details',
  templateUrl: './docsapp-history-details.component.html',
  styleUrls: ['./docsapp-history-details.component.scss']
})
export class DocsappHistoryDetailsComponent implements OnInit {
  progress: boolean;
  bookingId: string;
  bookingData;
  cardIcon;
  constructor(
    private route: ActivatedRoute,
    private docsappService: DocsappService,
    public dialog: MatDialog,
    public login: LoginService,
    private paymentService: PaymentHomeService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.progress = true;
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.bookingId = this.route.snapshot.queryParamMap.get("id");
          this.docsappService.getBookingDetailsById(this.bookingId).then(resp => {
            this.bookingData = resp['data'];
            if (this.bookingData.attributes.card_number) {
              this.paymentService
                .getCardDetails(this.bookingData.attributes.card_number.slice(0, 6))
                .subscribe(resp => {
                  this.cardIcon = this.getCardLink(resp["cardType"]);
                });
            }
            this.progress = false;
          });
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          };
        });
      });
  }

  back() {
    history.back();
  }

  convertMyWay() {
    let title = document.title;
    document.title = this.bookingId;
    window.print();
    document.title = title;
  }

  getCardLink(cardType) {
    return appConstants.cardIconsMapping[cardType]
      ? "assets/" + appConstants.cardIconsMapping[cardType]
      : null;
  }
}
