import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "removeUnderscore"
})
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    var splitStr = value.toLowerCase().split("_");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
}
