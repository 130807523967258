import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from "src/app/services/common/api/api.service";

@Injectable({
  providedIn: 'root'
})
export class ConciergeServicesService {
  apiUrl: string = environment.apiUrl; //flight_searches/create_flight_search'https://rewards-stage-api-customer.poshvine.com/api/v1/'
  stagingUrl: string = environment.stagingUrl; //flight_searches/create_flight_search'https://rewards-stage-api-customer.poshvine.com/api/v1/'
  constructor(public api: ApiService) { }

  getCities(query) {
    query = query ? query : '';
    return this.api
      .get(this.apiUrl + `country_details/search_city_locality?search=`+query);
  }

  submitEnquiry(params){
    // let supplier_name = localStorage.getItem('flights_merchant') === '' ? 'cleartrip' : localStorage.getItem('flights_merchant');
    // let a = { supplier_name : supplier_name}
    // params = { ...params, ...a};
    return this.api
      .post(
        this.apiUrl + `concierge/enquiry`,
        params
      )
      .toPromise();
  }

  bookingList(){
    return this.api.get(this.stagingUrl +`users/bookings?booking_type=ConciergeRequest`).toPromise();
  }

  cancelBookingRequest(bookingUniqueId, reason) {
    return this.api.post(this.stagingUrl + 'users/bookings/request_cancellation', {
      'booking_unique_id': bookingUniqueId,
      'reason': reason
    }).toPromise()
  }
}
