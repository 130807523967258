<div *ngIf="!progress">
    <div *ngIf="!bookings.length" class="no-rooms">
        No bookings found !
    </div>

    <div class="nat-geo-item d-block d-lg-none"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }"
        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
        <div class="d-flex justify-content-between align-items-center pointer">
            <div class="d-flex justify-content-start">
                <div class="mar-right-15">
                    <div class="icon-gifting"></div>
                </div>
                <div class="">
                    <div class="nat-geo-item-title">
                        {{'To, ' + booking.attributes.booking_data.data[0].attributes.display_first_name + ' ' + booking.attributes.booking_data.data[0].attributes.display_last_name}}
                    </div>
                    <div class="nat-geo-item-details">
                        {{booking.attributes.booking_data.data[0].attributes.delivery_date | date: 'MMM dd, yyyy'}}
                        <span>|</span>
                        {{booking.attributes.booking_data.data[0].attributes.delivery_time}}
                    </div>
                </div>
            </div>
            <div class="status-text">
                <div>
                    <app-status-label class="status-text" [status]="booking.attributes.status"
                        [date]="booking.attributes.booking_data.data[0].attributes.delivery_date"></app-status-label>
                </div>
            </div>
        </div>
    </div>
    <div class="nat-geo-item d-none d-lg-block"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }">
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-start w80">
                    <div class="mar-right-15">
                        <div class="icon-gifting"></div>
                    </div>
                    <div>
                        <div class="nat-geo-item-title">
                            {{'To, ' + booking.attributes.booking_data.data[0].attributes.display_first_name + ' ' + booking.attributes.booking_data.data[0].attributes.display_last_name}}
                        </div>
                        <div class="nat-geo-item-details">
                            {{booking.attributes.booking_data.data[0].attributes.delivery_date | date: 'MMM dd, yyyy'}}
                            <span>|</span>
                            {{booking.attributes.booking_data.data[0].attributes.delivery_time}}
                        </div>
                    </div>
                </div>
                <div class="status-text">
                    <div>
                        <app-status-label class="status-text" [status]="booking.attributes.status"
                            [date]="booking.attributes.booking_data.data[0].attributes.delivery_date">
                        </app-status-label>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="booking-id-detail">
                    <div *ngIf="booking.attributes?.booking_unique_id">
                        <span>Order ID : </span>
                        <span class="id">{{booking.attributes?.booking_unique_id}}</span>
                    </div>
                </div>
                <div class="d-flex">
                    <ng-container
                        *ngIf="checkIfUpcoming(booking.attributes.booking_data.data[0].attributes.delivery_date)">
                        <span class="cancel-booking pointer" *ngIf="booking.attributes.status == 'booking_confirmed'"
                            (click)="cancelBooking(booking)">Request Cancellation</span>
                        <span *ngIf="booking.attributes.status == 'booking_confirmed'"
                            class="pad-left-8 pad-right-8 pipe">|</span>
                    </ng-container>
                    <div class="ft-wt-400 cancel-ticket pointer"
                        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
                        View Details
                        <span class="icon-angle-right"></span>
                    </div>
                </div>
            </div>
        </div>
        <hr>

        <div class="d-flex flex-wrap product-container ">
            <div class="col-12 col-lg-6 col-md-6 d-flex align-items-center mar-bot-20"
                *ngFor="let each of booking.attributes.booking_data.data">
                <div class="product-image">
                    <img loading="lazy" default="assets/gifting/default-image.png"
                        [src]="each.attributes.images.image_link_1" alt="">
                </div>
                <div class="product-details ">
                    <div class="product-name">{{each.attributes.product_name}}</div>
                    <div class="product-amount"><span class="rupee-symbol mar-right-2">₹</span>
                        {{each.attributes.amount | indianCurrency}}</div>
                </div>
            </div>
        </div>

    </div>
    <div class="d-flex justify-content-center w100 my-5">
        <pagination-controls *ngIf="bookings.length>10" responsive="true" (pageChange)="pageNo = $event; moveUp();">
        </pagination-controls>
    </div>
</div>
<div class="d-flex justify-content-center w100 my-5">
    <div class="dot-flashing" *ngIf="progress"></div>
</div>