import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { appConstants } from "src/app/app.constants";
import { Observable } from 'rxjs/Rx';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  apps = appConstants.apps;
  public currentApp;
  private currentModule: BehaviorSubject<object> = new BehaviorSubject<object>(null);
  private refreshHeader: BehaviorSubject<object> = new BehaviorSubject<object>(null);
  private openToastr: BehaviorSubject<object> = new BehaviorSubject<object>(null);
  public counter = 1;


  private pointData = new BehaviorSubject(0);
  pointData$ = this.pointData.asObservable();


  constructor(public router: Router) {
  }

  public setCurrentModule(data){
    console.log('setCurrentModule',data);
    this.currentApp = data;
    this.currentModule.next(data);
  }

  public triggerToastr(type,msg){
    this.openToastr.next({type: type, msg : msg});
  }

  public getTriggerToastr(): Observable<object>{
    return this.openToastr.asObservable();
  }

  public getCurrentModule(): Observable<object>{
    return this.currentModule.asObservable();
  }

  public triggerRefreshHeader(){
    this.counter +=1;
    this.refreshHeader.next({status: this.counter});
  }

  public gettriggeredRefreshHeader(): Observable<object>{
    return this.refreshHeader.asObservable();
  }




  // setData(data){
  //   let headerNode = this.getData('headerNode');
  //   if((headerNode && headerNode.id && headerNode.id != data.id)){
  //       localStorage.setItem('headerNode', JSON.stringify(data));
  //       location.assign(data.url);
  //   }
  // }

  // getData(key){
  //   console.log(localStorage.getItem('headerNode'), typeof(localStorage.getItem('headerNode')), localStorage.getItem('headerNode') &&  localStorage.getItem('headerNode') != 'undefined')
  //   const data = JSON.parse(localStorage.getItem('headerNode') &&  localStorage.getItem('headerNode') != 'undefined'? localStorage.getItem('headerNode') : '{}');
  //   if(data && data.id){
  //     return data;
  //   }else{
  //     localStorage.setItem('headerNode', JSON.stringify(this.apps[0]));
  //     return this.apps[0];
  //   }
  // }
  changePointsData(data: any) {
    this.pointData.next(data)
  }
}
