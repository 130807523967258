import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListingPageComponent } from './listing-page/listing-page.component';
import { PointsTransferDetailsComponent } from './points-transfer-details/points-transfer-details.component';
import { BookingConfirmedComponent } from './booking-confirmed/booking-confirmed.component';
import { PointsTransferHistoryDetailsComponent } from '../booking-history/points-transfer-history-details/points-transfer-history-details.component';

const routes: Routes = [
  { path: "", component: ListingPageComponent },
  { path: "details/:provider_id", component: PointsTransferDetailsComponent },
  { path: "checkout-message/:transfer_id", component: BookingConfirmedComponent },
  { path: "booking-details", component: PointsTransferHistoryDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PointsTransferRoutingModule { }
