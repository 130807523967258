<div *ngIf="!progress" style="padding: 0 15px;">
    <div *ngIf="!bookings.length" class="no-rooms">
        No bookings found !
      </div>
    <div class="nat-geo-item d-block d-sm-none" *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }" (click)="openBookingDetails(booking.attributes.id)">
        <div class="d-flex justify-content-between align-items-center pointer">
            <div class="d-flex justify-content-start align-items-center">
                <div class="mar-right-15">
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17.5" r="17.5" fill="#C2A63F"/>
                        <path d="M21.9996 10.6V9H13.9996V10.6H11.5996V25H16.3996V23.4H19.5996V25H24.3996V10.6H21.9996ZM17.1996 21H15.5996V19.4H17.1996V21ZM17.1996 17.8H15.5996V16.2H17.1996V17.8ZM17.1996 14.6H15.5996V13H17.1996V14.6ZM20.3996 21H18.7996V19.4H20.3996V21ZM20.3996 17.8H18.7996V16.2H20.3996V17.8ZM20.3996 14.6H18.7996V13H20.3996V14.6Z" fill="#252525"/>
                    </svg> 
                </div>
                <div class="">
                    <div class="nat-geo-item-title">{{booking.attributes?.details?.attributes?.hotel_details?.name}}</div>
                    <div class="nat-geo-item-details">
                        <span class="from-to">
                            {{booking.attributes?.details?.attributes?.from_date | date: 'EEEE, dd MMM yyyy'}}
                        </span>
                        <span> - </span>
                        <span class="from-to">
                            {{booking.attributes?.details?.attributes?.to_date | date: 'EEEE, dd MMM yyyy'}}
                        </span>
                    </div>
                </div>
          </div>
          <div class="status-text">
            <div>
                <app-status-label class="status-text" [status]="getStatus(booking.attributes?.details?.attributes?.from_date, booking.attributes.status)"></app-status-label>
            </div>
          </div>
        </div>
    </div>
    <div class="nat-geo-item d-none d-sm-block" *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }">
    <div class="bg">
        <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-start align-items-center w80">
                <div class="mar-right-15">
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17.5" r="17.5" fill="#C2A63F"/>
                        <path d="M21.9996 10.6V9H13.9996V10.6H11.5996V25H16.3996V23.4H19.5996V25H24.3996V10.6H21.9996ZM17.1996 21H15.5996V19.4H17.1996V21ZM17.1996 17.8H15.5996V16.2H17.1996V17.8ZM17.1996 14.6H15.5996V13H17.1996V14.6ZM20.3996 21H18.7996V19.4H20.3996V21ZM20.3996 17.8H18.7996V16.2H20.3996V17.8ZM20.3996 14.6H18.7996V13H20.3996V14.6Z" fill="#252525"/>
                    </svg>    
                </div>
                <div>
                    <div>
                        <span class="hotel_name">
                            {{booking.attributes?.details?.attributes?.hotel_details.name}}
                        </span>
                    </div>
                    <div>
                        <span class="from-to">
                            {{booking.attributes?.details?.attributes?.hotel_details.address}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="status-text">
                <div>
                    <app-status-label class="status-text" [status]="getStatus(booking.attributes?.details?.attributes?.from_date, booking.attributes.status)"></app-status-label>
                    <!-- <span class="enquiry-status">{{booking.attributes.status | removeUnderscore}}</span> -->
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="bg">
        <div class="d-flex justify-content-between">
            <div class="booking-id-detail">
                <span>Booking ID : </span>
                <span class="id">{{booking.attributes?.unique_id}}</span>
            </div>
            <div>
                <span *ngIf="booking.attributes.status === 'booking_confirmed' && booking.attributes.details.attributes.partner_name === 'yatra'" class="download-ticket pointer" (click)="cancelOpt(booking)">Cancel Booking</span>
                <span class="mar-left-5 mar-right-5" *ngIf="booking.attributes.status === 'booking_confirmed' && booking.attributes.details.attributes.partner_name === 'yatra'">
                    <svg width="1" height="22" viewBox="0 0 1 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="1" height="22" fill="#E1E1E1"/>
                    </svg>
                </span>
                <span class="cancel-ticket pointer" (click)="openBookingDetails(booking.attributes.id)">
                    View Details
                </span>
                <span>
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 10.5L5.5 6L1.5 1.5" stroke="#C2A63F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>                        
                </span>
            </div>
        </div>
    </div>
    <hr>
    <div class="adfasdas">
        <div class="desktop-body d-flex align-items-center justify-content-between">
            <div class="check-in-out">
                Check-In
                <span>
                    <svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.3536 4.35355C19.5488 4.15829 19.5488 3.84171 19.3536 3.64645L16.1716 0.464466C15.9763 0.269204 15.6597 0.269204 15.4645 0.464466C15.2692 0.659728 15.2692 0.976311 15.4645 1.17157L18.2929 4L15.4645 6.82843C15.2692 7.02369 15.2692 7.34027 15.4645 7.53553C15.6597 7.7308 15.9763 7.7308 16.1716 7.53553L19.3536 4.35355ZM0 4.5H19V3.5H0V4.5Z" fill="white"/>
                    </svg>
                </span>
                Check-Out
            </div>
            <div class="check-in-out-txt">
                {{booking.attributes?.details?.attributes?.from_date | date: 'EEEE, dd MMM yyyy'}}
                <span>
                    <svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.3536 4.35355C19.5488 4.15829 19.5488 3.84171 19.3536 3.64645L16.1716 0.464466C15.9763 0.269204 15.6597 0.269204 15.4645 0.464466C15.2692 0.659728 15.2692 0.976311 15.4645 1.17157L18.2929 4L15.4645 6.82843C15.2692 7.02369 15.2692 7.34027 15.4645 7.53553C15.6597 7.7308 15.9763 7.7308 16.1716 7.53553L19.3536 4.35355ZM0 4.5H19V3.5H0V4.5Z" fill="white"/>
                    </svg>
                </span>
                {{booking.attributes?.details?.attributes?.to_date | date: 'EEEE, dd MMM yyyy'}}
            </div>
        </div>
        <hr>
        <div class="desktop-body d-flex align-items-center justify-content-between">
            <div class="check-in-out">
                Rooms
            </div>
            <div class="check-in-out-txt">
                {{booking.attributes?.details?.attributes?.rooms[0].room_count}}
                {{booking.attributes?.details?.attributes?.rooms[0].room_name}}
            </div>
        </div>
        <hr>
        <div class="desktop-body d-flex align-items-center justify-content-between">
            <div class="check-in-out">
                Guests
            </div>
            <div class="check-in-out-txt">
                {{booking.attributes?.details?.attributes.user_count.adults}} Adults, {{booking.attributes?.details?.attributes.user_count.children}} Children
            </div>
        </div>
    </div>
    <!-- <div class="desktop-body">
        <div class="user">
            <span>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66667 6.66667C8.50833 6.66667 10 5.175 10 3.33333C10 1.49167 8.50833 0 6.66667 0C4.825 0 3.33333 1.49167 3.33333 3.33333C3.33333 5.175 4.825 6.66667 6.66667 6.66667ZM6.66667 8.33333C4.44167 8.33333 0 9.45 0 11.6667V13.3333H13.3333V11.6667C13.3333 9.45 8.89166 8.33333 6.66667 8.33333Z" fill="#64707C"/>
                </svg>
            </span>
            <span class="user-name">
                {{booking.attributes.booking_data.user_info?.first_name}} {{booking.attributes.booking_data.user_info?.last_name}} 
            </span>
        </div>
        <div *ngIf="booking.attributes.amount" class="pricing">
            <div class="ft-wt-400">
                <span class="price">
                    <span class="rupee-symbol">₹</span>
                    {{booking.attributes?.amount | indianCurrency}}
                </span>
                <span class="for"> for {{ moment(booking.attributes?.details?.attributes?.to_date).diff(moment(booking.attributes.booking_data?.from_date), 'days')}} nights </span>
            </div>
        </div>
    </div> -->
    </div>
    <div class="d-flex justify-content-center w100 my-5">
        <pagination-controls *ngIf="bookings.length>10" responsive="true" (pageChange)="pageNo = $event; moveUp();"></pagination-controls>
    </div>
</div>
<div class="d-flex justify-content-center w100 my-5">
    <div class="dot-flashing" *ngIf="progress"></div>
</div>

<!-- <app-loader *ngIf="progress"></app-loader> -->

  