import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AirportType } from 'src/app/airport-concierge/enums/airport-type.enum';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AirportConciergeService } from 'src/app/airport-concierge/service/airport-concierge/airport-concierge.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { PaymentHomeService } from 'src/app/payment/payment-home.service';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import { appConstants } from 'src/app/app.constants';
import swal from "sweetalert2";

@Component({
  selector: 'app-airport-con-history-details',
  templateUrl: './airport-con-history-details.component.html',
  styleUrls: ['./airport-con-history-details.component.scss']
})
export class AirportConHistoryDetailsComponent implements OnInit {
  progress: boolean;
  bookingId: string;
  bookingData;
  cardIcon;
  servicesName = {
    'Meet and Assist': 'VIP Meet & Assist',
    'Porter Service': 'Baggage Porters',
    'Lounge Facilitation': 'Lounge Services'
  };
  AirportType = AirportType;
  exchangeRates = [];
  constructor(
    private route: ActivatedRoute,
    private airportConciergeService: AirportConciergeService,
    public dialog: MatDialog,
    public login: LoginService,
    private router: Router,
    private paymentService: PaymentHomeService
  ) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.progress = true;
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.bookingId = this.route.snapshot.queryParamMap.get("id");
          this.getCurrencyExchangeRates().then((res) => {
            this.airportConciergeService.getBookingDetailsById(this.bookingId).then(resp => {
              this.bookingData = resp['data'];
              if (this.bookingData.attributes.card_number) {
                this.paymentService
                  .getCardDetails(this.bookingData.attributes.card_number.slice(0, 6))
                  .subscribe(resp => {
                    this.cardIcon = this.getCardLink(resp["cardType"]);
                  });
              }
              this.progress = false;
            });
          });
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          };
        });
      });
  }

  back() {
    history.back();
  }

  convertMyWay() {
    let title = document.title;
    document.title = this.bookingId;
    window.print();
    document.title = title;
  }

  getCardLink(cardType) {
    return appConstants.cardIconsMapping[cardType]
      ? "assets/" + appConstants.cardIconsMapping[cardType]
      : null;
  }

  getServicePrice(each) {
    if (this.exchangeRates && this.exchangeRates.length) {
      const currencyCode = 'USD';
      const val = this.exchangeRates.find((each) => each.code.toLowerCase() === currencyCode.toLowerCase());
      if (val) {
        return each.rate_in_usd / val.rate;
      }
      return 0;
    }
  }

  getCurrencyExchangeRates() {
    return new Promise((resolve, reject) => {
      this.airportConciergeService.getCurrencyExchangeRates().then((res: any) => {
        this.exchangeRates = res.exchange_rates;
        resolve(true);
      });
    })
  }

  getArrivalFlightDetails() {
    let result = '';
    if (this.bookingData.attributes.booking_data.origin_airport) {
      result = this.bookingData.attributes.booking_data.origin_airport.city ? this.bookingData.attributes.booking_data.origin_airport.city : ''
      if (this.bookingData.attributes.booking_data.origin_airport.country) {
        result = result ? (result + ', ' + this.bookingData.attributes.booking_data.origin_airport.country) : this.bookingData.attributes.booking_data.origin_airport.country;
      }
    }
    result = result + ' | ' + this.bookingData.attributes.booking_data.journey_details.arrival_flight_no;
    return 'From ' + result;
  }

  getArrivalDateTime() {
    let result = '';
    result = moment(this.bookingData.attributes.booking_data.journey_details.arrival_date).format('MMM DD, YYYY');
    result = result + ' | ' + this.bookingData.attributes.booking_data.journey_details.arrival_time;
    return result;
  }

  getDepartureFlightDetails() {
    let result = '';
    if (this.bookingData.attributes.booking_data.destination_airport) {
      result = this.bookingData.attributes.booking_data.destination_airport.city ? this.bookingData.attributes.booking_data.destination_airport.city : ''
      if (this.bookingData.attributes.booking_data.destination_airport.country) {
        result = result ? (result + ', ' + this.bookingData.attributes.booking_data.destination_airport.country) : this.bookingData.attributes.booking_data.destination_airport.country;
      }
    }
    result = result + ' | ' + this.bookingData.attributes.booking_data.journey_details.departure_flight_no;
    return 'To ' + result;
  }

  getDepartureDateTime() {
    let result = '';
    result = moment(this.bookingData.attributes.booking_data.journey_details.departure_date).format('MMM DD, YYYY');
    result = result + ' | ' + this.bookingData.attributes.booking_data.journey_details.departure_time;
    return result;
  }

  getTravellers() {
    let result = '';
    if (this.bookingData.attributes.booking_data.user_pack.adults) {
      result = this.bookingData.attributes.booking_data.user_pack.adults + ' Adult(s)';
    }
    if (this.bookingData.attributes.booking_data.user_pack.children) {
      result = this.bookingData.attributes.booking_data.user_pack.children + ' Children';
    }
    if (this.bookingData.attributes.booking_data.user_pack.infants) {
      result = this.bookingData.attributes.booking_data.user_pack.infants + ' Infant(s)';
    }
    return result;
  }

  getAdditionCharge() {
    const currencyCode = 'USD';
    if (this.exchangeRates && this.exchangeRates.length) {
      const val = this.exchangeRates.find((each) => each.code.toLowerCase() === currencyCode.toLowerCase());
      if (val) {
        return (this.bookingData.attributes.booking_data.requested_service.additional_hour_charge / val.rate);
      }
    }
    return 0;
  }

  formatTerminalName(str) {
    return str.substring(4);
  }

  checkIfUpcoming() {
    return moment(this.bookingData.attributes.booking_data.service_date_time).isAfter(moment())
  }

  cancelBooking() {
    // let cancellationMessage = '<p><span class="icon-airportcon mar-right-5 fnt-14"></span>' + booking.attributes.booking_data.booking_response.product.name + '</p>' +
      // '<p><span class="icon-group-1 mar-right-5 fnt-14"></span> Date : ' + this.getDate(booking.attributes.booking_data.booking_response) +
      // '<p><span class="icon-clock mar-right-5 fnt-14"></span> Time : ' + this.getTime(booking.attributes.booking_data.booking_response) +
      // '<p><span class="icon-users mar-right-5 fnt-14"></span>' + this.getTravellers(booking);
    // for (const each of booking.attributes.booking_data.requested_service.requested_services) {

    // }
    swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this booking?',
        // html: cancellationMessage,
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reason...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        preConfirm: (input) => {
          if (!input) {
            swal.showValidationMessage("Please enter reason for cancellation.");
            return false;
          } else {
            return input;
          }
        }
      })
      .then(result => {
        if (result.value) {
          this.progress = true;
          this.airportConciergeService.cancelBooking(this.bookingData.attributes.booking_unique_id).then((resp: any) => {
            if (resp['status'] === "success") {
              // let refundTxt = "<p> Refundable amount : " +
              //   '<span class="rupee-symbol">₹</span> ' +
              //   resp.cancel_response.refunded_amount +
              //   "</p>";
              swal.fire({
                imageUrl: "assets/modal-info.png",
                imageHeight: 60,
                title: "Cancellation Successful",
                // html: refundTxt,
              }).then(res => {
                this.ngOnInit();
              })
            } else {
              swal.fire({
                imageUrl: "assets/modal-error.png",
                imageHeight: 60,
                title: "Cancellation Failed",
                html: '<p>' + 'In case of assistance contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            }
          });
        }
      });
  }

}
