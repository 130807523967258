// export const environment = {
//   production: false,
//   envName: 'staging',
//   apiUrl: 'https://rewardshub-stage-api-customer.poshvine.com/api/v1/',
//   stagingUrl:'https://rewardshub-stage-api-customer.poshvine.com/',
//   giftCard: {
//     apiUrl : 'https://sandbox-offerservice.poshvine.com/offer_service/internal/',
//     clientToken: '1234',
//   }
// };

export const environment = {
  production: false,
  envName: 'dev',
  sbiUrl: 'https://sbi-uat-new.sbicard.com',
  preLoginUrl: 'https://aurumcreditcard.com',
  apiUrl: 'https://rewardshub-stage-api-customer.poshvine.com/api/v1/',
  stagingUrl: 'https://rewardshub-stage-api-customer.poshvine.com/',
  giftCard: {
    apiUrl: 'https://sandbox-offerservice.poshvine.com/offer_service/internal/',
    apiUrlNew: 'https://sandbox-offerservice.poshvine.com/offer_service/v2',

    clientToken: '12345',
  },
  apiUrlPsV1: 'https://sandbox-api.poshvine.com/ps/v1/',
  v2ApiUrl: 'https://sandbox-api.poshvine.com/cs/v1',
  v2ApiBs: 'https://sandbox-api.poshvine.com/bs/v1',
  v2ApiBsInternal: 'https://sandbox-api.poshvine.com/bs/internal',
  apiUrlPaV1: "https://sandbox-api.poshvine.com/pa/v1/",
  apiUrlLsV1: "https://sandbox-api.poshvine.com/ls/v1/",
  apiUrlPtsV1: "https://sandbox-api.poshvine.com/pts/v1/",
  clientId: '669e82fd-4b00-42b3-af21-b54abcc11756',
  projectId: '81858da0-647a-4156-aec8-edf2f4c74447',
  gmApiKey: 'AIzaSyAXREnbpzSGulf8xS5J43tsYwrbBFltR7k',
  SENTRY_ORG: 'rzp',
  SENTRY_PROJECT: 'sbi-aurum',
  SENTRY_AUTH_TOKEN: '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',
  sentryDsn: 'https://9d078d6f381c277a40cba1ba0b43bada@o515678.ingest.us.sentry.io/4507389415784448'
};
