import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccountModalComponent } from "./account-modal/account-modal.component";

import { BookingConfirmedComponent } from "./booking-confirmed/booking-confirmed.component";

import { PointsTransferRoutingModule } from "./points-transfer-routing.module";
import { ListingPageComponent } from "./listing-page/listing-page.component";
import { PointsTransferDetailsModule } from "./points-transfer-details/points-transfer-details.module";
import { SharedModule } from "../shared/shared.module";
import { BookingHistoryModule } from "../booking-history/booking-history.module";
import { ToursExperienceComponent } from "./tours-experiences/tours-experiences.component";

@NgModule({
  declarations: [
    ListingPageComponent,
    BookingConfirmedComponent,
    AccountModalComponent,
    ToursExperienceComponent
  ],
  imports: [
    CommonModule,
    PointsTransferDetailsModule,
    PointsTransferRoutingModule,
    SharedModule,
    BookingHistoryModule
  ],
  exports: [ListingPageComponent],
})
export class PointsTransferModule {}
