import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocsappService } from 'src/app/docsapp/services/docsapp/docsapp.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { PaymentHomeService } from 'src/app/payment/payment-home.service';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import { appConstants } from 'src/app/app.constants';
import { ToastrService } from "ngx-toastr";
import swal from "sweetalert2";
import { ConciergeServicesService } from 'src/app/concierge-services/service/concierge-services.service';
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: 'app-concierge-services-history-details',
  templateUrl: './concierge-services-history-details.component.html',
  styleUrls: ['./concierge-services-history-details.component.scss']
})
export class ConciergeServicesHistoryDetailsComponent implements OnInit {
  progress: boolean;
  bookingId: string;
  booking;
  cardIcon;
  constructor(
    private route: ActivatedRoute,
    private docsappService: DocsappService,
    public dialog: MatDialog,
    public login: LoginService,
    private paymentService: PaymentHomeService,
    private conciergeServicesService: ConciergeServicesService,
    public data: DataService,
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.progress = true;
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.bookingId = this.route.snapshot.queryParamMap.get("id");
          this.docsappService.getBookingDetailsById(this.bookingId).then(resp => {
            this.booking = resp['data'];
            if (this.booking.attributes.card_number) {
              this.paymentService
                .getCardDetails(this.booking.attributes.card_number.slice(0, 6))
                .subscribe(resp => {
                  this.cardIcon = this.getCardLink(resp["cardType"]);
                });
            }
            this.progress = false;
          });
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          }
        });
      });
  }

  back() {
    history.back();
  }

  resendPaymentLink() {
    this.progress = true;
    this.paymentService.resendPaymentLink(this.bookingId).subscribe((res) => {
      this.progress = false;
      if (res["status"] == "success") {
        this.data.triggerToastr('success',res["msg"]);
      } else {
        this.data.triggerToastr('error',res["msg"]);
      }
    });
  }

  convertMyWay() {
    let title = document.title;
    document.title = this.bookingId;
    window.print();
    document.title = title;
  }

  getCardLink(cardType) {
    return appConstants.cardIconsMapping[cardType]
      ? "assets/" + appConstants.cardIconsMapping[cardType]
      : null;
  }

  getCategory(category){
    if(category === 'EventManagement'){
      return 'Organise an Event'
    }else if(category === 'EventManagement'){
      return 'Organise an Event'
    }

  }

  cancelBooking() {
    let cancellationMessage = '<p>' + '</p>';
    // <span class="icon-gifting mar-right-5 fnt-14"></span>
    swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this booking?',
        html: cancellationMessage,
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reason...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        preConfirm: (input) => {
          if (!input) {
            swal.showValidationMessage("Please enter reason for cancellation.");
            return false;
          } else {
            return input;
          }
        }
      })
      .then(result => {
        if (result.value) {
          this.progress = true;
          this.conciergeServicesService.cancelBookingRequest(this.bookingId, result.value).then((resp: any) => {
            if (resp['status'] === "success") {
              swal.fire({
                imageUrl: "assets/modal-info.png",
                imageHeight: 60,
                title: "Cancellation Requested",
                html: '<p>'+'You will soon receive the status of your cancellation request through email or call from the SBI Card Concierge team. '+'</p>',
              }).then(res => {
                this.ngOnInit();
              })
            } else {
              swal.fire({
                imageUrl: "assets/modal-error.png",
                imageHeight: 60,
                title: "Cancellation Request Failed",
                html: '<p>' + 'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            }
          });
        }
      });


  }

}
