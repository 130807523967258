<div *ngIf="!progress; else progressblock">
    <div *ngIf="!bookings?.length" class="no-rooms">
        No bookings found !
    </div>

    <div class="nat-geo-item d-block d-lg-none"
        *ngFor="let booking of bookings | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }"
        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
        <div class="d-flex justify-content-between align-items-center pointer">
            <div class="d-flex justify-content-start align-items-center">
                <div class="mar-right-15">
                    <div class="icon-gifting"></div>
                </div>
                <div>
                    <div class="nat-geo-item-title">
                        {{getOfferName(booking)}}
                    </div>
                    <div class="merchant-name mar-top-2 fnt-14 f300" *ngIf="getMerchantName(booking)">
                        <span>{{'by ' + getMerchantName(booking)}}</span>
                    </div>
                </div>
            </div>
            <div class="status-text">
                <div>
                    <app-status-label class="status-text" [status]="booking.attributes?.status">
                    </app-status-label>
                </div>
            </div>
        </div>
    </div>
    <div class="nat-geo-item d-none d-lg-block"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }">
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-start align-items-center w80">
                    <div class="mar-right-15">
                        <div class="icon-gifting"></div>
                    </div>
                    <div>
                        <div>
                            <span class="hotel_name">
                                {{getOfferName(booking)}}
                            </span>
                        </div>
                        <div class="merchant-name mar-top-2 fnt-14 f300" *ngIf="getMerchantName(booking)">
                            {{'by ' + getMerchantName(booking)}}
                        </div>
                    </div>
                </div>
                <div class="status-text">
                    <div>
                        <app-status-label class="status-text" [status]="booking.attributes?.status">
                        </app-status-label>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="booking-id-detail">
                    <div>
                        <span>Offer ID : </span>
                        <span class="id">{{booking.attributes?.booking_unique_id}}</span>
                    </div>
                </div>
                <div>


                    <span class="view-details pointer"
                        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
                        View Details
                        <span class="icon-angle-right">
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <hr>
        <div class="adfasdas">
            <div class="desktop-body d-flex align-items-center justify-content-between">
                <div class="check-in-out">
                    Coupon Code
                </div>
                <div class="check-in-out-txt">
                    <ng-container *ngIf="booking?.attributes?.booking_data?.coupon_code">
                        {{booking?.attributes.booking_data.coupon_code}}
                    </ng-container>
                    <ng-container *ngIf="!(booking?.attributes?.booking_data?.coupon_code)">
                        -
                    </ng-container>
                </div>
            </div>
            <hr>
            <div class="desktop-body d-flex align-items-center justify-content-between">
                <div class="check-in-out">
                    Valid Till
                </div>
                <div class="check-in-out-txt">
                    <ng-container *ngIf="booking?.attributes?.booking_data?.valid_till">
                        {{booking?.attributes.booking_data.valid_till | date:'MMM dd, yyyy'}}
                    </ng-container>
                    <ng-container *ngIf="!(booking?.attributes?.booking_data?.valid_till)">
                        -
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center w100 my-5">
        <pagination-controls *ngIf="bookings?.length>itemsPerPage" responsive="true"
            (pageChange)="pageNo = $event; moveUp();">
        </pagination-controls>
    </div>
</div>
<ng-template #progressblock>
    <div class="d-flex justify-content-center w100 my-5">
        <div class="dot-flashing" *ngIf="progress"></div>
    </div>
</ng-template>