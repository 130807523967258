<div *ngIf="!progress">
    <div *ngIf="!bookings.length" class="no-rooms">
        No bookings found !
    </div>

    <div class="nat-geo-item d-block d-sm-none"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }"
        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
        <div class="d-flex justify-content-between align-items-center pointer">
            <div class="d-flex justify-content-start">
                <div class="mar-right-15">
                    <div class="icon-events"></div>
                </div>
                <div class="">
                    <div class="nat-geo-item-title">
                        {{booking.attributes.booking_data.event_details.event_desc}}
                    </div>
                    <div class="nat-geo-item-details">
                        {{booking.attributes.booking_data.event_details.venue_desc + ', ' + booking.attributes.booking_data.event_details.city_desc  + ', ' + booking.attributes.booking_data.event_details.country_desc}}
                    </div>
                </div>
            </div>
            <div class="status-text">
                <div>
                    <app-status-label class="status-text" [status]="booking.attributes.status"
                        [date]="booking?.attributes?.booking_data?.seat_selection?.seats[0].date"></app-status-label>
                </div>
            </div>
        </div>
    </div>
    <div class="nat-geo-item d-none d-sm-block"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }">
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-start w80">
                    <div class="mar-right-15">
                        <div class="icon-events"></div>
                    </div>
                    <div>
                        <div class="nat-geo-item-title">
                            {{booking.attributes.booking_data.event_details.event_desc}}
                        </div>
                        <div class="nat-geo-item-details">
                            {{booking.attributes.booking_data.event_details.venue_desc + ', ' + booking.attributes.booking_data.event_details.city_desc  + ', ' + booking.attributes.booking_data.event_details.country_desc}}
                        </div>
                    </div>
                </div>
                <div class="status-text">
                    <div>
                        <app-status-label class="status-text" [status]="booking.attributes.status"
                            [date]="booking?.attributes?.booking_data?.seat_selection?.seats[0].date">
                        </app-status-label>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="booking-id-detail">
                    <div *ngIf="booking.attributes?.booking_unique_id">
                        <span>Booking ID : </span>
                        <span class="id">{{booking.attributes?.booking_unique_id}}</span>
                    </div>
                </div>
                <div class="d-flex">
                    <!-- <ng-container
                        *ngIf="checkIfUpcoming(booking.attributes.booking_data.data[0].attributes.delivery_date)">
                        <span class="cancel-booking pointer" *ngIf="booking.attributes.status == 'booking_confirmed'"
                            (click)="cancelBooking(booking)">Request Cancellation</span>
                        <span *ngIf="booking.attributes.status == 'booking_confirmed'"
                            class="pad-left-8 pad-right-8 pipe">|</span>
                    </ng-container> -->
                    <div class="ft-wt-400 cancel-ticket pointer"
                        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
                        View Details
                        <span class="icon-angle-right"></span>
                    </div>
                </div>
            </div>
        </div>
        <hr>

        <div class="adfasdas">
            <ng-container *ngIf="booking?.attributes?.booking_data?.seat_selection?.seats[0].date">
                <div class="desktop-body d-flex align-items-center justify-content-between">
                    <div class="check-in-out">
                        Date
                    </div>
                    <div class="check-in-out-txt">
                        {{booking.attributes.booking_data.seat_selection.seats[0].date | date:'EEEE, dd MMM yyyy'}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="booking?.attributes?.booking_data?.perf_details?.time_desc">
                <hr>
                <div class="desktop-body d-flex align-items-center justify-content-between">
                    <div class="check-in-out">
                        Time
                    </div>
                    <div class="check-in-out-txt">
                        {{booking?.attributes?.booking_data?.perf_details?.time_desc}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="getPeople(booking)">
                <hr>
                <div class="desktop-body d-flex align-items-center justify-content-between">
                    <div class="check-in-out">
                        Number of people
                    </div>
                    <div class="check-in-out-txt">
                        {{getPeople(booking)}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="getSeats(booking)">
                <hr>
                <div class="desktop-body d-flex align-items-center justify-content-between">
                    <div class="check-in-out">
                        Seats
                    </div>
                    <div class="check-in-out-txt">
                        {{getSeats(booking)}}
                    </div>
                </div>
            </ng-container>
        </div>

    </div>
    <div class="d-flex justify-content-center w100 my-5">
        <pagination-controls *ngIf="bookings.length>10" responsive="true" (pageChange)="pageNo = $event; moveUp();">
        </pagination-controls>
    </div>
</div>
<div class="d-flex justify-content-center w100 my-5">
    <div class="dot-flashing" *ngIf="progress"></div>
</div>