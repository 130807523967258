import { Injectable, Injector } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { Error404Component } from "./pages/error404/error404.component";
import { Error500Component } from "./pages/error500/error500.component";
import { BookingFailedComponent } from "./pages/booking-failed/booking-failed.component";
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { ApiService } from 'src/app/services/common/api/api.service';
import { environment } from 'src/environments/environment';
import {appConstants} from 'src/app/app.constants';
import { Routes, RouterModule } from "@angular/router";
import { AuthTokenComponent } from './pages/auth-token/auth-token.component';

const routes: Routes = [
  // { path: "", redirectTo: "", pathMatch: "full" },
  { path: "", component: HomepageComponent },
  {
    path: "hotels",
    component: HomepageComponent,
  },
  {
    matcher: hotelsMatcher,
    loadChildren: () => import("./hotel/hotel.module").then(m => m.HotelModule)
  },
  {
    path: "nat-geo",
    component: HomepageComponent,
  },
  {
    matcher: natGeoMatcher,
    loadChildren: () =>
      import("./nat-geo/nat-geo.module").then(m => m.NatGeoModule)
  },
  {
    path: "flights",
    component: HomepageComponent,
  },
  {
    matcher: flightsMatcher,
    loadChildren: () =>
      import("./flights/flights.module").then(m => m.FlightsModule)
  },
  {
    path: "giftcards",
    component: HomepageComponent,
  },
  {
    matcher: giftcardsMatcher,
    loadChildren: () =>
      import("./giftcards/giftcard.module").then(m => m.GiftcardModule)
  },
  {
    path: "tours-and-activities",
    component: HomepageComponent,
  },
  {
    matcher: toursActivitiesMatcher,
    loadChildren: () =>
      import("./tours-activities/tours-activities.module").then(m => m.ToursActivitiesModule)
  },
  {
    path: "charitable-donations",
    component: HomepageComponent,
  },
  {
    path: 'points-transfer',
    component: HomepageComponent,
  },
  {
    matcher: pointsTransferMatcher,
    loadChildren: () =>
      import("./points-transfer/points-transfer.module").then(m => m.PointsTransferModule)
  },
  {
    matcher: charitableGivingMatcher,
    loadChildren: () =>
      import("./charitable-giving/charitable-giving.module").then(m => m.CharitableGivingModule)
  },
  {
    path: "events-concerts",
    component: HomepageComponent,
  },
  {
    matcher: eventsConcertsMatcher,
    loadChildren: () =>
      import("./events-concerts/events-concerts.module").then(m => m.EventsConcertsModule)
  },
  {
    path: "booking",
    loadChildren: () =>
      import("./booking-history/booking-history.module").then(
        m => m.BookingHistoryModule
      )
  },
  // { path: 'payment', component: PaymentComponent },
  {
    path: "payments",
    loadChildren: () =>
      import("./payment/payment.module").then(m => m.PaymentModule)
  },
  // { path: 'payment-process', component: PaymentProcessComponent },
  { path: "error", component: Error500Component },
  { path: "booking-failed", component: BookingFailedComponent },
  { path: "myaccount", component: MyAccountComponent },
  { path: "auth_token", component: AuthTokenComponent },

  {
    path: "info",
    loadChildren: () =>
      import("./info/info.module").then(m => m.InfoModule)
  },
  { path: "**", pathMatch: "full", component: Error404Component }
];

export function flightsMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'flights' ? ({consumed: [url[0]]}) : null;
}

export function hotelsMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'hotels' ? ({consumed: [url[0]]}) : null;
}

export function natGeoMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'nat-geo' ? ({consumed: [url[0]]}) : null;
}

export function giftcardsMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'giftcards' ? ({consumed: [url[0]]}) : null;
}

export function toursActivitiesMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'tours-and-activities' ? ({consumed: [url[0]]}) : null;
}

export function charitableGivingMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'charitable-donations' ? ({consumed: [url[0]]}) : null;
}

export function eventsConcertsMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'events-concerts' ? ({consumed: [url[0]]}) : null;
}

export function pointsTransferMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'points-transfer' ? ({consumed: [url[0]]}) : null;
}

@Injectable({
  providedIn: 'root'
})



export class RoutesService {
  currentSettings: any;
  apiUrl: string = environment.apiUrl;
  routesMapping = appConstants.routesMapping;
  requiredApps = [];
  // routes;
  constructor(
    public api: ApiService,
    private injector: Injector,
  ) { }

  getServices(){
    return this.api.get(this.apiUrl+"clients/services").toPromise();
  }

  loadSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
     setTimeout(() => {
        let router = this.injector.get(Router);
        router.config = [];
        routes.forEach(element => {
          router.config.push(element);
        });
        console.log(router);
        resolve(true);
      //  this.getServices().then( res=>{
      //   //  let obtainedApps = res['data'];
      //   //  this.routesMapping.forEach( element => {
      //   //   if(obtainedApps.indexOf(Object.keys(element)[0]) >= 0)
      //   //     this.requiredApps.push(Object.values(element)[0])
      //   //  })
      //   //  this.requiredApps = this.requiredApps.concat(['','flights/lazy', 'booking', 'payments', 'error', 'booking-failed', 'myaccount', 'info', 'auth_token', '**'])
      //   //  let finalRoutes = routes.filter( element => {
      //   //    return this.requiredApps.indexOf(element.path) >= 0
      //   //  })
      //   //  console.log(finalRoutes);

      //  })
      });
     });
  }
}
