<div class="custom-card" (click)="navigateToDetailPage()">
    <div class="custom-card-img">
        <img default="assets/giftcard/default-giftcard.png" [src]="gift?.attributes?.image_url" alt="GiftCard image"
            class="zoom">
    </div>

    <div class="card-content">
        <div class="category">{{getSubCategoriesName(gift)}}</div>
        <p class="heading">{{gift?.attributes?.title}}</p>
        <div class="min">
            <!-- <span class="from mar-right-4">Min from</span> -->
            <span class="amount">
                <span class="rupee-symbol mar-right-2">₹</span>{{getMinValue(gift) | indianCurrency}}</span>

            <span class="amount mar-right-4 mar-left-4" *ngIf="getMinValue(gift)!=getMaxValue(gift)">-</span>

            <span class="amount" *ngIf="getMinValue(gift)!=getMaxValue(gift)">
                <span class="rupee-symbol mar-right-2">₹</span>{{getMaxValue(gift) | indianCurrency}}</span>

        </div>

        <button class="btn add-to-cart w100" (click)="openModal($event, gift)" *ngIf="showAddToCart">Add to
            Cart</button>
    </div>
</div>