import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { appConstants } from "src/app/app.constants";


@Component({
  selector:'app-points-input-box',
  templateUrl:'./points-input-box.component.html',
  styleUrls:['./points-input-box.component.scss']
})
export class PointsInputBoxComponent{
  @Input() public merchantImg:string;
  @Input() public merchantText:string = '';
  @Input() public rewardPoints: number;
  @Output() public pointsChangeEvent:EventEmitter<number> = new EventEmitter<number>();
  public appConstants = appConstants;
  public denominationError = false;
  @Input() public minimumValue = 0;
  @Input() public maximumValue = 0;
  @Input() public userRewardPoints = 0;
  @Input() public isReadOnly = false;
  @Input() public enableTransferArrow = false;
  @Input() public errorMessagesEnabled = true;
  @Input() public customError = '';
  constructor(){}
  public currencyInputChanged(value:any): number {
    this.denominationError = false;
    let num = value.replace(/[$,]/g, "");
    num = num.replace(/\D+/g, "");
    num = num == '' ? 0 : num
    console.log(num)
    return Number(num);
  }

  public handleCurrencyInputChange(event:number): void {
    this.rewardPoints = this.currencyInputChanged(event);
    this.pointsChangeEvent.emit(this.rewardPoints);
  }
  public bringToFocus(elem:any):void{
    console.log(elem?.focus(),'>>>> elem')
  }

  public get isUserLoggedIn(): boolean {
    return (
      Object.keys(JSON.parse(localStorage.getItem("login_details") ?? "{}"))
        .length > 0
    );
  }
}
