<div class="container page" *ngIf="!progress">
    <div class="to-show1">
        <div class="d-none d-sm-block">
            <div class="blueify mar-bot-10 d-flex justify-content-start align-items-center pointer" (click)="back()"> 
                <div class="mar-bot-3">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM13 5.25L1 5.25V6.75L13 6.75V5.25Z" fill="#C2A63F"/>
                    </svg>
                </div>
                <div class="mar-left-10">
                    Back
                </div>
            </div>
            <div class="checkout-message-top d-flex align-items-center justify-content-between mar-bot-15">
                <div class="d-flex  justify-content-start align-items-center">
                    <div class="d-flex flex-column address-detail">
                        <div class="hotel-name">
                            {{bookingData.attributes.booking_data?.booking_request_hash?.restaurant_name}}
                        </div>
                        <div class="address">
                            {{bookingData.attributes.booking_data?.booking_request_hash?.restaurant_address}}
                        </div>
                    </div>
                </div>
                <div class="d-none d-sm-block booking-id-detail">
                    <app-status-label class="status-text" [status]="getStatus(bookingData.attributes.booking_data.from_date, bookingData.attributes.status)"></app-status-label>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div>
                    <div class="email-phone d-flex justify-content-start align-items-center">
                        <div>
                            <span class="email">Email address : </span>
                            <span class="email-txt">{{bookingData.attributes.booking_data.booking_request_hash.email}} </span>
                        </div>
                    </div>
                    <div class="email-phone d-flex justify-content-start align-items-center mar-bot-15">
                        <div>
                            <span class="email">Phone number : </span>
                            <span class="email-txt">{{bookingData.attributes.booking_data.booking_request_hash.phone}}</span>
                        </div>
                    </div>
                </div>
                <div class="table-heading d-flex flex-column" (click)="convertMyWay()">
                    <div class="fnt-14">
                        Powered by 
                        <img default="./assets/default_image.png" src="./assets/dineoutlogo.png" style="width: inherit; height: 20px;">
                    </div>
                    <button
                    class="btn trip-detail-btn fnt-14" ><svg
                      width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.875 4H2.125C1.0875 4 0.25 4.8375 0.25 5.875V9.625H2.75V12.125H10.25V9.625H12.75V5.875C12.75 4.8375 11.9125 4 10.875 4ZM9 10.875H4V7.75H9V10.875ZM10.875 6.5C10.5312 6.5 10.25 6.21875 10.25 5.875C10.25 5.53125 10.5312 5.25 10.875 5.25C11.2188 5.25 11.5 5.53125 11.5 5.875C11.5 6.21875 11.2188 6.5 10.875 6.5ZM10.25 0.875H2.75V3.375H10.25V0.875Z"
                        fill="#485473" />
                    </svg>
                    Print </button>
                </div>
            </div>

            <!-- <div class="mar-bot-30">
                <div class="map-div" style="display: inline-flex; width: 50%; height: 200px;">
                    <div #map1 style="width: 100%;"></div>
                </div>
                <div class="hotel-image" style="display: inline-flex; height: 200px; width: 50%;">
                    <img default="./assets/default_image.png" src="{{bookingData.attributes.booking_data.image}}" style="width: inherit">
                </div>        
            </div> -->
        </div>
        <div class="d-block d-sm-none">
            <section class="top-bar w100 d-flex justify-content-center align-items-center">
                <div class="absolute back-arr" (click)="back()">
                  <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.469669 5.46967ZM17 5.25L1 5.25V6.75L17 6.75V5.25Z"
                      fill="#C2A63F" />
                  </svg>
                </div>
                <div class="f500 fnt-16 gold">Booking Details</div>
              </section>
              <div class="d-flex align-items-center justify-content-between each-strip">
                  <div>
                    <div class="hotel-name">
                        {{bookingData.attributes.booking_data?.booking_request_hash?.restaurant_name}}
                    </div>
                    <div class="address">
                        {{bookingData.attributes.booking_data?.booking_request_hash?.restaurant_address}}
                    </div>
                  </div>
                  <div>
                    <app-status-label class="status-text" [status]="getStatus(bookingData.attributes.booking_data.from_date, bookingData.attributes.status)"></app-status-label>
                  </div>
              </div>
              <div class="booking-details-bck d-flex justify-content-end align-items-center">
                <div>
                  <span class="booking-id mar-right-5">
                      Powered by
                  </span>
                </div>
                <div>
                    <img default="./assets/default_image.png" src="./assets/dineoutlogo.png" style="width: inherit; height: 20px;">
                </div>
            </div>
              <div class="booking-details-bck d-flex justify-content-between align-items-center">
                  <div>
                    <span class="booking-id">
                        Booking ID : 
                    </span>
                    <span class="booking-id-txt">
                        {{bookingData.attributes?.booking_unique_id}}
                    </span>
                  </div>
                  <div class="cancel-booking pointer" (click)="cancelOpt()" *ngIf="(bookingData.attributes.status === 'booking_confirmed' || bookingData.attributes.status === 'booking_in_progress')  && !bookingData.attributes.booking_data.fare_breakup">
                    Cancel Booking
                    </div>
              </div>
              <hr>
        </div>
        <div class="booking-details-card">
            <div class="d-block d-sm-none">
                <div class="d-flex align-items-center justify-content-between booking-details-bck" *ngIf="(bookingData.attributes.booking_data.fare_breakup | json) != '{}'">
                    <div class="booking-details-txt">
                        Booking Details
                    </div>
                    <div class="table-heading d-flex flex-column" (click)="convertMyWay()">
                        <div class="fnt-14 d-none d-sm-block">
                            Powered by 
                            <img default="./assets/default_image.png" src="./assets/dineoutlogo.png" style="width: inherit; height: 20px;">
                        </div>
                        <button
                        class="btn trip-detail-btn fnt-14" ><svg
                          width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.875 4H2.125C1.0875 4 0.25 4.8375 0.25 5.875V9.625H2.75V12.125H10.25V9.625H12.75V5.875C12.75 4.8375 11.9125 4 10.875 4ZM9 10.875H4V7.75H9V10.875ZM10.875 6.5C10.5312 6.5 10.25 6.21875 10.25 5.875C10.25 5.53125 10.5312 5.25 10.875 5.25C11.2188 5.25 11.5 5.53125 11.5 5.875C11.5 6.21875 11.2188 6.5 10.875 6.5ZM10.25 0.875H2.75V3.375H10.25V0.875Z"
                            fill="#485473" />
                        </svg>
                        Print </button>
                    </div>
                </div>
            </div>
            <div class="d-none d-sm-block">
                <div class="d-flex align-items-center justify-content-between booking-details-bck">
                    <div class="booking-details-txt">
                        Booking Details
                    </div>
                    <div class="booking-id-details">
                        <span class="booking-id">
                            Booking ID : 
                        </span>
                        <span class="booking-id-txt">
                            {{bookingData.attributes?.booking_unique_id}}
                        </span>
                    </div>
                </div>
            </div>
    
    
            <!-- responsive -->
            <div class="booking-details">
                <div class="each-strip flight-details" *ngIf="bookingData.attributes.booking_data?.booking_request_hash?.offer_text">
                    <div class="d-flex justify-content-between align-items-top">
                        <div class="check-in">
                            Offer
                        </div>
                        <div class="check-in-date">
                            {{bookingData.attributes.booking_data?.booking_request_hash?.offer_text}}
                        </div>
                    </div>
                </div>
                <hr *ngIf="bookingData.attributes.booking_data?.booking_request_hash?.offer_text">
                <div class="each-strip flight-details">
                    <div class="d-flex justify-content-between align-items-top">
                        <div class="check-in">
                            Number of people
                        </div>
                        <div class="check-in-date">
                            {{bookingData.attributes.booking_data?.booking_request_hash?.people}}
                        </div>
                    </div>
                </div>
                <hr>
                <div class="each-strip flight-details">
                    <div class="d-flex justify-content-between align-items-top">
                        <div class="check-in">
                            Date
                        </div>
                        <div class="check-in-date">
                            {{bookingData.attributes.booking_data?.booking_request_hash?.booking_date | date: 'EEEE, dd MMM yyyy'}}
                        </div>
                    </div>
                </div>
                <hr>
                <div class="each-strip flight-details">
                    <div class="d-flex justify-content-between align-items-top">
                        <div class="check-in">
                            Time
                        </div>
                        <div class="check-in-date">
                            {{bookingData.attributes.booking_data?.booking_request_hash?.booking_time.slice(0,2) + ':' + bookingData.attributes.booking_data?.booking_request_hash?.booking_time.slice(2,4) | time24to12}}
                        </div>
                    </div>
                </div>
                <hr>
                <div class="each-strip flight-details">
                    <div class="d-flex flex-column">
                        <div class="booked-by">
                            Booked by {{bookingData.attributes.booking_data.booking_request_hash.title}} {{bookingData.attributes.booking_data.booking_request_hash.first_name}} {{bookingData.attributes.booking_data.booking_request_hash.last_name}}
                        </div>
                        <div>
                            <span class="phone-email">
                                Phone : 
                            </span>
                            <span class="phone-email-txt">
                                {{bookingData.attributes.booking_data.booking_request_hash.phone}}
                            </span>
                            <span class="phone-email">|</span>
                            <span class="phone-email">
                                Email : 
                            </span>
                            <span class="phone-email-txt">
                                {{bookingData.attributes.booking_data.booking_request_hash.email}}
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            <hr>
            <div *ngIf="bookingData.attributes.booking_data.fare_breakup.base">
                <div class="d-flex align-items-center justify-content-between booking-details-bck" *ngIf="(bookingData.attributes.booking_data.fare_breakup | json) != '{}'">
                    <div class="booking-details-txt">
                        Fare Details
                    </div>
                </div>
                <div class="each-strip booking-details" *ngIf="(bookingData.attributes.booking_data.fare_breakup | json) != '{}'">
                    <div class="">
                        <div class="d-flex flex-column">
                          <div class="each-strip d-flex align-items-center justify-content-between"> 
                              <div class="check-in">Hotel Charges</div>
                              <div class="check-in-date">
                                  <span class="rupee-symbol mar-right-2">₹</span>
                                  <span>{{(bookingData.attributes.booking_data.fare_breakup.base) | indianCurrency}}</span>
                              </div>
                          </div>
                          <hr>
                          <div *ngIf="bookingData.attributes.booking_data?.partner_name=='yatra'" >
                            <div *ngFor="let tax of bookingData.attributes.booking_data.fare_breakup?.tax_breakup;">
                                <div class="each-strip d-flex align-items-center justify-content-between"> 
                                    <div class="check-in">
                                        <div *ngIf="tax.type === 'HOTEL_GST'">Hotel GST</div>
                                        <div *ngIf="tax.type !== 'HOTEL_GST'">{{tax.type | removeUnderscore | titlecase}}</div>
                                    </div>
                                    <div class="check-in-date">
                                        <span class="mar-right-2">+</span>
                                        <span class="rupee-symbol mar-right-2">₹</span>
                                        <span>{{(tax.amount) | indianCurrency}}</span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div *ngFor="let tax of bookingData.attributes.booking_data.fare_breakup?.charges;">
                                <div class="each-strip d-flex align-items-center justify-content-between"> 
                                    <div class="check-in">{{tax.type | removeUnderscore | titlecase}}</div>
                                    <div class="check-in-date">
                                        <span class="mar-right-2">+</span>
                                        <span class="rupee-symbol mar-right-2">₹</span>
                                        <span>{{(tax.amount) | indianCurrency}}</span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div *ngFor="let tax of bookingData.attributes.booking_data.fare_breakup?.discounts;">
                                <div class="each-strip deduction d-flex align-items-center justify-content-between"> 
                                    <div class="check-in" style="color: var(--green);">Hotel Offer</div>
                                    <div class="check-in-date" style="color: var(--green);">
                                        <span class="mar-right-2">-</span>
                                        <span class="rupee-symbol mar-right-2">₹</span>
                                        <span>{{(tax.amount) | indianCurrency}}</span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <hr>
                        </div>
                        <div *ngIf="bookingData.attributes.booking_data?.partner_name=='mms'">
                            <div class="each-strip d-flex align-items-center justify-content-between"> 
                                <div class="check-in">Taxes</div>
                                <div class="check-in-date">
                                    <span class="mar-right-2">+</span>
                                    <span class="rupee-symbol mar-right-2">₹</span>
                                    <span>{{(bookingData.attributes.booking_data.fare_breakup.taxes)| indianCurrency }}</span>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="each-strip d-flex align-items-center justify-content-between" *ngIf="bookingData.attributes.booking_data.fare_breakup.convenience_fee"> 
                            <div class="check-in">Convenience Fees<br><span class="fnt-12">(inclusive of GST)</span></div>
                            <div class="check-in-date">
                                <span class="mar-right-2">+</span>
                                <span class="rupee-symbol mar-right-2">₹</span>
                                <span>{{(bookingData.attributes.booking_data.fare_breakup.convenience_fee) | indianCurrency}}</span>
                            </div>
                        </div>
                        <hr>
                        <div class="each-strip total d-flex align-items-center justify-content-between" *ngIf="bookingData.attributes?.promo_code"> 
                            <div class="check-in boldify">Total Amount</div>
                            <div class="check-in-date boldify">
                                <span class="rupee-symbol mar-right-2">₹</span>
                                <span>{{bookingData.attributes?.gross_amount | indianCurrency}}</span>
                            </div>
                        </div>
                        <hr *ngIf="bookingData.attributes?.promo_code">
                        <div class="d-flex justify-content-between align-items-top each-strip flight-details" *ngIf="bookingData.attributes?.promo_code">
                            <div class="check-in" style="color: var(--green);">
                                Coupon Discount
                            </div>
                            <div class="check-in-date" style="color: var(--green);">
                                <span class="mar-right-2">-</span>
                                <span class="rupee-symbol mar-right-2">₹</span>
                                <span>{{(bookingData.attributes?.gross_amount - bookingData.attributes?.amount) | indianCurrency }}</span>
                            </div>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-between align-items-top each-strip flight-details">
                            <div class="check-in boldify">
                                Total Payable
                            </div>
                            <div class="check-in-date boldify">
                                <span class="rupee-symbol mar-right-2">₹</span>
                                <span>{{(bookingData.attributes?.promo_code ? bookingData.attributes?.amount : bookingData.attributes?.gross_amount) | indianCurrency}}</span>
                            </div>
                        </div>
                        </div>
                      </div>
                </div>
                <hr *ngIf="bookingData.attributes.booking_data.fare_breakup">
                <div class="booking-details-bck">
                    <span>Payment Breakup</span>
                </div>
                <div class="each-strip booking-details">
                    <div class="d-flex justify-content-between align-items-top each-strip flight-details">
                        <div class="check-in">
                            Amount paid by card <span style="letter-spacing: 3px">{{bookingData.attributes?.card_number | creditCardMask}}</span><img *ngIf="cardIcon" height="16" src="{{cardIcon}}">
                        </div>
                        <div class="check-in-date">
                            <span class="rupee-symbol mar-right-2">₹</span>
                            <span>{{bookingData.attributes?.amount | indianCurrency}}</span>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-between align-items-top each-strip flight-details">
                        <div class="check-in">
                            Amount paid by Reward Points 
                        </div>
                        <div class="check-in-date">
                            <span>NIL</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr *ngIf="(bookingData.attributes.status === 'booking_confirmed' || bookingData.attributes.status === 'booking_in_progress') && !bookingData.attributes.booking_data.fare_breakup.base">
            <div class="booking-details-bck" *ngIf="(bookingData.attributes.status === 'booking_confirmed' || bookingData.attributes.status === 'booking_in_progress')  && !bookingData.attributes.booking_data.fare_breakup.base">
                <div>Cancellation</div>
            </div>
            <div *ngIf="(bookingData.attributes.status === 'booking_confirmed' || bookingData.attributes.status === 'booking_in_progress') && !bookingData.attributes.booking_data.fare_breakup.base" class="each-strip booking-details">
                <div>
                    <div class="each-strip flight-details">
                        <button mat-button class="primary-btn"
                        (click)=cancelOpt()>
                            <span class="">Cancel Booking</span>
                      </button>
                    </div>
                </div>
            </div>
            <hr *ngIf="bookingData.attributes.status === 'booking_confirmed' && !bookingData.attributes.booking_data.fare_breakup.base" class="d-none d-sm-block">
            <div class="booking-details-bck d-none d-sm-block" *ngIf="bookingData.attributes.status === 'booking_confirmed' && !bookingData.attributes.booking_data.fare_breakup.base">
                <div>Payment</div>
            </div>
            <div *ngIf="bookingData.attributes.status === 'booking_confirmed' && !bookingData.attributes.booking_data.fare_breakup.base" class="each-strip booking-details d-none d-sm-block">
                <div>
                    <div class="each-strip flight-details">
                        <button mat-button class="primary-btn"
                        (click)=paythebill()>
                            <span class="">Pay your Bill</span>
                      </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="pay-it d-block d-sm-none w100" *ngIf="bookingData.attributes.status == 'booking_confirmed' && !bookingData.attributes.booking_data.fare_breakup.base">
            <button mat-button class="primary-btn w100"
            (click)=paythebill()>
                <span class="">Pay your Bill</span>
          </button>
        </div>
    </div>
    <div class="to-hide1">
        <div style="border: 1px solid var(--primary);">
            <div class="d-none d-sm-block">
                <div class="checkout-message-top d-flex align-items-center justify-content-between header-bg" style="padding: 10px 30px;">
                    <img class="img-fluid rewards-hub-header-logo pad-right-8" src="assets/sbic-images/logo.png" style="width: 200px;">
                </div>
                <div class="checkout-message-top d-flex align-items-center justify-content-between mar-bot-15" style="padding: 10px 30px;">
                    <div class="d-flex  justify-content-start align-items-center">
                        <div class="d-flex flex-column address-detail">
                            <div class="hotel-name">
                                {{bookingData.attributes.booking_data?.booking_request_hash?.restaurant_name}}
                            </div>
                            <div class="address">
                                {{bookingData.attributes.booking_data?.booking_request_hash?.restaurant_address}}
                            </div>
                        </div>
                    </div>
                    <div class="d-none d-sm-block booking-id-detail">
                        <app-status-label class="status-text" [status]="getStatus(bookingData.attributes.booking_data.from_date, bookingData.attributes.status)"></app-status-label>
                    </div>
                </div>
                <div class="d-flex justify-content-between" style="padding: 10px 30px;">
                    <div>
                        <div class="email-phone d-flex justify-content-start align-items-center">
                            <div>
                                <span class="email">Email address : </span>
                                <span class="email-txt">{{bookingData.attributes.booking_data.booking_request_hash.email}} </span>
                            </div>
                        </div>
                        <div class="email-phone d-flex justify-content-start align-items-center mar-bot-15">
                            <div>
                                <span class="email">Phone number : </span>
                                <span class="email-txt">{{bookingData.attributes.booking_data.booking_request_hash.phone}}</span>
                            </div>
                        </div>
                    </div>
                </div>
    
                <!-- <div class="mar-bot-30" style="padding: 10px 30px;">
                    <div class="hotel-image" style="display: inline-flex; height: 200px; width: 50%;">
                        <img default="./assets/default_image.png" src="{{bookingData.attributes.booking_data.image}}" style="width: inherit">
                    </div>        
                </div> -->
            </div>
            <div class="d-block d-sm-none">
                <section class="top-bar w100 d-flex justify-content-center align-items-center">
                    <div class="absolute back-arr" (click)="back()">
                      <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.469669 5.46967ZM17 5.25L1 5.25V6.75L17 6.75V5.25Z"
                          fill="#C2A63F" />
                      </svg>
                    </div>
                    <div class="f500 fnt-16 gold">Booking Details</div>
                  </section>
                  <hr>
                  <div class="d-flex align-items-center justify-content-between each-strip">
                      <div>
                        <div class="hotel-name">
                            {{bookingData.attributes.booking_data?.booking_request_hash?.restaurant_name}}
                        </div>
                        <div class="address">
                            {{bookingData.attributes.booking_data?.booking_request_hash?.restaurant_address}}
                        </div>
                      </div>
                      <div>
                        <app-status-label class="status-text" [status]="getStatus(bookingData.attributes.booking_data.from_date, bookingData.attributes.status)"></app-status-label>
                      </div>
                  </div>
                  <div class="booking-details-bck d-flex justify-content-between align-items-center">
                      <div>
                        <span class="booking-id">
                            Booking ID : 
                        </span>
                        <span class="booking-id-txt">
                            {{bookingData.attributes?.booking_unique_id}}
                        </span>
                      </div>
                      <div class="cancel-booking pointer" (click)="cancelOpt()" *ngIf="bookingData.attributes.status === 'booking_confirmed'  && !bookingData.attributes.booking_data.fare_breakup ">
                        Cancel Booking
                        </div>
                  </div>
                  <hr>
            </div>
            <div class="booking-details-card">
                <div class="d-block d-sm-none">
                    <div class="d-flex align-items-center justify-content-between booking-details-bck" *ngIf="(bookingData.attributes.booking_data.fare_breakup | json) != '{}'">
                        <div class="booking-details-txt">
                            Booking Details
                        </div>
                    </div>
                </div>
                <div class="d-none d-sm-block">
                    <div class="d-flex align-items-center justify-content-between booking-details-bck">
                        <div class="booking-details-txt">
                            Booking Details
                        </div>
                        <div class="booking-id-details">
                            <span class="booking-id">
                                Booking ID : 
                            </span>
                            <span class="booking-id-txt">
                                {{bookingData.attributes?.booking_unique_id}}
                            </span>
                        </div>
                    </div>
                </div>
        
        
                <!-- responsive -->
                <div class="booking-details">
                    <div class="each-strip flight-details" *ngIf="bookingData.attributes.booking_data?.booking_request_hash?.offer_text">
                        <div class="d-flex justify-content-between align-items-top">
                            <div class="check-in">
                                Offer
                            </div>
                            <div class="check-in-date">
                                {{bookingData.attributes.booking_data?.booking_request_hash?.offer_text}}
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="bookingData.attributes.booking_data?.booking_request_hash?.offer_text">
                    <div class="each-strip flight-details">
                        <div class="d-flex justify-content-between align-items-top">
                            <div class="check-in">
                                Number of people
                            </div>
                            <div class="check-in-date">
                                {{bookingData.attributes.booking_data?.booking_request_hash?.people}}
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="each-strip flight-details">
                        <div class="d-flex justify-content-between align-items-top">
                            <div class="check-in">
                                Date
                            </div>
                            <div class="check-in-date">
                                {{bookingData.attributes.booking_data?.booking_request_hash?.booking_date | date: 'EEEE, dd MMM yyyy'}}
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="each-strip flight-details">
                        <div class="d-flex justify-content-between align-items-top">
                            <div class="check-in">
                                Time
                            </div>
                            <div class="check-in-date">
                                {{bookingData.attributes.booking_data?.booking_request_hash?.booking_time.slice(0,2) + ':' + bookingData.attributes.booking_data?.booking_request_hash?.booking_time.slice(2,4) | time24to12}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="bookingData.attributes.booking_data.fare_breakup.base">
                    <hr>
                    <div class="d-flex align-items-center justify-content-between booking-details-bck" *ngIf="(bookingData.attributes.booking_data.fare_breakup | json) != '{}'">
                        <div class="booking-details-txt">
                            Fare Details
                        </div>
                    </div>
                    <div class="each-strip booking-details" *ngIf="(bookingData.attributes.booking_data.fare_breakup | json) != '{}'">
                        <div class="">
                            <div class="d-flex flex-column">
                              <div class="each-strip d-flex align-items-center justify-content-between"> 
                                  <div class="check-in">Hotel Charges</div>
                                  <div class="check-in-date">
                                      <span class="rupee-symbol mar-right-2">₹</span>
                                      <span>{{(bookingData.attributes.booking_data.fare_breakup.base) | indianCurrency}}</span>
                                  </div>
                              </div>
                              <hr>
                              <div *ngIf="bookingData.attributes.booking_data?.partner_name=='yatra'" >
                                <div *ngFor="let tax of bookingData.attributes.booking_data.fare_breakup?.tax_breakup;">
                                    <div class="each-strip d-flex align-items-center justify-content-between"> 
                                        <div class="check-in">
                                            <div *ngIf="tax.type === 'HOTEL_GST'">Hotel GST</div>
                                            <div *ngIf="tax.type !== 'HOTEL_GST'">{{tax.type | removeUnderscore | titlecase}}</div>
                                        </div>
                                        <div class="check-in-date">
                                            <span class="mar-right-2">+</span>
                                            <span class="rupee-symbol mar-right-2">₹</span>
                                            <span>{{(tax.amount) | indianCurrency}}</span>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div *ngFor="let tax of bookingData.attributes.booking_data.fare_breakup?.charges;">
                                    <div class="each-strip d-flex align-items-center justify-content-between"> 
                                        <div class="check-in">{{tax.type | removeUnderscore | titlecase}}</div>
                                        <div class="check-in-date">
                                            <span class="mar-right-2">+</span>
                                            <span class="rupee-symbol mar-right-2">₹</span>
                                            <span>{{(tax.amount) | indianCurrency}}</span>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div *ngFor="let tax of bookingData.attributes.booking_data.fare_breakup?.discounts;">
                                    <div class="each-strip deduction d-flex align-items-center justify-content-between"> 
                                        <div class="check-in" style="color: var(--green);">Hotel Offer</div>
                                        <div class="check-in-date" style="color: var(--green);">
                                            <span class="mar-right-2">-</span>
                                            <span class="rupee-symbol mar-right-2">₹</span>
                                            <span>{{(tax.amount) | indianCurrency}}</span>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <hr>
                            </div>
                            <div *ngIf="bookingData.attributes.booking_data?.partner_name=='mms'">
                                <div class="each-strip d-flex align-items-center justify-content-between"> 
                                    <div class="check-in">Taxes</div>
                                    <div class="check-in-date">
                                        <span class="mar-right-2">+</span>
                                        <span class="rupee-symbol mar-right-2">₹</span>
                                        <span>{{(bookingData.attributes.booking_data.fare_breakup.taxes) | indianCurrency}}</span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div class="each-strip d-flex align-items-center justify-content-between" *ngIf="bookingData.attributes.booking_data.fare_breakup.convenience_fee"> 
                                <div class="check-in">Convenience Fees<br><span class="fnt-12">(inclusive of GST)</span></div>
                                <div class="check-in-date">
                                    <span class="mar-right-2">+</span>
                                    <span class="rupee-symbol mar-right-2">₹</span>
                                    <span>{{(bookingData.attributes.booking_data.fare_breakup.convenience_fee) | indianCurrency}}</span>
                                </div>
                            </div>
                            <hr>
                            <div class="each-strip total d-flex align-items-center justify-content-between" *ngIf="bookingData.attributes?.promo_code"> 
                                <div class="check-in boldify">Total Amount</div>
                                <div class="check-in-date boldify">
                                    <span class="rupee-symbol mar-right-2">₹</span>
                                    <span>{{bookingData.attributes?.gross_amount | indianCurrency}}</span>
                                </div>
                            </div>
                            <hr *ngIf="bookingData.attributes?.promo_code">
                            <div class="d-flex justify-content-between align-items-top each-strip flight-details" *ngIf="bookingData.attributes?.promo_code">
                                <div class="check-in" style="color: var(--green);">
                                    Coupon Discount
                                </div>
                                <div class="check-in-date" style="color: var(--green);">
                                    <span class="mar-right-2">-</span>
                                    <span class="rupee-symbol mar-right-2">₹</span>
                                    <span>{{(bookingData.attributes?.gross_amount - bookingData.attributes?.amount) | indianCurrency}}</span>
                                </div>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-between align-items-top each-strip flight-details">
                                <div class="check-in boldify">
                                    Total Payable
                                </div>
                                <div class="check-in-date boldify">
                                    <span class="rupee-symbol mar-right-2">₹</span>
                                    <span>{{(bookingData.attributes?.promo_code ? bookingData.attributes?.amount : bookingData.attributes?.gross_amount) | indianCurrency}}</span>
                                </div>
                            </div>
                            </div>
                          </div>
                    </div>
                    <hr *ngIf="bookingData.attributes.booking_data.fare_breakup">
                    <div class="booking-details-bck">
                        <span>Payment Breakup</span>
                    </div>
                    <div class="each-strip booking-details">
                        <div class="d-flex justify-content-between align-items-top each-strip flight-details">
                            <div class="check-in">
                                Amount paid by card <span style="letter-spacing: 3px">{{bookingData.attributes?.card_number | creditCardMask}}</span><img *ngIf="cardIcon" height="16" src="{{cardIcon}}">
                            </div>
                            <div class="check-in-date">
                                <span class="rupee-symbol mar-right-2">₹</span>
                                <span>{{bookingData.attributes?.amount | indianCurrency}}</span>
                            </div>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-between align-items-top each-strip flight-details">
                            <div class="check-in">
                                Amount paid by Reward Points 
                            </div>
                            <div class="check-in-date">
                                <span>NIL</span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="booking-details-bck">
                    <div>Cancellation</div>
                </div>
                <div *ngIf="bookingData.attributes.status === 'booking_confirmed'  && !bookingData.attributes.booking_data.fare_breakup" class="each-strip booking-details">
                    <div>
                        <div class="each-strip flight-details">
                            <button mat-button class="primary-btn"
                            (click)=cancelOpt(booking)>
                                <span class="">Cancel Booking</span>
                          </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <hr>
            <div class="no-margin row px-3 py-custom border-bottom" style="background-color: #F6F6F6; display: block!important;">
                <div class="col-12 py-1 font-weight400">Need Help?</div>
                <div style="box-sizing:border-box;width:100%;padding-right:15px;padding-left:15px;max-width:100%;font-weight:300!important">Call us at <a href="tel:+919900518749" style="box-sizing:border-box;color:#C2A63F;text-decoration:underline;background-color:transparent" target="_blank">1800 212 7078</a> or send an Email at <a href="mailto:support@poshvine.com" style="box-sizing:border-box;color:#C2A63F;text-decoration:underline;background-color:transparent" target="_blank">support@poshvine.com</a>
                </div>
            </div>
            <div class="no-margin row px-3 py-custom border-bottom" style="background-color: #F6F6F6; display: block!important;">
                <div class="col-12 py-1 font-weight400">Important Information regarding your travel:</div>
                <div class="col-12">
                  <ul class="text-muted font-weight-light" style="font-size: 0.95rem">
                    <li>
                      <span class="text-dark font-weight400">Travel Support:</span> For any assistance regarding this booking, please contact PoshVine RewardsHub Service Centre at <a href="tel:+919900518749">1800 212 7078</a>. You can also write to PoshVine RewardsHub at <a href="mailto:aurumrewards@poshvine.com">aurumrewards@poshvine.com</a>.
                    </li>
                    <li>
                      <span class="text-dark font-weight400">Booking Modifications, Cancellations and Refunds:</span>
                      <ul>
                        <li>Every hotel has a different cancellation and modification policy and the user shall be charged according to the Hotel/Room cancellation or modification policy. Please note that these charges are subject to change by the hotel and its service provider, at its sole discretion and without notice to Company. The charges may be verified with the hotel and their service provider by the User.</li>
                        <li>For any hotel/room modification, you must contact the designated customer support team and/ or the hotel directly. And the request will be entertained as per the booking terms and any modification charge must be borne by the customer.</li>
                        <li>Only those cancellation requests which are made on the phone to designated customer support team shall be entertained. Please note that if you are contacting the Hotel directly for cancellation you are still liable to inform the RewardsHub customer support team to process the refund.</li>
                        <li>PoshVine RewardsHub shall not be liable to entertain any cancellation requests made directly to the hotel without intimating PoshVine and also through any other medium including, but not limited to, SMS or e-mail.</li>
                        <li>Members can visit <a href="https://rewards-hub-staging.poshvine.com/booking/home">https://rewards-hub-staging.poshvine.com/booking/home</a> in case of modification or cancellation requests or contact Customer Support.</li>
                      </ul>
                    </li>
                    <li>
                      <span class="text-dark font-weight400">Other Information:</span>
                      <ul>
                        <li>This booking receipt also serves as your confirmation email for your booking.</li>
                        <li>Please quote Booking ID and Reservation details for all communications with the hotel directly.</li>
                        <li>All passengers, including children and infants, must present their valid ID proof at the time of check-in to the hotel.</li>
                        <li>PoshVine and our partners Yatra or Mr. &amp; Mrs. Smith have no liability for the quality of service provided by the hotel.</li>
                        <li>The detailed terms and conditions are set out at <a href="https://rewards-hub-staging.poshvine.com/terms">https://rewards-hub-staging.poshvine.com/terms</a> and shall accordingly apply to the booking.</li>
                      </ul>
                    </li>
                  </ul>
                </div>
            </div> -->
        </div>
    </div>
</div>

<app-loader *ngIf="progress"></app-loader>