import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EventsConcertsService } from '../services/events-concerts/events-concerts.service';
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: 'app-events-concerts-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class EventsConcertsHomeComponent implements OnInit {
  isCityDropdownOpen: boolean = false;
  isClassDropdownOpen: boolean = false;
  citiesList = [];
  isLoading: boolean = false;
  selectedCity: any;
  selectedCategory: any;
  cityInput = new UntypedFormControl();
  inputToShow: string;
  dropDownPopOpen: boolean;
  progress: boolean = true;
  exchangeRates = [];
  filterCityList: boolean = false;
  classes = [];
  classInput;
  eventList = [];
  selectedClass;
  hidePageBottom: boolean = false;

  @ViewChild('cityDropdown', { static: false }) cityDropdown: ElementRef<HTMLElement>;

  constructor(
    private eventsConcertsService: EventsConcertsService,
    private renderer: Renderer2,
    private router: Router,
    public dataService: DataService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getCities();
    this.getCurrencyExchangeRates();
    if(window.location.pathname.split('/')[1]){
      this.hidePageBottom = true;
    }
  }


  search() {
    if (this.verifyForm()) {
      this.router.navigate(['events-concerts/listing/' + this.selectedCity.name], { queryParams: { category: this.selectedCategory && this.selectedCategory.name ? this.selectedCategory.name : '' } });
    }
  }

  closeCityDrop() {
    this.isCityDropdownOpen = false;
    this.cityInput.setValue(this.selectedCity.name +', '+ this.selectedCity.country);
    this.filterCityList = false;
  }

  openCityDrop() {
    if (window.innerWidth > 576) {
      this.isCityDropdownOpen = true;
    }
    this.cityfocusouthere();
    this.dropDownPopOpen = true;
    this.inputToShow = 'cityDrop'
    this.addModalOpen();
  }

  openClassDrop() {
    if (window.innerWidth > 576) {
      this.isClassDropdownOpen = true;
    }
    this.dropDownPopOpen = true;
    this.inputToShow = 'classDrop';
    this.addModalOpen();
  }

  closeClassDrop() {
    this.isClassDropdownOpen = false;
  }

  getCities() {
    this.eventsConcertsService.getCities().then((res: any) => {
      this.citiesList = res.data.city;
      this.selectedCity = this.citiesList[0];
      this.cityInput.setValue(this.selectedCity.name +', '+ this.selectedCity.country);
      this.sortCitiesAlphabetically();
      this.getEventsByCity();
      this.getAutocomplete();
    });
  }

  getEventsByCity() {
    this.isLoading = true;
    this.eventList = [];
    this.eventsConcertsService.getEventsByCity(this.selectedCity.id).then((res: any) => {
      this.eventList = res.events;
      this.progress = false;
      this.isLoading = false;
    })
  }

  sortCitiesAlphabetically() {
    this.citiesList = this.citiesList.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
      if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
      return 0;
    });
  }

  getAutocomplete() {
    this.eventsConcertsService.getAutocomplete().then((res: any) => {
      if (res && res.data && res.data.classes) {
        this.classes = res.data.classes;
      }
    })
  }

  setSelectedCity(city) {
    if (!this.selectedCity) {
      this.selectedCity = city;
      this.getEventsByCity();
    } else if (city.id !== this.selectedCity.id) {
      this.selectedCity = city;
      this.getEventsByCity();
    }
    this.cityInput.setValue(city.name +', '+ city.country);
    this.isCityDropdownOpen = false;
    this.filterCityList = false;
  }

  addModalOpen() {
    if (window.innerWidth < 576) {
      this.renderer.addClass(document.body, 'modal-open');
    }
  }

  removeModalOpen() {
    this.renderer.removeClass(document.body, 'modal-open');
    if (window.innerWidth < 576) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  getCurrencyExchangeRates() {
    this.eventsConcertsService.getCurrencyExchangeRates().then((res: any) => {
      this.exchangeRates = res.exchange_rates;
    });
  }


  verifyForm() {
    if (!this.selectedCity) {
      this.dataService.triggerToastr('error',"Select destination.")
      return false;
    }
    return true;
  }

  cityfocusouthere() {
    if (window.innerWidth < 576) {
      this.cityDropdown.nativeElement.blur();
    }
  }

  cityInputChange() {
    this.filterCityList = true;
  }


  setSelectedClass(where) {
    this.selectedClass = where;
    this.classInput = where.name;
  }

  viewAll(){
    this.router.navigateByUrl('/events-concerts/listing/'+this.selectedCity.name)
  }

  navigate(url){
    this.router.navigateByUrl(url);
  }
}
