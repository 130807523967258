import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/common/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class DocsappService {
  baseUrl: string = environment.apiUrl;
  stagingUrl = environment.stagingUrl;

  constructor(private api: ApiService) { }

  createBooking(data) {
    return this.api.post(this.baseUrl + 'docs_app_bookings', data).toPromise();
  }

  getBookings(){
    return this.api.get(this.stagingUrl + "users/bookings?booking_type=DocsAppBooking").toPromise()
  }

  getBookingDetailsById(id) {
    return this.api.get(this.stagingUrl + `users/bookings/booking_details?booking_unique_id=${id}`).toPromise();
  }

}
