import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NatGeoService } from '../../../services/nat-geo/nat-geo.service';
import { Router } from '@angular/router';
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: 'app-natgeo-home-top-part',
  templateUrl: './natgeo-home-top-part.component.html',
  styleUrls: ['./natgeo-home-top-part.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class NatgeoHomeTopPartComponent implements OnInit {
  @Input()
  filters;

  filterDestinations = [];
  isDestinationDropdownOpen = false;
  isThemeDropdownOpen = false;
  isMonthDropdownOpen = false;
  // listclick:boolean = false;
  submitted = false;
  enquiryFilters: any = {
    destination: "",
    theme: "",
    month: "",
    name: "All Destinations",
    page: "home",
  };
  showSearchError;
  theme;
  month;
  destination;
  // progress: boolean = true;
  clickedSearch: boolean = false;

  //error variables
  chooseOneCriteria;
  constructor(public natGeoS: NatGeoService, public router: Router, public data: DataService,) {}

  ngOnInit() {
    // this.natGeoS.getHomePageFilters().then((filters) => {
    //   this.filters = filters;
    //   this.progress = false;
    // });
  }

  openDestinationDrop() {
    this.isDestinationDropdownOpen = true;
  }

  closeDestinationDrop() {
    // this.isDestinationDropdownOpen = false;
  }

  changeCheckbox(event, type, filter) {
    this.chooseOneCriteria = '';
    if (event.target.checked) {
      this.filterDestinations.push(type);
    } else {
      this.filterDestinations = this.filterDestinations.filter((filterId) => {
        return filterId.region != type.region;
      });
    }

    this.destination = this.filterDestinations.map((dest) => {
      return dest.region;
    });

    let id = this.filterDestinations.map((dest) => {
      return dest.ids;
    });
    this.enquiryFilters.new = this.filterDestinations;

    this.enquiryFilters.destination = [].concat.apply([], id);
    this.enquiryFilters.name = this.destination;
  }

  openMonthDrop() {
    this.isMonthDropdownOpen = true;
  }

  closeThemeDrop() {
    this.isThemeDropdownOpen = false;
  }

  openThemeDrop() {
    this.isThemeDropdownOpen = true;
  }

  closeMonthDrop() {
    this.isMonthDropdownOpen = false;
  }
  submitParameters() {
    this.submitted = true;
  }

  checkParams() {
    console.log(this.enquiryFilters);
    if (
      !(
        this.enquiryFilters.destination ||
        this.enquiryFilters.theme ||
        this.enquiryFilters.month
      )
    ) {
      this.submitted = true;
    }
  }

  goToListing() {

    if (
      this.enquiryFilters.destination ||
      this.enquiryFilters.theme ||
      this.enquiryFilters.month
    ) {
      localStorage.setItem(
        "nat-geo-params",
        JSON.stringify(this.enquiryFilters)
      );
      this.clickedSearch = true;
      this.router.navigate(["/nat-geo/listing"]);
    } else{
      // this.data.triggerToastr('success','Select at least one search criteria');
      this.chooseOneCriteria = 'Select at least one search criteria';
    }
  }

  seeAllDestination() {
    localStorage.setItem(
      "nat-geo-params",
      JSON.stringify({
        // destination:"",
        // theme:"",
        // month:"",
        name: "All Destinations",
        page: "home",
      })
    );
    this.clickedSearch = true;
    this.router.navigate(["/nat-geo/listing"]);
  }
}
