<div *ngIf="!progress; else progressblock">
    <div *ngIf="!bookings?.length" class="no-rooms">
        No bookings found !
    </div>

    <div class="nat-geo-item d-block d-sm-none"
        *ngFor="let booking of bookings | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }"
        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
        <div class="d-flex justify-content-between align-items-center pointer">
            <div class="d-flex justify-content-start align-items-center">
                <div class="mar-right-15">
                    <div class="icon-airportcon"></div>
                </div>
                <div>
                    <div class="nat-geo-item-title">



                        <span>
                            <span class="product-details">
                                {{servicesName[booking.attributes.booking_data.requested_service.requested_services[0].name]}}
                            </span>
                            <span class="mar-right-4 fnt-12 f300"
                                *ngIf="booking.attributes.booking_data.requested_service.requested_services.length>1">
                                +{{booking.attributes.booking_data.requested_service.requested_services.length-1+' more'}}
                            </span>
                        </span>



                    </div>
                    <div class="merchant-name mar-top-2 fnt-14 f300">
                        <span>{{booking.attributes.booking_data.service_airport.airport_name}}</span>
                    </div>
                </div>
            </div>
            <div class="status-text" style="margin-bottom: -20px;">
                <div>
                    <app-status-label class="status-text" [status]="booking.attributes?.status"
                        [date]="booking.attributes.booking_data.service_date_time">
                    </app-status-label>
                </div>
            </div>
        </div>
    </div>
    <div class="nat-geo-item d-none d-sm-block"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }">
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-start align-items-center w80">
                    <div class="mar-right-15">
                        <div class="icon-airportcon"></div>
                    </div>
                    <div>
                        <div>
                            <span class="hotel_name">
                                <span
                                    *ngFor="let each of booking.attributes.booking_data.requested_service.requested_services; let i = index">
                                    <span class="product-details">
                                        {{servicesName[each.name]}}
                                    </span>
                                    <span class="mar-right-4 mar-left-4"
                                        *ngIf="booking.attributes.booking_data.requested_service.requested_services.length-1>i">|</span>
                                </span>
                            </span>
                        </div>
                        <!-- <div class="merchant-name mar-top-2 fnt-14 f300" *ngIf="getMerchantName(booking)">
                            {{'by ' + getMerchantName(booking)}}
                        </div> -->
                    </div>
                </div>

                <div class="status-text">
                    <div>
                        <app-status-label class="status-text" [status]="booking.attributes?.status"
                            [date]="booking.attributes.booking_data.service_date_time">
                        </app-status-label>
                    </div>
                </div>
            </div>
            <div class="service-airport">
                <div class="service-airport-name">{{booking.attributes.booking_data.service_airport.airport_name}}
                    <span *ngIf="booking.attributes.booking_data.journey_details.journey_type == AirportType.Departure">
                        {{' ('+ formatTerminalName(booking.attributes.booking_data.terminal_details.departure_terminal.terminalName)+ ')' }}
                    </span>
                    <span *ngIf="booking.attributes.booking_data.journey_details.journey_type == AirportType.Arrival">
                        {{' ('+ formatTerminalName(booking.attributes.booking_data.terminal_details.arrival_terminal.terminalName)+ ')' }}
                    </span>
                </div>
                <div class="service-airport-details">
                    {{booking.attributes.booking_data.service_airport.city + ', ' + booking.attributes.booking_data.service_airport.country}}
                </div>
            </div>
        </div>
        <hr>
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="booking-id-detail">
                    <div>
                        <span>Booking ID : </span>
                        <span class="id">{{booking.attributes?.booking_unique_id}}</span>
                    </div>
                </div>
                <div>

                    <ng-container
                        *ngIf="booking.attributes.status=='booking_confirmed' && checkIfUpcoming(booking.attributes.booking_data.service_date_time)">
                        <span class="cancel-booking pointer" (click)="cancelBooking(booking)">Cancel Booking</span>
                        <span class="pad-left-8 pad-right-8 pipe">|</span>
                    </ng-container>

                    <span class="view-details pointer"
                        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
                        View Details
                        <span class="icon-angle-right">
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <hr>
        <div class="adfasdas">
            <ng-container
                *ngIf="booking.attributes.booking_data.journey_details.journey_type == AirportType.Arrival || booking.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                <div class="desktop-body d-flex align-items-center justify-content-between">
                    <div class="check-in-out">
                        Arrival Flight
                    </div>
                    <div class="check-in-out-txt">
                        {{getArrivalFlightDetails(booking)}}
                    </div>
                </div>
                <hr>
            </ng-container>

            <ng-container *ngIf="booking.attributes.booking_data.journey_details.journey_type == AirportType.Arrival">
                <div class="desktop-body d-flex align-items-center justify-content-between">
                    <div class="check-in-out">
                        Arrival Date & Time
                    </div>
                    <div class="check-in-out-txt">
                        {{getArrivalDateTime(booking)}}
                    </div>
                </div>
                <hr>
            </ng-container>

            <ng-container
                *ngIf="booking.attributes.booking_data.journey_details.journey_type == AirportType.Departure || booking.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                <div class="desktop-body d-flex align-items-center justify-content-between">
                    <div class="check-in-out">
                        Departure Flight
                    </div>
                    <div class="check-in-out-txt">
                        {{getDepartureFlightDetails(booking)}}
                    </div>
                </div>
                <hr>
            </ng-container>

            <ng-container *ngIf="booking.attributes.booking_data.journey_details.journey_type == AirportType.Departure">
                <div class="desktop-body d-flex align-items-center justify-content-between">
                    <div class="check-in-out">
                        Departure Date & Time
                    </div>
                    <div class="check-in-out-txt">
                        {{getDepartureDateTime(booking)}}
                    </div>
                </div>
                <hr>
            </ng-container>

            <div class="desktop-body d-flex align-items-center justify-content-between">
                <div class="check-in-out">
                    Travellers
                </div>
                <div class="check-in-out-txt">
                    {{getTravellers(booking)}}
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center w100 my-5">
        <pagination-controls *ngIf="bookings?.length>itemsPerPage" responsive="true"
            (pageChange)="pageNo = $event; moveUp();">
        </pagination-controls>
    </div>
</div>
<ng-template #progressblock>
    <div class="d-flex justify-content-center w100 my-5">
        <div class="dot-flashing" *ngIf="progress"></div>
    </div>
</ng-template>