import { Component, OnInit } from '@angular/core';
import { NatGeoService } from 'src/app/nat-geo/services/nat-geo/nat-geo.service';
import { Router } from '@angular/router';
import { LoginService } from './../../services/login/login.service';
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import { MatDialog } from "@angular/material/dialog";
@Component({
  selector: 'app-nat-geo-history',
  templateUrl: './nat-geo-history.component.html',
  styleUrls: ['./nat-geo-history.component.scss']
})
export class NatGeoHistoryComponent implements OnInit {
  progress : boolean = true;  

  constructor(public natGeoS:NatGeoService,public router: Router, public dialog: MatDialog, public login: LoginService) { }
  bookings=[];

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
      if(result && result.id){
        this.natGeoS.getBookings().then((bookings:any)=>{
          console.log(bookings);
          this.bookings = bookings.data;
          this.progress = false;
    
          
          // this.bookings=bookings.nat_geo_bookings;
        })
      }else{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
          disableClose: true
        }).afterClosed().subscribe( res=> {
          if(res == 'true'){
            this.ngOnInit();
          }else if(res =='redirect'){
            history.back();
          };
        });
      }
    },
    (error)=>{
      this.dialog.open(SbicLoginComponent, {
        width: "450px",
        height: "70vh",
        disableClose: true
      }).afterClosed().subscribe( res=> {
        if(res == 'true'){
          this.ngOnInit();
        }else if(res =='redirect'){
          history.back();
        };
      });
    });

  }
  goToDetails(booking){
    this.router.navigate([`booking`, 'nat-geo', booking.attributes.id]);
  }
}
