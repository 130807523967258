<div class="custom-card" (click)="navigateToDetailPage()">
    <div class="custom-card-img">
        <img [src]="activity.banner_url?activity.banner_url:'assets/default_image.png'" class="zoom">
    </div>

    <div class="card-content">
        <p class="heading">{{activity.name}}</p>
        <p class="sub-heading">{{activity.sub_heading}}</p>

        <div style="display: flex;">
            <div class="rating col-md-6">
                <div *ngIf="activity.overall_rating>=0.5 && activity.number_of_ratings>=1" class="rating-div d-flex flex-column">
                    <span class="d-flex">
                        <img class="star" src="assets/Star.png" *ngFor="let item of getNoOfStars(activity.overall_rating)">
                    </span>
                    <span class="d-flex align-center">
                        <span class="value">{{activity.overall_rating}}</span>
                        <span class="noOfRatings">({{activity.number_of_ratings}})</span>
                    </span>
                </div>
            </div>
            <div class="amount col-md-6">
                <div class="from">From</div>
                <div class="val">
                    <span class="mar-right-5 fnt-14 accenttwo ft-wt-300" *ngIf="getValueOriginal() != getValue()">
                        <span class="rupee-symbol mar-right-2">₹</span>
                        <span style="text-decoration: line-through">{{getValueOriginal() | indianCurrency}}</span>
                    </span>
                    <span><span class="rupee-symbol mar-right-2">₹</span><span>{{getValue() | indianCurrency}}</span></span>
                </div>
            </div>

        </div>
    </div>
</div>