<div *ngIf="!progress">
    <div *ngIf="!bookings.length" class="no-rooms">
        No bookings found !
    </div>

    <div class="nat-geo-item d-block d-sm-none"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }"
        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
        <div class="d-flex justify-content-between align-items-center pointer">
            <div class="d-flex justify-content-start">
                <div class="mar-right-15">
                    <div class="icon-docsapp"></div>
                </div>
                <div class="">
                    <div class="nat-geo-item-title">
                        {{booking.attributes.booking_data.user_pack.name | titlecase}}
                    </div>
                    <div class="nat-geo-item-details">
                        {{booking.attributes.booking_data.booking_for | titlecase}}
                    </div>

                </div>
            </div>
            <div class="status-text">
                <div>
                    <app-status-label class="status-text" [status]="booking.attributes.status"
                        [date]="booking.attributes.booking_data.booking_for=='consultation' ? booking.attributes.created_at:''">
                    </app-status-label>
                </div>
            </div>
        </div>
    </div>
    <div class="nat-geo-item d-none d-sm-block"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }">
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-start w80 align-items-center">
                    <div class="mar-right-15">
                        <div class="icon-docsapp"></div>
                    </div>
                    <div>
                        <div class="nat-geo-item-title">
                            {{booking.attributes.booking_data.user_pack.name | titlecase}}
                        </div>
                    </div>
                </div>
                <div class="status-text">
                    <div>
                        <app-status-label class="status-text" [status]="booking.attributes.status"
                            [date]="booking.attributes.booking_data.booking_for=='consultation' ? booking.attributes.created_at:''">
                        </app-status-label>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="booking-id-detail">
                    <div *ngIf="booking.attributes?.booking_unique_id">
                        <span>Order ID : </span>
                        <span
                            class="id">{{booking.attributes?.booking_unique_id + ' ('}}<span>{{booking.attributes.booking_data.booking_for | titlecase}}</span>)
                        </span>
                    </div>
                </div>
                <div class="ft-wt-400 cancel-ticket pointer"
                    (click)="openBookingDetails(booking.attributes.booking_unique_id)">
                    View Details
                    <span class="icon-angle-right"></span>
                </div>
            </div>
        </div>

    </div>
    <div class="d-flex justify-content-center w100 my-5">
        <pagination-controls *ngIf="bookings.length>10" responsive="true" (pageChange)="pageNo = $event; moveUp();">
        </pagination-controls>
    </div>
</div>
<div class="d-flex justify-content-center w100 my-5">
    <div class="dot-flashing" *ngIf="progress"></div>
</div>