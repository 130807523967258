import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss']
})
export class GiftCardComponent implements OnInit {
  @Input() gift;
  @Input() showAddToCart: boolean;
  @Output() openModalEvent = new EventEmitter<any>(true);

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public login: LoginService,
  ) { }

  ngOnInit() {
    this.changeDenominations();
  }

  changeDenominations() {
    if (this.gift.attributes.eligible_denominations && this.gift.attributes.eligible_denominations.length) {
      this.gift.attributes.price_type = 'slab';
      this.gift.attributes.denominations = this.gift.attributes.eligible_denominations;
    } else if (this.gift.attributes.eligible_denomination_range && this.gift.attributes.eligible_denomination_range.length) {
      this.gift.attributes.price_type = 'range';
      this.gift.attributes.min_price = this.gift.attributes.eligible_denomination_range[0];
      this.gift.attributes.max_price = this.gift.attributes.eligible_denomination_range[1];
    }
  }

  getCategoriesName(giftcard) {
    let categories = []
    if (giftcard.attributes.categories.length) {
      giftcard.attributes.categories.forEach(element => {
        if (element.name && !element.parent_id) {
          categories.push(element.name)
        }
      });
    }
    return categories.join(', ');
  }

  getSubCategoriesName(giftcard) {
    let categories = []
    if (giftcard.attributes.categories.length) {
      giftcard.attributes.categories.forEach(element => {
        if (element.name && element.parent_id) {
          categories.push(element.name)
        }
      });
    }
    return categories.join(', ');
  }

  getMinValue(giftcard) {
    if (giftcard.attributes.price_type == 'slab') {
      return Math.min(...giftcard.attributes.denominations);
    }
    return giftcard.attributes.min_price;
  }

  getMaxValue(giftcard) {
    if (giftcard.attributes.price_type == 'slab') {
      return Math.max(...giftcard.attributes.denominations);
    }
    return giftcard.attributes.max_price;
  }


  navigateToDetailPage() {
    this.router.navigate(['giftcards', 'details', this.gift.id]);
  }

  openModal(event, giftcard) {
    event.stopPropagation();
    this.login
      .getUserDetails()
      // .toPromise()
      .subscribe((result: any) => {
        if (result && result.id) {
          this.openModalEvent.emit(giftcard);
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
            disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.openModalEvent.emit(giftcard);
            } else if (res == 'redirect') {
            }
          });
        }
      },
        (error) => {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
            disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.openModalEvent.emit(giftcard);
            } else if (res == 'redirect') {
            }
          });
        });

  }
}
