import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment'
import {Md5} from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
export class HotelUtilService {
  constructor() { }
}
