<div class="tours-container" *ngIf="!progress">
    <p class="tours-page-heading">Say Hello to spontaneous events & concerts and all things fun in your destination.</p>


    <div class="tours-search-bar container mar-top-2">

        <div class="row tours-search-row">
            <div class="col-lg-10">


                <div class="row">
                    <div class="col-md-12 col-lg-5 home-input-fields" dropdown>
                        <div class="form-field relative" dropdownToggle>
                            <div class="form-field__control ">
                                <input  id="exampleFieldOrigin" type="text" class="form-field__input arrows text-truncate" 
                                [formControl]="cityInput" placeholder=" " (input)="cityInputChange()">
                               <label for="exampleFieldOrigin" class="form-field__label">Select your destination</label>
                               <div class="form-field__bar"></div>
                            </div>
                        </div>
                        <div *dropdownMenu class="dropdown-menu location-dropdown">
                            <div *ngIf="!(citiesList.length)">
                                <div class="show dropdown-menu w100">
                                    <div class="option-item ellipsis">
                                        <div class="option-item-inner">
                                            No results found
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="citiesList.length">
                                <div>
                                    <div *ngIf="!filterCityList">
                                        <div class="option-item ellipsis" *ngFor="let where of citiesList">
                                            <div class="option-item-inner" (mousedown)="setSelectedCity(where)">
                                                {{where.name +', '+ where.country}}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="filterCityList && (citiesList | filter: cityInput.value : 'name') as result">
                                        <div class="option-item ellipsis" *ngFor="let where of result">
                                            <div class="option-item-inner" (mousedown)="setSelectedCity(where)">
                                                {{where.name + ', ' + where.country}}
                                            </div>
                                        </div>
                                        <div class="option-item ellipsis" *ngIf="result.length == 0">
                                            <div class="option-item-inner">
                                                No results found
                                            </div>
                                        </div>
    
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-7 home-input-fields" dropdown>
                        <div class="form-field relative" dropdownToggle>
                            <div class="form-field__control ">
                                <input  id="exampleFieldOriginDo" readonly type="text" class="form-field__input arrows text-truncate" 
                                [(ngModel)]="classInput" placeholder=" ">
                               <label for="exampleFieldOriginDo" class="form-field__label">What would you like to do ?</label>
                               <div class="form-field__bar"></div>
                            </div>
                        </div>
                        <div *dropdownMenu class="dropdown-menu location-dropdown">
                            <div *ngIf="classes.length">
                                <div class="option-item ellipsis" *ngFor="let where of classes">
                                    <div class="option-item-inner" (mousedown)="setSelectedClass(where)">
                                        {{where.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>


            </div>
            <div class="col-md-12 col-lg-2 d-flex home-input-fields">
                <div class="col-12 align-self-center home-enquire-button no-margin no-padding">
                    <button mat-button class="enquire-button" (click)="search()" color="accent">Search</button>
                </div>
            </div>
        </div>

        <div class="tours-view-all d-block d-sm-none" *ngIf="selectedCity">
            <span (click)="viewAll()" class="view-all">View All
                Events In
                {{selectedCity.name}}<span class="icon-right"></span></span>
        </div>

        <section class="all-mob-dropdowns d-lg-none d-xl-none" [ngClass]="{'active' : dropDownPopOpen}"
            *ngIf="dropDownPopOpen">
            <div class="close-bar flex align-center">
                <div class="close-icon-contain mar-top-15" (click)="dropDownPopOpen=false;removeModalOpen();">
                    <span class="icon-cancel"></span>
                </div>
            </div>
            <!-- City Drop -->

            <div id="origin-drop" *ngIf="inputToShow == 'cityDrop'">
                <div class="flex line-1 justify-center">
                    <div class="img-box icon-group mar-top-5">
                    </div>
                    <div class="txt-box fnt-16 f500 pad-top-2">Select your destination</div>
                </div>
                <div class="input-contain mar-top-20">
                    <input type="text" class="form-control fnt-14" [formControl]='cityInput'
                        [(ngModel)]="cityInput.value" (input)="cityInputChange()" placeholder="Select your destination">
                </div>
                <div class="option-contain pad-top-10">
                    <div class="dropdown location-dropdown" *ngIf="!citiesList.length">
                        <div class="show dropdown-menu w100">
                            <div class="option-item ellipsis">
                                <div class="option-item-inner">
                                    No results found
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dropdown location-dropdown" *ngIf="citiesList?.length">
                        <div *ngIf="!filterCityList">
                            <div class="option-item ellipsis" *ngFor="let where of citiesList">
                                <div class="option-item-inner" (mousedown)="setSelectedCity(where)">
                                    {{where.name +', '+ where.country}}
                                </div>
                            </div>
                        </div>

                        <div *ngIf="filterCityList && (citiesList | filter: cityInput.value : 'name') as result">
                            <div class="option-item ellipsis" *ngFor="let where of result">
                                <div class="option-item-inner" (mousedown)="setSelectedCity(where)">
                                    {{where.name +', '+ where.country}}
                                </div>
                            </div>
                            <div class="option-item ellipsis" *ngIf="result.length == 0">
                                <div class="option-item-inner">
                                    No results found
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="map-done w100 d-block d-sm-none">
                        <button class="w100 primary-btn height-45" (click)="dropDownPopOpen=false;removeModalOpen();"
                            mat-button>Done</button>
                    </div>
                </div>
            </div>

            <!-- City Drop -->

            <!-- Activity Drop -->

            <div id="origin-drop" *ngIf="inputToShow == 'classDrop'">
                <div class="flex line-1 justify-center">
                    <div class="txt-box fnt-16 f500 pad-top-2">What would you like to do ?</div>
                </div>
                <div class="input-contain mar-top-20">
                </div>
                <div class="option-contain pad-top-10">
                    <div class="dropdown location-dropdown" *ngIf="!(classes?.length)">
                        <div class="show dropdown-menu w100">
                            <div class="option-item ellipsis">
                                <div class="option-item-inner">
                                    No results found
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dropdown location-dropdown" *ngIf="classes?.length">
                        <div *ngIf="!filterActivityList">
                            <div class="option-item ellipsis" *ngFor="let where of classes"
                                (mousedown)="setSelectedClass(where)">
                                <div class="option-item-inner"
                                    [ngClass]="{'f500': selectedClass && selectedClass.code==where.code}">
                                    {{where.name}}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="map-done w100 d-block d-sm-none">
                        <button class="w100 primary-btn height-45" (click)="dropDownPopOpen=false;removeModalOpen();"
                            mat-button>Done</button>
                    </div>
                </div>
            </div>

            <!-- Activity Drop -->


        </section>
    </div>


</div>


<div class="container-fluid secondary-background py-2" *ngIf="!progress && hidePageBottom">
    <div class="header" *ngIf="selectedCity">
        <span class="list-heading">Popular Events in {{selectedCity.name}}</span>
        <span (click)="navigate('/events-concerts/listing/'+selectedCity.name)" class="view-all pointer">View All <span
                class="icon-right"></span></span>
    </div>

    <div class="listing-container">
        <div class="d-inline-flex justify-content-end dots-spinner" *ngIf="isLoading">
            <div class="dot-flashing" style="margin: auto"></div>
        </div>
        <div *ngIf="!isLoading && !eventList?.length" class="no-results">
            No results found.
        </div>

        <ng-container *ngFor="let event of eventList; let index=index">
            <div *ngIf="index<4" class="col-lg-3 col-md-3 col-sm-12">
                <app-event-card [event]="event" [exchangeRates]="exchangeRates"></app-event-card>
            </div>
        </ng-container>

    </div>
</div>

<app-loader *ngIf="progress"></app-loader>