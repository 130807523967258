<div
  class="modal-card d-flex flex-column justify-content-start align-items-center"
>  <div class="tourcard d-flex w-100 flex-row flex-wrap">
    <div class="title w-100 text-center">Tours & Experiences</div>
    <div class="card-tile d-flex justify-content-center align-items-center flex-row flex-wrap align-content-center" (click)="navigate('tours-and-activities')">
      <div class="img-container d-flex justify-content-center align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <path d="M14 18.6667C17.8663 18.6667 21 13.1997 21 9.33334C21 7.47683 20.2625 5.69635 18.9497 4.3836C17.637 3.07084 15.8565 2.33334 14 2.33334M14 18.6667C10.1337 18.6667 7 13.1997 7 9.33334C7 7.47683 7.7375 5.69635 9.05025 4.3836C10.363 3.07084 12.1435 2.33334 14 2.33334M14 18.6667C13.3812 18.6667 12.7877 17.8063 12.3501 16.2747C11.9125 14.7432 11.6667 12.6659 11.6667 10.5C11.6667 8.33408 11.9125 6.25685 12.3501 4.72531C12.7877 3.19376 13.3812 2.33334 14 2.33334M14 18.6667C14.6188 18.6667 15.2123 17.8063 15.6499 16.2747C16.0875 14.7432 16.3333 12.6659 16.3333 10.5C16.3333 8.33408 16.0875 6.25685 15.6499 4.72531C15.2123 3.19376 14.6188 2.33334 14 2.33334M11.6667 23.3333C11.6667 23.0239 11.7896 22.7272 12.0084 22.5084C12.2272 22.2896 12.5239 22.1667 12.8333 22.1667H15.1667C15.4761 22.1667 15.7728 22.2896 15.9916 22.5084C16.2104 22.7272 16.3333 23.0239 16.3333 23.3333V24.5C16.3333 24.8094 16.2104 25.1062 15.9916 25.325C15.7728 25.5438 15.4761 25.6667 15.1667 25.6667H12.8333C12.5239 25.6667 12.2272 25.5438 12.0084 25.325C11.7896 25.1062 11.6667 24.8094 11.6667 24.5V23.3333Z" stroke="#C2A63F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="card-title w-100">
        Experiences
      </div>
      <div class="sub-title w-100">
        Say hello to world's best curated experiences
      </div>
    </div>
    <div class="card-tile d-flex justify-content-center align-items-center flex-row flex-wrap align-content-center" (click)="navigate('nat-geo')">
      <div class="img-container d-flex justify-content-center align-items-center">
        <span class="icon-national-geographic text-brand"></span>
      </div>
      <div class="card-title w-100">
        NAT GEO Tours
      </div>
      <div class="sub-title w-100">
        Explore the globe with unique itineraries
      </div>
    </div>
  </div>
 </div>

