import { Injectable } from '@angular/core';
import { ApiService } from "src/app/services/common/api/api.service";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GolfService {
  apiUrl: string = environment.apiUrl; //'https://rewards-stage-api-customer.poshvine.com/api/v1/'
  baseUrl: string = environment.stagingUrl; //'https://rewards-stage-api-customer.poshvine.com/'

  constructor(private api: ApiService) { }

  getGolfCountries(){
    return this.api.get(this.apiUrl + 'golf_course_lists/golf_countries').toPromise();
  }

  getBookingDetails(bookingId){
    return this.api.get(this.baseUrl + `/users/bookings/booking_details?booking_unique_id=${bookingId}`).toPromise();
  }

  bookingList(){
    return this.api.get(this.baseUrl +`users/bookings?booking_type=GolfBooking`).toPromise()
  }

  getGolfCities(id){
    return this.api.get(this.apiUrl + `golf_course_lists/get_cities?country_id=${id}`).toPromise();
  }

  getGolfCourceList(city, top?, country_id?){
    if(top) return this.api.get(this.apiUrl + `golf_course_lists/city_course_list?top=true`).toPromise();
    if(city)
      return this.api.get(this.apiUrl + `golf_course_lists/city_course_list?city_name=${city}`).toPromise();
    else if(country_id)
      return this.api.get(this.apiUrl + `golf_course_lists/get_country_course_list?counrty_id=${country_id}`).toPromise();
    else
      return this.api.get(this.apiUrl + `golf_course_lists/city_course_list`).toPromise();
  }

  getGolfCourseDetails(id){
    return this.api.get(this.apiUrl + `golf_course_lists/${id}`).toPromise()
  }

  getTimeSlots(start, end) {
    let timeSlots = [];
    const startTime = new Date(`1 ${start}`);
    const endTime = new Date(`1 ${end}`);
    let slotTime = startTime;
    while (slotTime <= endTime) {
      timeSlots.push(new Date(slotTime));
      slotTime.setHours(slotTime.getHours() + 1);
    }
    return timeSlots;
  }

  createBooking(bookingDetails){
    return this.api.post(this.apiUrl + `golf_course_lists/create_booking`,bookingDetails).toPromise()
  }

  updateBooking(userData){
    return this.api.post(this.apiUrl + `golf_course_lists/update_golf_booking`,userData).toPromise()
  }
}
