import { Component, OnInit } from "@angular/core";
import { FlightsService } from "src/app/flights/service/flights.service";
import { Router } from "@angular/router";
import swal from "sweetalert2";
import { LoginService } from "./../../services/login/login.service";
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import { MatDialog } from "@angular/material/dialog";
import * as _moment from "moment";
const moment = _moment;
import { IndianCurrency } from "src/app/shared/pipes/indian-currency.pipe";

@Component({
  selector: "app-flights-history",
  templateUrl: "./flights-history.component.html",
  styleUrls: ["./flights-history.component.scss"],
  providers: [IndianCurrency],
})
export class FlightsHistoryComponent implements OnInit {
  bookings = [];
  progress = true;
  airlines;
  constructor(
    public flightS: FlightsService,
    public router: Router,
    public dialog: MatDialog,
    public login: LoginService,
    private indianCurrency: IndianCurrency
  ) {}

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
      .subscribe((result: any) => {
        if (result && result.id) {
          this.flightS.getBookings().then((bookings: any) => {
            this.bookings = bookings.data;
            console.log(this.bookings);
            this.progress = false;
          });
        } else {
          this.dialog
            .open(SbicLoginComponent, {
              width: "450px",
              height: "70vh",
              disableClose: true,
            })
            .afterClosed()
            .subscribe((res) => {
              if (res == "true") {
                this.ngOnInit();
              } else if (res == "redirect") {
                history.back();
              }
            });
        }
      },
      (error)=>{
        this.dialog
        .open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
          disableClose: true,
        })
        .afterClosed()
        .subscribe((res) => {
          if (res == "true") {
            this.ngOnInit();
          } else if (res == "redirect") {
            history.back();
          }
        });
      });
  }

  getStatus(date, cur_status) {
    if (this.checkIfUpcoming(date) && cur_status === "booking_confirmed") {
      return "upcoming";
    } else if (
      this.checkIfCompleted(date) &&
      cur_status === "booking_confirmed"
    ) {
      return "booking_completed";
    } else {
      return cur_status;
    }
  }

  checkIfCompleted(date) {
    return moment(date).isBefore(moment());
  }

  checkIfUpcoming(date) {
    return moment(date).isAfter(moment());
  }

  getAirlineImage(code) {
    if (code == 69) {
      code = "UK";
    }
    if (code == 62) {
      code = "AI";
    }
    if (code == 63) {
      code = "UK";
    }
    return `https://ui.cltpstatic.com/images/logos/air-logos/${code}.png`;
  }

  getAirlineName(code, airlines) {
    return airlines.find((airline) => airline.airline_code == code)
      .airline_name;
  }

  flightDuration(duration) {
    return (
      Math.floor(duration / 3600) +
      "h " +
      Math.ceil((duration % 3600) / 60) +
      "m"
    );
  }

  getFlightTime(flights, type, flightDuration?: string) {
    if (flightDuration) {
      return (
        `${parseInt(flightDuration?.split(':')[0])}h ${parseInt(flightDuration?.split(':')[1])}m`
      );
    }
    let duration = 0;
    // if (trip_response.flight_search.search_type == "international" && trip_response.flight_search.return_date) {
    let flightsArr = Object.keys(flights);
    flightsArr.forEach((flight) => {
      duration = duration + Number(flights[flight].duration);
    });
    // }
    //   // new Date(arrivalTime).getTime() - new Date(departureTime).getTime();
    for (let index = 1; index < Object.keys(flights).length; index++) {
      duration =
        duration +
        (new Date(flights[+flightsArr[0] + 1].departure_date).getTime() / 1000 -
          new Date(flights[+flightsArr[0]]["arrival-date"]).getTime() / 1000);
    }

    return (
      Math.floor(duration / 3600) +
      "h " +
      Math.ceil((duration % 3600) / 60) +
      "m"
    );
  }

  getStops(flight) {
    if (Object.keys(flight).length == 1) {
      return "Non-Stop";
    } else return Object.keys(flight).length - 1 + " Stops";
  }

  onwardSegmentLength(flights) {
    return Object.keys(flights).length;
  }

  viewDetails(bookingId, status, airlines) {
    console.log(airlines, "____");
    localStorage.setItem("flight-history-airlines", JSON.stringify(airlines));
    this.router.navigate([`booking`, "flights"], {
      queryParams: { id: bookingId, status: status },
    });
  }

  returnSegmentLength(flights) {
    return Object.keys(flights)[0];
  }

  returnSegmentLegth(flights) {
    return Object.keys(flights)[Object.keys(flights).length - 1];
  }
  totalTravellers(booking) {
    let search =
      booking.attributes.details.attributes.itinerary_response.flight_search;
    return search.adults + search.children + search.infants;
  }
  cancelBooking(booking) {
    let totalCharge = "";
    // if(booking.attributes.booking_data.supplier_name === 'cleartrip'){
    //   totalCharge =
    //   booking.attributes.details.attributes.itinerary_response.mini_rules.onward.total_cancellation_charges;
    //   if (booking.attributes.details.attributes.itinerary_response.mini_rules.return.total_cancellation_charges) {
    //     totalCharge =
    //       totalCharge +
    //       booking.attributes.details.attributes.itinerary_response.mini_rules.return.total_cancellation_charges;
    //   }
    // }
    let flightDetailsOnwReturn = Object.keys(
      booking.attributes.details.attributes.itinerary_response.flights[0]
        .segments
    ).length;
    let cancellationMessage =
      '<p><span class="icon-flight mar-right-5 fnt-14"></span><span>' +
      booking.attributes.details.attributes.itinerary_response.flights[0]
        .segments[1].departure_city_name +
      '<span class="icon-right mar-right-5 mar-left-5 fnt-14"></span>' +
      booking.attributes.details.attributes.itinerary_response.flights[0]
        .segments[flightDetailsOnwReturn].arrival_city_name +
      "</p>" +
      '<p><span class="icon-group-1 mar-right-5 fnt-14"></span> Departure Date: ' +
      moment(
        booking.attributes.details.attributes.itinerary_response.flight_search
          .depart_date
      ).format("DD MMM, YYYY") + //+moment(booking.flight_trip.flight_search.return_date).format('DD MMM YYYY')+'</p>'+
      (booking.attributes.details.attributes.itinerary_response.flight_search
        .return_date
        ? '<p><span class="icon-group-1 mar-right-5 fnt-14"></span> Return Date: ' +
          moment(
            booking.attributes.details.attributes.itinerary_response
              .flight_search.return_date
          ).format("DD MMM, YYYY")
        : "") +
      '<p><span class="icon-users mar-right-5 fnt-14"></span>' +
      this.totalTravellers(booking) +
      " traveller(s)" +
      "<p class='fnt-16' style='text-align:left; font-weight: 400 !important;'> Amount Paid: " +
      '<span class="rupee-symbol">₹</span> ' +
      this.indianCurrency.transform(
        booking.attributes.details.attributes.itinerary_response.pricing_summary
          .total_fare,
        null
      ) +
      "</p>" +
      (totalCharge
        ? "<p class='fnt-16' style='text-align:left; font-weight: 400 !important;'> Total cancellation charges: " +
          '<span class="rupee-symbol">₹</span> ' +
          this.indianCurrency.transform(parseFloat(totalCharge), null) +
          "</p>"
        : "") +
      '<p> Note : Convenience Fee of <span class="rupee-symbol">₹</span> ' +
      booking.attributes.details.attributes.itinerary_response.pricing_summary
        .convenience_fee +
      " will not be refunded.</p>";
    let fire = swal.fire({
      imageUrl: "assets/modal-info.png",
      imageHeight: 60,
      title: "Are you sure you want to cancel this booking?",
      html: cancellationMessage,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    });
    // if(booking.attributes.booking_data.supplier_name !== 'cleartrip'){
    //   fire = swal
    //   .fire({
    //     imageUrl: "assets/modal-info.png",
    //     imageHeight: 60,
    //     title: 'Are you sure you want to cancel this booking?',
    //     html: cancellationMessage,
    //     showCancelButton: true,
    //     input: 'textarea',
    //     inputPlaceholder: 'Reason...',
    //     inputAttributes: {
    //       'aria-label': 'Type your message here'
    //     },
    //     preConfirm: (input) => {
    //       if (!input) {
    //       swal.showValidationMessage("Please enter reason for cancellation.");
    //       return false;
    //       } else {
    //       return input;
    //       }
    //       },
    //     confirmButtonText: "Confirm",
    //     cancelButtonText: "Cancel"
    //   })
    // }
    fire.then((result) => {
      if (result.value) {
        this.progress = true;
        // if(booking.attributes.booking_data.supplier_name === 'cleartrip'){
        //   this.flightS.cancelBooking(booking.attributes.booking_unique_id).then((resp: any) => {
        //     if (resp.status == 200) {
        //       let refundTxt = "<p> Refundable amount : " +
        //         '<span class="rupee-symbol">₹</span> ' +
        //         resp.cancel_response.refunded_amount +
        //         "</p>";
        //       swal
        //         .fire({
        //           imageUrl: "assets/modal-info.png",
        //           imageHeight: 60,
        //           title: "Cancellation Successful",
        //           html: refundTxt,
        //           // showCancelButton: true,
        //           // confirmButtonText: "OK",
        //           // cancelButtonText: "Don't cancel"
        //         }).then( res => {
        //           this.ngOnInit();
        //         })
        //     }else{
        //       swal.fire({
        //         imageUrl: "assets/modal-error.png",
        //         imageHeight: 60,
        //         title: "Cancellation Failed",
        //         html: '<p>'+'In case of assistance contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>'+'</p>',
        //         // html: '<p>'+resp['msg'][0]+'</p>',
        //         // showCancelButton: true,
        //       }).then( res => {
        //         this.ngOnInit();
        //       })
        //     }
        //     this.progress = false;
        //   });
        // }else{
        this.flightS
          .cancelRequestConcierge(
            booking.attributes.booking_unique_id,
            result.value
          )
          .then((resp: any) => {
            console.log(resp);
            if (resp["status"] === "success") {
              swal
                .fire({
                  // icon: "success",
                  imageUrl: "assets/modal-info.png",
                  imageHeight: 60,
                  title: "Cancellation Requested",
                  html:
                    "<p>" +
                    'Cancellation request has been sent to <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' +
                    "</p>",
                  // showCancelButton: true,
                })
                .then((res) => {
                  this.ngOnInit();
                });
            } else {
              swal
                .fire({
                  imageUrl: "assets/modal-error.png",
                  imageHeight: 60,
                  title: "Cancellation Request Failed",
                  html:
                    "<p>" +
                    'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' +
                    "</p>",
                  // showCancelButton: true,
                })
                .then((res) => {
                  this.ngOnInit();
                });
            }
          });
        // }
      } else if (result.dismiss === swal.DismissReason.cancel) {
        console.log("close alert");
      }
    });
  }
}
