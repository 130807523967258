import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from "src/app/services/login/login.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input("isAuthenticated") isAuthenticated;
  preLoginUrl = environment.preLoginUrl;
  currentYear = new Date().getFullYear();
  constructor(
    public auth: LoginService,
  ) { }

  ngOnInit() {
    this.isAuthenticated = this.auth.isAuthenticated();
    this.auth
    .getUserDetails()
    // .toPromise()
    .subscribe((result:any) => {
      if (!(result && result.id)) {
        // this.login.logout();
        localStorage.removeItem("token");
        localStorage.removeItem("auth_token");
        // this.isAuthenticated = false;
      }
    });
  }

}
