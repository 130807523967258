import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'incrementDecrement'
})
export class IncrementDecrementPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    return value+1;
  }

}
