import { Injectable } from "@angular/core";
import { ApiService } from "src/app/services/common/api/api.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PointsTransferService {
  private ptsApiUrl: string = environment.apiUrlPtsV1;

  constructor(public api: ApiService) {}

  public getProviders(providerId: string = "") {
    let requestURL = `${this.ptsApiUrl}/points_providers`;
    if (providerId) {
      requestURL = requestURL + `/${providerId}`;
    }
    return this.api.get(requestURL);
  }

  public fetchUserAccounts(providerId: string) {
    return this.api.get(
      `${this.ptsApiUrl}/user_points_provider_accounts?points_provider_id=${providerId}`
    );
  }

  public saveUserAccount(
    points_provider_id: string,
    accountDetails: {
      account_id: string;
      name: string;
      mobile: string;
      email: string;
    }
  ) {
    return this.api.post(`${this.ptsApiUrl}/user_points_provider_accounts`, {
      points_provider_id,
      ...accountDetails,
    });
  }

  public validateUserAccount(
    points_provider_id: string,
    accountDetails: {
      account_id: string;
      name: string;
      mobile: string;
      email: string;
    }
  ) {
    return this.api.post(
      `${this.ptsApiUrl}/user_points_provider_accounts/validate_account`,
      {
        points_provider_id,
        ...accountDetails,
      }
    );
  }

  public unlinkUserAccount(points_provider_id: string, account_id: string) {
    return this.api.post(
      `${this.ptsApiUrl}/user_points_provider_accounts/${account_id}/unlink_account`,
      { points_provider_id }
    );
  }

  public getBookingsData() {
    let requestURL = `${this.ptsApiUrl}/transfers?per_page=100`;
    return this.api.get(requestURL);
  }

  public getTransferDetails(transferId: string) {
    let requestURL = `${this.ptsApiUrl}/transfers/${transferId}`;
    return this.api.get(requestURL);
  }

  public getBanners() {
    let requestURL = `${environment.apiUrlPsV1}/client_banners`;
    return this.api.get(requestURL);
  }

  public createTransfer(transferObject: {
    user_points_provider_account_id: string;
    points_provider_id: string;
    points: number;
    points_breakup: {
      reward_points: number;
      card: number;
    };
  }) {
    let requestURL = `${this.ptsApiUrl}/transfers`;
    return this.api.post(requestURL, transferObject);
  }
}
