import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { ApiService } from './../services/common/api/api.service';
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PaymentHomeService {
  apiUrl = environment.apiUrl;
  v2ApiBs = environment.v2ApiBs;
  apiUrlPaV1 = environment.apiUrlPaV1;
  stagingUrl = environment.stagingUrl;

  constructor(private api: ApiService) { }

  getBookingsById(id) {
    // https://rewards-stage-api-customer.poshvine.com/api/v1/payments/payment_link_details?booking_unique_id=<unique_id>

    return this.api
      .get(this.v2ApiBs + `/bookings/${id}`);
    // .toPromise();
  }

  getPaymentDetails(bookingId) {
    return this.api
      .get(this.apiUrlPaV1 + 'payments/' + bookingId)
      .toPromise();
  }

  resendPaymentLink(unique_id) {
    let body = {
      booking_id: unique_id,
    }
    return this.api
      .post(this.apiUrl + `payments/send_payment_link`, body)
  }

  getAllPromoCode(service_name?: string) {
    return this.api
      .get(this.v2ApiBs + `/promo_codes`);
    // .get(this.v2ApiBs + `/promo_codes` + (service_name ? '?service_name=' + service_name : ''));
  }

  getPromoCodeDiscount(bookingId, promoCode) {
    let body = {
      booking_id: bookingId,
      code: promoCode
    }
    return this.api
      .post(this.v2ApiBs + `/promo_codes/apply`, body);
  }

  removeCouponCode(bookingId, promoCode) {
    let body = {
      booking_id: bookingId,
      code: promoCode
    }
    return this.api
      .post(this.v2ApiBs + `/promo_codes/remove`, body);
  }

  getCardDetails(number) {
    return this.api.get(this.stagingUrl + 'api/v1/payments/get_card_details?card_num=' + number);
  }

  getConciergePaymentDetails(booking_id) {
    // return this.api.get(this.v2ApiBs + `/bookings/show_concierge_booking?id=${booking_id}`);;
    return this.api.get(this.v2ApiBs + `/bookings/concierge_payment_link_details?id=${booking_id}`);
  }

}
