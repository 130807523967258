<div class="relative points-input-box-container d-flex justify-content-start align-items-center pad-20-y pad-16-x pad-md-0-x pad-md-40-y
flex-row flex-wrap">
  <div class="merchant-img">
    <img class="w-100 h-100" [src]="merchantImg ?? appConstants.FALLBACK_IMAGE">
  </div>
  <div class="merchant-name flex-grow-1 font-13px font-md-14px text-grey-light">{{merchantText}}</div>
  <div class="input-box-container relative d-flex align-content-center justify-content-end" (click)="bringToFocus(inputBox)"
  [ngClass]="{'error-border':(minimumValue > rewardPoints && minimumValue !== 0 || maximumValue < rewardPoints && maximumValue !==0 || customError || !isUserLoggedIn) && errorMessagesEnabled}">
    <input #inputBox class="font-14px font-md-16px text-white ft-wt-600" type="text" [ngModel]=" rewardPoints | currency:'':'':'1.0-0'"
    (ngModelChange)="handleCurrencyInputChange($event)" numbersOnly [isCurrency]="true"
    [disabled]="isReadOnly" [ngClass]="{isReadOnly:'disabled','error-border':(minimumValue > rewardPoints || maximumValue < rewardPoints)}">
  </div>
  <ng-container *ngIf="errorMessagesEnabled">
    <ng-container *ngIf="isUserLoggedIn">
      <ng-container *ngIf="minimumValue > userRewardPoints">
        <div *ngIf="minimumValue > userRewardPoints" class="error-msg font-10px font-md-12px w-100">
          You don't have enough points to transfer.
        </div>
      </ng-container>
      <ng-container *ngIf="minimumValue < userRewardPoints">
        <div *ngIf="minimumValue > rewardPoints && minimumValue !== 0" class="error-msg font-10px font-md-12px w-100">
          Minimum {{minimumValue|currency:'':'':'1.0-0'}} AURUM points are required to initiate transfer.
        </div>
        <div *ngIf="maximumValue < rewardPoints && maximumValue !== 0" class="error-msg font-10px font-md-12px w-100">
          You can transfer a maximum of {{maximumValue|currency:'':'':'1.0-0'}} AURUM Reward Points at a time
        </div>
        <div *ngIf="customError" class="error-msg font-10px font-md-12px w-100">
          {{customError}}
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!isUserLoggedIn">
      <div class="error-msg font-10px font-md-12px w-100">
        You need to login to access your AURUM Rewards
      </div>

    </ng-container>


  </ng-container>
  <svg  *ngIf="enableTransferArrow" class="absolute bottom-arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" rx="12" fill="#2D2D2D"/>
    <path d="M12 9L12 15M12 15L10 13.2857M12 15L14 13.2857" stroke="#F7F7F7" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</div>
