import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import { EventsConcertsService } from 'src/app/events-concerts/services/events-concerts/events-concerts.service';

@Component({
  selector: 'app-event-history',
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.scss']
})
export class EventHistoryComponent implements OnInit {
  bookings = [];
  pageNo: number = 1;
  itemsPerPage = 10;
  progress: boolean = true;

  constructor(
    private eventsConcertsService: EventsConcertsService,
    public dialog: MatDialog,
    public login: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.eventsConcertsService.getBookings().then(resp => {
            this.bookings = resp['data'];
            this.progress = false;
          })
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          };
        });
      });
  }

  openBookingDetails(bookingId) {
    this.router.navigate(['/booking/events-concerts'], { queryParams: { 'id': bookingId } });
  }

  moveUp() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  getPeople(booking) {
    let people = [];
    if (booking.attributes.booking_data.ticket_orders.ticket_orders && booking.attributes.booking_data.ticket_orders.ticket_orders.length) {
      booking.attributes.booking_data.ticket_orders.ticket_orders.forEach(element => {
        element.ticket_order.forEach(order => {
          if (order.seats && order.seats.length) {
          } else {
            people.push(order.no_of_seats + ' ' + order.discount_desc);
          }
        });
      });
    }
    return people.join(', ');
  }

  getSeats(booking) {
    let seats = [];
    if (booking.attributes.booking_data.ticket_orders.ticket_orders && booking.attributes.booking_data.ticket_orders.ticket_orders.length) {
      booking.attributes.booking_data.ticket_orders.ticket_orders.forEach(element => {
        element.ticket_order.forEach(order => {
          if (order.seats && order.seats.length) {
            order.seats.forEach(seat => {
              seats.push(seat.full_id);
            });
          }
        });
      });
    }
    return seats.join(', ');
  }
}
