import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/services/common/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  apiUrl: string = environment.apiUrl;
  stagingUrl: string = environment.stagingUrl;
  giftCardApiParams = environment.giftCard;
  httpOptions = {
    headers: new HttpHeaders()
      .set('X-API-Client-Token', this.giftCardApiParams.clientToken)
  }

  constructor(
    private api: ApiService
  ) { }

  getCategories() {
    return this.api
      .get(this.giftCardApiParams.apiUrl + 'categories?parent_id=16&benefit_type=offers', this.httpOptions)
      .toPromise();
  }

  getAllMerchants() {
    return this.api
      .get(this.giftCardApiParams.apiUrl + 'merchants?benefit_type=offers', this.httpOptions)
      .toPromise();
  }

  getAllOffers() {
    return this.api
      .get(this.giftCardApiParams.apiUrl + 'offers?categories=health-wellness', this.httpOptions)
      .toPromise();
  }

  getOffersByMerchant(merchantId) {
    return this.api
      .get(this.giftCardApiParams.apiUrl + 'offers?merchant_id=' + merchantId, this.httpOptions)
      .toPromise();
  }

  getOfferById(id) {
    return this.api
      .get(this.giftCardApiParams.apiUrl + 'offers/' + id, this.httpOptions)
      .toPromise();
  }

  redeem(data) {
    return this.api
      .post(this.apiUrl + 'health_pass/redeem', data)
      .toPromise();
  }

  getBookingDetailsById(id) {
    return this.api.get(this.stagingUrl + `users/bookings/booking_details?booking_unique_id=${id}`).toPromise();
  }

  
  getBookings(){
    return this.api.get(this.stagingUrl + "users/bookings?booking_type=HealthPassRedemption").toPromise()
  }

}
