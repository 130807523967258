import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { filter, pairwise } from "rxjs/operators";
import { RouterExtService } from "src/app/services/util/router-ext.service";
import { appConstants } from 'src/app/app.constants';

@Component({
  selector: "app-history-container",
  templateUrl: "./history-container.component.html",
  styleUrls: ["./history-container.component.scss"],
})
export class HistoryContainerComponent implements OnInit {
  activeTab = 1; //flights
  apps;
  constructor(
    public router: Router,
    private routerExtService: RouterExtService,
    public route: ActivatedRoute
  ) {
    console.log("here", this.route.snapshot.queryParamMap.get("app"));
    this.apps = appConstants.apps;
  }

  ngOnInit() {

    if (this.route.snapshot.queryParamMap.get("app")) {
      this.router.navigateByUrl(
        `/booking/home?&`
        + `app=` + this.route.snapshot.queryParamMap.get("app")
      );
      this.activeTab = this.getAppIdByName(this.route.snapshot.queryParamMap.get("app"));
    } else {
      this.loadTab();
      this.makeActiveTab(this.activeTab);
    }
    console.log(this.getAppNameById(1), 'asdsad')
  }

  loadTab() {
    
    console.log('herere', this.routerExtService.previousRoutePath.value.split("/")[1])
    switch (this.routerExtService.previousRoutePath.value.split("/")[1]) {
      case "flights":
        this.activeTab = 1;
        break;
      case "hotels":
        this.activeTab = 2;
        break;
      case "tours-and-activities":
        this.activeTab = 3;
        break;
      case "dining":
        this.activeTab = 6;
        break;
      case "nat-geo":
        this.activeTab = 9;
        break;
      case "giftcards":
        this.activeTab = 4;
        break;
      case "charitable-donations":
        this.activeTab = 5;
        break;
      case "golf":
        this.activeTab = 11;
        break;
      case "designers":
        this.activeTab = 12;
        break;
      case "gifting":
        this.activeTab = 10;
        break;
      case "docsapp":
        this.activeTab = 16;
        break;

      case "concierge-services":
        this.activeTab = 101;
        break;
      case "offers":
        this.activeTab = 102;
        break;
      case "airport-concierge":
        this.activeTab = 8;
        break;
      case "events-concerts":
        this.activeTab = 7;
        break;
      default:
        this.activeTab = 1;
    }
  }

  getAppNameById(id) {
    if (id === 101) {
      return 'Concierge Services';
    } else if (id === 102) {
      return 'Offers';
    } else {
      return this.apps.filter(app => app.id == id)[0].name;
    }
  }

  getAppIdByName(name) {
    if (name === 'Concierge Services') {
      return 101;
    } else if (name === 'Offers') {
      return 102;
    } else {
      return this.apps.filter(app => app.name == name)[0].id;
    }
  }

  backToListing() {
    history.back();
  }

  makeActiveTab(num) {
    this.activeTab = num;
    this.router.navigateByUrl(
      `/booking/home?&`
      + `app=` + this.getAppNameById(num)
    );
  }

}
