import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HotelApiService } from 'src/app/hotel/services/hotel-api/hotel-api.service';
import { HotelUtilService } from 'src/app/hotel/services/hotel-util/hotel-util.service';
import {switchMap, debounceTime, tap, finalize, switchAll} from 'rxjs/operators';
import {Room} from 'src/app/hotel/components/dropdown/custom-dropdown.component';
import * as _moment from 'moment';
const moment = _moment;
import {Router} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { hotelType } from 'src/app/hotel/hotel.constants';
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: 'app-hotels-home',
  templateUrl: './hotels-home.component.html',
  styleUrls: ['./hotels-home.component.scss']
})
export class HotelsHomeComponent implements OnInit {
  isDestinationDropdownOpen = false;
  isdateRangeDropdownOpen = false;
  isRoomsDropdownOpen = false;
  whereInput = new UntypedFormControl();
  minCheckinoutDate = moment();
  isLoading = false;
  where = '';
  whereListDestination = [];
  whereListCountries = [];
  whereListHotels = [];
  whereListLocalities = [];
  rooms: Room[];
  checkInOutDates: any;
  isOpen = false;
  hotelOrLuxurystaycation;
  destination;
  destinationIsHotel: boolean = false;
  dropDownPopOpen = false;
  showText : string;
  currentUrl : string = JSON.parse(JSON.stringify(window.location.origin)) + JSON.parse(JSON.stringify(window.location.pathname)) + JSON.parse(JSON.stringify(window.location.search));
  isLuxuryStaycation : boolean = true;
  inputToShow : string = '';
  progress : boolean = true;
  hidePageBottom: boolean = false;
  clickedSearch: boolean = false;

  //error variables
  destinationError: string = '';
  checkinoutError: string = '';
  roomselectorError: string = '';


  @ViewChild('desktopDatepicker', { static: false }) desktopDatepicker: ElementRef<HTMLElement>;
  @ViewChild('mobileDatepicker', { static: false }) mobileDatepicker: ElementRef<HTMLElement>;
  @ViewChild('destinationDropdown', { static: false }) destinationDropdown: ElementRef<HTMLElement>;

  constructor(private hotelApiService : HotelApiService, private hotelUtilService : HotelUtilService, private router: Router, private renderer: Renderer2, public data: DataService) {
    this.rooms = [{"adults": 2, "children": 0, "children_arr" : [-1,-1,-1,-1]}];
    this.hotelOrLuxurystaycation = hotelType.hotel;
    // this.checkInOutDates = {
    //   startDate : moment(),
    //   endDate : moment().add(2,'days')
    // };
    // this.checkInOutDates.endDate = moment().add(2,'days')
  }

  datesUpdated(event){
    // let datepicker = document.querySelector(".hotel-desktop-daterange") as HTMLElement;
    // datepicker.innerHTML = event.startDate.format('YYYY-MM-DD')
    // console.log(event)
    // console.log('arearea',event.endDate.diff(event.startDate,'days'))
    // if(event.startDate.format('YYYY-MM-DD') === event.endDate.format('YYYY-MM-DD')){
    //   this.data.triggerToastr('error',"Enter diffrent Check-In Check-Out dates.");
    // }else if(event.endDate.diff(event.startDate,'days') > 20 && !this.hotelOrLuxurystaycation){
    //   this.data.triggerToastr('error',"Check-In Check-Out dates cannot be 21 days apart.");
    // }else if(event.endDate.diff(event.startDate,'days') > 14 && this.hotelOrLuxurystaycation){
    //   this.data.triggerToastr('error',"Check-In Check-Out dates cannot be 15 days apart.");
    // }
    // else{
      if(window.innerWidth <= 768){
        console.log('rearea',event)
        if(event.startDate.format('YYYY-MM-DD') !== event.endDate.format('YYYY-MM-DD')){
          this.closeDropDownPopOpen();
          this.isdateRangeDropdownOpen = false;
          this.checkInOutDates = event;
          this.checkinoutError = '';
        }
      }else{
        this.checkInOutDates = event;
        this.checkinoutError = '';
      }
    // }
  }

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if(window.location.pathname.split('/')[1]){
      this.hidePageBottom = true;
    }
    localStorage.setItem('isCountry', 'no');
    localStorage.setItem('isLocality', 'no');
    console.log(localStorage.getItem('hotelType'))
    if(localStorage.getItem('hotelType') === null){
      localStorage.setItem('hotelType', JSON.stringify(this.hotelOrLuxurystaycation))
    }
    this.isLuxuryStaycation = JSON.parse(localStorage.getItem('hotelType')) === 0 ? true : false;
    this.hotelOrLuxurystaycation = JSON.parse(localStorage.getItem('hotelType')) === 0 ? 0 : 1;
    // else{
    //   localStorage.setItem('hotelType', JSON.stringify(this.hotelOrLuxurystaycation));
    //   this.isLuxuryStaycation = JSON.parse(localStorage.getItem('hotelType')) ? true : false;
    // }
    console.log('here',this.isLuxuryStaycation)
    this.reInit();
    this.generateShowText();

    this.whereInput.valueChanges
    .pipe(
      debounceTime(300),
      tap(() => {
        this.whereListDestination = [];
        this.whereListHotels = [];
        this.whereListCountries = [];
        this.whereListLocalities = [];
        this.isLoading = true;
      }),
      switchMap(value => this.hotelApiService.getAutocompleteResult(value)
        .pipe(
          finalize(() => {
            if(this.destination && value != this.destination.name){
              this.destination = null;
              this.destinationError = '';
            }
            this.isLoading = false
          }),
        )
      )
    )
    .subscribe( data => {
      console.log('heerererere',data);
      this.whereListDestination = data['destination'];
      this.whereListHotels = data['hotels'];
      this.whereListLocalities = data['localities'] ? data['localities'] : [];
      this.whereListCountries = data['countries'] ? data['countries'] : [];
    })
  }

  reInit(){
    localStorage.setItem('isCountry', 'no');
    localStorage.setItem('isLocality', 'no');
    this.hotelApiService.getAutocompleteResult('').subscribe( data => {
      this.whereListDestination = data['destination'];
      this.whereListHotels = data['hotels'];
      this.whereListLocalities = data['localities'] ? data['localities'] : [];
      this.whereListCountries = data['countries'] ? data['countries'] : [];
      this.progress = false;
    })
  }

  radioChange($event){
    localStorage.setItem('hotelType', $event.value);
    console.log('herererer')
    this.isLuxuryStaycation = $event.value ? false : true;
    this.whereInput.setValue('');
    this.reInit();
  }

  displayFn(where) {
    if (where) { return where.name; }
  }

  onRoomsChange(rooms){
    this.rooms = rooms;
    this.roomselectorError = '';
  }

  onMobileDropdownClose(){
    this.generateShowText();
    this.removeModalOpen();
    this.dropDownPopOpen = false;
  }

  searchHotel(){
    console.log(this.verifyForm(),'()()()()(')
    if(this.verifyForm()){

      if(!this.destinationIsHotel){
        const store = {
          date_from: this.checkInOutDates.startDate.format('YYYY-MM-DD'),
          date_to: this.checkInOutDates.endDate.format('YYYY-MM-DD'),
          rooms: JSON.stringify(this.rooms),
          destination_id: this.destination.id,
          destination_name: this.destination.name,
          partner_id : this.destination.partner_id
        }
        localStorage.setItem('hotel_search', JSON.stringify(store));
        this.clickedSearch = true;
        this.router.navigate(["hotels","hotellist",this.destination.id])
      }else{
        const store = {
          date_from : this.checkInOutDates.startDate.format('YYYY-MM-DD'),
          date_to: this.checkInOutDates.endDate.format('YYYY-MM-DD'),
          rooms: JSON.stringify(this.rooms),
          hotel_id: this.destination.id,
          hotel_name : this.destination.name,
          mms_id : this.destination.partner_id
        }
        this.clickedSearch = true;
        localStorage.setItem('hotel_search', JSON.stringify(store))
        this.router.navigate(["hotels","hotel-detail",this.destination.partner_id, this.destination.name.replace(/ /g, "-")]);
      }
      this.removeModalOpen();
    }
  }

  bottomCardBasedRoute(city : string){
    window.scrollTo({top : 0, left : 0, behavior : 'smooth'});
    this.hotelOrLuxurystaycation = hotelType.luxurystaycation;
    localStorage.setItem('hotelType', JSON.stringify(hotelType.luxurystaycation));
    this.hotelApiService.getAutocompleteResult(city).subscribe( data => {
      this.destination = data['destination'][0];
      this.destinationError = '';
      this.whereInput.setValue(this.destination.name);
      if(window.innerWidth > 768){
        this.desktopDatepicker.nativeElement.click();
        this.removeModalOpen();
      }
    })
  }

  openMobileDatePicker(){
    if(this.mobileDatepicker){
      this.mobileDatepicker.nativeElement.click();
    }
  }

  focusouthere(){
    if(window.innerWidth <= 768){
      this.destinationDropdown?.nativeElement.blur();
    }
  }

  verifyForm(){
    if(!this.destination){
      // this.data.triggerToastr('error',"Select destination.");
      this.destinationError = "Select destination.";
      return false;
    }
    if(!this.checkInOutDates){
      // this.data.triggerToastr('error',"Select Check-In Check-Out date.");
      this.checkinoutError = "Select Check-In Check-Out date.";
      return false;
    }
    if(!this.rooms[0].adults){
      // this.data.triggerToastr('error',"Select minimum number of adults.");
      this.roomselectorError = "Select minimum number of adults.";
      return false;
    }
    if(this.checkInOutDates.startDate.format('YYYY-MM-DD') === this.checkInOutDates.endDate.format('YYYY-MM-DD')){
      this.checkinoutError = "Enter diffrent Check-In Check-Out dates.";
      // this.data.triggerToastr('error',"Enter diffrent Check-In Check-Out dates.");
      return false;
    }
    if(this.checkInOutDates.endDate.diff(this.checkInOutDates.startDate,'days') > 20 && !this.hotelOrLuxurystaycation){
      this.checkinoutError = "Check-In & Check-Out dates cannot be more than 21 days apart.";
      // this.data.triggerToastr('error',"Check-In & Check-Out dates cannot be more than 21 days apart.");
      return false;
    }
    if(this.checkInOutDates.endDate.diff(this.checkInOutDates.startDate,'days') > 14 && this.hotelOrLuxurystaycation){
      this.checkinoutError = "Check-In & Check-Out dates cannot be more than 15 days apart.";
      // this.data.triggerToastr('error',"Check-In & Check-Out dates cannot be more than 15 days apart.");
      return false;
    }
    console.log(this.checkChildrenAgeFilled(this.rooms),'-----')
    if(!this.checkChildrenAgeFilled(this.rooms)){
      // this.data.triggerToastr('error',"Enter the age of child.");
      this.roomselectorError = "Enter the age of child.";
      this.opendateRangeDropdown();
      this.openDropDownPopOpen();
      this.inputToShow = 'roomDrop';
      return false;
    }
    return true;
  }

  checkChildrenAgeFilled(rooms){
    for (let i=0; i < rooms.length; i++) {
      if(rooms[i].children){
        for (let j=0; j < rooms[i].children; j++) {
          console.log(rooms[i],rooms[i].children,j)
          if(rooms[i]['child'+j]<0){
            return false;
          }
        }
      }
    }
    return true;
  }

  setSelectedHotel(where, isDestinationHotel, isMobile, isCountry, isLocality){
    if(isCountry)
      localStorage.setItem('isCountry', 'yes');
    else
      localStorage.setItem('isCountry', 'no');

    if(isLocality)
      localStorage.setItem('isLocality', 'yes');
    else
      localStorage.setItem('isLocality', 'no');

    this.whereInput.setValue(where.name);
    this.destination = where;
    this.destinationIsHotel = isDestinationHotel;
    this.destinationError = '';
    if(isMobile){
      this.dropDownPopOpen = false;
    }
    this.closeDestinationDrop();
    if(window.innerWidth > 768){
      this.desktopDatepicker.nativeElement.click();
      this.removeModalOpen();
    }
  }

  openDropDownPopOpen(){
    this.dropDownPopOpen = true;
    // console.log(this.dropDownPopOpen, this.inputToShow, '_+_+_+_')
    // setTimeout(function() {
    //   let datePicker = document.querySelector(".hotel-mobile-date") as HTMLElement;
    //   if(this.dropDownPopOpen)
    //   datePicker.click();
    // }, 1000);
    if(window.innerWidth <= 768){
      this.addModalOpen();
    }
  }

  closeDropDownPopOpen(){
    console.log('adfadfadfadfadf')
    this.dropDownPopOpen = false;
    this.onMobileDropdownClose();
    this.removeModalOpen();
  }

  openDestinationDrop(){
    this.isDestinationDropdownOpen = true;
    if(window.innerWidth > 768){
      setTimeout(function() {
        let datePicker = document.querySelector(".hotel-desktop-destination") as HTMLElement;
        datePicker.focus();
      }, 100);
    }
    this.addModalOpen();
  }

  closeDestinationDrop(){
    if(this.isDestinationDropdownOpen){

      this.removeModalOpen();
    }
    this.isDestinationDropdownOpen = false;
  }

  opendateRangeDropdown(){
    this.isdateRangeDropdownOpen = true;
    // setTimeout(function() {
    //   let datePicker = document.querySelector(".hotel-desktop-daterange") as HTMLElement;
    //   datePicker.click();
    // }, 0);
    this.addModalOpen()
  }

  closedateRangeDropdown(){
    if(this.isdateRangeDropdownOpen){
      this.removeModalOpen();
    }
    this.isdateRangeDropdownOpen = false;
    // this.removeModalOpen();
  }

  openRoomsDropdown(){
    this.isRoomsDropdownOpen = true;
    this.addModalOpen();
  }

  closeRoomsDropdown(){
    if(this.isRoomsDropdownOpen){
      this.removeModalOpen();
    }
    this.isRoomsDropdownOpen = false;
    // this.removeModalOpen();
  }

  removeModalOpen(){
    this.renderer.removeClass(document.body, 'modal-open');
    if(window.innerWidth <= 768){
      window.scrollTo({top : 0, left : 0, behavior : 'smooth'});
    }
  }
  addModalOpen(){
    if(window.innerWidth <= 768){
      this.renderer.addClass(document.body, 'modal-open');
    }
    // this.closeRoomsDropdown();
  }

  generateShowText() {
    let noRooms = 0;
    let noChildren = 0;
    let noAdults = 0;
    if (this.rooms.length > 0) {
      noRooms = this.rooms.length;
      for (let i=0; i < this.rooms.length; i++) {
        noAdults += this.rooms[i].adults;
        noChildren += this.rooms[i].children;
      }
    }
    if (noRooms < 2 && noChildren === 0 && noAdults === 0) {
      this.showText = '';
    }else{
      this.showText =  noRooms + (noRooms > 1 ? ' Rooms. ' : ' Room. ') + noAdults +  (noAdults > 1 ? ' Adults. ':' Adult ');
      if(noChildren){
        this.showText =  noRooms + (noRooms > 1 ? ' Rooms. ' : ' Room. ') + noAdults +  (noAdults > 1 ? ' Adults. ':' Adult ') + noChildren + (noChildren > 1 ? ' Children. ' : ' Child. ');
      }
    }
    return this.showText;
  }

}
