import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import * as _moment from 'moment';
import { LoginService } from 'src/app/services/login/login.service';
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
const moment = _moment;
import { DiningService } from "src/app/dining/services/dining.service";
import swal from "sweetalert2";
import { PaymentHomeService } from 'src/app/payment/payment-home.service';

@Component({
  selector: 'app-dining-history-details',
  templateUrl: './dining-history-details.component.html',
  styleUrls: ['./dining-history-details.component.scss']
})
export class DiningHistoryDetailsComponent implements OnInit {
  bookingId;
  bookingData;
  progress : boolean = true;
  constructor(
    public dialog: MatDialog,
    public login: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private diningService : DiningService,
    private paymentHomeService : PaymentHomeService
  ) {
    this.bookingId = this.route.snapshot.queryParamMap.get("id");
   }

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    this.login
      .getUserDetails()
      .toPromise()
    .then((result:any) => {
      if(result && result.id){
        this.progress = true;
        this.diningService.getBookingDetails(this.bookingId).then( res => {
          this.progress = false;
          this.bookingData = res['data'];
          console.log(this.bookingData.attributes);

        })
      }else{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe( res=> {
            if(res == 'true'){
            this.ngOnInit();
          }else if(res =='redirect'){
            history.back();
          };
        });
      }
    });
  }

  cancelOpt(){
      swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this reservation?',
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      })
      .then(result => {
        if (result.value) {
          this.progress = true;
          let booking ={
            booking_unique_id : this.bookingId
          }
          this.diningService.cancelBooking(booking).then( res => {
            if (res['status'] === "cancelled") {
              swal.fire({
                imageUrl: "assets/modal-info.png",
                imageHeight: 60,
                title: "Cancellation Successful",
                html: '<p>' + 'Your restaurant reservation has been cancelled successfully.' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            } else {
              swal.fire({
                imageUrl: "assets/modal-error.png",
                imageHeight: 60,
                title: "Cancellation Request Failed",
                html: '<p>' + 'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            }
          })
        }
      });
  }

  back(){
    history.back();
  }

  convertMyWay(){
    let title = document.title;
    document.title = this.bookingId;
    window.print();
    document.title = title;
  }

  getStatus(date, cur_status){
    if(this.checkIfUpcoming(date) && cur_status === 'booking_confirmed'){
      return 'upcoming';
    }else if(this.checkIfCompleted(date) && cur_status === 'booking_confirmed'){
      return 'booking_completed';
    }else{
      return cur_status;
    }
  }

  paythebill(){
    let cancellationMessage = '<p class="fnt-14>'+this.bookingData.attributes.booking_data.booking_request_hash.restaurant_name+'</p>'+
    '<p class="fnt-14">'+this.bookingData.attributes.booking_data.booking_request_hash.restaurant_address+'</p>'
    ; //+ '<span class="icon-right mar-right-5 mar-left-5 fnt-14"></span>'+booking.flight_trip.flights[0].segments[this.flightDetailsOnwReturn].arrival_city_name +'</p>'//+
    // '<p><span class="icon-group-1 mar-right-5 fnt-14"></span> Departure Date: '+moment(booking.flight_trip.flight_search.depart_date).format('DD MMM, YYYY')+ //+moment(booking.flight_trip.flight_search.return_date).format('DD MMM YYYY')+'</p>'+
    // (booking.flight_trip.flight_search.return_date ? '<p><span class="icon-group-1 mar-right-5 fnt-14"></span> Return Date: '+moment(booking.flight_trip.flight_search.return_date).format('DD MMM, YYYY') : '' )+
    // '<p><span class="icon-users mar-right-5 fnt-14"></span>'+this.totalTravellers()+ ' traveller(s)' +
    // "<p class='fnt-16' style='text-align:left; font-weight: 400 !important;'> Amount Paid: " +'<span class="rupee-symbol">₹</span> ' +this.indianCurrency.transform(booking.flight_trip.pricing_summary.total_fare, null) + "</p>" +
    // (totalCharge ?  "<p class='fnt-16' style='text-align:left; font-weight: 400 !important;'> Total cancellation charges: " +'<span class="rupee-symbol">₹</span> ' +this.indianCurrency.transform(parseFloat(totalCharge),null) + "</p>" : '')+
    // '<p> Note : Convenience Fee of <span class="rupee-symbol">₹</span> '+booking.flight_trip.pricing_summary.convenience_fee+ ' will not be refunded.</p>';
    let fire = swal
    .fire({
      imageUrl: "assets/modal-info.png",
      imageHeight: 60,
      title: 'Enter the bill amount to pay?',
      html: cancellationMessage,
      showCancelButton: true,
      input: 'number',
      inputPlaceholder: 'Amount',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel"
    });
    fire.then((result:any) => {
      if (result.value) {
        let updateDetails = {
          booking_unique_id : this.bookingId,
          amount : parseFloat(result.value)
        }
        this.progress = true;
        this.paymentHomeService.resendPaymentLink(this.bookingId).subscribe(res=> {
          this.diningService.updateBooking(updateDetails).then(res=>{
            console.log(res);
            this.progress = false;
            if(res['status']=='success'){
              this.router.navigate(['payments'],  { queryParams: { 'id': this.bookingId}})
            }
          })
        })

      }
      //  else if (result.dismiss === swal.DismissReason.cancel) {

      // }
    });
    // this.diningService.updateBooking
  }

  openBookingDetails(bookingId){
    this.router.navigate([`booking`, 'dining'], { queryParams: { 'id': bookingId}});
  }

  checkIfUpcoming(date){
    return moment(date).isAfter(moment().startOf('day'))
  }

  checkIfCompleted(date){
    return moment(date).isBefore(moment().startOf('day'))
  }

  gotorest(){
    this.router.navigate(['dining', 'details', this.bookingData.attributes.booking_data.booking_request_hash.rest_id])
  }

  goToHome() {
    this.router.navigate([`dining`]);
  }
}
