import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/common/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AirportConciergeService {

  baseUrl: string = environment.apiUrl;
  apiUrl: string = this.baseUrl + 'gac';
  stagingUrl = environment.stagingUrl;

  constructor(private api: ApiService) { }

  getAllAirports(searchKey) {
    return this.api.get(this.apiUrl + '/all_airports' + (searchKey ? ('?search=' + searchKey) : ''));
  }

  getServiceAirports(searchKey) {
    return this.api.get(this.apiUrl + '/service_airports' + (searchKey ? ('?search=' + searchKey) : ''));
  }

  getTerminals(id) {
    return this.api.get(this.apiUrl + '/' + id + '/terminals');
  }

  getNationalities(){
    return this.api.get(this.baseUrl+'country_details/get_nationalities').toPromise();
  }

  saveTravelDetails(data){
    return this.api.post(this.apiUrl+'/submit_travel_details', data).toPromise();
  }

  saveTravellerDetails(data){
    return this.api.post(this.apiUrl+'/submit_traveller_details', data).toPromise();
  }

  getAvailableServices(bookingId) {
    return this.api.get(this.apiUrl + '/available_services?booking_id=' + bookingId).toPromise();
  }

  updateRequestServices(data) {
    return this.api.post(this.apiUrl + '/update_request_services', data).toPromise();
  }

  getCurrencyExchangeRates() {
    return this.api.get(this.baseUrl + 'exchange_rates').toPromise();
  }

  getBookingDetailsById(id) {
    return this.api.get(this.stagingUrl + `users/bookings/booking_details?booking_unique_id=${id}`).toPromise();
  }

  getBookings(){
    return this.api.get(this.stagingUrl + "users/bookings?booking_type=GacBooking").toPromise()
  }

  cancelBooking(bookingUniqueId){
    return this.api.post(this.apiUrl+'/cancel_booking',{
      "booking_id": bookingUniqueId,
    }).toPromise()
  }

}