import { Component, OnInit, Input, Renderer2, Output, EventEmitter, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import { LoginService } from "src/app/services/login/login.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { DataService } from "src/app/services/common/data/data.service";
import { MatSidenav } from "@angular/material/sidenav";
import { appConstants } from "src/app/app.constants";
import { DataSharingService } from "src/app/services/data-sharing.service";
import { Observable } from 'rxjs/Rx';
import {
  ToastContainerDirective,
  ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;
  @Input("sidenav") sidenav;
  @Output() headerUpdate = new EventEmitter<any>(true);

  window = window;
  isAuthenticated = this.auth.isAuthenticated();
  sideNavOpentoggled: boolean;
  openCloseAppSelector : boolean = false;
  showScroll: boolean;
  hideHeader: boolean;
  apps = appConstants.apps;
  isIpad :boolean = false;
  toShowInHeader = 6;
  selectedCityName;
  userDetails;
  points;
  preLoginUrl = environment.preLoginUrl;
  public currentModule : Observable<object>;
  counter : number = 1;
  public refreshingHeader : Observable<object>;
  public triggerToastr : Observable<object>;




  constructor(
    public dialog: MatDialog,
    public auth: LoginService,
    public router: Router,
    private location: Location,
    public data: DataService,
    public renderer : Renderer2,
    public datalogin: DataSharingService,
    private toastr: ToastrService,
    public route: ActivatedRoute
  ) {
    this.currentModule = this.data.getCurrentModule();
    this.refreshingHeader = this.data.gettriggeredRefreshHeader();
    this.triggerToastr = this.data.getTriggerToastr();

    this.loadHeader();

    // let headerNode = this.data.getData('headerNode');
    // if(headerNode){
    //   this.selectedCityName = headerNode;
    // }else{
    //   this.selectedCityName = this.apps[0];
    //   if(this.window.innerWidth > 576){
    //     // this.data.onDataChange.emit(this.selectedCityName)
    //     this.data.setData('headerNode',this.selectedCityName);
    //   }
    // }

    this.location.onUrlChange((url) => {
      console.log(url);
      this.loadHeader();
      if(window.location.pathname.split('/')[1] || window.innerWidth > 576){
        console.log('go to home')
        this.hideHeader = false;

      }else{
        console.log('stay at home');
        this.hideHeader = true;
      }
      // this.selectedCityName = this.data.getData('headerNode');
      this.removeModalOpen();
    });
  }

  ngOnInit() {
    this.toastr.overlayContainer = this.toastContainer;
    this.data.pointData$.subscribe(res => this.points = res)  //read the invoked data or default data
    this.currentModule.subscribe( current =>{
      console.log('getCurrentModule',current);
      if(current){
        this.selectedCityName = current;
        console.log(window.location.pathname.split('/')[1])
        console.log(this.apps.map(app => app.url.slice(1)).indexOf(window.location.pathname.split('/')[1]));
        let prevurl = localStorage.getItem('prevurl');
        if(window.location.pathname.split('/')[1] != this.selectedCityName.url.substring(1)){
          if(this.apps.map(app => app.url.slice(1)).indexOf(window.location.pathname.split('/')[1]) > -1 && window.location.pathname.split('/').length < 3)
            this.location.go(this.selectedCityName.url);
          else
            location.assign(this.selectedCityName.url);
        }
      }else
        this.selectedCityName = this.apps[0];
      console.log('this.selectedCityName', this.selectedCityName)
    });
    this.refreshingHeader.subscribe( (current : any) =>{
      console.log(current);
      if(current && current.status && this.counter!=current.status){
        //
        this.counter = current.status;
        this.ngOnInit();
      }
    });
    this.triggerToastr.subscribe( (current : any) =>{

      console.log(current);
      if(current){
        this.openToastr(current.type, current.msg);
      }
      // this.openToastr()
    });
    this.isAuthenticated = this.auth.isAuthenticated();
    this.initHeaderStyles();
    console.log(this.route.snapshot.queryParamMap.get("token"), !this.route.snapshot.queryParamMap.get("token"))

    if(this.isAuthenticated && window.location.pathname !="/auth_token"){
      this.auth
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (!(result && result.id)) {
          // this.login.logout();
          localStorage.removeItem("token");
          localStorage.removeItem("auth_token");
          // this.isAuthenticated = false;
          this.data.triggerRefreshHeader();
          this.headerUpdate.emit(false);
        }else{
          // this.isAuthenticated = true;
          // this.auth.getUserDetails().subscribe((res : any)=>{
            console.log(result);
            if(result && result.id){
              this.userDetails = result;
              if(this.userDetails.points) {
                this.points = this.userDetails.points;
                this.data.changePointsData(this.points);
              }
            }else{
              this.userDetails = null;
            }
            this.headerUpdate.emit(true);
          // })
        }
      },
      (error)=>{
        localStorage.removeItem("token");
        localStorage.removeItem("auth_token");
        // this.isAuthenticated = false;
        this.data.triggerRefreshHeader();
        this.headerUpdate.emit(false);
      });
    }else{
      this.userDetails = null;
    }
    // this.onAppChange(this.selectedCityName);
  }

  initHeaderStyles(){
    window.addEventListener('scroll', () => {
      const scrollTopVal = window.scrollY || document.documentElement.scrollTop;
      if (scrollTopVal > document.querySelector('.sp-header-container')['offsetHeight'] / 2) {
          document.querySelector('.sp-header-container').classList.add('fixed')
          if(document.querySelector('#login-header-cta')){
            document.querySelector('#login-header-cta').classList.add('outlined')
            document.querySelector('#login-header-cta').classList.remove('primary')
          }
          if (scrollTopVal > document.querySelector('.sp-header-container')['offsetHeight'] * .7) {
              document.querySelector('.sp-header-container').classList.add('show')
          } else {
              document.querySelector('.sp-header-container').classList.remove('show')
          }
      }
      else {
          document.querySelector('.sp-header-container').classList.remove('show')
          document.querySelector('.sp-header-container').classList.remove('fixed')
          if(document.querySelector('#login-header-cta')){
            document.querySelector('#login-header-cta').classList.remove('outlined')
            document.querySelector('#login-header-cta').classList.add('primary')
          }
      }
    });
  }

  removeModalOpen(){
    this.renderer.removeClass(document.body, 'modal-open');
  }

  scrollRight(e) {
    let wrapper = e.srcElement.closest('.container');
    wrapper.querySelector('.tabs-app-header').scrollLeft += 100;
  }
  scrollLeft(e) {
    let wrapper = e.srcElement.closest('.container');
    wrapper.querySelector('.tabs-app-header').scrollLeft -= 100;
  }

  openSidebar() {
    this.sidenav.toggle();
  }

  getAppImage() {
    let a = this.apps.find((app) => {
      if(this.selectedCityName && this.selectedCityName.name){
        return app.name === this.selectedCityName.name;
      }else{
        return false;
      }
   });
    if(a)
      return a.avatar;
    else{
      return '';
    }
  }

  loadHeader() {
    if(window.innerWidth > 576 && window.innerWidth < 800){
      this.isIpad = true;
      this.toShowInHeader = 3;
    }

    let selectedCityName = null;
    if(window.location.pathname.split("/")[1]){
      selectedCityName =  this.apps.find((app) => {
        if(window.location.pathname.split("/")[1]){
          return app.url === ('/'+window.location.pathname.split("/")[1]);
        }else{
          return false;
        }
      })
    }
    this.data.setCurrentModule(selectedCityName);
  }

  onAppChange($event) {
    if(window.location.pathname.split('/').length>=3){
        // prevurl = window.location.pathname;
        location.assign($event.url);
    }
    this.data.setCurrentModule($event);
  }

  navigateToHome() {
    this.router.navigateByUrl("/");
  }

  openDialog(): void {

  }

  openToastr(type ,msg){
    if(type=='success'){
      this.toastr.success(msg);
      // this.data.triggerToastr('success',msg);
    }else{
      this.toastr.error(msg);
      // this.data.triggerToastr('error',msg);
    }
  }
  openDialogSbic(){
    const dialogRef = this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
          disableClose: true
    });
  }

  redirectBack(){
    let token2 = localStorage.getItem('token');
    this.auth.redirectBack(token2).subscribe((res:any)=>{
      console.log(res);
      if(res.success){
        window.location.href = environment.sbiUrl+ `/creditcards/app/msw/sso-journey-non-otp?hs_token=${encodeURIComponent(res.token)}&channel=poshvine&feature=rewards-portal`;
      }else{
        ;
      }
    })
  }

  openBookingHistory() {
    this.router.navigate(["booking/home"]);
  }

  openMyAccount() {
    this.router.navigate(["myaccount"]);
  }

  logout() {
    this.headerUpdate.emit(false);
    this.auth.deleteSession().then( (res) => {
      console.log(res);
      this.auth.logout();
      this.ngOnInit();
      this.router.navigateByUrl('/');
    })

  }

  toggleMenu() {
    var allMenuItems = document.querySelectorAll('.sp-navigation > ul > li');
    var reverseMenuItems = [].slice.call(allMenuItems, 0).reverse()
    if (document.querySelector('.sp-navigation').classList.contains('active')) {
        setTimeout(function () {
            document.querySelector('.sp-navigation').classList.remove('active')
            setTimeout(function () {
                document.querySelector('.sp-navigation').classList.remove('in')

            }, 500);
        }, 400)
        if (document.querySelector('.sp-header').classList.contains('user-logged-in')) {
            document.querySelector('.user-mobile-block').classList.remove('fadein')
        }
        setTimeout(function () {
            document.querySelector('.sp-navigation .sp-header-logo').classList.remove('fade-in')
            document.querySelector('.sp-navigation .sp-header-cross').classList.remove('fade-in')
        }, allMenuItems.length * 50 - 400);
        for (var i = 0; i < allMenuItems.length; i++) {
            setTimeout(function (b) {
                allMenuItems[b].classList.toggle('loaded');
            }, 50 * i, i);
        }
    } else {
        document.querySelector('.sp-navigation').classList.add('in')
        setTimeout(function () {
            document.querySelector('.sp-navigation').classList.add('active')
        }, 1);
        setTimeout(function () {
            console.log(reverseMenuItems.length * 50 + 100);

            document.querySelector('.sp-navigation .sp-header-logo').classList.add('fade-in')
            document.querySelector('.sp-navigation .sp-header-cross').classList.add('fade-in')
        }, reverseMenuItems.length * 50 + 300);
        setTimeout(function () {
            for (var j = 0; j < reverseMenuItems.length; j++) {
                setTimeout(function (a) {
                    reverseMenuItems[a].classList.toggle('loaded');
                }, 50 * j, j);
            }
        }, 300);
        if (document.querySelector('.sp-header').classList.contains('user-logged-in')) {
            setTimeout(function () {
                document.querySelector('.user-mobile-block').classList.add('fadein')
            }, 500)
        }
    }

  }

  changeHash() {
      console.log(window)
      window.location.hash = 'login'
  }

  toggleContact(){

  }

  viewhome(){
    history.back();
  }

}
