import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { appConstants } from "src/app/app.constants";
import { PointsTransferService } from "src/app/points-transfer/points-transfer.service";
import { LoginService } from "src/app/services/login/login.service";
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";

export enum transferStatus {
  SUCCESS = "Success",
  INITIATED = "Initiated",
  FAILURE = "Failure",
}

@Component({
  selector: "app-points-transfer-history-details",
  templateUrl: "./points-transfer-history-details.component.html",
  styleUrls: ["./points-transfer-history-details.component.scss"],
})
export class PointsTransferHistoryDetailsComponent {
  public progress: boolean = true;
  public statusData: transferStatus = transferStatus.SUCCESS;
  public transferDetails: any;
  public appConstants = appConstants;
  public transferStatus = transferStatus;
  public providerDetailsLoading: boolean = true;
  public providerDetails: any;

  constructor(
    private route: ActivatedRoute,
    public login: LoginService,
    public dialog: MatDialog,
    private ptsService: PointsTransferService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.login.getUserDetails().subscribe(
      (result: any) => {
        if (result && result.id) {
          const bookingId = this.route.snapshot.queryParamMap.get("id");
          this.getPointsTransferBookingDetail(bookingId);
        } else {
          this.dialog
            .open(SbicLoginComponent, {
              width: "450px",
              height: "70vh",
              disableClose: true,
            })
            .afterClosed()
            .subscribe((res) => {
              if (res == "true") {
                this.ngOnInit();
              } else if (res == "redirect") {
                history.back();
              }
            });
        }
      },
      (error) => {
        this.dialog
          .open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
            disableClose: true,
          })
          .afterClosed()
          .subscribe((res) => {
            if (res == "true") {
              this.ngOnInit();
            } else if (res == "redirect") {
              history.back();
            }
          });
      }
    );
  }

  public handleRedirection(isSuccess: boolean) {
    if (isSuccess) {
      console.log("navigate to provider");
      window.open(this.transferDetails?.attributes?.points_provider?.url);
    } else {
      this.router.navigate([
        "points-transfer",
        "details",
        this.transferDetails?.attributes?.points_provider?.id,
      ]);
    }
  }

  public back() {
    history.back();
  }

  public navigateToHome() {
    this.router.navigate(["points-transfer"]);
  }

  public getBurnPoints(conversionRatio: number): number {
    return Math.floor(1 / conversionRatio);
  }

  private getPointsTransferBookingDetail(transferId: string): void {
    this.progress = true;
    this.ptsService.getTransferDetails(transferId).subscribe((res: any) => {
      this.progress = false;
      this.getPointsProvidersDetails(
        res?.data?.attributes?.points_provider?.id
      );
      if (res?.data?.attributes?.status === "success") {
        this.statusData = transferStatus.SUCCESS;
      } else if (
        res?.data?.attributes?.status === "initiated" ||
        res?.data?.attributes?.status === "in_progress"
      ) {
        this.statusData = transferStatus.INITIATED;
      } else {
        this.statusData = transferStatus.FAILURE;
      }
      this.transferDetails = res?.data;
    }),
      (err: any) => {
        console.log("err", err);
        this.toastr.error("Something went wrong.");
        this.progress = true;
      };
  }

  private getPointsProvidersDetails(providerID: string): void {
    this.providerDetailsLoading = true;
    this.ptsService.getProviders(providerID).subscribe((res: any) => {
      this.providerDetails = res?.data?.attributes;
      this.providerDetailsLoading = false;
    }),
      (err: any) => {
        console.log("err", err);
        this.toastr.error("Something went wrong.");
        this.providerDetailsLoading = false;
      };
  }
}
