import { Injectable } from "@angular/core";
import { ApiService } from "src/app/services/common/api/api.service";
import { environment } from "../../../environments/environment";
import { HttpParams, HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class GiftcardService {
  apiUrl: string = environment.apiUrl;
  apiUrlNew: string = environment.apiUrl;
  stagingUrl: string = environment.stagingUrl;
  v2ApiBs: string = environment.v2ApiBs;
  giftCardApiParams = environment.giftCard;
  httpOptions = {
    headers: new HttpHeaders()
      .set('X-API-Client-Token', this.giftCardApiParams.clientToken)
  }
  allGiftcards = [];
  progress: boolean = true;

  constructor(public api: ApiService, private http: HttpClient) {
    this.getAllGiftCards();
  }

  searchGiftcards(searchquery) {
    return this.api
      .get(environment.apiUrlPsV1 + 'gift_cards' + ('?search=' + searchquery));
  }

  getCategories() { ///
    return this.api
      .get(environment.apiUrlPsV1 + 'categories?benefit_type=gift_cards')
      .toPromise();
  }

  getSubCategories(categoryId) {
    return this.api
      .get(environment.apiUrlPsV1 + 'categories?parent_id=' + categoryId)
      .toPromise();
  }

  getGiftcards(pageNo, itemsPerPage, popular?: boolean) {
    return this.http
      .get(environment.apiUrlPsV1 +  'gift_cards' + ('?page=' + pageNo) + ('&per_page=' + itemsPerPage) + (popular ? ('&popular=' + popular) : ''), this.httpOptions)
      .toPromise();
  }
  getGiftcardsInCart() { ///
    return this.api
      .get(this.v2ApiBs + `/carts/list_items?category=GiftCard`)
      .toPromise();
  }

  checkout() {
    const data = {
      "category": "GiftCard"
    }
    return this.api
      .post(
        this.v2ApiBs + `/carts/checkout`, data
      );
  }

  addUserData(data) {
    return this.http
      .post(
        this.v2ApiBs + `/gift_cards/add_user_data`, data
      ).toPromise();
  }

  getGiftcardDetails(id) { ///
    return this.api
      .get(environment.apiUrlPsV1 + `gift_cards/${id}`)
      .toPromise();
  }
  deleteFromCart(deleteData, bookingId) { ///
    return this.api
    .post(this.v2ApiBs + `/carts/remove_item`, deleteData).toPromise();
    // if (bookingId) {
    //   let a = { booking_unique_id: bookingId }
    //   return this.api
    //     .delete(this.apiUrl + `shopping_carts/remove_items?item_id=${deleteData.id}&denomination=${deleteData.denomination}&recipient_type=${deleteData.recipient_type}&booking_unique_id=${bookingId}`)
    //     .toPromise();
    // } else {
    //   return this.api
    //     .delete(this.apiUrl + `shopping_carts/remove_items?item_id=${deleteData.id}&denomination=${deleteData.denomination}&recipient_type=${deleteData.recipient_type}`)
    //     .toPromise();
    // }
  }

  getBookingsById(id) {
    return this.api
      .get(this.v2ApiBs + `/bookings/${id}`)
      .toPromise();
  }
  getAllBookings() {
    return this.api
      .get(this.v2ApiBs + `/bookings?type=GiftCardBooking`)
      .toPromise();
  }
  pushToUsers(userParams) {
    return this.api
      .post(
        this.v2ApiBs + `/users/add_user_guests`,
        userParams
      )
      .toPromise();
  }
  pushToCart(cartParams) {
    console.log(cartParams, '_____')
    return this.api
      .post(
        this.v2ApiBs + `/carts/add_items`,
        cartParams
      );
  }
  pushToBuy(Params) {
    return this.api
      .post(
        this.apiUrl + `gift_cards/purchase`,
        Params
      )
      .toPromise();
  }

  getBookingId() {
    return this.api.get(this.apiUrl + `gift_cards/create_booking`)
  }

  cancelBookingRequest(bookingUniqueId, reason) {
    return this.api.post(this.stagingUrl + 'users/bookings/request_cancellation', {
      'booking_unique_id': bookingUniqueId,
      'reason': reason
    }).toPromise()
  }


  clearCartItems() { ///
    const data = {
      "category": "GiftCard"
    };
    return this.api.post(this.v2ApiBs + `/carts/clear_cart`, data).toPromise();
    // return this.api.delete(this.apiUrl + `shopping_carts/clear_cart_items`).toPromise();
  }

  resendGiftcards(bookingId) {
    return this.api.get(this.v2ApiBs + "/gift_cards/resend_gift_card?gift_card_order_id=" + bookingId).toPromise()
  }

  getAllGiftCards() {
    return new Promise((resolve) => {
      this.getGiftcards(1, 500).then((res: any) => {
        this.allGiftcards = res.data;
        this.progress = false;
        resolve('success');
      });
    });
  }

}
