import { NgModule } from "@angular/core";
import { Routes, RouterModule, UrlSegment } from "@angular/router";
// import { PaymentComponent } from './shared/components/payment/payment.component';
// import { PaymentProcessComponent } from './pages/payment-process/payment-process.component';
import { Error404Component } from "./pages/error404/error404.component";
import { Error500Component } from "./pages/error500/error500.component";
import { BookingFailedComponent } from "./pages/booking-failed/booking-failed.component";
import { HomepageComponent } from './pages/homepage/homepage.component';
import {InfoModule} from './info/info.module';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { AuthTokenComponent } from './pages/auth-token/auth-token.component';

const routes: Routes = [
  // { path: "", redirectTo: "", pathMatch: "full" },
  { path: "", component: HomepageComponent },
  {
    path: "hotels",
    component: HomepageComponent,
  },
  {
    matcher: hotelsMatcher,
    loadChildren: () => import("./hotel/hotel.module").then(m => m.HotelModule)
  },
  {
    path: "nat-geo",
    component: HomepageComponent,
  },
  {
    matcher: natGeoMatcher,
    loadChildren: () =>
      import("./nat-geo/nat-geo.module").then(m => m.NatGeoModule)
  },
  {
    path: "flights",
    component: HomepageComponent,
  },
  {
    matcher: flightsMatcher,
    loadChildren: () =>
      import("./flights/flights.module").then(m => m.FlightsModule)
  },
  {
    path: "giftcards",
    component: HomepageComponent,
  },
  {
    matcher: giftcardsMatcher,
    loadChildren: () =>
      import("./giftcards/giftcard.module").then(m => m.GiftcardModule)
  },
  {
    path: "tours-and-activities",
    component: HomepageComponent,
  },
  {
    matcher: toursActivitiesMatcher,
    loadChildren: () =>
      import("./tours-activities/tours-activities.module").then(m => m.ToursActivitiesModule)
  },
  {
    path: "charitable-donations",
    component: HomepageComponent,
  },
  {
    matcher: charitableGivingMatcher,
    loadChildren: () =>
      import("./charitable-giving/charitable-giving.module").then(m => m.CharitableGivingModule)
  },
  {
    path: "events-concerts",
    component: HomepageComponent,
  },
  {
    matcher: eventsConcertsMatcher,
    loadChildren: () =>
      import("./events-concerts/events-concerts.module").then(m => m.EventsConcertsModule)
  },
  {
    path: 'points-transfer',
    component: HomepageComponent,
  },
  {
    matcher: pointsTransferMatcher,
    loadChildren: () =>
      import("./points-transfer/points-transfer.module").then(m => m.PointsTransferModule)
  },
  {
    path: "booking",
    loadChildren: () =>
      import("./booking-history/booking-history.module").then(
        m => m.BookingHistoryModule
      )
  },
  // { path: 'payment', component: PaymentComponent },
  {
    path: "payments",
    loadChildren: () =>
      import("./payment/payment.module").then(m => m.PaymentModule)
  },
  // { path: 'payment-process', component: PaymentProcessComponent },
  { path: "error", component: Error500Component },
  { path: "booking-failed", component: BookingFailedComponent },
  { path: "myaccount", component: MyAccountComponent },
  { path: "auth_token", component: AuthTokenComponent },

  {
    path: "info",
    loadChildren: () =>
      import("./info/info.module").then(m => m.InfoModule)
  },
  { path: "**", pathMatch: "full", component: Error404Component }
];

export function flightsMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'flights' ? ({consumed: [url[0]]}) : null;
}

export function hotelsMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'hotels' ? ({consumed: [url[0]]}) : null;
}

export function natGeoMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'nat-geo' ? ({consumed: [url[0]]}) : null;
}

export function giftcardsMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'giftcards' ? ({consumed: [url[0]]}) : null;
}

export function toursActivitiesMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'tours-and-activities' ? ({consumed: [url[0]]}) : null;
}

export function charitableGivingMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'charitable-donations' ? ({consumed: [url[0]]}) : null;
}

export function eventsConcertsMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'events-concerts' ? ({consumed: [url[0]]}) : null;
}

export function pointsTransferMatcher(url: UrlSegment[]) {
  return url.length >= 2 && url[0].path === 'points-transfer' ? ({consumed: [url[0]]}) : null;
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
