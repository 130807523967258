import { Component, OnInit } from '@angular/core';
import { GolfService } from 'src/app/golf/golf.service';
import * as moment from 'moment';
import swal from "sweetalert2";
import { ToursActivitiesService } from 'src/app/tours-activities/services/tours-activities/tours-activities.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-golf-history',
  templateUrl: './golf-history.component.html',
  styleUrls: ['./golf-history.component.scss']
})
export class GolfHistoryComponent implements OnInit {
  bookings
  progress
  itemsPerPage = 10;
  pageNo = 1;
  constructor(private golfS:GolfService, private toursS: ToursActivitiesService, private router: Router) { 
    
   }

  ngOnInit() {
    this.progress = true;
    this.golfS.bookingList().then((bookings:any)=>{
      this.progress = false;
      this.bookings = bookings.data
    })
  }

  getStatus(date, cur_status) {
    console.log(date, cur_status)
    if (this.checkIfUpcoming(date) && cur_status === 'booking_confirmed') {
      return 'upcoming';
    } else if (this.checkIfCompleted(date) && cur_status === 'booking_confirmed') {
      return 'booking_completed';
    } else {
      return cur_status;
    }
  }

  checkIfUpcoming(date) {
    return moment(date).isAfter(moment().startOf('day'))
  }

  checkIfCompleted(date) {
    return moment(date).isBefore(moment().startOf('day'))
  }

  openBookingDetails(bookingId) {
    this.router.navigate(['golf', 'booking-details'], { queryParams: { 'id': bookingId } });
  }

  cancelBooking(booking,date,price) {
    // const cancellationMessage = "abc"
    const cancellationMessage = '<p><span class="icon-ballon mar-right-5 fnt-14"></span><span>' + 'Golf' + '<span></p>' +
      '<p><span class="icon-group-1 mar-right-5 fnt-14"></span> Date : ' +moment(date).format('MMMM D YYYY') +'</p>'+
      '<p><span class="icon-clock mar-right-5 fnt-14"></span> Time : ' + moment(date).format('h:mm a') +'</p>'+
      '<p style="font-weight:500!important"> Amount <span class="rupee-symbol">₹</span>: '+ price+'</p>';
    swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this booking?',
        html: cancellationMessage,
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reason...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        preConfirm: (input) => {
          if (!input) {
            swal.showValidationMessage("Please enter reason for cancellation.");
            return false;
          } else {
            return input;
          }
        }
      })
      .then(result => {
        if (result.value) {
          this.toursS.cancelBookingRequest(booking.attributes.booking_unique_id, result.value).then((resp: any) => {
            if (resp['status'] === "success") {
              swal.fire({
                imageUrl: "assets/modal-info.png",
                imageHeight: 60,
                title: "Cancellation Requested",
                html: '<p>' + 'Cancellation request has been sent to <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            } else {
              swal.fire({
                imageUrl: "assets/modal-error.png",
                imageHeight: 60,
                title: "Cancellation Request Failed",
                html: '<p>' + 'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            }
          });
        }
      });


  }

}
