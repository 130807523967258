export const hotelConstants = {
    facilityIconsMapping  : {
        wifi : 'wifi.svg',
        valet_parking : 'valet_parking.svg',
        valet : 'valet.svg',
        restaurant : 'restaurant.svg',
        bar : 'bar.svg',
        room_service : 'room_service.svg',
        breakfast_dinning_area : 'breakfast_dinning_area.svg',
        info : 'error.svg'
    }
}

export enum hotelType {
    luxurystaycation,
    hotel
}