<div class="rewardshub-header">
  <header class="sp-header-container floating" [ngClass]="{ 'post-login': isAuthenticated } ">
    <div class="sp-wrapper" style="border-bottom: 1px solid var(--black);">
      <div class="sp-header" *ngIf="!isAuthenticated">
        <a class="sp-header-logo" (click)="navigateToHome()">
          <img src="./assets/sbic-images/logo.png" alt="">
        </a>
        <nav class="sp-navigation">
          <div class=" full-width ham-logo-block">

            <a class="sp-header-logo " (click)="navigateToHome()">
              <img src="./assets/sbic-images/logo.png" alt="">
            </a>

            <a class="sp-header-cross" (click)="toggleMenu()">
              <img src="./assets/sbic-images/icons/cross-sp.svg" alt="">
            </a>
          </div>
          <ul>
            <li routerLinkActive="active" routerLinkActiveOptions="{ exact: true }">
              <a href="{{preLoginUrl}}">Overview</a>
            </li>
            <li routerLinkActive="active">
              <a href="{{preLoginUrl + '#benefits'}}">Benefits</a>
            </li>
            <li routerLinkActive="active">
              <a href="{{preLoginUrl + '#fees'}}">Fees</a>
            </li>
            <li routerLinkActive="active" class="active">
              <a (click)="navigateToHome()">AURUM Rewards</a>
            </li>
            <li>
              <a href="{{preLoginUrl + '#faq'}}">FAQ</a>
            </li>
            <li class="seperate-link sp-popover-wrapper">
              <a (click)="toggleContact()">
                Contact us
                <img src="./assets/sbic-images/icons/chevron-collapse.svg" alt="" srcset=""
                  class="collapse-contact-link">
              </a>
              <ul class="sp-popover">
                <li>
                  <span>
                    Call:
                  </span>
                  <a href="tel:18002127078"> 1800 212 7078</a>
                </li>
                <li>
                  <span>
                    Write to us:
                  </span>
                  <a href="mailto:aurumrewards@poshvine.com">
                    aurumrewards@poshvine.com
                  </a>
                </li>
              </ul>
            </li>
            <li class="mobile-links guest gold" *ngIf="!isAuthenticated">
              <a (click)="openDialogSbic()">Login</a>
            </li>
            <li class="mobile-links guest gold">
              <a href="">Apply </a>
            </li>
            <li class="mobile-links user gold">
              <a href="">Logout </a>
            </li>
          </ul>
        </nav>
        <!-- user not logged in -->
        <ul class="sp-user-options">
          <li><button id="apply-header-cta" class="sp-button noMargin primary"
              (click)="window.open('https://www.sbicard.com/eapply/campaign.page?path=personal/credit-cards/lifestyle/aurum-card.dcr&GEMID1=AURUMWeb&theme=aurum')">Apply
              Now</button></li>
          <li *ngIf="!isAuthenticated"><button id="login-header-cta" (click)="openDialogSbic()"
              class="sp-button noMargin outlined sp-login">Login</button> </li>
          <li class="relative" dropdown #dropdown="bs-dropdown" *ngIf="isAuthenticated">
            <button class="icons sp-button noMargin dropdown-toggle" dropdownToggle>
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 0C5.38331 0 0 5.38331 0 12C0 18.6167 5.38331 24 12 24C18.6167 24 24 18.6167 24 12C24 5.38331 18.6167 0 12 0ZM12 1.50002C17.7898 1.50002 22.5 6.21022 22.5 12C22.5 14.4121 21.6741 16.63 20.3016 18.4047C15.0373 15.9133 8.9626 15.9133 3.69839 18.4047C2.32593 16.63 1.50002 14.4121 1.50002 12C1.50002 6.21022 6.21022 1.50002 12 1.50002ZM4.75057 19.5774C9.37822 17.5277 14.6218 17.5277 19.2494 19.5774C17.3628 21.3831 14.8116 22.5001 12 22.5001C9.18839 22.5001 6.6372 21.3831 4.75057 19.5774Z"
                    fill="#C2A63F" />
                  <path
                    d="M12 15C14.4814 15 16.5 12.9815 16.5 10.5C16.5 8.01853 14.4814 6 12 6C9.51857 6 7.5 8.01853 7.5 10.5C7.5 12.9815 9.51857 15 12 15ZM12 7.50002C13.6546 7.50002 15 8.84548 15 10.5C15 12.1546 13.6545 13.5 12 13.5C10.3454 13.5 9.00002 12.1545 9.00002 10.5C9.00002 8.84548 10.3455 7.50002 12 7.50002Z"
                    fill="#C2A63F" />
                </svg>

                <!-- <img src="https://sbi-sit4.sbicard.com/sbi-card-en/assets/email-template/spa/super-premium-card/assets/images/user-icon.svg"> -->
                <span class="accent mar-left-10 full-name text-truncate">{{userDetails?.full_name}}
                  <span class="icon-down-open-mini"></span>
                </span>
              </span>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" style="color: var(--accenttwo);">
                <li role="menuitem option-item" (click)="openBookingHistory()">
                  <div class="option-item-inner d-flex align-items-center">
                    <span class="icon-historyicon fnt-14 mar-top-4 mar-right-10"></span>
                    <span class="fnt-14"> Booking History </span>
                  </div>
                </li>
                <li role="menuitem option-item" (click)="logout()">
                  <div class="option-item-inner d-flex align-items-center">
                    <span class="icon-logout fnt-14 mar-top-4 mar-right-10"></span>
                    <span class="fnt-14"> Logout </span>
                  </div>
                </li>
                <!-- <li role="menuitem">
                          <span class="icon-historyicon fnt-12 mar-top-5 mar-right-11"></span>
                          <span class="fnt-12"> My Account </span>
                        </li> -->
                <!-- <li role="menuitem">
                          <span class="icon-historyicon fnt-14 mar-top-5 mar-right-11"></span>
                          <span class="fnt-12"> Logout </span>
                        </li> -->
              </ul>
            </button>
          </li>
          <a class="sp-header-ham" (click)="toggleMenu()">
            <img src="./assets/sbic-images/icons/ham.svg" alt="">
          </a>
        </ul>
        <!-- user logged in -->
        <!-- <ul class="sp-user-logged-options">
          <li class="user-name">
            Hi <span>Subahlakshmi</span>
          </li>
          <li class="seperator"></li>
          <li class="user-points"><span>71,000 pts</span></li>
          <li class="dashboard-button"><button class="sp-button noMargin outlined sp-login">My
              Dashboard</button> </li>
          <li class="logout"><a href="">Logout</a></li>
          <a class="sp-header-ham" (click)="toggleMenu()">
            <img src="./assets/sbic-images/icons/ham.svg" alt="">
          </a>
        </ul> -->
      </div>
      <div class="sp-header post-login" *ngIf="isAuthenticated">
        <div (click)="redirectBack()" class="pointer">
          <span class="mar-right-10 primary redirect-icon">
            <img src="./assets/chevron-left.svg" alt="">
          </span>
        </div>
        <a class="sp-header-logo flex align-center" (click)="navigateToHome()">
          <h3 class="sp-heading gold mar-top-4 d-none d-md-block" style="font-weight: 500;">Rewards</h3>
          <span class="separator-line-header d-none d-md-block"></span>
          <img src="./assets/sbic-images/logo.png" class="d-none d-md-block" alt="">
          <img src="./assets/sbic-images/logo.png" class="d-block d-md-none" alt="" style="height:15px;">
        </a>
        <!-- <nav class="sp-navigation d-block d-sm-none">
              <div class=" full-width ham-logo-block">

                  <a class="sp-header-logo " (click)="navigateToHome()">
                      <img src="./assets/sbic-images/logo.png" alt="">
                  </a>

                  <a class="sp-header-cross" (click)="toggleMenu()">
                      <img src="./assets/sbic-images/icons/cross-sp.svg" alt="">
                  </a>
              </div>
              <div class="user-mobile-block">
                  <p class="user-name accent">Hello, <span>{{userDetails?.full_name}}</span></p>
                  <p class="user-points"><span>{{points}}</span></p>
                  <button id="mobile-dashboard-cta" class="sp-button noMargin outlined sp-login">
                      My Dashboard
                  </button>
              </div>
              <ul>
                  <li routerLinkActive="active" routerLinkActiveOptions="{ exact: true }">
                      <a href="https://aurumcreditcard.com">Overview</a>
                  </li>
                  <li routerLinkActive="active">
                      <a href="https://aurumcreditcard.com/benefits">Benefits</a>
                  </li>
                  <li routerLinkActive="active">
                      <a href="https://aurumcreditcard.com/fees">Fees</a>
                  </li>
                  <li routerLinkActive="active" class="active">
                      <a (click)="navigateToHome()">AURUM Rewards</a>
                  </li>
                  <li>
                      <a href="https://aurumcreditcard.com/faq">FAQ</a>
                  </li>
                  <li class="seperate-link sp-popover-wrapper">
                      <a (click)="toggleContact()">
                          Contact us
                          <img src="./assets/sbic-images/icons/chevron-collapse.svg" alt="" srcset=""
                              class="collapse-contact-link">
                      </a>
                      <ul class="sp-popover">
                          <li>
                              <span>
                                  Call:
                              </span>
                              <a href="tel:18605002000"> 1800 212 7078</a>
                          </li>
                          <li>
                              <span>
                                  Write to us:
                              </span>
                              <a href="mailto:aurumrewards@poshvine.com">
                                  aurumrewards@poshvine.com
                              </a>
                          </li>
                      </ul>
                  </li>
                  <li class="mobile-links guest gold" *ngIf="!isAuthenticated">
                      <a (click)="openDialogSbic()">Login</a>
                  </li>
                  <li class="mobile-links guest gold">
                      <a href="">Apply </a>
                  </li>
                  <li class="mobile-links user gold">
                      <a href="">Logout </a>
                  </li>
              </ul>
          </nav> -->
        <!-- user not logged in -->
        <ul class="sp-user-options">
          <li *ngIf="!isAuthenticated"><button id="login-header-cta" (click)="openDialogSbic()"
              class="sp-button noMargin outlined sp-login">Login</button> </li>
          <li class="relative" dropdown #dropdown="bs-dropdown" *ngIf="isAuthenticated"><button
              class="icons sp-button noMargin dropdown-toggle" dropdownToggle>
              <span class="d-flex align-center justify-content-start">
                <div class="mar-right-5 flex align-center">
                  <span class="d-none d-sm-block mar-right-5">
                    <span class="fnt-12 f300 gold">
                      AURUM Rewards
                    </span>
                  </span>
                  <span class="flex align-center justify-content-center sp-badge"
                    style="height: 18px; padding: 0 10px;">
                    <span class="icon-star gold mar-right-5"></span>
                    {{points | indianCurrency}}
                    <span class="d-block d-sm-none mar-left-3">RP</span>
                  </span>
                </div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 0C5.38331 0 0 5.38331 0 12C0 18.6167 5.38331 24 12 24C18.6167 24 24 18.6167 24 12C24 5.38331 18.6167 0 12 0ZM12 1.50002C17.7898 1.50002 22.5 6.21022 22.5 12C22.5 14.4121 21.6741 16.63 20.3016 18.4047C15.0373 15.9133 8.9626 15.9133 3.69839 18.4047C2.32593 16.63 1.50002 14.4121 1.50002 12C1.50002 6.21022 6.21022 1.50002 12 1.50002ZM4.75057 19.5774C9.37822 17.5277 14.6218 17.5277 19.2494 19.5774C17.3628 21.3831 14.8116 22.5001 12 22.5001C9.18839 22.5001 6.6372 21.3831 4.75057 19.5774Z"
                    fill="#C2A63F" />
                  <path
                    d="M12 15C14.4814 15 16.5 12.9815 16.5 10.5C16.5 8.01853 14.4814 6 12 6C9.51857 6 7.5 8.01853 7.5 10.5C7.5 12.9815 9.51857 15 12 15ZM12 7.50002C13.6546 7.50002 15 8.84548 15 10.5C15 12.1546 13.6545 13.5 12 13.5C10.3454 13.5 9.00002 12.1545 9.00002 10.5C9.00002 8.84548 10.3455 7.50002 12 7.50002Z"
                    fill="#C2A63F" />
                </svg>
                <span class="gold mar-left-10 full-name text-truncate">
                  <a class="sp-accordion-link"><img src="./assets/sbic-images/chevron.svg" alt="" srcset=""></a>
                </span>
              </span>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" style="color: var(--accenttwo);">
                <div class="name-section">
                  <span>Hello, {{userDetails?.full_name}}</span>
                </div>
                <li role="menuitem option-item" (click)="openBookingHistory()">
                  <div class="option-item-inner d-flex align-items-center">
                    <span class="icon-historyicon fnt-14 mar-top-4 mar-right-10"></span>
                    <span class="fnt-14"> Booking History </span>
                  </div>
                </li>
                <li role="menuitem option-item" (click)="logout()">
                  <div class="option-item-inner d-flex align-items-center">
                    <span class="icon-logout fnt-14 mar-top-4 mar-right-10"></span>
                    <span class="fnt-14"> Logout </span>
                  </div>
                </li>
                <!-- <li role="menuitem">
                        <span class="icon-historyicon fnt-12 mar-top-5 mar-right-11"></span>
                        <span class="fnt-12"> My Account </span>
                      </li> -->
                <!-- <li role="menuitem">
                        <span class="icon-historyicon fnt-14 mar-top-5 mar-right-11"></span>
                        <span class="fnt-12"> Logout </span>
                      </li> -->
              </ul>
            </button> </li>
          <!-- <a class="sp-header-ham" (click)="toggleMenu()">
                  <img src="./assets/sbic-images/icons/ham.svg" alt="">
              </a> -->
        </ul>
        <!-- user logged in -->
        <!-- <ul class="sp-user-logged-options">
          <li class="user-name">
            Hi <span>Subahlakshmi</span>
          </li>
          <li class="seperator"></li>
          <li class="user-points"><span>71,000 pts</span></li>
          <li class="dashboard-button"><button class="sp-button noMargin outlined sp-login">My
              Dashboard</button> </li>
          <li class="logout"><a href="">Logout</a></li>
          <a class="sp-header-ham" (click)="toggleMenu()">
            <img src="./assets/sbic-images/icons/ham.svg" alt="">
          </a>
        </ul> -->
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between sub-header" *ngIf="!hideHeader">
      <div class="d-flex justify-content-between align-items-center w100">
        <div class="mobile-app-select-width" [ngClass]="{ 'w100': !isAuthenticated } ">
          <div style="width: 100%;" class='d-flex align-items-center'>
            <div class="tabs-app-header no-padding mar-right-10" (mouseleave)="showScroll = false"
              (mouseenter)="showScroll = true" [ngClass]="{'show-scroll' : showScroll}">
              <div *ngFor="let app of apps;">
                <span *ngIf="app.url"
                  [class]="selectedCityName.name==app.name?'tab-item-app-header pointer active':'tab-item-app-header pointer'"
                  (click)="onAppChange(app)">
                  <a class="tab-link-app-header d-flex align-items-center justify-content-start">
                    <!-- <div [class]="app.avatar"></div> -->
                    <div class="fnt-14">{{app.name}}</div>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="d-block d-sm-none">
          <div class='d-flex align-items-center justify-content-start'>
            <div (click)="viewhome()" class="mar-top-4">
              <span class="icon-angle-left mar-right-10 accent"></span>
            </div>
            <div class="mobile-app-selector">
              <div class="btn-group relative" dropdown #dropdown="bs-dropdown">
                  <div class="main-selector dropdown-toggle" dropdownToggle>
                    <div class="btn d-flex align-items-center justify-content-start selected">
                      <div [class]="selectedCityName.avatar"></div>
                      <div class="fnt-12">{{selectedCityName.name}}</div>
                    </div>
                    <div class="mar-top-4 btn selected ">
                            <span class="caret">
                                <span class="" [ngClass]="{'icon-down-open-mini': !dropdown.isOpen, 'icon-up-open-mini': dropdown.isOpen}"></span>
                            </span>
                    </div>
                  </div>
                  <ul *dropdownMenu class="dropdown-menu"
                      role="menu">
                        <li role="menuitem" *ngFor="let app of apps;">
                          <div *ngIf="app.url" class="w100" (click)="onAppChange(app)">
                            <div class="dropdown-item w100 d-flex align-items-center justify-content-start" *ngIf="app.name !== selectedCityName.name">
                              <div [class]="app.avatar"></div>
                              <div class="fnt-12">{{app.name}}</div>
                            </div>
                          </div>
                        </li>
                  </ul>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="d-flex" *ngIf="datalogin.isAuthenticated()">
        <div class="d-block d-lg-none mar-top-5">
          <span class="icon-angle-right mar-right-5 accent"></span>
        </div>
        <div class="reward-points mar-top-2 d-flex flex-column justify-content-center mar-right-10" *ngIf="userDetails && isAuthenticated">
          <div class="flex align-center sp-badge" style="height: 19px; padding: 0 19px;">
            <span class="icon-star gold mar-right-5"></span>{{points | indianCurrency}}
          </div>
          <div class="fnt-12 accenttwo d-none d-lg-block" style="height: 20px;">
            <span>Reward Points</span>
          </div>
        </div>
      </div> -->
      </div>
    </div>
    <!-- <div class="moving-text-div" *ngIf="selectedCityName.name === 'Flights'">
      Flight bookings are down temporarily due to technical issues.
      Apologies for the inconvenience. We will be back
      soon.
    </div> -->
    <div toastContainer>
    </div>
  </header>
  <!-- <div class="aurum-header d-flex align-items-center justify-content-between">

 </div>
  <div class="container no-padding d-flex align-items-center justify-content-between" *ngIf="!hideHeader">
    <div class="d-flex justify-content-between align-items-center w100">
      <div style="width: 80%;" class='d-flex align-items-center'>
        <div class="scroll-right-btn pointer" (click) = "scrollLeft($event)">
          <span class="icon-angle-left"></span>
        </div>
        <div class="tabs-app-header no-padding mar-right-10"
        (mouseleave)="showScroll = false"
        (mouseenter)="showScroll = true"
        [ngClass]="{'show-scroll' : showScroll}">
        <div *ngFor="let app of apps;">
          <span *ngIf="app.url" [class]="selectedCityName.name==app.name?'tab-item-app-header pointer active':'tab-item-app-header pointer'" (click)="onAppChange(app)">
            <a class="tab-link-app-header d-flex align-items-center justify-content-start" >
              <div [class]="app.avatar"></div>
              <div class="fnt-12">{{app.name}}</div>
            </a>
          </span>
        </div>

        </div>
        <div class="scroll-right-btn pointer" (click) = "scrollRight($event)">
          <span class="icon-angle-right"></span>
        </div>
      </div>
    <div class="d-flex" *ngIf="datalogin.isAuthenticated()">
      <div class="reward-points d-flex flex-column mar-right-20">
        <div class="points fnt-16 ft-wt-400 accent" style="height: 20px;">
          <span class="icon-star" style="color: var(--primary);"></span> <span class="rupee-symbol">{{'15000' | indianCurrency}}</span>
        </div>
        <div class="fnt-12 accenttwo" style="height: 20px;">
          <span>Reward Points</span>
        </div>
      </div>
      <div class="history pointer" (click)="openBookingHistory()">
        <div class="icon-historyicon fnt-18 mar-top-5 mar-right-11"></div>
        <div>
           History
        </div>
      </div>
    </div>
    </div>

  </div> -->
</div>
