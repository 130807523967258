import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/common/api/api.service';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ToursActivitiesService {
  baseUrl: string = environment.v2ApiBs;
  apiUrl: string = this.baseUrl + '/headouts';
  v2ApiBsInternal: string = environment.v2ApiBsInternal;

  stagingUrl = environment.stagingUrl;

  constructor(private api: ApiService) { }

  getProductListByCity(city: string) {
    return this.api.get(this.apiUrl + '?city=' + city).toPromise();
  }

  getCities() {
    return this.api.get(this.apiUrl + '/cities').toPromise();
  }

  getCityCategories(city: string) {
    return this.api.get(this.apiUrl + '/categories?city=' + city).toPromise();
  }

  getCurrencyExchangeRates() {
    return this.api.get(this.v2ApiBsInternal + "/exchange_rates").toPromise();
  }

  getProductDetails(id) {
    return this.api.get(this.apiUrl + '/' + id).toPromise();
  }


  getInventories(variantId, startDateTime, endDateTime) {
    const options = {
      params: new HttpParams()
        .set('variantId', variantId)
        .set('startDateTime', startDateTime)
        .set('endDateTime', endDateTime)
    }
    return this.api.get(this.apiUrl + '/inventories', options).toPromise();
  }


  initiateBooking(data) {
    return this.api.post(this.apiUrl + '/booking', data).toPromise();
  }

  getBookingDetails(id){
    return this.api.get(this.baseUrl + `/bookings/${id}`).toPromise()
  }
  
  getBookings(){
    return this.api.get(this.baseUrl + "/bookings?type=HeadoutBooking").toPromise()
  }

  applyPromoCode(id, code){
    const data = {"uniq_id":id,"code":code};
    return this.api.post(this.baseUrl + "promo_codes/apply_promo_code", data).toPromise()
  }

  cancelBookingRequest(bookingUniqueId, reason){
    return this.api.post(this.stagingUrl+'users/bookings/request_cancellation',{
      'booking_unique_id': bookingUniqueId,
      'reason': reason
    }).toPromise()
  }

}