import { Injectable } from "@angular/core";
import { ApiService } from "src/app/services/common/api/api.service";
import { environment } from "../../environments/environment";
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class DesignersService {
  apiUrl: string = environment.apiUrl; //'https://rewards-stage-api-customer.poshvine.com/api/v1/'
  baseUrl: string = environment.stagingUrl; //'https://rewards-stage-api-customer.poshvine.com/'

  constructor(private api: ApiService) {}

  getDesigners(searchQuery) {
    return this.api.get(
      this.apiUrl + `designer_api/autocomplete?search=${searchQuery}`
    ).pipe(share());
  }

  getDetails(id) {
    return this.api.get(this.apiUrl + `designer_api/search_offers?id=${id}`).pipe(share());;
  }

  purchaseOffer(params) {
    return this.api.post(this.apiUrl + `designer_api/purchase_offer`, {
      ...params
    }).pipe(share());
  }

  getBookingDetails(bookingId){
    return this.api.get(this.baseUrl + `/users/bookings/booking_details?booking_unique_id=${bookingId}`).toPromise();
  }


  bookingList(){
    return this.api.get(this.baseUrl +`users/bookings?booking_type=DesignerBooking`).toPromise()
  }
}
