<section class="container book-container" *ngIf="!progress">
  <div *ngIf="!bookingDetails?.data.length" class="no-rooms">
    No bookings found !
  </div>
  <div class="book-container-in d-none d-sm-block">
    <ng-container *ngFor="let order of bookingDetails?.data;let i = index">
      <div class="body-contain mar-bot-25" *ngIf="order?.attributes?.status != 'initiated'">
        <div class="git-top flex space-between" [ngClass]="{'failed-block' : order?.attributes?.status == 'failed'}">
          <div class="gift-total-info flex align-center">
            <div class="gift-logo">
              <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#C2A63F" />
                <path
                  d="M24.0198 11H10.502C9.67589 11 9 11.6609 9 12.4686V22.7488C9 23.5565 9.67589 24.2174 10.502 24.2174H24.0198C24.8459 24.2174 25.5217 23.5565 25.5217 22.7488V12.4686C25.5217 11.6609 24.8459 11 24.0198 11ZM17.2609 16.8744H10.502V15.4058H17.2609V16.8744ZM17.2609 13.9372H10.502V12.4686H17.2609V13.9372Z"
                  fill="#252525" />
              </svg>
            </div>
            <div>
              <div class="g-num fnt-16 f500">{{order.totalQuantity}} Gift Card(s)</div>
              <div class="g-brand mar-top-2 fnt-16 f300" *ngIf="getMerchantTitles(order)">by
                <span>{{getMerchantTitles(order)}}</span>
              </div>
            </div>
          </div>
          <div class="gift-top-rht flex align-center">
            <!-- <div class="gift-total flex align-center">
              <span class="gtxt fnt-16 f300">Total</span>
              <span class="gnum f500"><span class="rupee-symbol">₹</span> {{order?.attributes?.amount}} </span>
            </div> -->
            <div>
              <app-status-label class="status-text" [status]="order?.attributes?.status"></app-status-label>
            </div>
          </div>
        </div>
        <hr>
        <div class="bg">
          <div class="d-flex justify-content-between">
            <div class="git-top1">
              <span class="gtxt fnt-16 f300">Order ID: </span>
              <span class="gnum fnt-16 f400">{{order?.attributes?.unique_id}}</span>
            </div>
            <div class="d-flex align-center git-top1">
              <span class="cancel-ticket pointer fnt-16 mar-right-8"
                (click)="openBookingDetails(order.attributes.id)">
                View Details
              </span>
              <span class="icon-angle-right gold mar-top-2">
              </span>
            </div>
          </div>
        </div>
        <div class="pad-top-20 body-contain-in" *ngIf="order?.selfGift?.status">
          <div class="flex space-between w100 mar-bot-15 buy-for">
            <div class="buying-for fnt-12 f300 flex align-center">Buying for yourself <span class="j-line"></span></div>
            <!-- <div class="flex align-center">
              <svg class="person-ic" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.66667 6.66667C8.50833 6.66667 10 5.175 10 3.33333C10 1.49167 8.50833 0 6.66667 0C4.825 0 3.33333 1.49167 3.33333 3.33333C3.33333 5.175 4.825 6.66667 6.66667 6.66667ZM6.66667 8.33333C4.44167 8.33333 0 9.45 0 11.6667V13.3333H13.3333V11.6667C13.3333 9.45 8.89166 8.33333 6.66667 8.33333Z" fill="#64707C"/>
              </svg>
              <span class="person-name fnt-14">{{order?.selfGift?.displayName}}</span>
              <svg class="divider-ic" width="1" height="20" viewBox="0 0 1 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="1" height="20" fill="#64707C"/>
              </svg>
              <span class="person-amt fnt-14 f500"><span class="rupee-symbol f500">₹</span> {{order?.selfGift?.amount}}</span>
            </div> -->
          </div>
          <!-- <div class="mob-person-name fnt-12 mar-bot-15">{{order?.selfGift?.displayName}}</div> -->
          <div class="body-left">
            <div class="row cart-item-row">
              <ng-container class="" *ngFor="let giftItem of order?.attributes?.booking_data?.data;let i = index">
                <div class="cart-item mar-bot-15 " *ngIf="giftItem?.attributes?.recipient_type == 'self'">
                  <div class="cart-item-in flex" [ngClass]="{'failed-item' : giftItem?.attributes?.status == 'failed'}">
                    <div class="item-img">
                      <img alt="gift image" class="img-fluid" default="assets/giftcard/default-giftcard.png"
                        [src]="getImageUrl(giftItem.attributes.gift_card_id) ? getImageUrl(giftItem.attributes.gift_card_id) : 'assets/giftcard/default-giftcard.png'">
                    </div>
                    <div class="item-info">
                      <div class="card-name" *ngIf="getCardName(giftItem.attributes.gift_card_id)">
                        {{getCardName(giftItem.attributes.gift_card_id)}}</div>
                      <div class="flex">
                        <div class="flex info-c">
                          <div class="info-ic flex pad-top-4">
                            <svg fill="none" height="10" viewBox="0 0 10 10" width="10"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="5" cy="5" fill="#EFF0F2" r="5"></circle>
                              <path clip-rule="evenodd"
                                d="M4.44373 6.31611L3.32677 5.19914L2.94641 5.57682L4.44373 7.07414L7.65802 3.85986L7.28034 3.48218L4.44373 6.31611Z"
                                fill="#323937" fill-rule="evenodd"></path>
                              <mask height="5" id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" width="6" x="2"
                                y="3">
                                <path clip-rule="evenodd"
                                  d="M4.44373 6.31611L3.32677 5.19914L2.94641 5.57682L4.44373 7.07414L7.65802 3.85986L7.28034 3.48218L4.44373 6.31611Z"
                                  fill="white" fill-rule="evenodd"></path>
                              </mask>
                              <g mask="url(#mask0)"></g>
                            </svg>
                          </div>
                          <div class="info-txt-c fnt-12">
                            <div class="info-val-txt">Denomination</div>
                            <div class="mar-top-3 info-num f500"><span class="rupee-symbol">₹</span>
                              {{giftItem?.attributes?.denomination | indianCurrency}}</div>
                          </div>
                        </div>
                        <div class="flex info-c pad-left-10">
                          <div class="info-ic flex pad-top-4">
                            <svg fill="none" height="10" viewBox="0 0 10 10" width="10"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="5" cy="5" fill="#EFF0F2" r="5"></circle>
                              <path clip-rule="evenodd"
                                d="M4.44373 6.31611L3.32677 5.19914L2.94641 5.57682L4.44373 7.07414L7.65802 3.85986L7.28034 3.48218L4.44373 6.31611Z"
                                fill="#323937" fill-rule="evenodd"></path>
                              <mask height="5" id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" width="6" x="2"
                                y="3">
                                <path clip-rule="evenodd"
                                  d="M4.44373 6.31611L3.32677 5.19914L2.94641 5.57682L4.44373 7.07414L7.65802 3.85986L7.28034 3.48218L4.44373 6.31611Z"
                                  fill="white" fill-rule="evenodd"></path>
                              </mask>
                              <g mask="url(#mask0)"></g>
                            </svg>
                          </div>
                          <div class="info-txt-c fnt-12">
                            <div class="info-val-txt">Quantity</div>
                            <div class="mar-top-3 info-num f500">{{giftItem?.attributes?.quantity}} <span
                                class="f300">Gift
                                Card(s)</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex error-block align-center fnt-12 pad-top-10"
                    *ngIf="giftItem?.attributes?.status == 'failed'">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 19.9942C15.5228 19.9942 20 15.52 20 10.0008C20 4.48154 15.5228 0.00732422 10 0.00732422C4.47715 0.00732422 0 4.48154 0 10.0008C0 15.52 4.47715 19.9942 10 19.9942Z"
                        fill="#FF4C4C" />
                      <path d="M6.82852 5.28062L5.28198 6.82715L13.1725 14.7176L14.719 13.1711L6.82852 5.28062Z"
                        fill="white" />
                      <path d="M13.1717 5.28041L5.28125 13.1709L6.82778 14.7174L14.7183 6.82694L13.1717 5.28041Z"
                        fill="white" />
                    </svg>
                    <span>Order Failed! </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="pad-top-20 body-contain-in" *ngIf="order?.guestGift?.status">
          <div class="flex space-between w100 mar-bot-15 buy-for">
            <div class="buying-for fnt-12 f300 flex align-center">Sending as Gift <span class="j-line"></span></div>
            <!-- <div class="flex align-center">
              <svg class="person-ic" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.66667 6.66667C8.50833 6.66667 10 5.175 10 3.33333C10 1.49167 8.50833 0 6.66667 0C4.825 0 3.33333 1.49167 3.33333 3.33333C3.33333 5.175 4.825 6.66667 6.66667 6.66667ZM6.66667 8.33333C4.44167 8.33333 0 9.45 0 11.6667V13.3333H13.3333V11.6667C13.3333 9.45 8.89166 8.33333 6.66667 8.33333Z" fill="#64707C"/>
              </svg>
              <span class="person-name fnt-14">To, {{order?.guestGift?.displayName}}</span>
              <svg class="divider-ic" width="1" height="20" viewBox="0 0 1 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="1" height="20" fill="#64707C"/>
              </svg>
              <span class="person-amt fnt-14 f500"><span class="rupee-symbol f500">₹</span> {{order?.guestGift?.amount}}</span>
            </div> -->
          </div>
          <!-- <div class="mob-person-name fnt-12 mar-bot-15">{{order?.guestGift?.displayName}}</div> -->
          <div class="body-left">
            <div class="row cart-item-row">
              <ng-container class="" *ngFor="let giftItem of order?.attributes?.booking_data?.data;let i = index">
                <div class="cart-item mar-bot-15 " *ngIf="giftItem?.attributes?.recipient_type != 'self'">
                  <div class="cart-item-in flex" [ngClass]="{'failed-item' : giftItem?.attributes?.status == 'failed'}">
                    <div class="item-img">
                      <img alt="gift image" class="img-fluid" default="assets/giftcard/default-giftcard.png"
                        [src]="getImageUrl(giftItem.attributes.gift_card_id) ? getImageUrl(giftItem.attributes.gift_card_id) : 'assets/giftcard/default-giftcard.png'">
                    </div>
                    <div class="item-info">
                      <div class="card-name" *ngIf="getCardName(giftItem.attributes.gift_card_id)">
                        {{getCardName(giftItem.attributes.gift_card_id)}}</div>
                      <div class="flex">
                        <div class="flex info-c">
                          <div class="info-ic flex pad-top-4">
                            <svg fill="none" height="10" viewBox="0 0 10 10" width="10"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="5" cy="5" fill="#EFF0F2" r="5"></circle>
                              <path clip-rule="evenodd"
                                d="M4.44373 6.31611L3.32677 5.19914L2.94641 5.57682L4.44373 7.07414L7.65802 3.85986L7.28034 3.48218L4.44373 6.31611Z"
                                fill="#323937" fill-rule="evenodd"></path>
                              <mask height="5" id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" width="6" x="2"
                                y="3">
                                <path clip-rule="evenodd"
                                  d="M4.44373 6.31611L3.32677 5.19914L2.94641 5.57682L4.44373 7.07414L7.65802 3.85986L7.28034 3.48218L4.44373 6.31611Z"
                                  fill="white" fill-rule="evenodd"></path>
                              </mask>
                              <g mask="url(#mask0)"></g>
                            </svg>
                          </div>
                          <div class="info-txt-c fnt-12">
                            <div class="info-val-txt">Denomination</div>
                            <div class="mar-top-3 info-num f500"><span class="rupee-symbol">₹</span>
                              {{giftItem?.attributes?.denomination | indianCurrency}}</div>
                          </div>
                        </div>
                        <div class="flex info-c pad-left-10">
                          <div class="info-ic flex pad-top-4">
                            <svg fill="none" height="10" viewBox="0 0 10 10" width="10"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="5" cy="5" fill="#EFF0F2" r="5"></circle>
                              <path clip-rule="evenodd"
                                d="M4.44373 6.31611L3.32677 5.19914L2.94641 5.57682L4.44373 7.07414L7.65802 3.85986L7.28034 3.48218L4.44373 6.31611Z"
                                fill="#323937" fill-rule="evenodd"></path>
                              <mask height="5" id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" width="6" x="2"
                                y="3">
                                <path clip-rule="evenodd"
                                  d="M4.44373 6.31611L3.32677 5.19914L2.94641 5.57682L4.44373 7.07414L7.65802 3.85986L7.28034 3.48218L4.44373 6.31611Z"
                                  fill="white" fill-rule="evenodd"></path>
                              </mask>
                              <g mask="url(#mask0)"></g>
                            </svg>
                          </div>
                          <div class="info-txt-c fnt-12">
                            <div class="info-val-txt">Quantity</div>
                            <div class="mar-top-3 info-num f500">{{giftItem?.attributes?.quantity}} <span
                                class="f300">Gift
                                Card(s)</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex error-block align-center fnt-12 pad-top-10"
                    *ngIf="giftItem?.attributes?.status == 'failed'">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 19.9942C15.5228 19.9942 20 15.52 20 10.0008C20 4.48154 15.5228 0.00732422 10 0.00732422C4.47715 0.00732422 0 4.48154 0 10.0008C0 15.52 4.47715 19.9942 10 19.9942Z"
                        fill="#FF4C4C" />
                      <path d="M6.82852 5.28062L5.28198 6.82715L13.1725 14.7176L14.719 13.1711L6.82852 5.28062Z"
                        fill="white" />
                      <path d="M13.1717 5.28041L5.28125 13.1709L6.82778 14.7174L14.7183 6.82694L13.1717 5.28041Z"
                        fill="white" />
                    </svg>
                    <span>Order Failed! </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="d-block d-sm-none mar-top-5 pad-left-15 pad-right-15">
    <ng-container *ngFor="let order of bookingDetails?.data;let i = index">
      <div class="git-top flex space-between bgg" [ngClass]="{'failed-block' : order?.attributes?.status == 'failed'}"
        (click)="openBookingDetails(order.attributes.id)">
        <div class="gift-total-info flex align-center text-truncate">
          <div class="gift-logo">
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17.5" cy="17.5" r="17.5" fill="#C2A63F" />
              <path
                d="M24.0198 11H10.502C9.67589 11 9 11.6609 9 12.4686V22.7488C9 23.5565 9.67589 24.2174 10.502 24.2174H24.0198C24.8459 24.2174 25.5217 23.5565 25.5217 22.7488V12.4686C25.5217 11.6609 24.8459 11 24.0198 11ZM17.2609 16.8744H10.502V15.4058H17.2609V16.8744ZM17.2609 13.9372H10.502V12.4686H17.2609V13.9372Z"
                fill="#252525" />
            </svg>
          </div>
          <div>
            <div class="g-num fnt-16 f500">{{order.totalQuantity}} Gift Card(s)</div>
            <div class="g-brand mar-top-2 fnt-16 f300 w150 text-truncate" *ngIf="getMerchantTitles(order)">by
              <span>{{getMerchantTitles(order)}}</span>
            </div>
          </div>
        </div>
        <div class="gift-top-rht flex align-center">
          <!-- <div class="gift-total flex align-center">
            <span class="gtxt fnt-16 f300">Total</span>
            <span class="gnum f500"><span class="rupee-symbol">₹</span> {{order?.attributes?.amount}} </span>
          </div> -->
          <div>
            <app-status-label class="status-text" [status]="order?.attributes?.status"></app-status-label>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
<div class="d-flex justify-content-center w100 my-5">
  <div class="dot-flashing" *ngIf="progress"></div>
</div>