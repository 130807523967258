import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardMask'
})
export class CreditCardMaskPipe implements PipeTransform {
  transform(plainCreditCard: string, visibleDigits: number = 4): string {
    //const visibleDigits = 4;
    if(!plainCreditCard){
      return '';
    }
    let maskedSection = plainCreditCard.slice(visibleDigits, -visibleDigits);
    let visibleSectionStart = plainCreditCard.slice(0,visibleDigits);
    let visibleSectionEnd = plainCreditCard.slice(-visibleDigits);
    return visibleSectionStart +'-'+ maskedSection.replace(/./g, 'X') +'-'+ visibleSectionEnd;
  }
}