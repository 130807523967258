import { Component, OnInit } from '@angular/core';
import { AirportConciergeService } from 'src/app/airport-concierge/service/airport-concierge/airport-concierge.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import { AirportType } from 'src/app/airport-concierge/enums/airport-type.enum';
import * as moment from 'moment';
import swal from "sweetalert2";

@Component({
  selector: 'app-airport-con-history',
  templateUrl: './airport-con-history.component.html',
  styleUrls: ['./airport-con-history.component.scss']
})
export class AirportConHistoryComponent implements OnInit {
  bookings = [];
  pageNo: number = 1;
  itemsPerPage = 10;
  progress: boolean = true;
  servicesName = {
    'Meet and Assist': 'VIP Meet & Assist',
    'Porter Service': 'Baggage Porters',
    'Lounge Facilitation': 'Lounge Services'
  };
  AirportType = AirportType;
  constructor(
    private airportConciergeService: AirportConciergeService,
    public dialog: MatDialog,
    public login: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.airportConciergeService.getBookings().then(resp => {
            this.bookings = resp['data'];
            this.progress = false;
          })
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          };
        });
      });
  }

  openBookingDetails(bookingId) {
    this.router.navigate(['/booking/airport-concierge'], { queryParams: { 'id': bookingId } });
  }

  moveUp() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  getTravellers(booking) {
    let result = '';
    if (booking.attributes.booking_data.user_pack.adults) {
      result = booking.attributes.booking_data.user_pack.adults + ' Adult(s)';
    }
    if (booking.attributes.booking_data.user_pack.children) {
      result = booking.attributes.booking_data.user_pack.children + ' Children';
    }
    if (booking.attributes.booking_data.user_pack.infants) {
      result = booking.attributes.booking_data.user_pack.infants + ' Infant(s)';
    }
    return result;
  }

  getArrivalFlightDetails(booking) {
    let result = '';
    if (booking.attributes.booking_data.origin_airport) {
      result = booking.attributes.booking_data.origin_airport.city ? booking.attributes.booking_data.origin_airport.city : ''
      if (booking.attributes.booking_data.origin_airport.country) {
        result = result ? (result + ', ' + booking.attributes.booking_data.origin_airport.country) : booking.attributes.booking_data.origin_airport.country;
      }
    }
    result = result + ' | ' + booking.attributes.booking_data.journey_details.arrival_flight_no;
    return 'From ' + result;
  }

  getArrivalDateTime(booking) {
    let result = '';
    result = moment(booking.attributes.booking_data.journey_details.arrival_date).format('MMM DD, YYYY');
    result = result + ' | ' + booking.attributes.booking_data.journey_details.arrival_time;
    return result;
  }

  getDepartureFlightDetails(booking) {
    let result = '';
    if (booking.attributes.booking_data.destination_airport) {
      result = booking.attributes.booking_data.destination_airport.city ? booking.attributes.booking_data.destination_airport.city : ''
      if (booking.attributes.booking_data.destination_airport.country) {
        result = result ? (result + ', ' + booking.attributes.booking_data.destination_airport.country) : booking.attributes.booking_data.destination_airport.country;
      }
    }
    result = result + ' | ' + booking.attributes.booking_data.journey_details.departure_flight_no;
    return 'To ' + result;
  }

  getDepartureDateTime(booking) {
    let result = '';
    result = moment(booking.attributes.booking_data.journey_details.departure_date).format('MMM DD, YYYY');
    result = result + ' | ' + booking.attributes.booking_data.journey_details.departure_time;
    return result;
  }

  formatTerminalName(str) {
    return str.substring(4);
  }

  checkIfUpcoming(date) {
    return moment(date).isAfter(moment())
  }

  cancelBooking(booking) {
    // let cancellationMessage = '<p><span class="icon-airportcon mar-right-5 fnt-14"></span>' + booking.attributes.booking_data.booking_response.product.name + '</p>' +
      // '<p><span class="icon-group-1 mar-right-5 fnt-14"></span> Date : ' + this.getDate(booking.attributes.booking_data.booking_response) +
      // '<p><span class="icon-clock mar-right-5 fnt-14"></span> Time : ' + this.getTime(booking.attributes.booking_data.booking_response) +
      // '<p><span class="icon-users mar-right-5 fnt-14"></span>' + this.getTravellers(booking);
    // for (const each of booking.attributes.booking_data.requested_service.requested_services) {

    // }
    swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this booking?',
        // html: cancellationMessage,
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reason...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        preConfirm: (input) => {
          if (!input) {
            swal.showValidationMessage("Please enter reason for cancellation.");
            return false;
          } else {
            return input;
          }
        }
      })
      .then(result => {
        if (result.value) {
          this.progress = true;
          this.airportConciergeService.cancelBooking(booking.attributes.booking_unique_id).then((resp: any) => {
            if (resp['status'] === "success") {
              // let refundTxt = "<p> Refundable amount : " +
              //   '<span class="rupee-symbol">₹</span> ' +
              //   resp.cancel_response.refunded_amount +
              //   "</p>";
              swal.fire({
                imageUrl: "assets/modal-info.png",
                imageHeight: 60,
                title: "Cancellation Successful",
                // html: refundTxt,
              }).then(res => {
                this.ngOnInit();
              })
            } else {
              swal.fire({
                imageUrl: "assets/modal-error.png",
                imageHeight: 60,
                title: "Cancellation Failed",
                html: '<p>' + 'In case of assistance contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            }
          });
        }
      });
  }

}
