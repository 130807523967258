<footer class="sp-footer">
    <div *ngIf="!isAuthenticated">
        <div class="wrapper-padding">
            <div class="sp-wrapper">
                <div class="row">
                    <div class="col-lg-3 col-md-4">
                        <ul class="footer-list">
                            <li><a href="./faq.html"> AURUM FAQs</a></li>
                            <li><a href="https://www.sbicard.com/en/faq.page" target="_blank"> Other FAQs </a></li>


                            <li><a href="https://www.sbicard.com/en/credit-bureau-faqs.page" target="_blank"> Credit
                                    Bureau FAQs </a></li>

                            <li><a href="https://www.sbicard.com/en/forms-central.page" target="_blank"> Forms Central
                                </a></li>

                            <li><a href="https://www.sbicard.com/en/grievance-redressal-policy.page" target="_blank">
                                    Grievance
                                    Redressal
                                    Policy</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <ul class="footer-list">
                            <li><a href="https://www.sbicard.com/en/security.page" target="_blank"> Security</a></li>

                            <li><a href="https://www.sbicard.com/en/order-of-payment-settlement.page" target="_blank">
                                    Order of Payment Settlement </a></li>


                            <li><a href="https://www.sbicard.com/en/cardholder-agreement.page" target="_blank">
                                    Cardholder Agreement </a></li>
                            <li><a href="https://www.sbicard.com/en/usage-agreement.page" target="_blank"> Usage
                                    Agreement </a></li>


                            <li><a href="https://www.sbicard.com/en/fair-practice-code.page" target="_blank"> Fair
                                    Practice Code</a></li>


                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <ul class="footer-list">
                            <li><a href="{{preLoginUrl + '#terms'}}">Terms & Conditions</a></li>
                            <li><a target="_blank" href="https://www.sbicard.com/en/personal/offers.page"> SBI Card
                                    Offers </a></li>


                            <li><a href="https://www.sbicard.com/en/who-we-are.page" target="_blank"> Who we are </a>
                            </li>
                            <li><a href="https://www.sbicard.com/en/personal/pay.page" target="_blank"> PAY </a></li>

                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-12" id="footer-cta">
                        <p class="sp-subtext mar-bot-20 white">
                            Experience the benefits of being an AURUM cardholder today
                        </p>
                        <button class="sp-button mar-bot-20 primary" onclick="window.open('https://aurumcreditcard.com/')">Apply
                            Now</button>

                        <a href="tel:18002127078" class="sp-icon-link sp-subtext white full-width">
                            <img src="./assets/sbic-images/phone.png" alt=""> Contact us:
                            <span>
                                1800 212 7078
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-footer">
            <ul>
                <li>© 2022 </li>
                <li> <a href="/info/privacy-policy" target="_blank"> Privacy Policy </a> </li>
                <li> <a href="/info/terms-and-conditions" target="_blank"> Terms & Conditions </a> </li>
                <!-- <li> <a href="https://www.sbicard.com/en/privacy-policy.page" target="_blank"> Privacy Policy </a> </li> -->
                <!-- <li> <a href="https://www.sbicard.com/en/offer-terms-conditions.page" target="_blank"> Offer Terms &
                        Conditions </a> </li> -->
            </ul>

            <p class=" sp-small-text">Site best viewed in browsers Mozilla 3.5+, Chrome V23+, Firefox V15+, Safari 6+ and Edge30+ on all desktops, laptops, and Android &amp; iOS mobile/tablet devices.</p>
            <div class="powered-by flex align-items-center justify-content-center">
                <!-- <img src="./assets/sbic-images/powered-logo.png" alt="" srcset="" class="mob">
                <div class="mar-left-10 mar-right-10 d-none d-sm-block" style="width: 1px; height: 23px; border-right: 1px solid #b5b8b7;">  </div> -->
                <span>
                    <span class="fnt-10 mar-right-5"  style="color: #B5B8B7;">POWERED BY</span>
                <img src="./assets/sbic-images/poshvine_logo.png" alt="" srcset="" style="height: 14px;">
                </span>
            </div>
        </div>
    </div>
    <div class="row footer-post-login" *ngIf="isAuthenticated">
        <div class="col px-0">
            <div class="footer-container">
                <div class="links">
                    © {{currentYear}} |
                    <!-- <a href="https://www.sbicard.com/en/most-important-terms-and-conditions.page" target="_blank">Most Important Terms & Conditions</a> |  -->
                    <a href="/info/privacy-policy" target="_blank">Privacy Policy</a> |
                    <!-- <a href="https://www.sbicard.com/en/disclaimer.page" target="_blank">Disclaimer</a> |  -->
                    <a href="/info/terms-and-conditions" target="_blank">Terms & Conditions</a>
                    <!-- | <a href="https://www.sbicard.com/en/offer-terms-conditions.page" target="_blank">Offer Terms & Conditions</a> -->
                </div>
                <div class="best-viewed hide-on-mobile f300">
                    Site best viewed in browsers Mozilla 3.5+, Chrome V23+, Firefox V15+, Safari 6+ and Edge30+ on all desktops, laptops, and Android &amp; iOS mobile/tablet devices.
                </div>
                <div class="powered-by flex align-items-center justify-content-center">
                    <!-- <img src="./assets/sbic-images/powered-logo.png" alt="" srcset="" class="mob">
                    <div class="mar-left-10 mar-right-10 d-none d-sm-block" style="width: 1px; height: 23px; border-right: 1px solid var(--primary);">  </div> -->
                    <span>
                        <span class="fnt-10 mar-right-5"  style="color: #B5B8B7;">POWERED BY</span>
                    <img src="./assets/sbic-images/poshvine_logo.png" alt="" srcset="" style="height: 14px;">
                    </span>
                </div>

            </div>
        </div>
        <a class="scroll-to-top" onclick="scrollToTop()">
            <svg viewBox="0 0 65 65" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				<!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
				<title>Group 21</title>
				<desc>Created with Sketch.</desc>
				<g id="Page-1" stroke="none" stroke-width="1" fill="#000" fill-rule="evenodd">
					<g id="Static-Sample-Victorinox" transform="translate(-1311.000000, -2663.000000)" stroke="#C2A63F"
						stroke-width="2">
						<g id="Group-21" transform="translate(1311.000000, 2663.000000)">
							<circle id="Oval" cx="32.5" cy="32.5" r="31.5"></circle>
							<g id="Group-19" transform="translate(19.000000, 17.000000)">
								<polyline id="Rectangle"
									transform="translate(13.469621, 13.550093) rotate(-45.000000) translate(-13.469621, -13.550093) "
									points="4.29571489 4.48075605 22.643527 4.37618719 22.5389581 22.7239993"></polyline>
								<line x1="13.5" y1="1" x2="13.5" y2="31" id="Line-6" stroke-linecap="square"></line>
							</g>
						</g>
					</g>
				</g>
			</svg>
        </a>
    </div>

</footer>
