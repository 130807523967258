<div class="page-container" *ngIf="!progress">
    <div class="d-flex top-container sp-section  dark3 ">
        <div class="content col-lg-6 col-12">
            <div class="charity flex flex-column">
                <div>
                    <h1 class="sp-heading gold mar-right-10 mb-60 mob-text-center">Donate for a cause and make your points count for good!</h1>
                </div>
                <p class="sp-text mb-40 mob-text-center">Support the charitable organisations and causes that inspire you!</p>

            </div>
            <!-- <div class="tag">
                 Together, we can heal the world.
            </div>
            <div class="moto mar-top-20">
                
            </div> -->

            <div class="d-flex align-center mar-bot-20">
                <button mat-button class="primary-btn mob-width-100" (click)="goToBottomContainer()">
                   <span class="pad-left-30  pad-right-30">
                    Start Donating
                   </span> 
                </button>
                <div class=" pointer d-none d-lg-block" (click)="openCauseModal()">
                    <span class="sp-icon-link">
                        or find your cause
                    </span></div>
            </div>
        </div>
        <div class="review-content col-lg-6 col-12">
            <div class="yellow-circle"></div>
            <img [src]="reviews[selectedReviewIndex].image">
            <div class="review">
                <div class="review-text">{{reviews[selectedReviewIndex].review}}</div>
                <div class="by">- {{reviews[selectedReviewIndex].by}}</div>
            </div>
        </div>
    </div>

    <div class="bottom-container d-none d-lg-block sp-section dark4 center" #bottomContainer>
        <div class="heading-bar">
            <h1 class="sp-heading gold mb-30">Who you’re helping</h1>
            <button class="sp-button noMargin sp-login outlined" (click)="openCauseModal()">Filter by cause
                <!-- <span class="icon-angle-right"></span> -->
            </button>
        </div>

        <div class="container listing listing-container">
            <ng-container *ngIf="filteredNgos?.length">
                <div *ngFor="let ngo of filteredNgos" class="mar-bot-30 col-lg-4 col-12">
                    <div class="card-container">
                        <div class="image-container">
                            <img *ngIf="ngo.image_url" [src]="ngo.image_url?ngo.image_url:'assets/default_image.png'"
                                alt="image" style="width: 100%;" class="zoom pointer" [routerLink]="'/charitable-donations/ngo/'+ngo.name">
                            <img [src]="ngo.logo_url?ngo.logo_url:'assets/default_image.png'" alt="logo" class="logo">
                        </div>
                        <div class="card-content">
                            <div class="name">{{ngo.name}}</div>
                            <div class="card-cause-name d-flex align-center">
                                <ng-container *ngIf="ngo?.cause?.cause_name">
                                    <span class="icon-donation accenttwo fnt-30 mar-right-8"
                                        > </span>
                                    <span class="accenttwo">{{ngo.cause.cause_name}} </span>
                                </ng-container>
                            </div>
                            <button mat-button class="primary-btn donate-btn" (click)="openPaymentModal(ngo)">Donate
                                Now!</button>
                            <div class="know-more">
                                <a class="sp-link arrow-link" [routerLink]="'/charitable-donations/ngo/'+ngo.name">Know more</a>
                                <!-- <span class="pointer" [routerLink]="'/charitable-donations/ngo/'+ngo.name">
                                    Know more
                                    <span class="icon-right"></span>
                                </span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>

    <div class="mob-bottom-container d-block d-lg-none" #mobBottomContainer>
        <div class="heading-bar">
            <h2 class="sp-heading gold">Find Your Cause</h2>
        </div>

        <div class="listing">
            <ng-container *ngFor="let eachCause of filteredCauses; let i=index;">
                <div *ngIf="eachCause?.ngo?.length" class="card-container" [ngStyle]="{background: eachCause.color2}"
                    (click)="goToCause(eachCause)">
                    <img [src]="eachCause.cause_image?eachCause.cause_image:'assets/default_image.png'" alt="image"
                        style="width: 100%; height:100%">
                    <div class="content">
                        <div class="cause-name">{{eachCause.cause_name}}</div>
                        <button mat-button class="primary-btn donate-btn w100" (click)="goToCause(eachCause)">Start Donating</button>
                        <!-- <a class="sp-link arrow-link">Start Donating</a> -->
                    </div>
                </div>
            </ng-container>
        </div>

    </div>

</div>


<div class="modal fade cause-modal show" *ngIf="showCauseModal" id="causeModal" tabindex="-1" role="dialog"
    aria-hidden="true" style="background: rgba(0, 0, 0, 0.4);">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="border: none;">
                <h2 class="sp-heading gold mar-left-40">Find Your Cause</h2>
                <button type="button" class="close" (click)="closeCauseModal()">
                    <span aria-hidden="true" style="color: white;" class="icon-cancel"></span>
                </button>
            </div>
            <div class="modal-body mar-left-40 mar-right-30 mar-bot-40" style="border: none;">
                <div class="d-flex" style="flex-wrap: wrap;">
                    <ng-container *ngFor="let cause of causes;let i = index">
                        <div class="col-lg-6 col-md-6 col-sm-12 mar-bot-20 pointer" *ngIf="cause?.ngo?.length"
                            (click)="setCause(cause);closeCauseModal();">
                            <div class="d-flex" style="border-radius: 4px; overflow: hidden;"
                                [ngStyle]="{background: cause.color2}">
                                <div class="col-lg-5 col-md-5 col-sm-5 p-0" style="overflow: hidden;">
                                    <img [src]="cause.cause_image?cause.cause_image:'assets/default_image.png'"
                                        alt="image" style="width: 100%; height:100%" class="zoom">
                                </div>
                                <div class="col-lg-7 col-md-7 col-sm-7 pad-top-20">
                                    <div class="cause-name">{{cause.cause_name}}</div>
                                    <a class="sp-link arrow-link">Start Donating</a>
                                    <!-- <div class="start">Start Donating
                                        <span class="icon-right"></span>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="for-all-container">
                    <div class="for-all">For All Causes</div>
                    <a class="sp-link arrow-link" (click)="setCause(undefined);closeCauseModal();">Start Donating</a>
                    <!-- <div class="start pointer" style="line-height:unset"
                        (click)="setCause(undefined);closeCauseModal();">Start Donating
                        <span class="icon-right"></span>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade payment-modal show" *ngIf="showPaymentModal" id="paymentModal" tabindex="-1" role="dialog"
    aria-hidden="true" style="background: rgba(0, 0, 0, 0.4);">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="border: none;">
                <button type="button" class="close" (click)="closePaymentModal()">
                    <span aria-hidden="true" style="color: white;" class="icon-cancel"></span>
                </button>
            </div>
            <div class="modal-body" style="border: none;">
                <div class="icon-donation"></div>
                <h2 class="sp-heading gold mb-10 text-center">Donate Now</h2>

                <div class="tax-statement">All donations are tax-exempt under 80-G</div>
                <div class="d-flex justify-content-between mar-top-30 mar-bot-25 pad-left-20 pad-right-20">
                    <div class="pointer"
                        [ngClass]="{'select-type': payment.type==='once', 'unselect-type': payment.type==='monthly'}"
                        (click)="payment.type='once'">Give Once</div>
                    <div class="pointer"
                        [ngClass]="{'select-type': payment.type==='monthly', 'unselect-type': payment.type==='once'}"
                        (click)="payment.type='monthly'">Donate Monthly</div>
                </div>


                <div class="dropdown-content-item d-flex align-items-center" *ngIf="payment.type==='monthly'">
                    <div class="adult-children mar-right-10">
                        <div class="adult d-flex align-items-center">Subscribe for </div>
                    </div>
                    <div class="custom-counter d-flex justify-content-between align-items-center">
                        <button mat-button class="counter-decrement-button" (click)="change('-')">-</button>
                        <div class="bg counter-text d-flex justify-content-center align-items-center">
                            {{payment.count}}
                        </div>
                        <button mat-button class="counter-increment-button" (click)="change('+')">+</button>
                    </div>
                    <div class="adult-children mar-left-10">
                        <div class="adult d-flex align-items-center">Months </div>
                    </div>
                </div>

                <div class="payment-container mar-top-10">

                    <div class="d-flex justify-content-between" style="flex-wrap: wrap;">
                        <div class="value" [ngClass]="{'selected-amount': payment.amount==500}"
                            (click)="setAmount(500)">
                            <span class="ft-wt-400"><span class="rupee-symbol">₹</span>500</span>
                        </div>
                        <div class="value" [ngClass]="{'selected-amount': payment.amount==1000}"
                            (click)="setAmount(1000)">
                            <span class="ft-wt-400"><span class="rupee-symbol">₹</span>1,000</span>
                        </div>
                        <div class="value" [ngClass]="{'selected-amount': payment.amount==2000}"
                            (click)="setAmount(2000)">
                            <span class="ft-wt-400"><span class="rupee-symbol">₹</span>2,000</span>
                        </div>
                        <div class="value" [ngClass]="{'selected-amount': payment.amount==5000}"
                            (click)="setAmount(5000)">
                            <span class="ft-wt-400"><span class="rupee-symbol">₹</span>5,000</span>
                        </div>
                        <div class="value" [ngClass]="{'selected-amount': payment.amount==10000}"
                            (click)="setAmount(10000)">
                            <span class="ft-wt-400"><span class="rupee-symbol">₹</span>10,000</span>
                        </div>
                        <div class="value" [ngClass]="{'selected-amount': payment.amount==20000}"
                            (click)="setAmount(20000)">
                            <span class="ft-wt-400"><span class="rupee-symbol">₹</span>20,000</span>
                        </div>

                    </div>
                    <div>
                        <span class="rupee-symbol mar-right-5">₹</span>
                        <input type="text" maxlength="20" numbersOnly [isCurrency]="true" placeholder="Enter Amount"
                            class="amount-input" [ngModel]="payment.amount | indianCurrency"
                            (ngModelChange)="payment.amount=currencyInputChanged($event)">
                    </div>
                    <button mat-button class="primary-btn flex justify-content-center" style="width: 50%;" (click)="proceed()">
                        <span class="">
                            Proceed
                        </span>
                    </button>
                </div>
                <div class="why-monthly">
                    <div class="why-text">Why you should give monthly</div>
                    <hr>
                    <div class="d-flex flex-wrap why-points">
                        <div class="col-6 each-point">
                            <span class="icon-cursor icon-point"></span>
                            Convenient to setup
                        </div>
                        <div class="col-6 each-point">
                            <span class="icon-marketing icon-point"></span>
                            Long-term impact
                        </div>
                        <div class="col-6 each-point">
                            <span class="icon-respect icon-point"></span>
                            Deep engagement
                        </div>
                        <div class="col-6 each-point">
                            <span class="icon-tick icon-point"></span>
                            High commitment
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-loader *ngIf="progress"></app-loader>