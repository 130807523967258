import { Injectable } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class PaymentService {
  apiUrl = environment.apiUrlPaV1;
  apiUrlLsV1 = environment.apiUrlLsV1;
  v2ApiUrl = environment.v2ApiUrl;

  constructor(
    private http: HttpClient
  ) { }

  changePaymentStatus(bookingId, status){
    let body ={
      booking_id: bookingId,
      status: status
    }
    return this.http
    .post(this.apiUrl + 'payments/change_status', body)
    .toPromise();
  }
  createPayment(data) {
    return this.http
      .post(this.apiUrl + 'payments', data)
      .toPromise();
  }

  getBurnRatio() {
    return this.http
      .get(this.apiUrlLsV1 + 'burn_rules/burn_ratio')
      .toPromise();
  }

  getCardDetails(number){
    const httpOptions = {
      headers: new HttpHeaders({
        'X-API-Client':  'test_client',
        'X-API-Client-Token': '12345',
      })
    }
    let url = 'https://rewardshub-stage-api-customer.poshvine.com/api/v1/payments/get_card_details?card_num='+number;
    // let url = this.apiUrl+'payments/get_card_details?card_num='+number; // 'https://rewardshub-stage-api-customer.poshvine.com/api/v1/payments/get_card_details?card_num='+number;

    return this.http.get(url, httpOptions)
  }

  getPaymentDetails(bookingId) {
    return this.http
      .get(this.apiUrl + 'payments/' + bookingId)
      .toPromise();
  }

  getRedemptionInquiry() {
    return this.http
    .get(this.v2ApiUrl + '/users/redemption_inquiry');
  }

}
