import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/common/api/api.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FlightsService {
  apiUrl: string = environment.v2ApiBs + '/'; // flight_searches/create_flight_search'https://rewards-stage-api-customer.poshvine.com/api/v1/'
  stagingUrl: string = environment.stagingUrl; // flight_searches/create_flight_search'https://rewards-stage-api-customer.poshvine.com/api/v1/'

  constructor(public api: ApiService) {}

  // tslint:disable-next-line: variable-name
  getAirports(searchquery, top_cities?: boolean) {
    let supplier_name =
      localStorage.getItem('flights_merchant') === ''
        ? 'cleartrip'
        : localStorage.getItem('flights_merchant');
    return this.api.get(
      this.apiUrl +
        `airports?search_for=${searchquery}` +
        `&supplier_name=yatra` +
        (top_cities ? '&top_cities=true' : '')
    );
  }

  getBookings() {
    return this.api
      .get(this.apiUrl + '/bookings?type=FlightItinerary')
      .toPromise();
  }

  cancelBooking(bookingUniqueId) {
    return this.api
      .post(this.apiUrl + 'flight_itineraries/cancel_trip', {
        uniq_id: bookingUniqueId,
        cancel_type: 'full',
      })
      .toPromise();
  }

  cancelRequestConcierge(bookingUniqueId, reason) {
    return this.api
      .post(this.apiUrl + 'bookings/request_cancellation', {
        booking_id: bookingUniqueId,
        reason: reason,
      })
      .toPromise();
  }

  createFlightSearch(searchParams) {
    let supplier_name = 'yatra'

    let a = { supplier_name: supplier_name };

    searchParams = { ...searchParams, ...a };

    return this.api
      .post(
        this.apiUrl + `flight_searches/create_and_fetch_flight_results`,
        searchParams
      )
      .toPromise();
  }

  createSearchItenrary(params) {
    let supplier_name = 'yatra'
    let a = { supplier_name: supplier_name };
    params = { ...params, ...a };
    return this.api
      .post(
        this.apiUrl + `flight_search_results/create_search_itinerary`,
        params
      )
      .toPromise();
  }

  createFlightItenrary(params) {
    let supplier_name = 'yatra'
    return this.api
      .get(
        this.apiUrl +
          `flight_itineraries/create_itinerary?id=${params}` +
          `&supplier_name=${supplier_name}`
      )
      .toPromise();
  }

  priceCheck(params) {

    let supplier_name = 'yatra'
    let a = { supplier_name: supplier_name };
    params = { ...params, ...a };
    return this.api
      .post(this.apiUrl + `flight_itineraries/price_check`, params)
      .toPromise();
  }

  bookItenrary(params) {
    let supplier_name = 'yatra'

    return this.api
      .get(
        this.apiUrl +
          `flight_itineraries/book_itinerary?itinerary_id=${params}` +
          `&supplier_name=${supplier_name}`
      )
      .toPromise();
  }

  payForFlight(params) {
    return this.api
      .post(this.apiUrl + `payments/create_payment_request`, params)
      .toPromise();
  }

  getNationalities() {
    return this.api
      .get(environment.v2ApiBsInternal + '/country_details/get_nationalities')
      .toPromise();
  }

  getCountries() {
    return this.api
      .get(environment.v2ApiBsInternal + '/country_details/get_countries')
      .toPromise();
  }

  getError() {
    return this.api
      .get(
        this.apiUrl +
          'dineout/search_restaurants?city_name=bangalore&arr_locality[]=Hennur&arr_locality[]=Hebbal'
      )
      .toPromise();
  }

  viewItenraryDetails(itineraryId) {
    return this.api
      .get(this.apiUrl + `flight_itineraries/view_trip?id=${itineraryId}`)
      .toPromise();
  }

  getBookingsById(id) {
    return this.api
      .get(this.apiUrl + `bookings/${id}`)
      .toPromise();
  }
  public removeTimeZoneFromString(timeString: string): string {
		return timeString.split('+')[0];
	}
}
