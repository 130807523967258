import { Component, OnInit } from '@angular/core';
import { OfferService } from 'src/app/offers/services/offer/offer.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';

@Component({
  selector: 'app-offers-history-details',
  templateUrl: './offers-history-details.component.html',
  styleUrls: ['./offers-history-details.component.scss']
})
export class OffersHistoryDetailsComponent implements OnInit {
  offerDetails;
  bookingData;
  bookingId: string;
  progress: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private offerService: OfferService,
    public dialog: MatDialog,
    public login: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.bookingId = this.route.snapshot.queryParamMap.get("id");
          this.offerService.getBookingDetailsById(this.bookingId).then(resp => {
            this.bookingData = resp['data'];
            this.getOfferDetails();
          })
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          };
        });
      });
  }

  getOfferDetails() {
    this.offerService.getOfferById(this.bookingData.attributes.booking_data.offer_id).then((res: any) => {
      if (res.data) {
        this.offerDetails = res.data;
      }
      this.progress = false;
    });
  }

  openBookingDetails(bookingId) {
    this.router.navigate(['/booking/offers'], { queryParams: { 'id': bookingId } });
  }

  moveUp() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  back(){
    history.back();
  }

}
