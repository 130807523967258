import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { NatGeoHistoryComponent } from './nat-geo-history/nat-geo-history.component';
import { HistoryContainerComponent } from './history-container/history-container.component';
import { FlightsHistoryDetailsComponent } from './flights-history-details/flights-history-details.component';
import { NatGeoHistoryDetailsComponent } from './nat-geo-history-details/nat-geo-history-details.component';
import { GiftingHistoryDetailsComponent } from './gifting-history/gifting-history-details/gifting-history-details.component';
import { DocsappHistoryDetailsComponent } from './docsapp-history/docsapp-history-details/docsapp-history-details.component';
import { ConciergeServicesHistoryDetailsComponent } from './concierge-services-history-details/concierge-services-history-details.component';
import { OffersHistoryDetailsComponent } from './offers-history/offers-history-details/offers-history-details.component';
import { DiningHistoryDetailsComponent } from './dining-history-details/dining-history-details.component';
import { AirportConHistoryDetailsComponent } from './airport-con-history/airport-con-history-details/airport-con-history-details.component';
import { EventHistoryDetailsComponent } from './events-history/event-history-details/event-history-details.component';
import { PointsTransferHistoryDetailsComponent } from './points-transfer-history-details/points-transfer-history-details.component';

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: HistoryContainerComponent },
  { path: 'flights', component: FlightsHistoryDetailsComponent },
  { path: 'nat-geo/:booking_id', component: NatGeoHistoryDetailsComponent },
  { path: 'gifting', component: GiftingHistoryDetailsComponent },
  { path: 'doctor-consultation', component: DocsappHistoryDetailsComponent },
  { path: 'concierge-services', component: ConciergeServicesHistoryDetailsComponent },
  { path: 'offers', component: OffersHistoryDetailsComponent },
  { path: 'airport-concierge', component: AirportConHistoryDetailsComponent },
  { path: 'dining', component: DiningHistoryDetailsComponent },  
  { path: 'events-concerts', component: EventHistoryDetailsComponent },
  { path: 'points-transfer', component: PointsTransferHistoryDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingHistoryRoutingModule { }
