<div *ngIf="!progress">
  <div class="container mar-bot-20 to-show1">
    <div class="pad-top-60">
      <div class="d-none d-sm-block">
        <div class="mar-bot-3 pointer d-flex align-items-center" (click)="back()">
          <div class="mar-top-2">
            <span class="mar-left-5 fnt-14 color-primary">
              Back
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center" style="padding: 10px 0;">
          <div class="flex flex-column  pad-top-10 pad-bot-10">
            <div>
              <span class="ft-wt-400 fnt-18">
                <span *ngIf="booking?.attributes.booking_data.category === 'EverythingElse'">Other Concierge
                  Services</span>
                <span *ngIf="booking?.attributes.booking_data.category === 'LuxuryShopping'">Premium Services</span>
                <span *ngIf="booking?.attributes.booking_data.category === 'BookingsAndSpecialEventAccess'">Book an
                  Event</span>
                <span *ngIf="booking?.attributes.booking_data.category === 'EventManagement'">Organise an Event</span>
              </span>
            </div>
          </div>
          <div>
            <!-- <span class="enquiry-status">{{booking.attributes.status | removeUnderscore}}</span> -->
            <app-status-label [status]="booking.attributes.status"></app-status-label>
          </div>
        </div>
      </div>
      <div class="pad-top-10 pad-bot-10 bg d-block d-sm-none">
        <div class=" flex">
          <div class="col-1 pad-top-16" (click)="back()">
            <span class="icon-left-arrow"></span>
          </div>
          <div class="col-8 pad-top-14">
            <div class="f500 fnt-16 pad-bot-5">
              <span *ngIf="booking?.attributes.booking_data.category === 'EverythingElse'">Other Concierge
                Services</span>
              <span *ngIf="booking?.attributes.booking_data.category === 'LuxuryShopping'">Premium Services</span>
              <span *ngIf="booking?.attributes.booking_data.category === 'BookingsAndSpecialEventAccess'">Book an
                Event</span>
              <span *ngIf="booking?.attributes.booking_data.category === 'EventManagement'">Organise an Event</span>
            </div>
          </div>
        </div>
      </div>

      <div class="booking-confirmed-container">
        <div>
          <div class="trip-details-bar flex justify-content-between pad-top-10 pad-bot-10 pad-left-20 pad-right-20">
            <span class="fnt-16">Booking Id: <span
                class="ft-wt-400">{{booking.attributes.unique_id}}</span></span>
            <span class="print-button" (click)="convertMyWay()"> <span class="fnt-14">
                <span class="icon-print"></span>
                Print </span>
            </span>
          </div>
          <div
            class="trip-details-bar flex justify-content-between align-center pad-top-10 pad-bot-10 pad-left-20 pad-right-20">
            <span class="fnt-16"> Booking Details</span>
            <span class="p-0 d-block d-sm-none">
              <app-status-label class="status-text flex" [status]="booking.attributes.status"></app-status-label>
            </span>
          </div>
          <!-- <hr> -->
          <div class="trip-details-body">
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Contact Name</span><span
                class="fnt-14">{{booking?.attributes.booking_data.user_pack.name}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Email Address</span><span
                class="fnt-14">{{booking?.attributes.booking_data.user_pack.email}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Mobile Number</span><span
                class="fnt-14">{{booking?.attributes.booking_data.user_pack.mobile}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Preferred Contact Time</span><span
                class="fnt-14">{{booking?.attributes.booking_data.user_pack.available_at}}</span>
            </div>
          </div>
          <div class="trip-details-bar flex justify-content-between pad-top-10 pad-bot-10 pad-left-20 pad-right-20">
            <span class="fnt-16">Other Details</span>
          </div>
          <div class="trip-details-body" *ngIf="booking?.attributes.booking_data.category === 'EventManagement'">
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Event Type</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Event Type']}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Budget</span><span class="fnt-14"
                [innerHTML]="booking?.attributes.booking_data.form_data.Budget" style="font-family: sans-serif;"></span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Guests</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data.Guests}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Event Date</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data.Date}}</span>
            </div>
            <hr>
          </div>
          <div class="trip-details-body"
            *ngIf="booking?.attributes.booking_data.category === 'BookingsAndSpecialEventAccess'">
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Event Name</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Event Name']}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Location</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Location']}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between align-items-center">
              <span class="color-grey">Date & Time</span><span class="fnt-14"
                [innerHTML]="booking?.attributes.booking_data.form_data['Date & Time']"
                style="font-family: sans-serif;"></span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Event Status</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Event Status']}}</span>
            </div>
            <hr>
          </div>
          <div class="trip-details-body" *ngIf="booking?.attributes.booking_data.category === 'LuxuryShopping'">
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Item Type</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Item Type']}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between align-items-center">
              <span class="color-grey">Description</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Description']}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Recipient Details</span>
              <!-- <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Event Name']}}</span> -->
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Name</span>
              <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Recipient'].name}}</span>
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Mobile</span>
              <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Recipient'].mobile}}</span>
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">City</span>
              <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Recipient'].city}}</span>
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Address</span>
              <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Recipient'].address}}</span>
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Pincode</span>
              <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Recipient'].pincode}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Delivery Timeline</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Delivery Timeline']}}</span>
            </div>
            <hr>
          </div>
          <div class="trip-details-body" *ngIf="booking?.attributes.booking_data.category === 'EverythingElse'">
            <div class="pad-top-10 pad-bot-10 flex justify-content-between">
              <span class="color-grey">Requirement</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Requirement']}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between"
              *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
              <span class="color-grey">Vendor Details</span>
              <!-- <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Event Name']}}</span> -->
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between"
              *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
              <span class="color-grey">Name</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].name}}</span>
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between"
              *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
              <span class="color-grey">Mobile</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].mobile}}</span>
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between"
              *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
              <span class="color-grey">City</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].city}}</span>
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between"
              *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
              <span class="color-grey">Address</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].address}}</span>
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between"
              *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
              <span class="color-grey">Pincode</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].pincode}}</span>
            </div>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between"
              *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes' && booking?.attributes.booking_data.form_data['Vendor Details'].special_request">
              <span class="color-grey">Special Request</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].special_request}}</span>
            </div>
            <hr>
            <div class="pad-top-10 pad-bot-10 flex justify-content-between align-items-center">
              <span class="color-grey">Fulfillment Timeline</span><span
                class="fnt-14">{{booking?.attributes.booking_data.form_data['Fulfillment Timeline']}}</span>
            </div>
            <hr>
          </div>
          <div *ngIf="booking.attributes.status == 'payment_link_sent'"
            class="bg pad-top-10 pad-bot-10 pad-left-20 pad-right-20 fnt-14">
            <span class="color-grey">
              Payment link has been sent to you, check you email {{booking.email}} or your mobile {{booking.mobile}}
            </span>
            <br>
            <span>or</span>
            <br>
            <span class="bluify pointer" (click)="resendPaymentLink()">Resend payment link</span>
          </div>
          <div class="bg fare-breakup flex align-center pad-left-15 fnt-16"
            *ngIf="booking.attributes.status == 'booking_confirmed'">Fare Breakup</div>

          <div class="trip-details-body" *ngIf="booking.attributes.status == 'booking_confirmed'">
            <div class="sidebox">
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Base Fare</span><span class="fnt-14"><span
                    class="rupee-symbol mar-right-3">₹</span>{{booking?.attributes.booking_data.fare_breakup?.base | indianCurrency}}</span>
              </div>
              <hr>
              <div class="pad-top-15">
                <div class="desktop-labels mar-bot-8 font-13 d-flex align-items-center justify-content-between">
                  <div>
                    Tax
                  </div>
                  <div class="fnt-14">
                    <span class="rupee-symbol mar-right-3">+
                      ₹</span><span>{{booking?.attributes.booking_data.fare_breakup?.taxes| indianCurrency}}</span>
                  </div>
                </div>
              </div>
              <hr>

              <div class="pad-top-15">
                <div class="desktop-labels mar-bot-8 font-13 d-flex align-items-center justify-content-between">
                  <div>
                    Convenience Fees<br><span class="fnt-12">(inclusive of GST)</span>
                  </div>
                  <div class="fnt-14">
                    <span class="rupee-symbol mar-right-3">+
                      ₹</span><span>{{booking?.attributes.booking_data.fare_breakup?.convenience_fee| indianCurrency}}</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="pad-top-15">
                <div class="desktop-labels mar-bot-8 font-13 d-flex align-items-center justify-content-between">
                  <div class="ft-wt-400">
                    Total Amount
                  </div>
                  <div class="fnt-14">
                    <span
                      class="rupee-symbol mar-right-3">₹</span><span>{{booking?.attributes.booking_data.fare_breakup?.price| indianCurrency}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <ng-container *ngIf="booking.attributes.status == 'booking_confirmed'">
            <div class="bg fare-breakup flex align-center pad-left-15 fnt-16">Cancellation</div>

            <div class="trip-details-body">
              <div class="sidebox cancel-strip">
                <button mat-button class="primary-btn cancellation-button" (click)="cancelBooking()">Request
                  Cancellation
                </button>
              </div>
            </div>
          </ng-container>

        </div>
      </div>

    </div>
  </div>
  <div class="container mar-bot-20 to-hide1">
    <div style="border:1px solid var(--primary); border-radius:8px">
      <div class="checkout-message-top d-flex align-items-center justify-content-between header-bg mar-bot-25">
        <img class="img-fluid rewards-hub-header-logo pad-right-8" style="width: 200px;"
          src="assets/sbic-images/logo.png">
      </div>
      <div class="">
        <div class="d-none d-sm-block">
          <div class="mar-bot-3 pointer d-flex align-items-center" (click)="back()" style="padding: 0 15px;">
            <div class="mar-top-2">
              <span class="mar-left-5 fnt-14 color-primary">
                Back
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center" style="padding: 10px 20px;">
            <div class="flex flex-column  pad-top-10 pad-bot-10">
              <div>
                <span class="ft-wt-400 fnt-18">
                  <span *ngIf="booking?.attributes.booking_data.category === 'EverythingElse'">Other Concierge
                    Services</span>
                  <span *ngIf="booking?.attributes.booking_data.category === 'LuxuryShopping'">Premium Services</span>
                  <span *ngIf="booking?.attributes.booking_data.category === 'BookingsAndSpecialEventAccess'">Book an
                    Event</span>
                  <span *ngIf="booking?.attributes.booking_data.category === 'EventManagement'">Organise an Event</span>
                </span>
              </div>
            </div>
            <div>
              <!-- <span class="enquiry-status">{{booking.attributes.status | removeUnderscore}}</span> -->
              <app-status-label [status]="booking.attributes.status"></app-status-label>
            </div>
          </div>
        </div>
        <div class="pad-top-10 pad-bot-10 bg d-block d-sm-none">
          <div class=" flex">
            <div class="col-1 pad-top-16" (click)="back()">
              <span class="icon-left-arrow"></span>
            </div>
            <div class="col-8 pad-top-14">
              <div class="f500 fnt-16 pad-bot-5">
                <span *ngIf="booking?.attributes.booking_data.category === 'EverythingElse'">Other Concierge
                  Services</span>
                <span *ngIf="booking?.attributes.booking_data.category === 'LuxuryShopping'">Premium Services</span>
                <span *ngIf="booking?.attributes.booking_data.category === 'BookingsAndSpecialEventAccess'">Book an
                  Event</span>
                <span *ngIf="booking?.attributes.booking_data.category === 'EventManagement'">Organise an Event</span>
              </div>
            </div>
            <div class="col-3 p-0">
              <app-status-label class="status-text flex" [status]="booking.attributes.status"></app-status-label>
            </div>
          </div>
        </div>

        <div class="booking-confirmed-container">
          <div>
            <div class="trip-details-bar flex justify-content-between pad-top-10 pad-bot-10 pad-left-20 pad-right-20">
              <span class="d-none d-sm-block"> Booking Details</span>
              <span class="fnt-16">Booking Id: <span
                  class="ft-wt-400">{{booking.attributes.unique_id}}</span></span>

              <span class="print-button d-sm-none" (click)="convertMyWay()"> <span class="fnt-14">
                  <span class="icon-print"></span>
                  Print </span>
              </span>
            </div>
            <!-- <hr> -->
            <div class="trip-details-body">
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Contact Name</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.user_pack.name}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Email Address</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.user_pack.email}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Mobile Number</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.user_pack.mobile}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Preferred Contact Time</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.user_pack.available_at}}</span>
              </div>
            </div>
            <div class="trip-details-bar flex justify-content-between pad-top-10 pad-bot-10 pad-left-20 pad-right-20">
              <span class="fnt-16">Other Details</span>
            </div>
            <div class="trip-details-body" *ngIf="booking?.attributes.booking_data.category === 'EventManagement'">
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Event Type</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Event Type']}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Budget</span><span class="fnt-14"
                  [innerHTML]="booking?.attributes.booking_data.form_data.Budget"
                  style="font-family: sans-serif;"></span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Guests</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data.Guests}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Event Date</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data.Date}}</span>
              </div>
              <hr>
            </div>
            <div class="trip-details-body"
              *ngIf="booking?.attributes.booking_data.category === 'BookingsAndSpecialEventAccess'">
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Location</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Location']}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Event Name</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Event Name']}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between align-items-center">
                <span class="color-grey">Date & Time</span><span class="fnt-14"
                  [innerHTML]="booking?.attributes.booking_data.form_data['Date & Time']"
                  style="font-family: sans-serif;"></span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Event Status</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Event Status']}}</span>
              </div>
              <hr>
            </div>
            <div class="trip-details-body" *ngIf="booking?.attributes.booking_data.category === 'LuxuryShopping'">
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Item Type</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Item Type']}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Recipient Details</span>
                <!-- <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Event Name']}}</span> -->
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Name</span>
                <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Recipient'].name}}</span>
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Mobile</span>
                <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Recipient'].mobile}}</span>
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">City</span>
                <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Recipient'].city}}</span>
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Address</span>
                <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Recipient'].address}}</span>
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Pincode</span>
                <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Recipient'].pincode}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between align-items-center">
                <span class="color-grey">Description</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Description']}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Delivery Timeline</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Delivery Timeline']}}</span>
              </div>
              <hr>
            </div>
            <div class="trip-details-body" *ngIf="booking?.attributes.booking_data.category === 'EverythingElse'">
              <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                <span class="color-grey">Requirement</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Requirement']}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between"
                *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
                <span class="color-grey">Vendor Details</span>
                <!-- <span class="fnt-14">{{booking?.attributes.booking_data.form_data['Event Name']}}</span> -->
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between"
                *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
                <span class="color-grey">Name</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].name}}</span>
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between"
                *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
                <span class="color-grey">Mobile</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].mobile}}</span>
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between"
                *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
                <span class="color-grey">City</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].city}}</span>
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between"
                *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
                <span class="color-grey">Address</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].address}}</span>
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between"
                *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes'">
                <span class="color-grey">Pincode</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].pincode}}</span>
              </div>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between"
                *ngIf="booking?.attributes.booking_data.form_data['Vendor'] == 'Yes' && booking?.attributes.booking_data.form_data['Vendor Details'].special_request">
                <span class="color-grey">Special Request</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Vendor Details'].special_request}}</span>
              </div>
              <hr>
              <div class="pad-top-10 pad-bot-10 flex justify-content-between align-items-center">
                <span class="color-grey">Fulfillment Timeline</span><span
                  class="fnt-14">{{booking?.attributes.booking_data.form_data['Fulfillment Timeline']}}</span>
              </div>
              <hr>
            </div>
            <div *ngIf="booking.attributes.status == 'payment_link_sent'"
              class="bg pad-top-10 pad-bot-10 pad-left-20 pad-right-20 fnt-14">
              <span class="color-grey">
                Payment link has been sent to you, check you email {{booking.email}} or your mobile {{booking.mobile}}
              </span>
              <br>
              <span>or</span>
              <br>
              <span class="bluify pointer" (click)="resendPaymentLink()">Resend payment link</span>
            </div>
            <div class="bg fare-breakup flex align-center pad-left-15 fnt-16"
              *ngIf="booking.attributes.status == 'booking_confirmed'">Fare Breakup</div>

            <div class="trip-details-body" *ngIf="booking.attributes.status == 'booking_confirmed'">
              <div class="sidebox">
                <div class="pad-top-10 pad-bot-10 flex justify-content-between">
                  <span class="color-grey">Base Fare</span><span class="fnt-14"><span
                      class="rupee-symbol mar-right-3">₹</span>{{booking?.attributes.booking_data.fare_breakup?.base}}</span>
                </div>
                <hr>
                <div class="pad-top-15">
                  <div class="desktop-labels mar-bot-8 font-13 d-flex align-items-center justify-content-between">
                    <div>
                      Tax
                    </div>
                    <div class="fnt-14">
                      <span class="rupee-symbol mar-right-3">+
                        ₹</span><span>{{booking?.attributes.booking_data.fare_breakup?.taxes| indianCurrency}}</span>
                    </div>
                  </div>
                </div>
                <hr>

                <div class="pad-top-15">
                  <div class="desktop-labels mar-bot-8 font-13 d-flex align-items-center justify-content-between">
                    <div>
                      Convenience Fees<br><span class="fnt-12">(inclusive of GST)</span>
                    </div>
                    <div class="fnt-14">
                      <span class="rupee-symbol mar-right-3">+
                        ₹</span><span>{{booking?.attributes.booking_data.fare_breakup?.convenience_fee| indianCurrency}}</span>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="pad-top-15">
                  <div class="desktop-labels mar-bot-8 font-13 d-flex align-items-center justify-content-between">
                    <div class="ft-wt-400">
                      Total Amount
                    </div>
                    <div class="fnt-14">
                      <span
                        class="rupee-symbol mar-right-3">₹</span><span>{{booking?.attributes.booking_data.fare_breakup?.price| indianCurrency}}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<app-loader *ngIf="progress"></app-loader>