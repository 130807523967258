<div *ngIf="!progress; else progressblock">
  <div *ngIf="!bookings?.length" class="no-rooms">
    No bookings found !
  </div>

  <div class="nat-geo-item d-block d-sm-none"
    *ngFor="let booking of bookings | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }"
    (click)="openBookingDetails(booking.attributes.booking_unique_id)">
    <div class="d-flex justify-content-between align-items-center pointer">
      <div class="d-flex justify-content-start align-items-center">
        <div class="mar-right-10">
          <img height=35 width=35 src="assets/designers/booking-history-icon.png">

        </div>
        <div>
          <div class="nat-geo-item-title">
            {{booking?.attributes.booking_data.designer_data.experience_name}}</div>
          <div class="nat-geo-item-details">
            {{booking?.attributes.booking_data.offer_data.offer_name}}
          </div>
        </div>
      </div>
      <div class="status-text">
        <div>
          <app-status-label class="status-text"
            [status]="getStatus(booking.attributes?.booking_data?.booking_date_time, booking.attributes.status)">
          </app-status-label>
        </div>
      </div>
    </div>
    <hr>
  </div>
  <div class="nat-geo-item d-none d-sm-block"
    *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }">
    <div class="bg">
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-start align-items-center w80">
          <div class="mar-right-15">
            <img height=35 width=35 src="assets/designers/booking-history-icon.png">

          </div>
          <div>
            <div>
              <span class="hotel_name">
                {{booking?.attributes.booking_data.designer_data.experience_name}}
              </span>
            </div>
            <div>
              <span class="from-to">
                {{booking?.attributes.booking_data.offer_data.offer_name}}
              </span>
            </div>
          </div>
        </div>
        <div class="status-text">
          <div>
            <app-status-label class="status-text"
              [status]="getStatus(booking.attributes?.booking_data?.booking_date_time, booking.attributes.status)">
            </app-status-label>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="bg">
      <div class="d-flex justify-content-between">
        <div class="booking-id-detail">
          <div>
            <span>Order ID : </span>
            <span class="id">{{booking.attributes?.booking_unique_id}}</span>
          </div>
        </div>
        <div>
          <!-- <span class="cancel-booking pointer fnt-14" *ngIf="booking.attributes.status == 'booking_confirmed'" (click)="cancelBooking(booking,
                        booking?.attributes.booking_data.booking_date_time,booking?.attributes.amount)">Request
            Cancellation</span>
          <span *ngIf="booking.attributes.status == 'booking_confirmed'" class="pad-left-8 pad-right-8 pipe">|</span>

          <span class="cancel-ticket pointer" (click)="openBookingDetails(booking.attributes.booking_unique_id)">
            View Details
          </span> -->
          <!-- <span>
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 10.5L5.5 6L1.5 1.5" stroke="#C2A63F" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span> -->
        </div>
      </div>
    </div>
    <hr>
    <!-- {{booking.attributes.booking_data.offer_data.redemption_mode}} -->
    <div class="d-none d-sm-block desktop-body"
      *ngIf="booking.attributes.booking_data.offer_data.redemption_mode=='enquiry';else elseblock">
      <span class="fnt-14" style="color:var(--accenttwo)">You will soon receive a call from the Poshvine’s Customer Support
        Team to discuss about your preferences and
        Meeting details.</span>
    </div>
    <ng-template #elseblock>
      <div class="d-none d-sm-block desktop-body">
        <div class="flex">
          <div class="coupon-code">
            <span #couponCode>{{booking.attributes.booking_data.coupon_code}}</span>
            <span class="pointer pad-left-10" (click)="copyText(couponCode)"><svg width="14" height="16"
                viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.3158 0H1.47368C0.663158 0 0 0.654545 0 1.45455V11.6364H1.47368V1.45455H10.3158V0ZM9.57895 2.90909H4.42105C3.61053 2.90909 2.95474 3.56364 2.95474 4.36364L2.94737 14.5455C2.94737 15.3455 3.60316 16 4.41368 16H12.5263C13.3368 16 14 15.3455 14 14.5455V7.27273L9.57895 2.90909ZM4.42105 14.5455V4.36364H8.8421V8H12.5263V14.5455H4.42105Z"
                  fill="#C2A63F" />
              </svg></span>
          </div>
          <div class="redeem-button mar-left-10">
            <button class="primary-btn pad-right-34 pad-left-34 height-45" mat-button
              (click)="redeemNow(booking.attributes.booking_data.designer_data.website_url)"><span
                class="pad-right-34 pad-left-34">Redeem Now</span></button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="d-flex justify-content-center w100 my-5">
    <pagination-controls *ngIf="bookings?.length>10" responsive="true" (pageChange)="pageNo = $event;">
    </pagination-controls>
  </div>
</div>
<ng-template #progressblock>
  <div class="d-flex justify-content-center w100 my-5">
    <div class="dot-flashing" *ngIf="progress"></div>
  </div>
</ng-template>
