import { Component, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { appConstants } from "src/app/app.constants";
import { PointsTransferService } from "../points-transfer.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-booking-confirmed",
  templateUrl: "./booking-confirmed.component.html",
  styleUrls: ["./booking-confirmed.component.scss"],
})
export class BookingConfirmedComponent {
  public appConstants = appConstants;
  public isSuccess: boolean = true;
  public showLoader: boolean = true;
  public transferDetails: any;
  public isMobile: boolean = true;
  public filteredBanners: any[] = [];
  private bannerItems: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private ptsService: PointsTransferService,
    private toastr: ToastrService,
    private router: Router
  ) {
    const transferId = this.route.snapshot.paramMap.get("transfer_id");
    this.fetchTransferDetails(transferId);
    this.fetchBanners();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 768;
    this.filterBanners();
  }

  public handleRedirection(isSuccess) {
    if (isSuccess) {
      this.router.navigate([`points-transfer`, "booking-details"], {
        queryParams: { id: this.transferDetails?.id },
      });
    } else {
      this.router.navigate([
        "points-transfer",
        "details",
        this.transferDetails?.attributes?.points_provider?.id,
      ]);
    }
  }

  public navigateToHome() {
    this.router.navigate(["points-transfer"]);
  }

  private fetchTransferDetails(transferId: string) {
    this.showLoader = true;
    this.ptsService.getTransferDetails(transferId).subscribe((res: any) => {
      this.showLoader = false;
      this.isSuccess =
        res?.data?.attributes?.status === "success" ||
        res?.data?.attributes?.status === "initiated" ||
        res?.data?.attributes?.status === "in_progress";
      this.transferDetails = res?.data;
    }),
      (err: any) => {
        console.log("err", err);
        this.toastr.error("Something went wrong.");
        this.showLoader = true;
      };
  }

  private fetchBanners() {
    this.ptsService.getBanners().subscribe((res: any) => {
      this.bannerItems = res?.data;
      this.filterBanners();
    })
  }

  private filterBanners() {
    this.filteredBanners = [];
    this.bannerItems.forEach((banner) => {
       let bannerCode = banner.attributes.code;
       if ((bannerCode.includes('mobile') && this.isMobile)) {
        return this.filteredBanners.push(banner);
       } else if ((!bannerCode.includes('mobile') && !this.isMobile)) {
        return this.filteredBanners.push(banner);
       }
      }
    );
  }
}
