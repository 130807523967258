import { Directive, HostListener, HostBinding, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[credit-card]'
})
export class CreditCardDirective {
  @Input() cardType:string;

  @HostBinding('style.border')
  border: string;

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/\D+/g, '');
    if (trimmed.length > 16) {
      trimmed = trimmed.substr(0, 16);
    }

    let numbers = [];
    console.log(this.cardType)
    if(this.cardType == 'DINR'){
      if(trimmed.length<=4)
          numbers.push(trimmed.substr(0,4));
      else if(trimmed.length>4 && trimmed.length <= 10){
        numbers.push(trimmed.substr(0,4));
        numbers.push(trimmed.substr(4,6));        
      }else{
        numbers.push(trimmed.substr(0,4));
        numbers.push(trimmed.substr(4,6));
        numbers.push(trimmed.substr(10,4));
      }
    }else if(this.cardType == 'AMEX'){
      if(trimmed.length<=4)
          numbers.push(trimmed.substr(0,4));
      else if(trimmed.length>4 && trimmed.length <= 10){
        numbers.push(trimmed.substr(0,4));
        numbers.push(trimmed.substr(4,6));        
      }else{
        numbers.push(trimmed.substr(0,4));
        numbers.push(trimmed.substr(4,6));
        numbers.push(trimmed.substr(10,5));
      }
    }else{
      for (let i = 0; i < trimmed.length; i += 4) {
        numbers.push(trimmed.substr(i, 4));
      }
    }


    input.value = numbers.join(' ');

    this.border = '';
    if (/[^\d]+/.test(trimmed)) {
      this.border = '1px solid red';
    }

  }
}