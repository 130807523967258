import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-giftcard-home-container',
  templateUrl: './giftcard-home-container.component.html',
  styleUrls: ['./giftcard-home-container.component.scss']
})
export class GiftcardHomeContainerComponent implements OnInit {


  constructor() { }

  ngOnInit() {

  }

}
