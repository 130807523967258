<div *ngIf="!progress">
    <div *ngIf="!bookings.length" class="no-rooms">
        No bookings found !
    </div>
    <div class="d-block d-lg-none mob-sections">
        <div class="nat-geo-item"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }"
        (click)="openBookingDetails(booking.attributes.id)">
        <div class="d-flex justify-content-between align-items-center pointer">
            <div class="d-flex justify-content-start align-items-center">
                <div class="mar-right-10 mar-top-10">
                    <span class="icon-donation"></span>
                </div>
                <div class="">
                    <div class="nat-geo-item-title text-truncate">{{booking.attributes?.details?.attributes?.campaign_name}}</div>
                    <div class="nat-geo-item-details">
                        Donation: <span class="ft-wt-500"><span class="rupee-symbol">₹</span>
                            {{booking.attributes?.details?.attributes?.fare_breakup?.total}}</span>
                    </div>
                </div>
            </div>
            <div class="status-text">
                <div>
                    <app-status-label class="status-text" [status]="booking.attributes.status" [date]="booking.attributes?.confirmed_at"></app-status-label>
                </div>
            </div>
        </div>
    </div>
    </div>

    <div class="nat-geo-item d-none d-lg-block"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }">
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-start align-items-center w80">
                    <div class="mar-right-15">
                        <span class="icon-donation"></span>
                    </div>
                    <div>
                        <div>
                            <span class="hotel_name">
                                {{booking.attributes?.details?.attributes?.campaign_name}}
                            </span>
                        </div>
                        <div>
                            <span class="donation">
                                Donation : <span class="ft-wt-500"><span class="rupee-symbol">₹</span>
                                    {{booking.attributes?.details?.attributes?.fare_breakup?.total}}</span>

                            </span>
                        </div>
                    </div>
                </div>
                <div class="status-text">
                    <div>
                        <app-status-label class="status-text" [status]="booking.attributes.status" [date]="booking.attributes?.confirmed_at"></app-status-label>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="booking-id-detail">
                    <div *ngIf="booking?.attributes?.unique_id">
                        <span>Reference ID : </span>
                        <span class="id">{{booking?.attributes?.unique_id}}</span>
                    </div>
                </div>
                <div class="ft-wt-400 cancel-ticket pointer"
                    (click)="openBookingDetails(booking.attributes.id)">
                    View Details
                    <span class="icon-angle-right"></span>
                </div>
            </div>
        </div>
        <hr>
        <div class="adfasdas">
            <div class="desktop-body d-flex align-items-center justify-content-between">
                <div class="check-in-out">
                    Donation Amount
                </div>
                <div class="check-in-out-txt">
                    <span class="rupee-symbol mar-right-4">₹</span>
                    <span>{{booking.attributes?.details?.attributes?.fare_breakup?.total | indianCurrency}} </span>
                </div>
            </div>
            <hr>
            <div class="desktop-body d-flex align-items-center justify-content-between">
                <div class="check-in-out">
                    Date of Donation
                </div>
                <div class="check-in-out-txt">
                    {{getDate(booking.attributes?.confirmed_at)}}
                </div>
            </div>
            <hr>
            <div class="desktop-body d-flex align-items-center justify-content-between">
                <div class="check-in-out">
                    <div class="name">Booked by {{booking.attributes?.details?.attributes?.user_pack?.first_name}} {{booking.attributes?.details?.attributes?.user_pack?.last_name}}</div>
                    <div class="sub-detail">
                        Phone : <span class="sub-detail-val">{{booking.attributes?.details?.attributes?.user_pack?.mobile}}</span> |
                        Email :
                        <span class="sub-detail-val">{{booking.attributes?.details?.attributes?.user_pack?.email}}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="d-flex justify-content-center w100 my-5">
        <pagination-controls *ngIf="bookings.length>10" responsive="true" (pageChange)="pageNo = $event; moveUp();">
        </pagination-controls>
    </div>
</div>
<div class="d-flex justify-content-center w100 my-5">
    <div class="dot-flashing" *ngIf="progress"></div>
</div>