<div class="d-xl-none d-lg-none d-md-none d-flex align-items-center fnt-16 pad-top-10 pad-bot-10 relative">
  <div
    (click)="back()"
    class="d-flex justify-content-start align-items-center pointer mar-left-20 mar-right-20 absolute" style="left:5px;">
    <div class="mar-bot-3">
      <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.469669 5.46967ZM17 5.25L1 5.25V6.75L17 6.75V5.25Z"
          fill="#C2A63F" />
      </svg>
    </div>
  </div>
  <span class="ft-wt-500 fnt-16 gold" style="margin: auto;">Booking Details</span>
</div>
<hr class="d-xl-none d-lg-none d-md-none">
<div class="d-none d-sm-none d-md-block back-container">
  <div class="blueify mar-bot-10 d-flex justify-content-start align-items-center pointer" (click)="back()"
    style="width: max-content;">
    <div class="mar-bot-3">
      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM13 5.25L1 5.25V6.75L13 6.75V5.25Z"
          fill="#C2A63F" />
      </svg>
    </div>
    <div class="mar-left-10 fnt-14 primary-color">
      Back
    </div>
  </div>
</div>
<div class="booking-details-container" *ngIf="!progress">
  <div class="booking-card d-flex align-items-center">
    <img
      class="merchant-logo img-fluid"
      [src]="
        transferDetails?.attributes?.points_provider?.logo ||
        appConstants.FALLBACK_IMAGE
      "
    />
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center">
        <div
          class="d-flex transfer-points align-items-center ellipsis"
          [ngClass]="{
            success: statusData === transferStatus.SUCCESS,
            initiated: statusData === transferStatus.INITIATED,
            failure: statusData === transferStatus.FAILURE
          }"
        >
          <span class="points-value">{{
            transferDetails?.attributes?.points
          }}</span>
          <span class="points-currency">{{
            transferDetails?.attributes?.points_provider?.currency_name
          }}</span>
        </div>
        <svg
          *ngIf="statusData === transferStatus.SUCCESS"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.31317 0.873691C7.37099 0.325796 8.62901 0.325796 9.68683 0.873691C10.0431 1.05824 10.4273 1.18307 10.8241 1.2432C12.0019 1.42171 13.0197 2.16116 13.5534 3.22619C13.7332 3.58493 13.9706 3.91173 14.2563 4.19358C15.1042 5.03031 15.493 6.22677 15.2988 7.40212C15.2334 7.79802 15.2334 8.20198 15.2988 8.59788C15.493 9.77323 15.1042 10.9697 14.2563 11.8064C13.9706 12.0883 13.7332 12.4151 13.5534 12.7738C13.0197 13.8388 12.0019 14.5783 10.8241 14.7568C10.4273 14.8169 10.0431 14.9418 9.68683 15.1263C8.62901 15.6742 7.37099 15.6742 6.31317 15.1263C5.95686 14.9418 5.57267 14.8169 5.17594 14.7568C3.9981 14.5783 2.98033 13.8388 2.44658 12.7738C2.2668 12.4151 2.02936 12.0883 1.74374 11.8064C0.895774 10.9697 0.507022 9.77323 0.701217 8.59788C0.766629 8.20198 0.766629 7.79802 0.701217 7.40212C0.507022 6.22677 0.895773 5.03031 1.74374 4.19358C2.02936 3.91173 2.2668 3.58493 2.44658 3.22619C2.98033 2.16116 3.9981 1.42171 5.17594 1.2432C5.57267 1.18307 5.95686 1.05824 6.31317 0.873691Z"
            fill="#008659"
          />
          <path
            d="M4.55553 8.6672L6.9046 11L11.4444 6.49163C11.6589 6.27863 11.6595 5.93189 11.4458 5.71813C11.2331 5.5054 10.8884 5.50479 10.6749 5.71677L6.9046 9.46098L5.3223 7.89401C5.1099 7.68367 4.76763 7.68391 4.55553 7.89454C4.34111 8.10747 4.34111 8.45427 4.55553 8.6672Z"
            fill="white"
          />
        </svg>
        <svg
          *ngIf="statusData === transferStatus.FAILURE"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.31317 0.873691C7.37099 0.325796 8.62901 0.325796 9.68683 0.873691C10.0431 1.05824 10.4273 1.18307 10.8241 1.2432C12.0019 1.42171 13.0197 2.16116 13.5534 3.22619C13.7332 3.58493 13.9706 3.91173 14.2563 4.19358C15.1042 5.03031 15.493 6.22677 15.2988 7.40212C15.2334 7.79802 15.2334 8.20198 15.2988 8.59788C15.493 9.77323 15.1042 10.9697 14.2563 11.8064C13.9706 12.0883 13.7332 12.4151 13.5534 12.7738C13.0197 13.8388 12.0019 14.5783 10.8241 14.7568C10.4273 14.8169 10.0431 14.9418 9.68683 15.1263C8.62901 15.6742 7.37099 15.6742 6.31317 15.1263C5.95686 14.9418 5.57267 14.8169 5.17594 14.7568C3.9981 14.5783 2.98033 13.8388 2.44658 12.7738C2.2668 12.4151 2.02936 12.0883 1.74374 11.8064C0.895774 10.9697 0.507022 9.77323 0.701217 8.59788C0.766629 8.20198 0.766629 7.79802 0.701217 7.40212C0.507022 6.22677 0.895773 5.03031 1.74374 4.19358C2.02936 3.91173 2.2668 3.58493 2.44658 3.22619C2.98033 2.16116 3.9981 1.42171 5.17594 1.2432C5.57267 1.18307 5.95686 1.05824 6.31317 0.873691Z"
            fill="#D13821"
          />
          <path
            d="M5.90454 9.46081L5.54962 9.80017C5.33571 10.0047 5.32172 10.3418 5.51795 10.5633C5.72779 10.8003 6.0937 10.812 6.31827 10.589L10.4444 6.49147C10.6588 6.27846 10.6595 5.93173 10.4457 5.71796C10.233 5.50524 9.88831 5.50463 9.67486 5.7166L5.90454 9.46081Z"
            fill="white"
          />
          <path
            d="M6.70537 6.0712L6.36601 5.71629C6.16146 5.50237 5.82442 5.48838 5.60285 5.68461C5.36591 5.89445 5.35419 6.26036 5.57721 6.48494L9.67471 10.611C9.88771 10.8255 10.2345 10.8261 10.4482 10.6124C10.6609 10.3997 10.6615 10.055 10.4496 9.84152L6.70537 6.0712Z"
            fill="white"
          />
        </svg>
        <svg
          *ngIf="statusData === transferStatus.INITIATED"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.31317 0.873691C7.37099 0.325796 8.62901 0.325796 9.68683 0.873691C10.0431 1.05824 10.4273 1.18307 10.8241 1.2432C12.0019 1.42171 13.0197 2.16116 13.5534 3.22619C13.7332 3.58493 13.9706 3.91173 14.2563 4.19358C15.1042 5.03031 15.493 6.22677 15.2988 7.40212C15.2334 7.79802 15.2334 8.20198 15.2988 8.59788C15.493 9.77323 15.1042 10.9697 14.2563 11.8064C13.9706 12.0883 13.7332 12.4151 13.5534 12.7738C13.0197 13.8388 12.0019 14.5783 10.8241 14.7568C10.4273 14.8169 10.0431 14.9418 9.68683 15.1263C8.62901 15.6742 7.37099 15.6742 6.31317 15.1263C5.95686 14.9418 5.57267 14.8169 5.17594 14.7568C3.9981 14.5783 2.98033 13.8388 2.44658 12.7738C2.2668 12.4151 2.02936 12.0883 1.74374 11.8064C0.895774 10.9697 0.507022 9.77323 0.701217 8.59788C0.766629 8.20198 0.766629 7.79802 0.701217 7.40212C0.507022 6.22677 0.895773 5.03031 1.74374 4.19358C2.02936 3.91173 2.2668 3.58493 2.44658 3.22619C2.98033 2.16116 3.9981 1.42171 5.17594 1.2432C5.57267 1.18307 5.95686 1.05824 6.31317 0.873691Z"
            fill="#BD7A03"
          />
          <path
            d="M6.96663 11.6667C6.96663 12.219 7.41435 12.6667 7.96663 12.6667C8.51892 12.6667 8.96663 12.219 8.96663 11.6667L8.96663 8.33341C8.96663 7.78113 8.51892 7.33341 7.96663 7.33341C7.41435 7.33341 6.96663 7.78113 6.96663 8.33341L6.96663 11.6667Z"
            fill="#101010"
          />
          <path
            d="M8 4.00008C7.44772 4.00008 7 4.4478 7 5.00008C7 5.55237 7.44772 6.00008 8 6.00008C8.55229 6.00008 9 5.55237 9 5.00008C9 4.4478 8.55229 4.00008 8 4.00008Z"
            fill="#101010"
          />
        </svg>
      </div>
      <span class="merchant-title-points"
        >{{ transferDetails?.attributes?.points_provider?.name }} Points</span
      >
    </div>
    <div class="flex-fill"></div>
    <div class="d-none d-lg-block d-md-block">
      <ng-container [ngTemplateOutlet]="bookingActionBtn"> </ng-container>
    </div>
  </div>
  <div class="booking-details-top">
    <div class="d-flex align-items-center">
      <span class="sub-details-heading">Transferred to</span>
      <span class="sub-details-value">{{
        transferDetails?.attributes?.user_points_provider_account?.account_id
      }}</span>
    </div>
    <div class="d-flex align-items-center">
      <span class="sub-details-heading">Transfer ID</span>
      <span class="sub-details-value">{{
        transferDetails?.attributes?.unique_id
      }}</span>
    </div>
    <div class="d-flex align-items-center">
      <span class="sub-details-heading">Created Date</span>
      <span class="sub-details-value">{{
        transferDetails?.attributes?.created_at | date : "MMM d, y"
      }}</span>
    </div>
    <div
      class="d-flex align-items-center"
      *ngIf="transferDetails?.attributes?.confirmed_at"
    >
      <span class="sub-details-heading">Confirmation Date</span>
      <span class="sub-details-value">{{
        transferDetails?.attributes?.confirmed_at | date : "MMM d, y"
      }}</span>
    </div>
    <!-- <div class="d-flex align-items-center">
      <span class="sub-details-heading">Validity</span>
      <span class="sub-details-value">NULL</span>
    </div> -->
  </div>
  <div class="d-md-none d-lg-none">
    <ng-container [ngTemplateOutlet]="bookingActionBtn"> </ng-container>
  </div>
  <div class="details-heading">Transfer details</div>
  <div class="transfer-details-container details-container">
    <div class="d-flex align-items-center justify-content-between">
      <span>AURUM Reward points used : </span>
      <span class="points-used-heading">{{
        transferDetails?.attributes?.amount /
          transferDetails?.attributes?.burn_ratio
      }}</span>
    </div>
    <div class="points-divider"></div>
    <span *ngIf="!providerDetailsLoading; else loaderBlock"
      >{{ providerDetails?.conversion_ratio }}
      Reward points = 1
      {{ transferDetails?.attributes?.points_provider?.currency_name }}</span
    >
  </div>
  <div class="details-heading">Terms & Conditions</div>
  <div class="transfer-details-container details-container">
    <app-read-more
      *ngIf="!providerDetailsLoading && providerDetails?.tnc; else loaderBlock"
      description="{{ providerDetails?.tnc }}"
    ></app-read-more>
  </div>

</div>
<div
  class="back-to-home-container d-lg-none d-md-none cursor"
  *ngIf="!progress"
  (click)="navigateToHome()"
>
  <button
    class="back-to-home-cta d-flex align-items-center justify-content-center"
  >
    Go to home
  </button>
</div>
<app-loader *ngIf="progress"></app-loader>
<ng-template #loaderBlock>
  <div class="d-flex align-items-center justify-content-center w100">
    <div class="dot-flashing"></div>
  </div>
</ng-template>

<ng-template #bookingActionBtn>
  <div
    class="booking-action-btn d-flex align-items-center justify-content-center cursor"
    (click)="handleRedirection(statusData === transferStatus.SUCCESS)"
    *ngIf="
      statusData === transferStatus.SUCCESS ||
      statusData === transferStatus.FAILURE
    "
  >
    <span class="action-title">{{
      statusData === transferStatus.SUCCESS ? "Redeem now" : "Try Again"
    }}</span>
    <svg
      *ngIf="statusData === transferStatus.SUCCESS"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 2.5C4.22386 2.5 4 2.72386 4 3C4 3.27614 4.22386 3.5 4.5 3.5H7.79289L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L8.5 4.20711V7.5C8.5 7.77614 8.72386 8 9 8C9.27614 8 9.5 7.77614 9.5 7.5V3C9.5 2.72386 9.27614 2.5 9 2.5H4.5Z"
        fill="#C2A63F"
      />
    </svg>
  </div>
</ng-template>
