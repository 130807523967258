import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "src/app/services/login/login.service";
import { DataService } from "src/app/services/common/data/data.service";

@Component({
  selector: "app-auth-token",
  templateUrl: "./auth-token.component.html",
  styleUrls: ["./auth-token.component.scss"],
})
export class AuthTokenComponent implements OnInit {
  theParams;
  constructor(private route: ActivatedRoute,
    public router: Router,
    public dataService: DataService,

     private login: LoginService) {}

  ngOnInit() {
    console.log(this.route.snapshot.queryParamMap.get("token"));
    // localStorage.setItem(
    //   "token",
    //   this.route.snapshot.queryParamMap.get("token")
    // );
    console.log(this.route.snapshot.queryParamMap.get("token"))
    //
    this.login.sbiValidate().subscribe( (res:any) =>{
      if(res.success){
        this.dataService.triggerToastr('success',"You are logged in.");
        localStorage.setItem('auth_token', res.token);
        // this.dialogRef.close('true');
        this.dataService.triggerRefreshHeader();
        location.assign('/')
        // this.router.navigateByUrl('/');
      }else{
        this.dataService.triggerToastr('error',"Unable to login. Please try again.");
        // this.dialogRef.close('true');
      }
    });

    this.theParams = JSON.stringify(this.route.snapshot.queryParamMap);
  }
}
