import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import * as _moment from 'moment';
import { LoginService } from 'src/app/services/login/login.service';
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
const moment = _moment;
import { DiningService } from "src/app/dining/services/dining.service";
import swal from "sweetalert2";

@Component({
  selector: 'app-dining-history',
  templateUrl: './dining-history.component.html',
  styleUrls: ['./dining-history.component.scss']
})
export class DiningHistoryComponent implements OnInit {
  bookings;
  progress : boolean = true;
  pageNo: number = 1;
  itemsPerPage = 10;
  constructor(
    public dialog: MatDialog,
    public login: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private diningService : DiningService,
  ) { }

  ngOnInit() {
    this.login
      .getUserDetails()
      .toPromise()
    .then((result:any) => {
      if(result && result.id){
        this.diningService.getBookinghistory().then( resp => {
          this.bookings = resp['data'];
          // this.numDays = moment(this.checkoutDate).diff(moment(this.checkinDate), 'days');
          this.progress = false;
        })
      }else{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe( res=> {
          if(res == 'true'){
            this.ngOnInit();
          }else if(res =='redirect'){
            history.back();
          };
        });
      }
    });
  }

  cancelOpt(bookingId){
    swal
    .fire({
      imageUrl: "assets/modal-info.png",
      imageHeight: 60,
      title: 'Are you sure you want to cancel this reservation?',
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    })
    .then(result => {
      if (result.value) {
        this.progress = true;
        let booking ={
          booking_unique_id : bookingId
        }
        this.diningService.cancelBooking(booking).then( res => {
          if (res['status'] === "cancelled") {
            swal.fire({
              imageUrl: "assets/modal-info.png",
              imageHeight: 60,
              title: "Cancellation Successful",
              html: '<p>' + 'Your restaurant reservation has been cancelled successfully.' + '</p>',
            }).then(res => {
              this.ngOnInit();
            })
          } else {
            swal.fire({
              imageUrl: "assets/modal-error.png",
              imageHeight: 60,
              title: "Cancellation Request Failed",
              html: '<p>' + 'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
            }).then(res => {
              this.ngOnInit();
            })
          }
        })
      }
    });
}

  getStatus(date, cur_status){
    console.log(date, cur_status)
    if(this.checkIfUpcoming(date) && cur_status === 'booking_confirmed'){
      return 'upcoming';
    }else if(this.checkIfCompleted(date) && cur_status === 'booking_confirmed'){ 
      return 'booking_completed';
    }else{
      return cur_status;
    }
  }

  openBookingDetails(bookingId){
    this.router.navigate([`booking`, 'dining'], { queryParams: { 'id': bookingId}});
  }

  checkIfUpcoming(date){
    return moment(date).isAfter(moment().startOf('day'))
  }

  checkIfCompleted(date){
    return moment(date).isBefore(moment().startOf('day'))
  }

  moveUp(){
    window.scrollTo({top : 0, left : 0, behavior : 'smooth'});
  }

}
