import { Component, OnInit } from '@angular/core';
import { ToursActivitiesService } from 'src/app/tours-activities/services/tours-activities/tours-activities.service';
import * as _moment from 'moment';
import { Router } from "@angular/router";
import { LoginService } from './../../services/login/login.service';
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import { MatDialog } from "@angular/material/dialog";
import swal from "sweetalert2";
const moment = _moment;

@Component({
  selector: 'app-tours-history',
  templateUrl: './tours-history.component.html',
  styleUrls: ['./tours-history.component.scss']
})
export class ToursHistoryComponent implements OnInit {
  bookings = [];
  numDays;
  pageNo: number = 1;
  itemsPerPage = 10;
  progress: boolean = true;

  constructor(
    private toursActivitiesService: ToursActivitiesService,
    public dialog: MatDialog,
    public login: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.toursActivitiesService.getBookings().then(resp => {
            this.bookings = resp['data'];
            console.log(this.bookings.length)
            this.bookings = this.bookings.filter(each => each.attributes.status != 'booking_failed');
            console.log(this.bookings.length)

            this.progress = false;
          })
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            };
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          };
        });
      });
  }

  openBookingDetails(bookingId) {
    this.router.navigate(['tours-and-activities', 'booking-details', bookingId]);
  }

  moveUp() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  getStatus(date, cur_status) {
    if (this.checkIfUpcoming(date) && cur_status === 'booking_confirmed') {
      return 'upcoming';
    } else if (this.checkIfCompleted(date) && cur_status === 'booking_confirmed') {
      return 'booking_completed';
    } else {
      return cur_status;
    }
  }

  checkIfUpcoming(date) {
    return moment(date).isAfter(moment().startOf('day'))
  }

  checkIfCompleted(date) {
    return moment(date).isBefore(moment().startOf('day'))
  }

  getDate(booking) {
    if (booking && booking.attributes.details.attributes.response_data.startDateTime) {
      let day = ''
      switch (moment(booking.attributes.details.attributes.response_data.startDateTime).day()) {
        case 0: day = 'Sunday'; break;
        case 1: day = 'Monday'; break;
        case 2: day = 'Tuesday'; break;
        case 3: day = 'Wednesday'; break;
        case 4: day = 'Thursday'; break;
        case 5: day = 'Friday'; break;
        case 6: day = 'Saturday'; break;
      }

      return day + ', ' + moment(booking.attributes.details.attributes.response_data.startDateTime).format('MMM DD, YYYY');
    }
    return '-';
  }

  getTime(booking) {
    if (booking && booking.attributes.details.attributes.response_data.startDateTime) {
      return moment(booking.attributes.details.attributes.response_data.startDateTime).format('h:mm A');
    }
    return '-';
  }

  getPerson(booking) {
    if (booking
      && booking.attributes
      && booking.attributes.details
      && booking.attributes.details  
      && booking.attributes.details.attributes
      && booking.attributes.details.attributes.response_data
      && booking.attributes.details.attributes.response_data.customersDetails
      && booking.attributes.details.attributes.response_data.customersDetails.customers
      && booking.attributes.details.attributes.response_data.customersDetails.count) {
      return booking.attributes.details.attributes.response_data.customersDetails.count;
    } else {
      return '-';
    }
  }

  cancelBooking(booking) {
    let cancellationMessage = '<p><span class="icon-ballon mar-right-5 fnt-14"></span>' + booking.attributes.details.attributes.response_data.product.name + '</p>' +
      '<p><span class="icon-group-1 mar-right-5 fnt-14"></span> Date : ' + this.getDate(booking) +
      '<p><span class="icon-clock mar-right-5 fnt-14"></span> Time : ' + this.getTime(booking) +
      '<p><span class="icon-users mar-right-5 fnt-14"></span>' + this.getPerson(booking) + ' Person(s)';
    swal
      .fire({
        imageUrl: "assets/modal-info.png",
        imageHeight: 60,
        title: 'Are you sure you want to cancel this booking?',
        html: cancellationMessage,
        showCancelButton: true,
        input: 'textarea',
        inputPlaceholder: 'Reason...',
        inputAttributes: {
          'aria-label': 'Type your message here'
        },
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        preConfirm: (input) => {
          if (!input) {
            swal.showValidationMessage("Please enter reason for cancellation.");
            return false;
          } else {
            return input;
          }
        }
      })
      .then(result => {
        if (result.value) {
          this.progress = true;
          this.toursActivitiesService.cancelBookingRequest(booking.attributes.id, result.value).then((resp: any) => {
            if (resp['status'] === "success") {
              swal.fire({
                imageUrl: "assets/modal-info.png",
                imageHeight: 60,
                title: "Cancellation Requested",
                html: '<p>' + 'Cancellation request has been sent to <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            } else {
              swal.fire({
                imageUrl: "assets/modal-error.png",
                imageHeight: 60,
                title: "Cancellation Request Failed",
                html: '<p>' + 'Something went wrong. Please try again or contact <a href="mailto:aurumrewards@poshvine.com" style="color: #C2A63F;">aurumrewards@poshvine.com.</a>' + '</p>',
              }).then(res => {
                this.ngOnInit();
              })
            }
          });
        }
      });


  }

}
