  <div class="d-block d-sm-none pad-left-15 pad-right-15" *ngIf="!progress" >
    <div *ngIf="!bookings?.length" class="no-rooms">
      No bookings found !
    </div>
    <div class="" *ngFor="let booking of bookings">
      <div *ngIf="booking.attributes.status=='booking_confirmed'"
        class=" nat-geo-item d-flex justify-content-between w100 align-items-center" (click)='viewDetails(booking.attributes.id,booking.attributes.status,booking.attributes.details.attributes.airlines)'>
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" style="min-width: 35px;">
          <circle cx="17.5" cy="17.5" r="17.5" fill="#C2A63F" />
          <path
            d="M24.5762 10.4238C24.0029 9.85051 23.0705 9.86009 22.5091 10.4451L19.4363 13.6471L11.9374 11.1655L10.3633 12.7396L16.6138 16.5882L13.4511 19.8839L11.4228 19.551L10 20.9738L12.9818 22.0182L14.0262 25L15.449 23.5772L15.1161 21.5489L18.4118 18.3862L22.2604 24.6367L23.8345 23.0625L21.3529 15.5637L24.5549 12.4909C25.1399 11.9295 25.1495 10.9971 24.5762 10.4238Z"
            fill="#252525" />
        </svg>
        <div class=" d-flex flight-details align-items-center">

          <div class="fnt-14 pad-left-10 pad-right-15">
            <span
              class="fnt-14 f500">{{booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_airport}}</span>
            <svg *ngIf="!booking.attributes.details.attributes.itinerary_response?.flights[1]" width="16" height="6"
              viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                fill="#ffffff" />

            </svg>
            <svg *ngIf="booking.attributes.details.attributes.itinerary_response?.flights[1]" width="17" height="10"
              viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM1 4.5L16 4.5V3.5L1 3.5L1 4.5Z"
                fill="#ffffff" />
              <path
                d="M0.646445 11.6464C0.451183 11.8417 0.451183 12.1583 0.646445 12.3536L3.82843 15.5355C4.02369 15.7308 4.34027 15.7308 4.53553 15.5355C4.73079 15.3403 4.73079 15.0237 4.53553 14.8284L1.70711 12L4.53553 9.17157C4.73079 8.97631 4.73079 8.65973 4.53553 8.46447C4.34027 8.2692 4.02369 8.2692 3.82843 8.46447L0.646445 11.6464ZM16 11.5L0.999999 11.5V12.5L16 12.5V11.5Z"
                fill="#ffffff" />
            </svg>

            <span
              class="fnt-14 f500">{{booking.attributes.details.attributes.itinerary_response?.flights[0].segments[onwardSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[0].segments)].arrival_airport}}</span>
            <div class="fnt-14 f300">
              {{flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_date)|date:"MMM d,y"}}
              <svg *ngIf="booking.attributes.details.attributes.itinerary_response?.flights[1]" width="17" height="10"
                viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM1 4.5L16 4.5V3.5L1 3.5L1 4.5Z"
                  fill="#ffffff" />
                <path
                  d="M0.646445 11.6464C0.451183 11.8417 0.451183 12.1583 0.646445 12.3536L3.82843 15.5355C4.02369 15.7308 4.34027 15.7308 4.53553 15.5355C4.73079 15.3403 4.73079 15.0237 4.53553 14.8284L1.70711 12L4.53553 9.17157C4.73079 8.97631 4.73079 8.65973 4.53553 8.46447C4.34027 8.2692 4.02369 8.2692 3.82843 8.46447L0.646445 11.6464ZM16 11.5L0.999999 11.5V12.5L16 12.5V11.5Z"
                  fill="#ffffff" />
              </svg>
              <span
                *ngIf="booking.attributes.details.attributes.itinerary_response?.flights[1]">{{flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[1].segments[onwardSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[0].segments)+1].departure_date)|date:"MMM d, y"}}</span>

            </div>
          </div>
        </div>

        <app-status-label class="status-text"
          [status]="getStatus(flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_date), booking.attributes.status)">
        </app-status-label>

      </div>

    </div>
  </div>
  <div class="d-none d-sm-block" *ngIf="!progress">
    <div *ngIf="!bookings?.length" class="no-rooms">
      No bookings found !
    </div>
    <div *ngFor="let booking of bookings">
      <div class="background-white" *ngIf="booking.attributes.details && booking.attributes.details.attributes.itinerary_response">
        <div class="fnt-16 ft-wt-400 bg w100 flex">
          <div class="flex align-center"><svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17.5" cy="17.5" r="17.5" fill="#C2A63F" />
              <path
                d="M24.5762 10.4238C24.0029 9.85051 23.0705 9.86009 22.5091 10.4451L19.4363 13.6471L11.9374 11.1655L10.3633 12.7396L16.6138 16.5882L13.4511 19.8839L11.4228 19.551L10 20.9738L12.9818 22.0182L14.0262 25L15.449 23.5772L15.1161 21.5489L18.4118 18.3862L22.2604 24.6367L23.8345 23.0625L21.3529 15.5637L24.5549 12.4909C25.1399 11.9295 25.1495 10.9971 24.5762 10.4238Z"
                fill="#252525" />
            </svg>
            <span
              class="pad-left-20">{{booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_city_name}}
              <svg *ngIf="!booking.attributes.details.attributes.itinerary_response?.flights[1]" width="16" height="6"
                viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                  fill="#ffffff" />

              </svg>
              <svg *ngIf="booking.attributes.details.attributes.itinerary_response?.flights[1]" width="17" height="10"
                viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM1 4.5L16 4.5V3.5L1 3.5L1 4.5Z"
                  fill="#ffffff" />
                <path
                  d="M0.646445 11.6464C0.451183 11.8417 0.451183 12.1583 0.646445 12.3536L3.82843 15.5355C4.02369 15.7308 4.34027 15.7308 4.53553 15.5355C4.73079 15.3403 4.73079 15.0237 4.53553 14.8284L1.70711 12L4.53553 9.17157C4.73079 8.97631 4.73079 8.65973 4.53553 8.46447C4.34027 8.2692 4.02369 8.2692 3.82843 8.46447L0.646445 11.6464ZM16 11.5L0.999999 11.5V12.5L16 12.5V11.5Z"
                  fill="#ffffff" />
              </svg>
              {{booking.attributes.details.attributes.itinerary_response?.flights[0].segments[onwardSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[0].segments)].arrival_city_name}}</span>
          </div>
          <app-status-label class="status-text"
            [status]="getStatus(flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_date), booking.attributes.status)">
          </app-status-label>
        </div>
        <div class="bag-bar flex fnt-16 align-center">
          <span class="ft-wt-400">Booking ID: <span class="ft-wt-500">{{booking.attributes.unique_id}}</span></span>
          <span class="mar-left-auto">
            <!-- <span class="cancel-booking pointer"
              *ngIf="booking.attributes.status == 'booking_confirmed' && getStatus(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_date, booking.attributes.status) !='booking_completed' && booking.attributes.details.attributes.supplier_name == 'cleartrip'"
              (click)="cancelBooking(booking)">Cancel booking</span>
            <span class="cancel-booking pointer"
              *ngIf="booking.attributes.status == 'booking_confirmed' && getStatus(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_date, booking.attributes.status) !='booking_completed'"
              (click)="cancelBooking(booking)">Request Cancellation</span>
            <span
              *ngIf="booking.attributes.status == 'booking_confirmed'  && getStatus(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_date, booking.attributes.status) !='booking_completed'"
              class="pad-left-8 pad-right-8 pipe">|</span> -->
            <span class="primary-color pointer"
              (click)='viewDetails(booking.attributes.id,booking.attributes.status,booking.attributes.details.attributes.airlines)'><span
                class="pad-right-8">View Details</span><svg width="7" height="12" viewBox="0 0 7 12" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 10.5L5.5 6L1.5 1.5" stroke="#C2A63F" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
          </span>
          <!-- <span class="sec-color fnt-14 pad-left-40">Download Ticket</span> -->
        </div>
        <div>
          <div>
            <div>
              <div class="pad-left-20 pad-right-20 d-flex flight-details align-items-center">
                <div>
                  <div>
                    <div>
                      <img width="20" height="20"
                        src="{{getAirlineImage(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].airline,booking.attributes.details.attributes.airlines)}}">
                    </div>
                  </div>
                </div>
                <div class="pad-left-8 pad-right-30">
                  <div class="fnt-16 grey">
                    {{getAirlineName(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].airline,booking.attributes.details.attributes.airlines)}}
                  </div>
                  <div class="fnt-14 port">
                    {{booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].airline}}-{{booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].flight_number}}
                  </div>
                </div>
                <div class="air-info flex pad-top-30 pad-bot-30">
                  <div class="l-info">
                    <div class="fnt-16 tim pad-bot-7">
                      {{booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_airport}}
                      <b>{{flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_date)|date:"HH:mm"}}</b>
                    </div>
                    <div class="fnt-14 port pad-bot-5">
                      {{flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].departure_date)|date:"EEE, MMM d"}}
                    </div>
                    <!-- <div class="fnt-14 port">
                  {{booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1].airport_name}}
                </div> -->
                  </div>
                  <div class="m-info f500 fnt-14 text-center">
                    <div class="pad-bot-2 mar-bot-5 color-grey">
                      {{getFlightTime(booking.attributes.details.attributes.itinerary_response?.flights[0].segments, booking.attributes.details.attributes.supplier_name, booking.attributes.details.attributes?.flight_results[0]?.total_duration)}}
                    </div>
                    <p class="m-0 color-greyone via-text">
                      {{booking.attributes.details.attributes.itinerary_response?.flights[0].segments[1]?.stops}}</p>
                  </div>
                  <div class="l-info text-right">
                    <div class="fnt-16 tim pad-bot-7">
                      <b>{{flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[onwardSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[0].segments)]['arrival-date'])|date:"HH:mm"}}</b>
                      {{booking.attributes.details.attributes.itinerary_response?.flights[0].segments[onwardSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[0].segments)].arrival_airport}}
                    </div>
                    <div class="fnt-14 port pad-bot-5">
                      {{flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[0].segments[onwardSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[0].segments)]['arrival-date'])|date:'EEE, MMM d'}}
                    </div>
                  </div>


                </div>
                <!-- <div class="pad-left-10 names">
          <div> PNR: {{getPNR(0)}}</div> <div>Ticket Number: {{getTicket(0)}}</div>
        </div> -->
              </div>
              <!-- <div class="bag-bar flex fnt-12 align-center">
        <div class="bag-indi text-right">
          <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.8 2.31579H9V1.15789C9 0.515263 8.466 0 7.8 0H4.2C3.534 0 3 0.515263 3 1.15789V2.31579H1.2C0.534 2.31579 0 2.83105 0 3.47368V9.8421C0 10.4847 0.534 11 1.2 11H10.8C11.466 11 12 10.4847 12 9.8421V3.47368C12 2.83105 11.466 2.31579 10.8 2.31579ZM4.2 1.15789H7.8V2.31579H4.2V1.15789ZM10.8 9.8421H1.2V8.68421H10.8V9.8421ZM10.8 6.94737H1.2V3.47368H3V4.63158H4.2V3.47368H7.8V4.63158H9V3.47368H10.8V6.94737Z"
              fill="#64707C" />
          </svg>
          <span class="b-txt relative">Cabin bag - 7kg
            {{itenraryDetails.flight_itinerary['baggage-allowances'][1][0][0.departure_airport+'_'+0.arrival_airport].cab}}
          </span>
        </div>
        <div class="divide-line text-center">
          <svg width="1" height="18" viewBox="0 0 1 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1" height="18" fill="#64707C" />
          </svg>
        </div>
        <div class="bag-indi">
          <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.55405 0.410149C7.43691 0.385731 7.31752 0.365523 7.19573 0.349232C6.66768 0.279528 6.0964 0.279309 5.49964 0.279309C5.32289 0.279309 5.14823 0.27832 4.97646 0.27832C4.59693 0.27832 4.2307 0.283263 3.88248 0.314819C3.76309 0.325692 3.64638 0.340226 3.53151 0.358238C1.82773 0.623469 0.638672 1.76515 0.638672 6.62751C0.638672 8.755 1.26629 9.64972 2.26312 10.0221V10.1426V10.2628V10.7935C2.26312 10.9076 2.35556 11.0001 2.46978 11.0001H3.35029C3.46451 11.0001 3.55702 10.9076 3.55702 10.7935V10.4851V10.373V10.2604C3.83675 10.2778 4.12988 10.2829 4.43403 10.2829C4.77687 10.2829 5.13362 10.2767 5.49942 10.2767C6.16691 10.2767 6.80269 10.3216 7.3817 10.3216C7.42523 10.3216 7.46832 10.3211 7.51097 10.3207V10.4332V10.5453V10.7935C7.51097 10.9076 7.60326 11.0001 7.71762 11.0001H8.59825C8.71236 11.0001 8.80491 10.9076 8.80491 10.7935V10.3745V10.2639V10.1408C9.76117 9.81131 10.3604 8.92033 10.3604 6.62751C10.3608 2.01556 9.21156 0.754932 7.55405 0.410149ZM5.49986 1.89266C7.19704 1.89266 8.57273 1.89266 8.57273 7.93159H2.42698C2.42698 1.89266 3.80278 1.89266 5.49986 1.89266Z"
              fill="#64707C" />
          </svg>
          <span class="b-txt relative">Checkin bag - 15kg
            {{itenraryDetails.flight_itinerary['baggage-allowances'][1][0][0.departure_airport+'_'+0.arrival_airport].cib}}
          </span>
        </div>
      </div> -->

            </div>
          </div>
        </div>
        <div *ngIf="booking.attributes.details.attributes.itinerary_response?.flights[1]">
          <div>
            <div>
              <div class="pad-left-20 pad-right-20 d-flex flight-details align-items-center">
                <div>
                  <div>
                    <div>
                      <img width="20" height="20"
                        src="{{getAirlineImage(booking.attributes.details.attributes.itinerary_response?.flights[1].segments[returnSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)].airline,booking.attributes.details.attributes.airlines)}}">
                    </div>
                  </div>

                </div>
                <div class="pad-left-8 pad-right-30">
                  <div class="fnt-16 grey">
                    {{getAirlineName(booking.attributes.details.attributes.itinerary_response?.flights[1].segments[returnSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)].airline,booking.attributes.details.attributes.airlines)}}
                  </div>
                  <div class="fnt-14 port">
                    {{booking.attributes.details.attributes.itinerary_response?.flights[1].segments[returnSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)].airline}}-{{booking.attributes.details.attributes.itinerary_response?.flights[1].segments[returnSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)].flight_number}}
                  </div>
                </div>
                <div class="air-info flex pad-top-30 pad-bot-30">
                  <div class="l-info">
                    <div class="fnt-16 tim pad-bot-7">
                      {{booking.attributes.details.attributes.itinerary_response?.flights[1].segments[returnSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)].departure_airport}}
                      <b>{{flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[1].segments[returnSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)].departure_date)|date:"HH:mm"}}</b>
                    </div>
                    <div class="fnt-14 port pad-bot-5">
                      {{flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[1].segments[returnSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)].departure_date)|date:"EEE, MMM d"}}
                    </div>
                  </div>
                  <div class="m-info f500 fnt-14 text-center">
                    <div class="pad-bot-2 mar-bot-5 color-grey">
                      {{getFlightTime(booking.attributes.details.attributes.itinerary_response?.flights[1].segments, booking.attributes.details.attributes.supplier_name, booking.attributes.details.attributes?.flight_results[1]?.total_duration)}}
                    </div>
                    <p class="m-0 color-greyone via-text">
                      {{booking.attributes.details.attributes.itinerary_response?.flights[1].segments[returnSegmentLength(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)]?.stops}}
                  </div>
                  <div class="l-info text-right">
                    <div class="fnt-16 tim pad-bot-7">
                      <b>{{flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[1].segments[ returnSegmentLegth(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)]['arrival-date'])|date:"HH:mm"}}</b>
                      {{booking.attributes.details.attributes.itinerary_response?.flights[1].segments[returnSegmentLegth(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)].arrival_airport}}
                    </div>
                    <div class="fnt-14 port pad-bot-5">
                    {{flightS.removeTimeZoneFromString(booking.attributes.details.attributes.itinerary_response?.flights[1].segments[returnSegmentLegth(booking.attributes.details.attributes.itinerary_response?.flights[1].segments)]['arrival-date'])|date:'EEE, MMM d'}}
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <app-flights-history-details [booking]='bookings[0]'></app-flights-history-details> -->
  <div class="d-flex justify-content-center w100 my-5">
    <div class="dot-flashing" *ngIf="progress"></div>
  </div>
