<div class="custom-card" (click)="navigateToDetailPage()">
    <div class="custom-card-img">
        <img loading="lazy" default="assets/default_image.png" [src]="event?.media?.media_asset[0].secure_complete_url" class="zoom">
    </div>

    <div class="card-content">
        <p class="category-name">{{getClasses()}}</p>
        <p class="heading">{{event.event_desc}}</p>
        <p class="sub-heading">{{event.venue_desc}}</p>

        <div style="display: flex;">
            <div class="rating col-md-8">
                <div *ngIf="getRating()>=0.5 && getRatingCount()>=1" class="rating-div">
                    <span class="value">{{getRating()}}</span>
                    <span style="padding: 0px 5px;">
                        <img class="star" src="assets/Star.png" *ngFor="let item of getNoOfStars(getRating())">
                    </span>
                    <span class="noOfRatings">({{getRatingCount()}})</span>
                </div>
            </div>
            <div class="amount col-md-4" *ngIf="event?.cost_range">
                <div class="from">From</div>
                <div class="val">
                    <span class="rupee-symbol mar-right-4">₹</span>
                    <span>{{getValue() | indianCurrency}}</span>
                </div>

            </div>

        </div>
    </div>
</div>