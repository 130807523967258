import { NgModule } from "@angular/core";
import { PointsInputBoxComponent } from "./points-input-box.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
  imports:[CommonModule, FormsModule, SharedModule],
  declarations:[PointsInputBoxComponent],
  exports:[PointsInputBoxComponent]
})
export class PointsInputBoxModule {
  constructor(){}
}
