import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/common/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class EventsConcertsService {
  baseUrl: string = environment.apiUrl;
  apiUrl: string = this.baseUrl + 'ingresso';
  stagingUrl = environment.stagingUrl;

  constructor(private api: ApiService) { }

  getCities() {
    return this.api.get(this.apiUrl + '/locations').toPromise();
  }


  getAutocomplete(city_id?) {
    return this.api.get(this.apiUrl + '/autocomplete' + (city_id ? ('?city_id=' + city_id) : '')).toPromise();
  }

  getCurrencyExchangeRates() {
    return this.api.get(this.baseUrl + 'exchange_rates').toPromise();
  }

  getEventsByCity(cityId) {
    return this.api.get(this.apiUrl + '/event_list?city_id=' + cityId).toPromise();
  }

  getEventDetails(id) {
    return this.api.get(this.apiUrl + '/' + id + '/event_details').toPromise();
  }


  getPerformancesByEvent(id, startDate?, endDate?) {
    return this.api.get(this.apiUrl + '/' + id + '/performance_list' + (startDate ? ('?date_range=' + startDate + ':' + endDate) : '')).toPromise();
  }

  checkPerformanceAvailabilty(perf_id) {
    return this.api.get(this.apiUrl + '/availability?perf_id=' + perf_id).toPromise();
  }

  reserve(id, data) {
    return this.api.post(this.apiUrl + '/' + id + '/reserve', data).toPromise();
  }

  addUserData(data) {
    return this.api.post(this.apiUrl + '/add_user_data', data).toPromise();
  }

  getBookingDetailsById(id) {
    return this.api.get(this.stagingUrl + `users/bookings/booking_details?booking_unique_id=${id}`).toPromise();
  }

  getBookings() {
    return this.api.get(this.stagingUrl + "/bookings?type=EventBooking").toPromise()
  }

}
