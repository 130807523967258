import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class DataSharingService {
  login;
  constructor() {
    this.login = this.isAuthenticated();
    console.log(this.login)
  }

  isAuthenticated() {
    let storage = localStorage;
    this.login = Boolean(localStorage.getItem("auth_token"));
    return this.login;
  }

  setLogin(loggedState) {
    this.login = loggedState;
  }

  getLogin() {
    return this.login();
  }
}
