import { Component, OnInit } from '@angular/core';
import { NatGeoService } from 'src/app/nat-geo/services/nat-geo/nat-geo.service';

@Component({
  selector: 'app-natgeo-home-container',
  templateUrl: './natgeo-home-container.component.html',
  styleUrls: ['./natgeo-home-container.component.scss']
})
export class NatgeoHomeContainerComponent implements OnInit {

  constructor(public natGeoS: NatGeoService) { }
  progress: boolean = true;
  filters;

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.natGeoS.getHomePageFilters().then((filters) => {
      this.filters = filters;
      this.progress = false;
    });
  }

}
