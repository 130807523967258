import { Component, OnInit } from '@angular/core';
import { CharitableDonationService } from 'src/app/charitable-giving/services/charitable-donation/charitable-donation.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-charity-history',
  templateUrl: './charity-history.component.html',
  styleUrls: ['./charity-history.component.scss']
})
export class CharityHistoryComponent implements OnInit {
  bookings = [];
  numDays;
  pageNo: number = 1;
  itemsPerPage = 10;
  progress: boolean = true;

  constructor(
    private charitableDonationService: CharitableDonationService,
    public dialog: MatDialog,
    public login: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.login
      .getUserDetails()
      // .toPromise()
    .subscribe((result:any) => {
        if (result && result.id) {
          this.charitableDonationService.getBookings().then(resp => {
            this.bookings = resp['data'];
            this.progress = false;
          })
        } else {
          this.dialog.open(SbicLoginComponent, {
            width: "450px",
            height: "70vh",
disableClose: true
          }).afterClosed().subscribe(res => {
            if (res == 'true') {
              this.ngOnInit();
            } else if (res == 'redirect') {
              history.back();
            }
          });
        }
      },
      (error)=>{
        this.dialog.open(SbicLoginComponent, {
          width: "450px",
          height: "70vh",
disableClose: true
        }).afterClosed().subscribe(res => {
          if (res == 'true') {
            this.ngOnInit();
          } else if (res == 'redirect') {
            history.back();
          }
        });
      });
  }

  getDate(date) {
    return moment(date).format('MMM DD, YYYY');
  }

  openBookingDetails(bookingId) {
    this.router.navigate(['charitable-donations', 'booking-details', bookingId]);
  }

  moveUp() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

}
