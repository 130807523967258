<div class="history-container d-flex flex-column" *ngIf="!progress">
  <div *ngIf="ObjectKeys(bookingsData)?.length === 0" class="no-rooms">
    No bookings found !
  </div>
  <ng-container *ngFor="let data of bookingsData | keyvalue: dateSorting">
    <span class="booking-dates font-md-16px mar-md-20-b">{{ data?.key }}</span>
    <div
      class="booking-data border-radius-md border-brand-md-1px pad-md-20px mar-md-20-b"
      *ngFor="let booking of data?.value"
      (click)="navigateToBookingDetails(booking?.id)"
    >
      <div
        class="d-flex align-items-center justify-content-between booking-data-top"
      >
        <span class="booking-id font-md-16px"
          >Transfer ID: {{ booking?.attributes?.unique_id }}</span
        >
        <div class="d-flex align-items-center">
          <span class="booking-id font-md-16px text-md-brand cursor">View Details</span>
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.88211 8.64142C8.96021 8.56331 8.96021 8.43668 8.88211 8.35858L6.1951 5.67157C5.93475 5.41122 5.93475 4.98911 6.1951 4.72876C6.45545 4.46841 6.87756 4.46841 7.13791 4.72876L10.202 7.79289C10.5926 8.18341 10.5926 8.81658 10.202 9.2071L7.13791 12.2712C6.87756 12.5316 6.45545 12.5316 6.1951 12.2712C5.93475 12.0109 5.93475 11.5888 6.1951 11.3284L8.88211 8.64142Z"
              fill="#C2A63F"
            />
          </svg>
        </div>
      </div>
      <div class="booking-card d-flex align-items-center pad-md-20-y">
        <img
          class="merchant-logo img-fluid"
          [src]="
            booking?.attributes?.points_provider?.logo ||
            appConstants.FALLBACK_IMAGE
          "
        />
        <div class="d-flex flex-column">
          <span class="merchant-title font-md-16px">{{
            booking?.attributes?.points_provider?.name
          }}</span>
          <span class="merchant-points font-md-16px"
            >{{ booking?.attributes?.points }}
            {{ booking?.attributes?.points_provider?.currency_name }} Points
          </span>
        </div>
        <div class="flex-fill"></div>
        <div class="d-flex flex-column">
          <div
            class="d-flex align-items-center justify-content-center booking-status capitalize"
          >
            <ng-container *ngIf="booking?.attributes?.status === 'success'">
              <svg
                class="booking-status-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_979_2863)">
                  <mask
                    id="mask0_979_2863"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="12"
                    height="12"
                  >
                    <g clip-path="url(#clip1_979_2863)">
                      <path
                        d="M5.24537e-07 6C8.1423e-07 2.68629 2.68629 -8.1423e-07 6 -5.24537e-07C9.31371 -2.34843e-07 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 2.34843e-07 9.31371 5.24537e-07 6Z"
                        fill="white"
                      />
                    </g>
                  </mask>
                  <g mask="url(#mask0_979_2863)">
                    <path
                      d="M12 12L0 12L1.04907e-06 -1.04907e-06L12 0L12 12Z"
                      fill="#008659"
                    />
                    <path
                      d="M3 6L5 8L9 4"
                      stroke="white"
                      stroke-width="1.3"
                      stroke-linecap="round"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_979_2863">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="translate(12 12) rotate(-180)"
                    />
                  </clipPath>
                  <clipPath id="clip1_979_2863">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="translate(12 12) rotate(-180)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span class="booking-status-text success-text font-md-12px">Confirmed</span>
            </ng-container>
            <ng-container
              *ngIf="
                booking?.attributes?.status === 'failed' ||
                booking?.attributes?.status === 'canceled'
              "
            >
              <svg
                class="booking-status-icon"
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.73488 1.15527C5.52824 0.744347 6.47176 0.744347 7.26512 1.15527C7.53236 1.29368 7.82049 1.3873 8.11805 1.4324C9.00143 1.56629 9.76475 2.12087 10.1651 2.91964C10.2999 3.1887 10.478 3.4338 10.6922 3.64518C11.3282 4.27274 11.6197 5.17008 11.4741 6.05159C11.425 6.34852 11.425 6.65148 11.4741 6.94841C11.6197 7.82992 11.3282 8.72726 10.6922 9.35482C10.478 9.5662 10.2999 9.8113 10.1651 10.0804C9.76475 10.8791 9.00143 11.4337 8.11805 11.5676C7.82049 11.6127 7.53236 11.7063 7.26512 11.8447C6.47176 12.2557 5.52824 12.2557 4.73488 11.8447C4.46764 11.7063 4.17951 11.6127 3.88195 11.5676C2.99857 11.4337 2.23525 10.8791 1.83494 10.0804C1.7001 9.8113 1.52202 9.5662 1.3078 9.35482C0.67183 8.72726 0.380267 7.82992 0.525913 6.94841C0.574972 6.65148 0.574972 6.34852 0.525913 6.05159C0.380267 5.17008 0.67183 4.27274 1.3078 3.64518C1.52202 3.4338 1.7001 3.1887 1.83494 2.91964C2.23525 2.12087 2.99857 1.56629 3.88195 1.4324C4.17951 1.3873 4.46764 1.29368 4.73488 1.15527Z"
                  fill="#F74747"
                />
                <path
                  d="M4.42852 7.59571L4.16234 7.85023C4.0019 8.00364 3.99141 8.25643 4.13858 8.4226C4.29596 8.60031 4.57039 8.6091 4.73883 8.44183L7.83339 5.36871C7.99425 5.20895 7.99471 4.9489 7.83441 4.78858C7.67488 4.62904 7.41636 4.62858 7.25626 4.78756L4.42852 7.59571Z"
                  fill="white"
                />
                <path
                  d="M5.02927 5.05345L4.77475 4.78726C4.62134 4.62683 4.36856 4.61633 4.20238 4.76351C4.02468 4.92089 4.01589 5.19532 4.18315 5.36375L7.25628 8.45831C7.41603 8.61918 7.67608 8.61964 7.8364 8.45933C7.99595 8.2998 7.99641 8.04128 7.83742 7.88119L5.02927 5.05345Z"
                  fill="white"
                />
              </svg>
              <span class="booking-status-text failed-text font-md-12px">Failed</span>
            </ng-container>
            <ng-container
              *ngIf="
                booking?.attributes?.status === 'initiated' ||
                booking?.attributes?.status === 'in_progress'
              "
            >
              <svg
                class="booking-status-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_979_2920)">
                  <mask
                    id="mask0_979_2920"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="12"
                    height="12"
                  >
                    <g clip-path="url(#clip1_979_2920)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 -5.24537e-07C2.68629 -8.1423e-07 8.1423e-07 2.68629 5.24537e-07 6C2.34843e-07 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 -2.34843e-07 6 -5.24537e-07ZM5.97498 9.5C5.56076 9.5 5.22498 9.16421 5.22498 8.75L5.22498 6.25C5.22498 5.83579 5.56076 5.5 5.97498 5.5C6.38919 5.5 6.72498 5.83579 6.72498 6.25L6.72498 8.75C6.72498 9.16421 6.38919 9.5 5.97498 9.5ZM5.25 3.75C5.25 3.33579 5.58579 3 6 3C6.41421 3 6.75 3.33579 6.75 3.75C6.75 4.16421 6.41421 4.5 6 4.5C5.58579 4.5 5.25 4.16421 5.25 3.75Z"
                        fill="white"
                      />
                    </g>
                  </mask>
                  <g mask="url(#mask0_979_2920)">
                    <path
                      d="M12 12L0 12L1.04907e-06 -1.04907e-06L12 0L12 12Z"
                      fill="#BD7A03"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_979_2920">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="translate(12 12) rotate(-180)"
                    />
                  </clipPath>
                  <clipPath id="clip1_979_2920">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="translate(12 12) rotate(-180)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span class="booking-status-text initiated-text font-md-12px">Initiated</span>
            </ng-container>
          </div>
          <span class="invisible details-text">Details</span>
        </div>
      </div>
      <div class="non-success-text font-md-12px">
        <ng-container
          *ngIf="
            booking?.attributes?.status === 'failed' ||
            booking?.attributes?.status === 'canceled'
          "
          >Any Points deducted will be refunded.</ng-container
        >
        <ng-container
          *ngIf="
            booking?.attributes?.status === 'initiated' ||
            booking?.attributes?.status === 'in_progress'
          "
          >Points will reflect in your {{booking?.attributes?.points_provider?.name}} account within {{booking?.attributes?.points_provider?.transfer_type}}</ng-container
        >
      </div>
    </div>
  </ng-container>
</div>
<div class="d-flex justify-content-center w100 my-5">
  <div class="dot-flashing" *ngIf="progress"></div>
</div>
