<div class="each-item">
  <div class="item-header" (click)="isOpen=!isOpen;">
    <div class="item-header-content d-flex justify-content-between align-items-center">
      <span class="item-header-room-number">
        Room {{roomIndex | incrementDecrement}}
      </span>
      <div class="adult-children mar-right-0 d-flex align-items-center">
        <div class="mar-right-3" *ngIf="room.adults">{{room.adults}} {{room.adults > 1 ? 'Adults':'Adult'}}  <span *ngIf="room.children">,</span></div>
        <div class="mar-right-3" *ngIf="room.children">{{room.children}}{{ (room.children > 1 ? ' Children. ' : ' Child. ')}} </div>  
        <div class="icon-minus-circle-1 fnt-14 mar-top-5" *ngIf="roomIndex" (click)="deleteRoom(isOpen);$event.stopPropagation();"></div>
      </div>
    </div>
  </div>
  <hr>
  <div *ngIf="isOpen" class="dropdown-content">
    <div class="dropdown-content-item d-flex align-items-center">
      <div class="adult-children">
        <div class="adult d-flex align-items-center">Adults</div>
        <div class="children d-flex align-items-center">12 years and above</div>
      </div>
      <div class="custom-counter d-flex justify-content-between align-items-center">
        <button mat-button class="counter-decrement-button" (click)="editRoomDetail(0)">-</button>
        <div class="counter-text d-flex justify-content-center align-items-center">{{room.adults}}</div>
        <button mat-button class="counter-increment-button" (click)="editRoomDetail(1)">+</button>
      </div>
    </div>
    <div class="dropdown-content-item d-flex align-items-center">
      <div class="adult-children">
        <div class="adult d-flex align-items-center">Children</div>
        <div class="children d-flex align-items-center">Below 12 years</div>
      </div>
      <div class="custom-counter d-flex justify-content-between align-items-center">
        <button mat-button class="counter-decrement-button" (click)="editRoomDetail(2)">-</button>
        <div class="counter-text d-flex justify-content-center align-items-center">{{room.children}}</div>
        <button mat-button class="counter-increment-button" (click)="editRoomDetail(3)">+</button>
      </div>
    </div>
    <div *ngIf="room.children" class="dropdown-content-item d-flex flex-column align-items-center">
      <div class="adult-children">
        <div class="children d-flex align-items-center">What is the age of the child you're travelling with?</div>
      </div>
      <div class="d-flex align-items-center justify-content-start w100">
        <div *ngFor="let childnumber of [1,2,3,4]" style="padding: 0 5px 0 0;">
          <div *ngIf="childnumber <= room.children" class="age-drp">
            <select class="form-control fnt-12 rounded" [(ngModel)]="room?.children_arr[childnumber-1]" [ngClass]="{'rounded-error' : ageError[childnumber-1]}" (change)="addAge($event.target.value, childnumber)">
              <option value="-1">Age</option>
              <option value="0">&lt;1 year</option>
              <option value="1">1 year</option>
              <option value="2">2 years</option>
              <option value="3">3 years</option>
              <option value="4">4 years</option>
              <option value="5">5 years</option>
              <option value="6">6 years</option>
              <option value="7">7 years</option>
              <option value="8">8 years</option>
              <option value="9">9 years</option>
              <option value="10">10 years</option>
              <option value="11">11 years</option>
              <option value="12">12 years</option>

              <!-- <option value="5">Audi</option>
              <option value="6">Audi</option>
              <option value="7">Audi</option>
              <option value="8">Audi</option>
              <option value="9">Audi</option>
              <option value="10">Audi</option>
              <option value="11">Audi</option>
              <option value="12">Audi</option> -->
            </select>
          </div>
          <!-- <div *ngIf="childnumber <= room.children">
            <div class="activity-select d-flex justify-content-between align-items-center">
              <input readonly (click)="openDestinationDrop()" (focusout)="closeDestinationDrop()"
                [(ngModel)]="selectedAge" class="w65 text-center">
              <i [class]="isDestinationDropdownOpen == true ? 'fa fa-angle-up' : 'fa fa-angle-down'" style="width: 20px;"></i>
            </div>
            <div class="dropdown location-dropdown">
              <div class="dropdown-menu" [class]="isDestinationDropdownOpen == true ? 'show dropdown-menu min-wid' : 'dropdown-menu min-wid'">
                <div class="block loc-results">
                  <div class="option-item ellipsis">
                    <div class="option-item-inner" *ngFor="let age of ages;" (mousedown)="setAge(age)">
                      <span>{{age}} years</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
