<div class="d-block" *ngIf="!progress">
  <div class="dining-home-top-container relative">
    <div class="bg-overlay w100 absolute"></div>
    <div class="banner-content-contain w100">
      <div class="banner-text-contain">
        <div class="home-page-heading d-flex align-items-center justify-content-center fnt-18 absolute">
          <h1 class="sp-heading gold">
            Buy or Send Gift Cards using AURUM Reward Points
          </h1>
        </div>
      </div>

    </div>
    <section class="home-page-search container absolute">
      <div class="w100 relative">

        <div class="row gifting-search-row m-0">
          <div class="col-lg-10">
            <div class="row">
              <div class="col-md-12 col-lg-4 home-input-fields form-field relative" dropdown #dropdown="bs-dropdown">
                <div dropdownToggle class="form-field__control"
                  (click)="openCategoryDrop()">
                  <input id="exampleFieldcategory" class='form-field__input arrows text-truncate pointer'
                    readonly [(ngModel)]="categoryInput" placeholder=" " [ngClass]="{'error' : chooseCategoryError}">
                  <label for="exampleFieldcategory" class="form-field__label">Choose Category</label>
                  <div class="form-field__bar"></div>
                </div>
                <div *dropdownMenu class="dropdown-menu default-dropdow">
                  <div *ngIf="allCategories && allCategories.length">
                    <div class="block results">
                      <div class="option-item-inner" *ngFor="let where of allCategories"
                        (mousedown)="setSelectedCategory(where);">
                        {{where.attributes.name}}
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="chooseCategoryError">
                  <span class="fnt-10 error-msg">{{chooseCategoryError}}</span>
                </div>
              </div>

              <div class="col-md-12 col-lg-4 home-input-fields form-field relative" dropdown>
                <div dropdownToggle class="form-field__control"
                  (click)="openSubCategoryDrop()">
                  <input id="exampleFieldsubcategory" placeholder=" "
                    class='form-field__input arrows text-truncate pointer' readonly
                    [(ngModel)]="subCategoryInput">
                  <label for="exampleFieldsubcategory" class="form-field__label">Choose sub category</label>
                  <div class="form-field__bar"></div>
                </div>
                <div *dropdownMenu class="dropdown-menu default-dropdow">
                  <ng-container *ngIf="selectedCategory?.id">
                    <div *ngIf="!isLoading; else loadingBlock;" class="block results">
                      <ng-container *ngIf="allSubCategories?.length; else elseBlock">
                        <div class="option-item-inner" *ngFor="let where of allSubCategories"
                          (mousedown)="setSelectedSubcategory(where)">
                          {{where.name}}
                        </div>
                      </ng-container>
                      <ng-template class="block loc-results w100" #elseBlock>
                        <div class="option-item-inner">
                          No applicable sub category
                        </div>
                      </ng-template>
                    </div>
                    <ng-template class="block loc-results w100" #loadingBlock>
                      <div class="option-item-inner">
                        Loading...
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
              </div>

              <div class="col-md-12 col-lg-4 home-input-fields form-field relative" dropdown>
                <div dropdownToggle class="form-field__control">
                  <input id="exampleFieldsearch" placeholder=" " class='form-field__input arrows text-truncate pointer'
                    (click)="openGiftDrop()" (focusin)="openGiftDrop()" (focusout)="closeGiftDrop()"
                    [formControl]="giftInput" (input)="giftInputChange()">
                  <label for="exampleFieldsearch" class="form-field__label">Search for Gift Card</label>
                  <div class="form-field__bar"></div>
                </div>
                <div *dropdownMenu class="dropdown-menu show location-dropdown">
                  <div *ngIf="!(filteredGiftCardList.length)" class="w100">
                    <div class="option-item ellipsis">
                      <div class="option-item-inner">
                        No results found.
                      </div>
                    </div>
                  </div>
                  <div *ngIf="filteredGiftCardList.length">
                    <div class="w100">
                      <div *ngIf="!filterGiftList">
                        <div class="option-item ellipsis d-flex" *ngFor="let where of filteredGiftCardList"
                          (mousedown)="setSelectedGift(where)">
                          <div class="option-item-inner">
                            {{where.attributes.title}}
                          </div>
                        </div>
                      </div>
                      <div *ngIf="filteredGiftCardList.length">
                        <div class="w100">
                          <div *ngIf="!filterGiftList">
                            <div class="option-item ellipsis d-flex" *ngFor="let where of filteredGiftCardList"
                              (mousedown)="setSelectedGift(where)">
                              <div class="option-item-inner">
                                {{where.attributes.title}}
                              </div>
                            </div>
                          </div>
    
                          <div
                            *ngIf="filterGiftList && (filteredGiftCardList | filter: giftInput.value : 'attributes.title') as result">
                            <div class="option-item ellipsis d-flex" *ngFor="let where of result"
                              (mousedown)="setSelectedGift(where)">
                              <div class="option-item-inner">
                                {{where.attributes.title}}
                              </div>
                            </div>
                            <div class="option-item ellipsis" *ngIf="result.length == 0">
                              <div class="option-item-inner">
                                No results found.
                              </div>
                            </div>
                          </div>
    
                        </div>
                      </div>
                      <!-- <div
                        *ngIf="filterGiftList && (filteredGiftCardList | filter: giftInput.value : 'attributes.title') as result">
                        <div class="option-item ellipsis d-flex" *ngFor="let where of result"
                          (mousedown)="setSelectedGift(where)">
                          <div class="option-item-inner">
                            {{where.attributes.title}}
                          </div>
                        </div>
                        <div class="option-item ellipsis" *ngIf="result.length == 0">
                          <div class="option-item-inner">
                            No results found.
                          </div>
                        </div>
                      </div> -->

                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div class="col-md-12 col-lg-2 d-flex home-input-fields">
            <div class="col-12 align-self-center home-enquire-button no-margin no-padding">
              <button mat-button class="enquire-button height-45" (click)="search()" color="accent">Search</button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex align-center mar-top-10 see-all w100">
        <div class="fnt-14 f500 cfff see-all-txt pointer" (click)="seeAll()">
          <span>See All Gift Cards</span>
          <span class="icon-right"></span>
        </div>
      </div>
    </section>
  </div>
</div>

<section class="all-mob-dropdowns d-lg-none d-xl-none" [ngClass]="{'active' : dropDownPopOpen}" *ngIf="dropDownPopOpen">
  <div class="close-bar flex align-center">
    <div class="close-icon-contain mar-top-15 fnt-25" (click)="dropDownPopOpen=false;removeModalOpen();">
      <span class="icon-cancel"></span>
    </div>
  </div>

  <!-- Category Drop -->

  <div id="origin-drop" *ngIf="inputToShow == 'categoryDrop'">
    <div class="flex line-1 justify-center">
      <div class="txt-box fnt-16 f500 pad-top-2 accenttwo">Choose category</div>
    </div>
    <div class="input-contain mar-top-10">
      <input type="text" class="form-control fnt-14"
      [(ngModel)]="categoryInput">
    </div>

    <div class="option-contain pad-top-10">
      <div class="dropdown location-dropdown" *ngIf="!(allCategories?.length)">
        <div class="show w100">
          <div class="option-item ellipsis">
            <div class="option-item-inner">
              No results found
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown location-dropdown" *ngIf="allCategories?.length">
        <div class="show w100">
          <div class="option-item ellipsis" *ngFor="let where of allCategories"
            (mousedown)="setSelectedCategory(where);">
            <div class="option-item-inner">
              {{where.attributes.name}}
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

  <!-- Category Drop -->

  <!-- Sub Category Drop -->

  <div id="origin-drop" *ngIf="inputToShow == 'subCategoryDrop'">
    <div class="flex line-1 justify-center">
      <div class="txt-box fnt-16 f500 pad-top-2 accenttwo">Choose sub category</div>
    </div>
    <div class="input-contain mar-top-10">
      <input type="text" class="form-control fnt-14"
      [(ngModel)]="subCategoryInput">
    </div>
    <div class="option-contain pad-top-10">
      <ng-container *ngIf="selectedCategory?.id">
        <div *ngIf="!isLoading; else loadingBlock;" class="block results">
          <ng-container *ngIf="allSubCategories?.length; else elseBlock">
            <div class="option-item-inner" *ngFor="let where of allSubCategories"
              (mousedown)="setSelectedSubcategory(where)">
              {{where.name}}
            </div>
          </ng-container>
          <ng-template class="block loc-results w100" #elseBlock>
            <div class="option-item-inner">
              No applicable sub category
            </div>
          </ng-template>
        </div>
        <ng-template class="block loc-results w100" #loadingBlock>
          <div class="option-item-inner">
            Loading...
          </div>
        </ng-template>
      </ng-container>
    </div>

  </div>

  <!-- Sub Category Drop -->

  <!-- Gift Drop -->

  <div id="origin-drop" *ngIf="inputToShow == 'giftDrop'">
    <div class="flex line-1 justify-center">
      <div class="icon-search">
      </div>
      <div class="txt-box fnt-16 f500 pad-top-2 accenttwo">Search for Gift Card</div>
    </div>
    <div class="input-contain mar-top-20">
      <input type="text" class="form-control fnt-14" [formControl]='giftInput' [(ngModel)]="giftInput.value"
        (input)="giftInputChange()" placeholder="Search for Gift Card">
    </div>
    <div class="option-contain pad-top-10">
      <div class="dropdown location-dropdown" *ngIf="!filteredGiftCardList.length">
        <div class="show w100">
          <div class="option-item ellipsis">
            <div class="option-item-inner">
              No results found {{filteredGiftCardList.length}}
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown location-dropdown" *ngIf="filteredGiftCardList?.length">
        <div *ngIf="!filteredGiftCardList">
          <div class="option-item ellipsis" *ngFor="let where of filteredGiftCardList" style="display: flex;"
            (mousedown)="setSelectedGift(where)">
            <div class="option-item-inner">
              {{where.attributes.title}}
            </div>
          </div>

        </div>

        <div
          *ngIf="filteredGiftCardList && (filteredGiftCardList | filter: giftInput.value : 'attributes.title') as result">
          <div class="option-item ellipsis" *ngFor="let where of result" style="display: flex;"
            (mousedown)="setSelectedGift(where)">
            <div class="option-item-inner">
              {{where.attributes.title}}
            </div>
          </div>
          <div class="option-item ellipsis" *ngIf="result.length == 0">
            <div class="option-item-inner">
              No results found
            </div>
          </div>
        </div>
      </div>

      <div class="map-done w100 d-block d-sm-none">
        <button class="w100 primary-btn height-45" (click)="dropDownPopOpen=false;removeModalOpen();"
          mat-button>Done</button>
      </div>
    </div>
  </div>

  <!-- Gift Drop -->

  <div class="map-done w100 d-block d-sm-none">
    <button class="w100 primary-btn height-45" (click)="dropDownPopOpen=false;removeModalOpen();"
      mat-button>Done</button>
  </div>
</section>


<div class="secondary-background  sp-section  dark3 d-none d-sm-block" *ngIf="!progress && popularGiftCards?.length">
  <div class="mar-left-15 header">
    <h1 class="sp-heading gold center mb-30">Popular Gift Cards</h1>
    <!-- <span [routerLink]="'/giftcards/listing'" class="view-all pointer">View All <span
              class="icon-right"></span></span> -->
  </div>

  <div class="popular-gift-listing">
    <div class="listing-container" #popularListing>
      <ng-container *ngFor="let gift of popularGiftCards; let index=index">
        <div class="col-lg-3 col-md-3 col-sm-12">
          <app-gift-card [gift]="gift" [showAddToCart]="false"></app-gift-card>
        </div>
      </ng-container>
    </div>
    <img src="assets/left-arrow-circle.png" alt="" class="carousel-arrow arrow-left pointer" (click)="previous()"
      *ngIf="popularGiftCards.length>4">
    <img src="assets/right-arrow-circle.png" alt="" class="carousel-arrow arrow-right pointer" (click)="next()"
      *ngIf="popularGiftCards.length>4">
  </div>

</div>

<app-loader *ngIf="progress"></app-loader>