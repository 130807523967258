import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { BookingHistoryRoutingModule } from "./booking-history-routing.module";
import { SharedModule } from "./../shared/shared.module";
import { NatGeoHistoryComponent } from "./nat-geo-history/nat-geo-history.component";
import { HistoryContainerComponent } from "./history-container/history-container.component";
import { NatGeoHistoryDetailsComponent } from "./nat-geo-history-details/nat-geo-history-details.component";
import { FlightsHistoryComponent } from './flights-history/flights-history.component';
import { FlightsHistoryDetailsComponent } from './flights-history-details/flights-history-details.component';
import { HotelBookingHistoryComponent } from './hotel-booking-history/hotel-booking-history.component';
import { GiftcardBookingHistoryComponent } from './giftcard-booking-history/giftcard-booking-history.component';
import { ToursHistoryComponent } from './tours-history/tours-history.component';
import { CharityHistoryComponent } from './charity-history/charity-history.component';
import { GolfHistoryComponent } from './golf-history/golf-history.component';
import { DesignerHistoryComponent } from './designer-history/designer-history.component';
import { GiftingHistoryComponent } from './gifting-history/gifting-history/gifting-history.component';
import { GiftingHistoryDetailsComponent } from './gifting-history/gifting-history-details/gifting-history-details.component';
import { DocsappHistoryComponent } from './docsapp-history/docsapp-history/docsapp-history.component';
import { DocsappHistoryDetailsComponent } from './docsapp-history/docsapp-history-details/docsapp-history-details.component';
import { ConciergeServicesHistoryComponent } from './concierge-services-history/concierge-services-history.component';
import { ConciergeServicesHistoryDetailsComponent } from './concierge-services-history-details/concierge-services-history-details.component';
import { OffersHistoryComponent } from './offers-history/offers-history/offers-history.component';
import { OffersHistoryDetailsComponent } from './offers-history/offers-history-details/offers-history-details.component';
import { DiningHistoryComponent } from './dining-history/dining-history.component';
import { DiningHistoryDetailsComponent } from './dining-history-details/dining-history-details.component';
import { AirportConHistoryComponent } from './airport-con-history/airport-con-history/airport-con-history.component';
import { AirportConHistoryDetailsComponent } from './airport-con-history/airport-con-history-details/airport-con-history-details.component';
import { EventHistoryComponent } from './events-history/event-history/event-history.component';
import { EventHistoryDetailsComponent } from './events-history/event-history-details/event-history-details.component';
import { PointsTransferHistoryComponent } from './points-transfer-history/points-transfer-history.component';
import { PointsTransferHistoryDetailsComponent } from './points-transfer-history-details/points-transfer-history-details.component';
// import { StatusLabelComponent } from './status-label/status-label.component';

@NgModule({
  declarations: [
    NatGeoHistoryComponent,
    HistoryContainerComponent,
    NatGeoHistoryDetailsComponent,
    FlightsHistoryComponent,
    FlightsHistoryDetailsComponent,
    HotelBookingHistoryComponent,
    GiftcardBookingHistoryComponent,
    ToursHistoryComponent,
    CharityHistoryComponent,
    GolfHistoryComponent,
    DesignerHistoryComponent,
    GiftingHistoryComponent,
    GiftingHistoryDetailsComponent,
    DocsappHistoryComponent,
    DocsappHistoryDetailsComponent,
    ConciergeServicesHistoryComponent,
    ConciergeServicesHistoryDetailsComponent,
    OffersHistoryComponent,
    OffersHistoryDetailsComponent,
    DiningHistoryComponent,
    DiningHistoryDetailsComponent,
    AirportConHistoryComponent,
    AirportConHistoryDetailsComponent,
    EventHistoryComponent,
    EventHistoryDetailsComponent,
    PointsTransferHistoryComponent,
    PointsTransferHistoryDetailsComponent,
    // StatusLabelComponent
  ],
  imports: [CommonModule, BookingHistoryRoutingModule, SharedModule],
  exports: [PointsTransferHistoryDetailsComponent]
})
export class BookingHistoryModule {}
