<div class="page container" *ngIf="!progress">
    <div class="to-show1">
        <div class="d-none d-sm-block">
            <div class="blueify mar-bot-10 d-flex justify-content-start align-items-center pointer">
                <div class="mar-top-4" (click)="back()">
                    <span class="icon-left-arrow"></span>
                </div>
                <div class="mar-left-10" (click)="back()">
                    Back
                </div>
            </div>
            <div class="checkout-message-top d-flex align-items-center justify-content-between mar-bot-15">
                <div class="w100">
                    <div class="d-none d-sm-block booking-id-detail">
                        <div class="d-flex justify-content-between">


                            <div class="to-name d-flex mar-bot-20">
                                <div class="d-flex align-items-center each-product"
                                    *ngFor="let each of bookingData.attributes.booking_data.requested_service.requested_services; let i = index;">
                                    <div class="product-details">
                                        {{servicesName[each.name]}}
                                    </div>
                                    <div class="mar-right-10 mar-left-10"
                                        *ngIf="bookingData.attributes.booking_data.requested_service.requested_services.length-1>i">
                                        |</div>
                                </div>
                            </div>
                            <div>
                                <app-status-label class="status-text" [status]="bookingData.attributes.status"
                                    [date]="bookingData.attributes.booking_data.service_date_time">
                                </app-status-label>
                                <div class="print-button d-none d-sm-block pointer ml-auto" (click)="convertMyWay()">
                                    <div class="fnt-14" style="float: right;">
                                        <span class="icon-print"></span>
                                        Print </div>
                                </div>
                            </div>
                        </div>

                        <div class="service-airport">
                            <div class="service-airport-name">
                                {{bookingData.attributes.booking_data.service_airport.airport_name}}
                                <span
                                    *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Departure">
                                    {{' ('+ formatTerminalName(bookingData.attributes.booking_data.terminal_details.departure_terminal.terminalName)+ ')' }}
                                </span>
                                <span
                                    *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Arrival">
                                    {{' ('+ formatTerminalName(bookingData.attributes.booking_data.terminal_details.arrival_terminal.terminalName)+ ')' }}
                                </span>
                            </div>
                            <div class="service-airport-details">
                                {{bookingData.attributes.booking_data.service_airport.city + ', ' + bookingData.attributes.booking_data.service_airport.country}}
                            </div>
                        </div>

                    </div>
                    <div class="d-flex flex-wrap each-strip product-container ">
                        <div class="col-12 col-lg-6 col-md-6 d-flex align-items-center mar-bot-20 each-product"
                            *ngFor="let each of bookingData.attributes.booking_data.data">
                            <div class="product-image">
                                <img loading="lazy" default="assets/gifting/default-image.png"
                                    [src]="each.attributes.images.image_link_1" alt="">
                            </div>
                            <div class="product-details ">
                                <div class="product-name">{{each.attributes.product_name}}</div>
                                <div class="product-amount"><span class="rupee-symbol mar-right-2">₹</span>
                                    {{each.attributes.amount | indianCurrency}}</div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="d-block d-sm-none">
            <section class="top-bar w100 d-flex align-items-center justify-content-between bg">
                <div class="d-flex align-items-center">
                    <div class="back-arr mar-right-20" (click)="back()">
                        <span class="icon-left-arrow" style="vertical-align: -7px;"></span>
                    </div>

                    <div class="to-name">
                        <div class="d-flex align-items-center each-product"
                            *ngFor="let each of bookingData.attributes.booking_data.requested_service.requested_services; let i = index;">
                            <div class="product-details">
                                {{servicesName[each.name]}}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <app-status-label class="status-text" [status]="bookingData.attributes.status"
                        [date]="bookingData.attributes.booking_data.service_date_time">
                    </app-status-label>
                </div>
            </section>

            <hr>

            <div class="service-airport pad-20">
                <div class="service-airport-name">
                    {{bookingData.attributes.booking_data.service_airport.airport_name}}
                    <span
                        *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Departure">
                        {{' ('+ formatTerminalName(bookingData.attributes.booking_data.terminal_details.departure_terminal.terminalName)+ ')' }}
                    </span>
                    <span
                        *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Arrival">
                        {{' ('+ formatTerminalName(bookingData.attributes.booking_data.terminal_details.arrival_terminal.terminalName)+ ')' }}
                    </span>
                </div>
                <div class="service-airport-details">
                    {{bookingData.attributes.booking_data.service_airport.city + ', ' + bookingData.attributes.booking_data.service_airport.country}}
                </div>
            </div>
            <hr>

            <div class="booking-details-bck d-flex justify-content-between align-items-center"
                style="background: var(--background);">
                <div>
                    <span class="booking-id">
                        Booking ID :
                    </span>
                    <span class="booking-id-txt">
                        {{bookingData?.attributes.booking_unique_id}}
                    </span>
                </div>
            </div>
            <hr>
        </div>
        <div class="booking-details-card booking-confirmed-container">
            <div class="d-block d-sm-none">
                <div class="d-flex align-items-center justify-content-between booking-details-bck">
                    <div class="booking-details-txt">
                        Flight Details
                    </div>
                </div>
            </div>
            <div class="d-none d-sm-block">
                <div class="d-flex align-items-center justify-content-between booking-details-bck">
                    <div>
                        <span class="booking-id">
                            Flight Details
                        </span>
                    </div>
                    <div>
                        <span class="booking-id">
                            Booking ID:
                        </span>
                        <span class="booking-id-txt">
                            {{bookingData.attributes.booking_unique_id}}
                        </span>
                    </div>

                </div>
            </div>


            <!-- responsive -->

            <hr>

            <div class="detail each-strip">
                <ng-container
                    *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Arrival || bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Arrival Flight</div>
                        <div class="value">
                            {{getArrivalFlightDetails()}}
                        </div>
                    </div>
                    <hr>
                </ng-container>

                <ng-container
                    *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Arrival || bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Arrival Date & Time</div>
                        <div class="value">
                            {{getArrivalDateTime()}}
                        </div>
                    </div>
                    <hr>
                </ng-container>

                <ng-container
                    *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Arriving Terminal</div>
                        <div class="value">
                            {{formatTerminalName(bookingData.attributes.booking_data?.terminal_details?.arrival_terminal?.terminalName)}}
                        </div>
                    </div>
                    <hr>
                </ng-container>

                <ng-container
                    *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Departure || bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Departure Flight</div>
                        <div class="value">
                            {{getDepartureFlightDetails()}}
                        </div>
                    </div>
                    <hr>
                </ng-container>

                <ng-container
                    *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Departure || bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Depart Date & Time</div>
                        <div class="value">
                            {{getDepartureDateTime()}}
                        </div>
                    </div>
                    <hr>
                </ng-container>

                <ng-container
                    *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Departure Terminal</div>
                        <div class="value">
                            {{formatTerminalName(bookingData.attributes.booking_data?.terminal_details?.departure_terminal?.terminalName)}}
                        </div>
                    </div>
                    <hr>
                </ng-container>

                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Travellers</div>
                    <div class="value">
                        {{getTravellers()}}
                    </div>
                </div>

                <!-- <hr>
                <div class="field mar-top-10">
                    <div class="name">Primary Detail</div>
                    <div class="sub-detail">llll
                    </div>
                </div> -->
            </div>

            <hr>

            <div class="pay-header each-strip">Fare Breakup</div>

            <hr>

            <div class="payment-detail each-strip">

                <ng-container
                    *ngFor="let each of bookingData.attributes.booking_data.requested_service.requested_services; let i = index;">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">{{servicesName[each.name]}}</div>
                        <div class="value">
                            <span class="mar-right-2" *ngIf="i>0">+</span>
                            <span class="rupee-symbol">₹</span>
                            {{getServicePrice(each) | indianCurrency}}</div>
                    </div>
                    <hr>
                </ng-container>

                <ng-container *ngIf="bookingData?.attributes?.booking_data?.requested_service?.additional_hour_charge">
                    <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                        <div class="name">Additional Hours Charges
                            <span *ngIf="bookingData?.attributes?.booking_data?.requested_service?.additional_hours">
                                {{'('+bookingData?.attributes?.booking_data?.requested_service?.additional_hours + ' Hrs)'}}
                            </span>    
                        </div>
                        <div class="value">
                            <span class="mar-right-2">+</span>
                            <span class="rupee-symbol">₹</span>
                            {{getAdditionCharge() | indianCurrency}}</div>
                    </div>
                    <hr>
                </ng-container>


                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name">Total Amount</div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.gross_amount| indianCurrency}}</div>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10 discount"
                    *ngIf="bookingData.attributes.gross_amount-bookingData.attributes.amount>0">
                    <div class="name">Discount</div>
                    <div class="value">
                        <span class="mar-right-2">-</span>
                        <span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.gross_amount-bookingData.attributes.amount| indianCurrency}}</div>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name">Amount Paid</div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.amount| indianCurrency}}</div>
                </div>


            </div>

            <hr>

            <div class="pay-header each-strip">Payment Breakup</div>

            <hr>

            <div class="payment-detail each-strip">
                <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                    <div class="name"><span>Amount paid by card
                            {{bookingData?.attributes?.card_number | creditCardMask}}</span><img *ngIf="cardIcon"
                            height="16" src="{{cardIcon}}"></div>
                    <div class="value"><span class="rupee-symbol">₹</span>
                        {{bookingData.attributes.amount| indianCurrency}}</div>
                </div>

                <hr>

                <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10">
                    <div class="name">Amount paid by Reward Points</div>
                    <div class="value">NIL</div>
                </div>



            </div>

            <ng-container *ngIf="bookingData.attributes.status=='booking_confirmed' && checkIfUpcoming()">
                <hr>

                <div class="pay-header each-strip">Cancellation</div>

                <hr>

                <div class="each-strip booking-details cancel-strip">
                    <button mat-button class="primary-btn cancellation-button" (click)="cancelBooking()">Cancel Booking
                    </button>
                </div>
            </ng-container>

        </div>
    </div>



    <div class="booking-confirmed-container to-hide1" style="border: 1px solid var(--primary);border-radius: 10px;">
        <div class="checkout-message-top each-strip d-flex align-items-center justify-content-between header-bg">
            <img class="img-fluid rewards-hub-header-logo pad-right-8" src="assets/sbic-images/logo.png"
                style="width: 200px;">
        </div>

        <div class="checkout-message-top each-strip d-flex align-items-center justify-content-between">
            <div class="d-flex w-100">
                <div>
                    <img src="assets/large-green-tick.png" alt="tick">
                </div>
                <div class="mar-left-20 mar-top-4">
                    <div class="congrats-text">Congratulations! You booking is confirmed.</div>
                </div>
                <div class="d-none d-sm-block booking-id-detail ml-auto text-right">
                    <div>
                        Booking ID : <span class="id">{{bookingData?.attributes.booking_unique_id}}</span>
                    </div>
                    <!-- <div class="print-button d-none d-sm-block pointer" (click)="convertMyWay()">
                        <div class="fnt-14">
                            <span class="icon-print"></span>
                            Print </div>
                    </div> -->
                </div>

            </div>

        </div>

        <hr>

        <div class="each-strip product-container bg">
            <div class="d-flex flex-wrap">
                <div class="d-flex align-items-center mar-bot-20 each-product"
                    *ngFor="let each of bookingData.attributes.booking_data.requested_service.requested_services">
                    <div class="icon-circle-tick"></div>
                    <div class="product-details">
                        {{servicesName[each.name]}}
                    </div>
                </div>
            </div>
            <div class="service-airport">
                <div class="service-airport-name">{{bookingData.attributes.booking_data.service_airport.airport_name}}
                    <span
                        *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Departure">
                        {{' ('+ formatTerminalName(bookingData.attributes.booking_data.terminal_details.departure_terminal.terminalName)+ ')' }}
                    </span>
                    <span
                        *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Arrival">
                        {{' ('+ formatTerminalName(bookingData.attributes.booking_data.terminal_details.arrival_terminal.terminalName)+ ')' }}
                    </span>
                </div>
                <div class="service-airport-details">
                    {{bookingData.attributes.booking_data.service_airport.city + ', ' + bookingData.attributes.booking_data.service_airport.country}}
                </div>
            </div>
        </div>

        <hr>

        <div class="d-block d-sm-none mob-booking-id each-strip">
            Booking ID : <span class="id">{{bookingData?.attributes.booking_unique_id}}</span>
        </div>

        <hr class="d-block d-sm-none">


        <div class="detail each-strip">
            <ng-container
                *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Arrival || bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Arrival Flight</div>
                    <div class="value">
                        {{getArrivalFlightDetails()}}
                    </div>
                </div>
                <hr>
            </ng-container>

            <ng-container
                *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Arrival || bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Arrival Date & Time</div>
                    <div class="value">
                        {{getArrivalDateTime()}}
                    </div>
                </div>
                <hr>
            </ng-container>

            <ng-container
                *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Arriving Terminal</div>
                    <div class="value">
                        {{formatTerminalName(bookingData.attributes.booking_data?.terminal_details?.arrival_terminal?.terminalName)}}
                    </div>
                </div>
                <hr>
            </ng-container>

            <ng-container
                *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Departure || bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Departure Flight</div>
                    <div class="value">
                        {{getDepartureFlightDetails()}}
                    </div>
                </div>
                <hr>
            </ng-container>

            <ng-container
                *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Departure || bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Depart Date & Time</div>
                    <div class="value">
                        {{getDepartureDateTime()}}
                    </div>
                </div>
                <hr>
            </ng-container>

            <ng-container
                *ngIf="bookingData.attributes.booking_data.journey_details.journey_type == AirportType.Connecting">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Departure Terminal</div>
                    <div class="value">
                        {{formatTerminalName(bookingData.attributes.booking_data?.terminal_details?.departure_terminal?.terminalName)}}
                    </div>
                </div>
                <hr>
            </ng-container>

            <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                <div class="name">Travellers</div>
                <div class="value">
                    {{getTravellers()}}
                </div>
            </div>

            <!-- <hr>
            <div class="field mar-top-10">
                <div class="name">Primary Detail</div>
                <div class="sub-detail">llll
                </div>
            </div> -->
        </div>

        <hr>

        <div class="pay-header each-strip">Fare Breakup</div>

        <hr>

        <div class="payment-detail each-strip">

            <ng-container
                *ngFor="let each of bookingData.attributes.booking_data.requested_service.requested_services; let i = index;">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">{{servicesName[each.name]}}</div>
                    <div class="value">
                        <span class="mar-right-2" *ngIf="i>0">+</span>
                        <span class="rupee-symbol">₹</span>
                        {{getServicePrice(each) | indianCurrency}}</div>
                </div>
                <hr>
            </ng-container>

            <ng-container *ngIf="bookingData?.attributes?.booking_data?.requested_service?.additional_hour_charge">
                <div class="d-flex align-items-center justify-content-between field mar-bot-10 mar-top-10">
                    <div class="name">Additional Hours Charges</div>
                    <div class="value">
                        <span class="mar-right-2">+</span>
                        <span class="rupee-symbol">₹</span>
                        {{getAdditionCharge() | indianCurrency}}</div>
                </div>
                <hr>
            </ng-container>


            <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                <div class="name">Total Amount</div>
                <div class="value"><span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.gross_amount| indianCurrency}}</div>
            </div>

            <hr>

            <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10 discount"
                *ngIf="bookingData.attributes.gross_amount-bookingData.attributes.amount>0">
                <div class="name">Discount</div>
                <div class="value">
                    <span class="mar-right-2">-</span>
                    <span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.gross_amount-bookingData.attributes.amount| indianCurrency}}</div>
            </div>

            <hr>

            <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                <div class="name">Amount Paid</div>
                <div class="value"><span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.amount| indianCurrency}}</div>
            </div>


        </div>

        <hr>

        <div class="pay-header each-strip">Payment Breakup</div>

        <hr>

        <div class="payment-detail each-strip">
            <div class="d-flex align-items-center justify-content-between total-field mar-bot-10 mar-top-10">
                <div class="name"><span>Amount paid by card
                        {{bookingData?.attributes?.card_number | creditCardMask}}</span><img *ngIf="cardIcon"
                        height="16" src="{{cardIcon}}"></div>
                <div class="value"><span class="rupee-symbol">₹</span>
                    {{bookingData.attributes.amount| indianCurrency}}</div>
            </div>

            <hr>

            <div class="d-flex align-items-center justify-content-between field total mar-bot-10 mar-top-10">
                <div class="name">Amount paid by Reward Points</div>
                <div class="value">NIL</div>
            </div>



        </div>
        <!-- <hr>

        <div class="pad-left-20 pad-right-20 d-flex align-items-center go-to-home pointer fnt-14" (click)="goToHome()">
            <span>&#60;</span>
            <span class="pad-left-8">Go to Home</span>
        </div> -->

    </div>

</div>

<app-loader *ngIf="progress"></app-loader>