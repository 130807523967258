import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/common/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CharitableDonationService {
  payment = {
    amount: 500,
    type: 'once',
    count: 2
  };

  baseUrl: string = environment.v2ApiBs;
  stagingUrl: string = environment.stagingUrl;
  v2ApiBsInternal: string = environment.v2ApiBsInternal;
  apiUrl: string = this.baseUrl + '/ngo_donation';

  constructor(private api: ApiService) { }

  getNgoList() {
    return this.api.get(this.apiUrl + '/ngo_list').toPromise();
  }

  getNgoDetails(key) {
    return this.api.get(this.apiUrl + '/ngo_details?ngo_name=' + key);
  }

  registerDonation(data) {
    return this.api.post(this.apiUrl + '/register_donation', data);
  }

  getBookingById(id) {
    return this.api
    .get(this.baseUrl + `/bookings/${id}`)
    .toPromise();
    // return this.api.get(this.stagingUrl + 'users/bookings/booking_details?booking_unique_id=' + id).toPromise();
  }

  getBookings(){
    return this.api.get(this.baseUrl + "/bookings?type=NgoDonation").toPromise()
  }

  getNationalities(){
    return this.api.get(this.v2ApiBsInternal+"/country_details/get_nationalities").toPromise()
  }

  getCountries(){
    return this.api.get(this.v2ApiBsInternal+"/country_details/get_countries").toPromise()
  }

  getDetailsByPin(code){
    return this.api.get(this.v2ApiBsInternal+"/country_details/get_details_by_pin?pin_code=" + code);
  }

}
