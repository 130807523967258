<div *ngIf="!progress">
  <div class="container to-show1">
    <div>
      <div class="d-xl-none d-lg-none d-md-none">
        <div class="d-flex align-items-center fnt-16 pad-top-10 pad-right-15 pad-bot-10 relative">
          <div (click)="back()"
            class="d-flex justify-content-start align-items-center pointer mar-left-20 mar-right-20 absolute" style="left:5px;">
            <div class="mar-bot-3">
              <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.469669 5.46967ZM17 5.25L1 5.25V6.75L17 6.75V5.25Z"
                  fill="#C2A63F" />
              </svg>
            </div>
          </div>
          <span class="pad-right-10 ft-wt-500 fnt-16 gold" style="margin: auto;">Booking Details</span>


        </div>
        <hr>
        <div class="booking-id-mobile  d-flex align-items-center">
          <span class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
          <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
              fill="#ffffff" />
          </svg>
          <span
            class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
          <span class="status-text table-heading d-flex justify-content-between align-items-center"
            (click)="convertMyWay()">
            <span class=" trip-detail-btn fnt-14"><svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.875 4H2.125C1.0875 4 0.25 4.8375 0.25 5.875V9.625H2.75V12.125H10.25V9.625H12.75V5.875C12.75 4.8375 11.9125 4 10.875 4ZM9 10.875H4V7.75H9V10.875ZM10.875 6.5C10.5312 6.5 10.25 6.21875 10.25 5.875C10.25 5.53125 10.5312 5.25 10.875 5.25C11.2188 5.25 11.5 5.53125 11.5 5.875C11.5 6.21875 11.2188 6.5 10.875 6.5ZM10.25 0.875H2.75V3.375H10.25V0.875Z"
                  fill="#fff" />
              </svg>
            </span>
          </span>
        </div>
        <div class="booking-id-mobile flex justify-content-between align-center">
          <span class="d-flex flex-column">
            <div>
              <span class="mar-right-5">Booking ID: </span><span
              class="ft-wt-500 black-color">{{ booking?.flight_trip?.unique_id}}</span>
            </div>
            <div>
              <span class="fnt-12 accenttwo">Booked at: {{booking?.flight_trip?.confirmed_at | date: 'dd MMM yyyy, hh:mm a'}}</span>
            </div>
          </span>
          <span>
            <app-status-label class="status-text" [status]='status' style="display: block;"></app-status-label>
          </span>
        </div>

      </div>
      <div class="d-none d-sm-none d-md-block">
        <div class="blueify mar-bot-10 d-flex justify-content-start align-items-center pointer" (click)="back()"
          style="width: max-content;">
          <div class="mar-bot-3">
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM13 5.25L1 5.25V6.75L13 6.75V5.25Z"
                fill="#C2A63F" />
            </svg>
          </div>
          <div class="mar-left-10 fnt-14 primary-color">
            Back
          </div>
        </div>
      </div>
      <div class="booking-confirmed-container">
        <div class="d-none d-sm-none d-md-block">
          <div class="d-flex align-items-center fnt-22 pad-20">
            <span class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                fill="#ffffff" />
            </svg>
            <span
              class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
            <app-status-label class="status-text" [status]='status'></app-status-label>
          </div>
          <div class="booking-id flex pad-left-20 pad-right-20">
            <div class="d-flex flex-column">
              <div>
                <span class="mar-right-5">Booking ID: </span>
                <span
                  class="ft-wt-500 black-color">{{ booking?.flight_trip?.unique_id}}</span>
              </div>
              <div>
                <span class="fnt-12 accenttwo">Booked at: {{booking?.flight_trip?.confirmed_at | date: 'dd MMM yyyy, hh:mm a'}}</span>
              </div>
            </div>

            <span
              class="status-text table-heading d-flex justify-content-between align-items-center"
              (click)="convertMyWay()">
              <button class="trip-detail-btn fnt-14 btn"><svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.875 4H2.125C1.0875 4 0.25 4.8375 0.25 5.875V9.625H2.75V12.125H10.25V9.625H12.75V5.875C12.75 4.8375 11.9125 4 10.875 4ZM9 10.875H4V7.75H9V10.875ZM10.875 6.5C10.5312 6.5 10.25 6.21875 10.25 5.875C10.25 5.53125 10.5312 5.25 10.875 5.25C11.2188 5.25 11.5 5.53125 11.5 5.875C11.5 6.21875 11.2188 6.5 10.875 6.5ZM10.25 0.875H2.75V3.375H10.25V0.875Z"
                    fill="#fff" />
                </svg>
                Print </button>
            </span>
          </div>
        </div>
        <div class="display-card-mobile">
          <div class="display-card">
            <div class="d-none d-sm-none d-md-block pad-left-20 pad-right-20">
              <div
                class="d-flex bg justify-content-between align-items-center flight-details-strip fnt-16">
                <div><span
                    class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
                  <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                      fill="#ffffff" />
                  </svg>
                  <span
                    class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
                  <span class="pad-left-10 pad-right-10">|</span>
                  <span>{{totalTravellers()}} traveller(s)</span>
                  <span class="pad-left-10 pad-right-10">|</span>
                  <span>{{booking.flight_trip.flight_search.depart_date|date:'MMM d, y'}}</span>
                  <span class="pad-left-10 pad-right-10">|</span>
                  <span
                    class="cabin-type">{{(booking.flight_trip.flights[0].segments[1].stops != "0")?(booking.flight_trip.flights[0].segments[1].stops): 'Non -' }} Stop</span>
                  <!-- <span class="pad-right-10 names">
                  <svg class="mar-right-12 mar-left-10" width="14" height="14" viewBox="0 0 14 14" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.66667 6.66667C8.50833 6.66667 10 5.175 10 3.33333C10 1.49167 8.50833 0 6.66667 0C4.825 0 3.33333 1.49167 3.33333 3.33333C3.33333 5.175 4.825 6.66667 6.66667 6.66667ZM6.66667 8.33333C4.44167 8.33333 0 9.45 0 11.6667V13.3333H13.3333V11.6667C13.3333 9.45 8.89166 8.33333 6.66667 8.33333Z"
                      fill="#64707C" />
                  </svg>
                </span> -->
                </div>
                <!-- <div class="ft-wt-500"><span class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.total_fare}}
              </div> -->
                <div class="d-none d-sm-block" *ngIf="booking.flight_trip.flight_search.supplier_name">
                  <div class="flex flex-column"><span class="fnt-12">Booking Powered by</span>
                    <img *ngIf="booking.flight_trip.flight_search.supplier_name == 'yatra'" class="powered-img"
                      src="assets/yatra.png">
                  </div>
                </div>
              </div>
            </div>
            <div class="d-xl-none d-lg-none d-md-none">
              <div
                class="d-flex bg justify-content-between align-items-center flight-details-strip fnt-16">
                <div>
                  <div>
                    <span
                      class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                        fill="#ffffff" />
                    </svg>
                    <span
                      class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
                  </div>
                  <div class="fnt-12">
                    <span>{{totalTravellers()}} traveller(s)</span>
                    <span class="pad-left-10 pad-right-10">|</span>
                    <span>{{booking.flight_trip.flight_search.depart_date|date:'MMM d, y'}}</span>
                    <span class="pad-left-10 pad-right-10">|</span>
                    <span
                      class="cabin-type">
                        {{(booking.flight_trip.flights[0].segments[1].stops != "0")?(booking.flight_trip.flights[0].segments[1].stops): 'Non -' }} Stop
                    </span>
                  </div>
                </div>
              </div>

            </div>

            <div class=" flight-details" *ngFor="let flightDetailsNumb of flightDetailsNum; let i = index">

              <div class="pad-left-20 pad-right-20 row m-0 align-items-center mob-no-padding">
                <div class="col-lg-2 col-sm-2 col-md-2">
                  <div class="airline-details">

                    <img width="30" height="30"
                      src="{{getAirlineImage(booking.flight_trip.flights[0].segments[flightDetailsNumb].airline)}}">
                    <!-- </div> -->
                    <div class="flex align-items-center flex-column">
                      <div class="fnt-14 airline-name text-center">
                        {{getAirlineName(booking.flight_trip.flights[0].segments[flightDetailsNumb].airline)}}</div>
                      <div class="fnt-12 airline-number">
                        {{booking.flight_trip.flights[0].segments[flightDetailsNumb].airline}}-{{booking.flight_trip.flights[0].segments[flightDetailsNumb].flight_number}}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="air-info flex pad-top-30 col-lg-8 col-sm-8 col-md-8 pad-bot-30">
                  <div class="l-info">
                    <div class="fnt-16 tim pad-bot-7">
                      {{booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_airport}}
                      <b>{{flightS.removeTimeZoneFromString(booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_date)|date:"HH:mm"}}</b>
                    </div>
                    <div class="fnt-14 dat pad-bot-5">
                      {{flightS.removeTimeZoneFromString(booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_date)|date:"EEE, MMM d"}}
                    </div>
                    <div class="fnt-16 ft-wt-500">
                      {{booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_city_name}}
                    </div>
                    <div class="fnt-12 port">
                      {{booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_airport_name}}
                    </div>
                    <div class="fnt-12 port"
                      *ngIf="booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_terminal">Terminal:
                      {{booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_terminal}}
                    </div>
                  </div>
                  <div class="m-info f500 fnt-14 text-center">
                    <div class="pad-bot-2 mar-bot-5 accenttwo">
                      {{flightDuration(booking.flight_trip.flights[0].segments[flightDetailsNumb].duration)}}</div>
                    <!-- <p>{{getStops(booking.flight_trip.flights[0].segments[flightDetailsNumb])}}</p> -->
                  </div>
                  <div class="l-info text-right">
                    <div class="fnt-16 tim pad-bot-7">
                      <b>{{flightS.removeTimeZoneFromString(booking.flight_trip.flights[0].segments[flightDetailsNumb]['arrival-date'])|date:"HH:mm"}}
                      </b>{{booking.flight_trip.flights[0].segments[flightDetailsNumb].arrival_airport}}
                    </div>
                    <div class="fnt-14 dat pad-bot-5">
                      {{flightS.removeTimeZoneFromString(booking.flight_trip.flights[0].segments[flightDetailsNumb]['arrival-date'])|date:'EEE, MMM d'}}
                    </div>
                    <div class="fnt-16 ft-wt-500">
                      {{booking.flight_trip.flights[0].segments[flightDetailsNumb].arrival_city_name}}
                    </div>
                    <div class="fnt-12 port">
                      {{booking.flight_trip.flights[0].segments[flightDetailsNumb].arrival_airport_name}}
                    </div>
                    <div class="fnt-12 port"
                      *ngIf="booking.flight_trip.flights[0].segments[flightDetailsNumb].arrival_terminal">Terminal:
                      {{booking.flight_trip.flights[0].segments[flightDetailsNumb].arrival_terminal}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="bag-bar flex align-center">
                <span class="ft-wt-500">{{travClass[booking.flight_trip.flight_search.cabin_type]}}</span><span
                  class="pad-left-7">|</span>
                <span class="bag-indi text-right">
                  <span
                    *ngFor="let baggage of getBaggageInfo(booking.flight_trip.flights[0].segments[flightDetailsNumb],0) | keyvalue;">
                    <span class="ft-wt-500 pad-left-8 pad-right-8">{{baggageType[baggage.key]}}</span>
                    <span>
                      Checkin: <span class="ft-wt-500 pad-left-5 pad-right-10">{{baggage.value.cib}}</span>Cabin: <span
                        class="ft-wt-500 pad-left-5">{{baggage.value.cab}}</span>
                    </span>
                  </span>
                </span>

              </div>
              <div class="trav-table mar-left-15 mar-right-15 pad-bot-20">
                <div class="bg ">
                  <div class="pad-left-10 pad-right-10">
                    <div class="pad-top-13 pad-bot-13 fnt-14 row airline-name">
                      <span class="col-4 text-left">Travellers</span>
                      <span class="col-4 text-center">PNR</span>
                      <span class="col-4 text-right">Ticket Number</span>
                    </div>
                    <hr>
                    <div *ngIf="booking.flight_trip.flight_search.supplier_name == 'yatra'">
                      <div *ngFor="let traveller of booking.flight_trip.pax_info_list;let j =index;">
                        <div class="pad-top-13 pad-bot-13 fnt-14 row ft-wt-500 accenttwo">
                          <span class="col-4 text-left ">{{traveller.title}} {{traveller.first_name}}
                            {{traveller.last_name}}
                            <span  class="custom-tag" *ngIf="traveller.type == 'INF'">infant</span>
                            <span  class="custom-tag" *ngIf="traveller.type == 'CHD'">Child</span>
                          </span>
                          <span
                            class="col-4 text-center ">{{booking.flight_trip.flights[0].segments[flightDetailsNumb].airline_pnr}}</span>
                          <span
                            class="col-4 text-right ">{{booking.flight_trip.flights[0].segments[flightDetailsNumb].gds_pnr ? booking.flight_trip.flights[0].segments[flightDetailsNumb].gds_pnr : booking.flight_trip.flights[0].segments[flightDetailsNumb].airline_pnr}}</span>
                        </div>
                        <hr *ngIf="booking.flight_trip.pax_info_list.length!=j+1">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="flightDetailsNum.length !=i+1" class="layover-bar relative text-center">
                <!-- <div class="line absolute"></div> -->
                <span
                  class="layover-txt fnt-14 f500 relative">{{layoverTime(booking.flight_trip.flights[0].segments[flightDetailsNumb],booking.flight_trip.flights[0].segments[+flightDetailsNumb+1]) }}
                  layover</span>
              </div>

            </div>

            <!-- <div *ngIf="!flightDetailsReturn" class="pad-left-20 pad-right-20 d-flex align-items-center go-to-home">
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 1L1 4L4 7" stroke="#C2A63F" stroke-linecap="round" stroke-linejoin="round" />
            </svg><span class="fnt-14 pad-left-8 f300 pointer" (click)="goToHome()">Go to Home</span>
          </div> -->
          </div>
        </div>
        <div class="display-card-mobile">
          <div class="display-card" *ngIf="flightDetailsReturn">


            <div class=" flight-details align-items-center">
              <div class="d-none d-sm-block d-md-block">
                <div
                  class="pad-left-20 pad-right-20 bg d-flex justify-content-between align-items-center flight-details-strip fnt-16">
                  <div><span
                      class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                        fill="#ffffff" />
                    </svg>
                    <span
                      class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
                    <span class="pad-left-10 pad-right-10">|</span>
                    <span>{{totalTravellers()}} traveller(s)</span>
                    <span class="pad-left-10 pad-right-10">|</span>
                    <span>{{booking.flight_trip.flight_search.return_date|date:'MMM d, y'}}</span>
                    <span class="pad-left-10 pad-right-10">|</span>
                    <span
                      class="cabin-type">
                      {{(booking.flight_trip.flights[1].segments[returnSegmentLength(booking.flight_trip?.flights[1].segments)]?.stops != "0")?(booking.flight_trip.flights[1].segments[returnSegmentLength(booking.flight_trip?.flights[1].segments)]?.stops): 'Non -' }} Stop
                    </span>
                    <!-- <span class="pad-right-10 names">
                    <svg class="mar-right-12 mar-left-10" width="14" height="14" viewBox="0 0 14 14" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.66667 6.66667C8.50833 6.66667 10 5.175 10 3.33333C10 1.49167 8.50833 0 6.66667 0C4.825 0 3.33333 1.49167 3.33333 3.33333C3.33333 5.175 4.825 6.66667 6.66667 6.66667ZM6.66667 8.33333C4.44167 8.33333 0 9.45 0 11.6667V13.3333H13.3333V11.6667C13.3333 9.45 8.89166 8.33333 6.66667 8.33333Z"
                        fill="#64707C" />
                    </svg>
                  </span> -->
                  </div>
                  <!-- <div class="ft-wt-500"><span class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.total_fare}}
                </div> -->
                  <div class="d-none d-sm-block" *ngIf="booking.flight_trip.flight_search.supplier_name">
                    <div class="flex flex-column"><span class="fnt-12">Booking Powered by</span>
                      <img *ngIf="booking.flight_trip.flight_search.supplier_name == 'yatra'" class="powered-img"
                        src="assets/yatra.png">
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-xl-none d-lg-none d-md-none">
                <div
                  class="d-flex bg justify-content-between align-items-center flight-details-strip fnt-16">
                  <div>
                    <div>
                      <span
                        class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
                      <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                          fill="#ffffff" />
                      </svg>
                      <span
                        class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
                    </div>
                    <div class="fnt-12">
                      <span>{{totalTravellers()}} traveller(s)</span>
                      <span class="pad-left-10 pad-right-10">|</span>
                      <span>{{booking.flight_trip.flight_search.return_date|date:'MMM d, y'}}</span>
                      <span class="pad-left-10 pad-right-10">|</span>
                      <span
                        class="cabin-type">
                        {{(booking.flight_trip.flights[1].segments[returnSegmentLength(booking.flight_trip?.flights[1].segments)]?.stops != "0")?(booking.flight_trip.flights[1].segments[returnSegmentLength(booking.flight_trip?.flights[1].segments)]?.stops): 'Non -' }} Stop
                      </span>
                    </div>
                  </div>
                </div>

              </div>
              <div class=" flight-details" *ngFor="let flightDetailsNumb of flightDetailsReturn; let i = index">
                <!-- <div class="row"> -->
                <div class="pad-left-20 pad-right-20 row m-0 align-items-center mob-no-padding">
                  <div class="col-lg-2 col-sm-2 col-md-2">
                    <div class="airline-details">
                      <!-- <div> -->
                      <img width="30" height="30"
                        src="{{getAirlineImage(booking.flight_trip.flights[1].segments[flightDetailsNumb].airline)}}">
                      <!-- </div> -->
                      <div class="flex align-items-center flex-column">
                        <div class="fnt-14 airline-name text-center">
                          {{getAirlineName(booking.flight_trip.flights[1].segments[flightDetailsNumb].airline)}}</div>
                        <div class="fnt-12 airline-number">
                          {{booking.flight_trip.flights[1].segments[flightDetailsNumb].airline}}-{{booking.flight_trip.flights[1].segments[flightDetailsNumb].flight_number}}
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="air-info flex pad-top-30 col-lg-8 col-sm-8 col-md-8 pad-bot-30">
                    <div class="l-info">
                      <div class="fnt-16 tim pad-bot-7">
                        {{booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_airport}}
                        <b>{{flightS.removeTimeZoneFromString(booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_date)|date:"HH:mm"}}</b>
                      </div>
                      <div class="fnt-14 dat pad-bot-5">
                        {{flightS.removeTimeZoneFromString(booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_date)|date:"EEE, MMM d"}}
                      </div>
                      <div class="fnt-16 ft-wt-500">
                        {{booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_city_name}}
                      </div>
                      <div class="fnt-12 port">
                        {{booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_airport_name}}
                      </div>

                      <div class="fnt-12 port"
                        *ngIf="booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_terminal">Terminal:
                        {{booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_terminal}}
                      </div>
                    </div>
                    <div class="m-info f500 fnt-14 text-center">
                      <div class="pad-bot-2 mar-bot-5 accenttwo">
                        {{flightDuration(booking.flight_trip.flights[1].segments[flightDetailsNumb].duration)}}</div>
                    </div>
                    <div class="l-info text-right">
                      <div class="fnt-16 tim pad-bot-7">
                        <b>{{flightS.removeTimeZoneFromString(booking.flight_trip.flights[1].segments[flightDetailsNumb]['arrival-date'])|date:"HH:mm"}}
                        </b>{{booking.flight_trip.flights[1].segments[flightDetailsNumb].arrival_airport}}
                      </div>
                      <div class="fnt-14 dat pad-bot-5">
                        {{flightS.removeTimeZoneFromString(booking.flight_trip.flights[1].segments[flightDetailsNumb]['arrival-date'])|date:'EEE, MMM d'}}
                      </div>
                      <div class="fnt-16 ft-wt-500">
                        {{booking.flight_trip.flights[1].segments[flightDetailsNumb].arrival_city_name}}
                      </div>
                      <div class="fnt-12 port">
                        {{booking.flight_trip.flights[1].segments[flightDetailsNumb].arrival_airport_name}}
                      </div>
                      <div class="fnt-12 port"
                        *ngIf="booking.flight_trip.flights[1].segments[flightDetailsNumb].arrival_terminal">Terminal:
                        {{booking.flight_trip.flights[1].segments[flightDetailsNumb].arrival_terminal}}
                      </div>
                    </div>


                  </div>
                  <!-- <div class="pad-left-10 names">
                  <div> PNR: {{getPNR(flightDetailsNumb)}}</div>
                  <div>Ticket Number: {{getTicket(flightDetailsNumb)}}</div>
                </div> -->
                </div>
                <!-- </div> -->
                <div class="bag-bar flex align-center">
                  <span class="ft-wt-500">{{travClass[booking.flight_trip.flight_search.cabin_type]}}</span><span
                    class="pad-left-7">|</span>
                  <span class="bag-indi text-right">
                    <span
                      *ngFor="let baggage of getBaggageInfo(booking.flight_trip.flights[1].segments[flightDetailsNumb],1)|keyvalue">
                      <span class="ft-wt-500 pad-left-8 pad-right-8">{{baggageType[baggage.key]}}</span>
                      <span>
                        Checkin: <span class="ft-wt-500 pad-left-5 pad-right-10">{{baggage.value.cib}}</span>Cabin:
                        <span class="ft-wt-500 pad-left-5">{{baggage.value.cab}}</span>
                      </span>
                    </span>
                  </span>


                </div>
                <div class="trav-table mar-left-15 mar-right-15 pad-bot-20">
                  <div class="bg ">
                    <div class="pad-left-10 pad-right-10">
                      <div class="pad-top-13 pad-bot-13 fnt-14 row airline-name">
                        <span class="col-4 text-left">Travellers</span>
                        <span class="col-4 text-center">PNR</span>
                        <span class="col-4 text-right">Ticket Number</span>
                      </div>
                      <hr>
                      <div *ngIf="booking.flight_trip.flight_search.supplier_name == 'yatra'">
                        <div *ngFor="let traveller of booking.flight_trip.pax_info_list;let j =index;">
                          <div class="pad-top-13 pad-bot-13 fnt-14 row ft-wt-500 accenttwo">
                            <span class="col-4 text-left ">{{traveller.title}} {{traveller.first_name}}
                              {{traveller.last_name}}
                              <span  class="custom-tag" *ngIf="traveller.type == 'INF'">infant</span>
                              <span  class="custom-tag" *ngIf="traveller.type == 'CHD'">Child</span>
                            </span>
                            <span
                              class="col-4 text-center ">{{booking.flight_trip.flights[1].segments[flightDetailsNumb].airline_pnr}}</span>
                            <span
                              class="col-4 text-right ">{{booking.flight_trip.flights[1].segments[flightDetailsNumb].gds_pnr ? booking.flight_trip.flights[1].segments[flightDetailsNumb].gds_pnr : booking.flight_trip.flights[1].segments[flightDetailsNumb].airline_pnr}}</span>
                          </div>
                          <hr *ngIf="booking.flight_trip.pax_info_list.length!=j+1">
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div *ngIf="flightDetailsReturn.length !=i+1" class="layover-bar relative text-center">
                  <!-- <div class="line absolute"></div> -->
                  <span
                    class="layover-txt fnt-14 f500 relative">{{layoverTime(booking.flight_trip.flights[1].segments[flightDetailsNumb],booking.flight_trip.flights[1].segments[+flightDetailsNumb+1]) }}
                    layover</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg fare-breakup flex align-center pad-left-15 fnt-16">Fare Breakup</div>
        <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.base_fare">
          <div class="flex justify-content-between fnt-16 pricing-elements">
            <span class="airline-name">Base Fare</span><span class="ft-wt-500"><span
                class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.base_fare| indianCurrency}}</span>
          </div>
          <hr>
        </div>
        <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.taxes">
          <div class="flex justify-content-between fnt-16 pricing-elements">
            <span class="airline-name">Taxes</span><span class="ft-wt-500">
              <span class="mar-right-2">+</span>
              <span class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.taxes| indianCurrency}}
              <span class="icon-info taxes-info" triggers="mouseenter:mouseleave" [popover]="taxesTemplate"
                popoverTitle="Taxes and Surcharges"></span>

            </span>
          </div>

          <ng-template #taxesTemplate>
            <div *ngFor="let pricingElem of booking.flight_trip.pax_pricing_info_list.pricing_elements;let j=index">
              <div class="flex justify-content-between" *ngIf="pricingElem.amount && pricingElem.category != 'BF'">
                <span>{{pricingElem.code}}</span><span class="pad-left-25"><span
                    class="rupee-symbol">₹</span>{{pricingElem.amount|indianCurrency}}</span>
              </div>
            </div>
          </ng-template>

          <hr>
        </div>
        <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.convenience_fee">
          <div class="flex justify-content-between fnt-16 pricing-elements">
            <span class="airline-name">Convenience Fee</span><span class="ft-wt-500">
              <span class="mar-right-2">+</span>
              <span
                class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.convenience_fee| indianCurrency}}</span>
          </div>
          <hr>
        </div>
        <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.discount">
          <div class="flex justify-content-between fnt-16 pricing-elements" style="color: var(--primary);">
            <span class="airline-name" style="color: var(--primary);"> Coupon Discount </span>
            <span class="ft-wt-500">
              <span class="mar-right-2">-</span>
              <span class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.discount| indianCurrency}}</span>
          </div>
          <hr>
        </div>
        <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.markup">
          <div class="flex justify-content-between fnt-16 pricing-elements">
            <span class="airline-name">Markup </span><span class="ft-wt-500">
              <span class="mar-right-2">+</span>
              <span class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.markup| indianCurrency}}</span>
          </div>
          <hr>
        </div>
        <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.total_fare">
          <div class="flex justify-content-between fnt-16 pricing-elements">
            <span class="airline-name">Total Fare </span><span class="ft-wt-500"><span
                class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.total_fare| indianCurrency}}</span>
          </div>
        </div>

        <div class="bg fare-breakup flex align-center pad-left-15 fnt-16">Payment Breakup</div>
        <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.base_fare">
          <div class="flex justify-content-between fnt-16 pricing-elements">
            <span class="airline-name">
              Amount Paid by Card <span style="letter-spacing: 3px">
                {{booking.flight_trip?.card_number | creditCardMask}}
              </span> <img *ngIf="cardIcon" height="16" src="{{cardIcon}}">
            </span>

            <span class="ft-wt-500">
              <ng-container *ngIf="getAmountPaidBy('payment_gateway')">
                <span class="rupee-symbol mar-right-2">₹</span>
                <span>
                    {{getAmountPaidBy('payment_gateway') | indianCurrency}}
                </span>
              </ng-container>
              <ng-container *ngIf="!getAmountPaidBy('payment_gateway')">
                  NIL
              </ng-container>
            </span>
          </div>
          <hr>
        </div>
        <div class="pad-left-15 pad-right-15 back-white" style="border-radius: 0 0 8px 8px;" *ngIf="booking.flight_trip.pricing_summary.base_fare">
          <div class="flex justify-content-between fnt-16 pricing-elements">
            <span class="airline-name">Amount Paid by Reward Points</span>
            <span class="ft-wt-500">
              <ng-container *ngIf="getAmountPaidBy('points')">
                <span class="rupee-symbol mar-right-2">₹</span>
                <span>
                    {{getAmountPaidBy('points') | indianCurrency}}
                </span>
              </ng-container>
              <ng-container *ngIf="!getAmountPaidBy('points')">
                  NIL
              </ng-container>
            </span>
          </div>
        </div>

        <!-- <div class="bg fare-breakup flex align-center pad-left-15 fnt-16">Cancellation</div>
        <div class="pad-left-15 pad-right-15 back-white"
          *ngIf="booking.flight_trip.mini_rules && booking.flight_trip.booking_status=='booking_confirmed'">
          <div class="flex justify-content-between fnt-16 pricing-elements">
            <span class="airline-name">Airlines Cancellation Charges</span><span class="ft-wt-500"><span
                class="rupee-symbol">₹</span>
              {{(booking.flight_trip.mini_rules.onward.airlines_cancellation_charges + ((booking.flight_trip.mini_rules.return | json) != '{}' ?  booking.flight_trip.mini_rules.return.airlines_cancellation_charges : 0))| indianCurrency}}</span>
          </div>
          <hr>
          <div class="flex justify-content-between fnt-16 pricing-elements">
            <span class="airline-name">Partner Cancellation Charges</span><span class="ft-wt-500"><span
                class="rupee-symbol">₹</span>{{(booking.flight_trip.mini_rules.onward.partner_cancellation_charges + ((booking.flight_trip.mini_rules.return | json) != '{}' ? booking.flight_trip.mini_rules.return.partner_cancellation_charges : 0))| indianCurrency}}</span>
          </div>
          <hr>
          <div class="flex justify-content-between fnt-16 pricing-elements">
            <span class="airline-name">Total Cancellation Charges</span><span class="ft-wt-500"><span
                class="rupee-symbol">₹</span>
              {{(booking.flight_trip.mini_rules.onward.total_cancellation_charges + ((booking.flight_trip.mini_rules.return | json) != '{}' ? booking.flight_trip.mini_rules.return.total_cancellation_charges : 0)) | indianCurrency}}</span>
          </div>
        </div>
        <div *ngIf="booking.flight_trip.mini_rules && booking.flight_trip.booking_status=='booking_confirmed'">
          <div class="pad-left-15 pad-right-15 fnt-14 pad-top-15 port"><span class="icon-bullet mar-right-5"
              style="font-size: 8px;"></span>Outbound Cancellation
            Rules: {{booking.flight_trip.mini_rules.onward.note}}</div>
          <div class="pad-left-15 pad-right-15 fnt-14 pad-top-10 port"
            *ngIf="booking.flight_trip.mini_rules.return?.note"><span class="icon-bullet mar-right-5"
              style="font-size: 8px;"></span>Inbound Cancellation
            Rules: {{booking.flight_trip.mini_rules.return.note}}</div>
        </div>
        <div class="pad-left-15 pad-right-15 pad-top-15">
          <button *ngIf="booking.flight_trip.booking_status=='booking_confirmed'" mat-button class=""
            (click)=cancelBooking(booking)>
            <span class="pad-left-20 pad-right-20"></span>Cancel Booking
          </button>
          <div class="fnt-14" *ngIf="status=='cancellation_requested'">Cancellation Requested</div>
        </div>
        <div class="pad-left-15 pad-right-15 back-white"
          *ngIf="booking.flight_trip.booking_status=='booking_cancelled'">
          <div class="flex justify-content-between fnt-16 pricing-elements" *ngIf="booking.flight_trip.refund_amount">
            <span class="airline-name">Refundable Amount</span>
            <span class="ft-wt-500"><span
                class="rupee-symbol">₹</span>{{booking.flight_trip.refund_amount | indianCurrency}}</span>
          </div>
          <div class="flex justify-content-between fnt-16 pricing-elements" *ngIf="!booking.flight_trip.refund_amount">
            <span class="airline-name">Refundable Requested</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="container to-hide1">
    <div style="border: 1px solid var(--primary);    border-radius: 10px;">
      <div class="checkout-message-top d-flex align-items-center justify-content-between header-bg mar-bot-25">
        <img class="img-fluid rewards-hub-header-logo pad-right-8" src="assets/sbic-images/logo.png" style="width: 200px;">
      </div>
      <div>
        <div class="d-xl-none d-lg-none d-md-none">
          <div class="d-flex align-items-center fnt-14">
            <span class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                fill="#ffffff" />
            </svg>
            <span
              class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
            <app-status-label class="status-text mar-top-10 mar-bot-10 mar-right-10" [status]='status'>
            </app-status-label>
          </div>
          <div class="booking-id-mobile d-flex flex-column">
            <div>
              <span class="mar-right-5">Booking ID: </span><span
              class="ft-wt-500 black-color">{{booking?.flight_trip?.unique_id}}</span>
            </div>
            <div>
              <span class="fnt-12 accenttwo">Booked at: {{booking?.flight_trip?.confirmed_at | date: 'dd MMM yyyy, hh:mm a'}}</span>
            </div>
          </div>
        </div>
        <div class="d-none d-sm-none d-md-block">

          <div class="d-flex align-items-center fnt-18 pad-left-20 pad-right-20">
            <span
              class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                fill="#ffffff" />
            </svg>
            <span
              class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
            <app-status-label class="status-text" [status]='status'></app-status-label>
          </div>
          <div class="booking-id mar-top-10 mar-bot-20 pad-left-20 pad-right-20">
            <div>
              <span class="mar-right-5">Booking ID:
              </span><span class="ft-wt-500 black-color">{{booking?.flight_trip?.unique_id}}</span>
            </div>
            <div>
              <span class="fnt-12 accenttwo">Booked at: {{booking?.flight_trip?.confirmed_at | date: 'dd MMM yyyy, hh:mm a'}}</span>
            </div>
          </div>
        </div>
        <div class="booking-confirmed-container">
          <div class="display-card-mobile">
            <div class="display-card">
              <div class="d-none d-sm-none d-md-block pad-left-20 pad-right-20">
                <div
                  class="d-flex bg justify-content-between align-items-center flight-details-strip fnt-16">
                  <div><span
                      class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                        fill="#ffffff" />
                    </svg>
                    <span
                      class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
                    <span class="pad-left-10 pad-right-10">|</span>
                    <span>{{totalTravellers()}} traveller(s)</span>
                    <span class="pad-left-10 pad-right-10">|</span>
                    <span>{{booking.flight_trip.flight_search.depart_date|date:'MMM d, y'}}</span>
                    <span class="pad-left-10 pad-right-10">|</span>
                    <span
                      class="cabin-type">
                      {{(booking.flight_trip.flights[0].segments[1]?.stops != "0")?(booking.flight_trip.flights[0].segments[1]?.stops): 'Non -' }} Stop
                    </span>
                    <!-- <span class="pad-right-10 names">
                    <svg class="mar-right-12 mar-left-10" width="14" height="14" viewBox="0 0 14 14" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.66667 6.66667C8.50833 6.66667 10 5.175 10 3.33333C10 1.49167 8.50833 0 6.66667 0C4.825 0 3.33333 1.49167 3.33333 3.33333C3.33333 5.175 4.825 6.66667 6.66667 6.66667ZM6.66667 8.33333C4.44167 8.33333 0 9.45 0 11.6667V13.3333H13.3333V11.6667C13.3333 9.45 8.89166 8.33333 6.66667 8.33333Z"
                        fill="#64707C" />
                    </svg>
                  </span> -->
                  </div>
                  <!-- <div class="ft-wt-500"><span class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.total_fare}}
                </div> -->
                  <div class="d-none d-sm-block" *ngIf="booking.flight_trip.flight_search.supplier_name">
                    <div class="flex flex-column"><span class="fnt-12">Booking Powered by</span>
                      <img *ngIf="booking.flight_trip.flight_search.supplier_name == 'yatra'" class="powered-img"
                        src="assets/yatra.png">
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-xl-none d-lg-none d-md-none">
                <div
                  class="d-flex bg justify-content-between align-items-center flight-details-strip fnt-16">
                  <div>
                    <div>
                      <span
                        class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
                      <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                          fill="#ffffff" />
                      </svg>
                      <span
                        class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
                    </div>
                    <div class="fnt-12">
                      <span>{{totalTravellers()}} traveller(s)</span>
                      <span class="pad-left-10 pad-right-10">|</span>
                      <span>{{booking.flight_trip.flight_search.depart_date|date:'MMM d, y'}}</span>
                      <span class="pad-left-10 pad-right-10">|</span>
                      <span
                        class="cabin-type">
                          {{(booking.flight_trip.flights[0].segments[1]?.stops != "0")?(booking.flight_trip.flights[0].segments[1]?.stops): 'Non -' }} Stop
                      </span>
                    </div>
                  </div>
                </div>

              </div>

              <div class=" flight-details" *ngFor="let flightDetailsNumb of flightDetailsNum; let i = index">

                <div class="pad-left-20 pad-right-20 row m-0 align-items-center mob-no-padding">
                  <div class="col-lg-2 col-sm-2 col-md-2">
                    <div class="airline-details">

                      <img width="30" height="30"
                        src="{{getAirlineImage(booking.flight_trip.flights[0].segments[flightDetailsNumb].airline)}}">
                      <!-- </div> -->
                      <div class="flex align-items-center flex-column">
                        <div class="fnt-14 airline-name text-center">
                          {{getAirlineName(booking.flight_trip.flights[0].segments[flightDetailsNumb].airline)}}</div>
                        <div class="fnt-12 airline-number">
                          {{booking.flight_trip.flights[0].segments[flightDetailsNumb].airline}}-{{booking.flight_trip.flights[0].segments[flightDetailsNumb].flight_number}}
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="air-info flex pad-top-30 col-lg-8 col-sm-8 col-md-8 pad-bot-30">
                    <div class="l-info">
                      <div class="fnt-16 tim pad-bot-7">
                        {{booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_airport}}
                        <b>{{flightS.removeTimeZoneFromString(booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_date)|date:"HH:mm"}}</b>
                      </div>
                      <div class="fnt-14 dat pad-bot-5">
                        {{flightS.removeTimeZoneFromString(booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_date)|date:"EEE, MMM d"}}
                      </div>
                      <div class="fnt-16 ft-wt-500">
                        {{booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_city_name}}
                      </div>
                      <div class="fnt-12 port">
                        {{booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_airport_name}}
                      </div>
                      <div class="fnt-12 port"
                        *ngIf="booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_terminal">Terminal:
                        {{booking.flight_trip.flights[0].segments[flightDetailsNumb].departure_terminal}}
                      </div>
                    </div>
                    <div class="m-info f500 fnt-14 text-center">
                      <div class="pad-bot-2 mar-bot-5 accenttwo">
                        {{flightDuration(booking.flight_trip.flights[0].segments[flightDetailsNumb].duration)}}</div>
                      <!-- <p>{{getStops(booking.flight_trip.flights[0].segments[flightDetailsNumb])}}</p> -->
                    </div>
                    <div class="l-info text-right">
                      <div class="fnt-16 tim pad-bot-7">
                        <b>{{flightS.removeTimeZoneFromString(booking.flight_trip.flights[0].segments[flightDetailsNumb]['arrival-date'])|date:"HH:mm"}}
                        </b>{{booking.flight_trip.flights[0].segments[flightDetailsNumb].arrival_airport}}
                      </div>
                      <div class="fnt-14 dat pad-bot-5">
                        {{flightS.removeTimeZoneFromString(booking.flight_trip.flights[0].segments[flightDetailsNumb]['arrival-date'])|date:'EEE, MMM d'}}
                      </div>
                      <div class="fnt-16 ft-wt-500">
                        {{booking.flight_trip.flights[0].segments[flightDetailsNumb].arrival_city_name}}
                      </div>
                      <div class="fnt-12 port">
                        {{booking.flight_trip.flights[0].segments[flightDetailsNumb].arrival_airport_name}}
                      </div>
                      <div class="fnt-12 port"
                        *ngIf="booking.flight_trip.flights[0].segments[flightDetailsNumb].arrival_terminal">Terminal:
                        {{booking.flight_trip.flights[0].segments[flightDetailsNumb].arrival_terminal}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bag-bar flex align-center">
                  <span class="ft-wt-500">{{travClass[booking.flight_trip.flight_search.cabin_type]}}</span><span
                    class="pad-left-7">|</span>
                  <span class="bag-indi text-right">
                    <span
                      *ngFor="let baggage of getBaggageInfo(booking.flight_trip.flights[0].segments[flightDetailsNumb],0) | keyvalue;">
                      <span class="ft-wt-500 pad-left-8 pad-right-8">{{baggageType[baggage.key]}}</span>
                      <span>
                        Checkin: <span class="ft-wt-500 pad-left-5 pad-right-10">{{baggage.value.cib}}</span>Cabin:
                        <span class="ft-wt-500 pad-left-5">{{baggage.value.cab}}</span>
                      </span>
                    </span>
                  </span>

                </div>
                <div class="trav-table mar-left-15 mar-right-15 pad-bot-20">
                  <div class="bg ">
                    <div class="pad-left-10 pad-right-10">
                      <div class="pad-top-13 pad-bot-13 fnt-14 row airline-name">
                        <span class="col-4 text-left">Travellers</span>
                        <span class="col-4 text-center">PNR</span>
                        <span class="col-4 text-right">Ticket Number</span>
                      </div>
                      <hr>
                      <div *ngIf="booking.flight_trip.flight_search.supplier_name == 'yatra'">
                        <div *ngFor="let traveller of booking.flight_trip.pax_info_list;let j =index;">
                          <div class="pad-top-13 pad-bot-13 fnt-14 row ft-wt-500 accenttwo">
                            <span class="col-4 text-left ">{{traveller.title}} {{traveller.first_name}}
                              {{traveller.last_name}}
                              <span  class="custom-tag" *ngIf="traveller.type == 'INF'">infant</span>
                              <span  class="custom-tag" *ngIf="traveller.type == 'CHD'">Child</span>
                            </span>
                            <span
                              class="col-4 text-center ">{{booking.flight_trip.flights[0].segments[flightDetailsNumb].airline_pnr}}</span>
                            <span
                              class="col-4 text-right ">{{booking.flight_trip.flights[0].segments[flightDetailsNumb].gds_pnr ? booking.flight_trip.flights[0].segments[flightDetailsNumb].gds_pnr : booking.flight_trip.flights[0].segments[flightDetailsNumb].airline_pnr}}</span>
                          </div>
                          <hr *ngIf="booking.flight_trip.pax_info_list.length!=j+1">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="flightDetailsNum.length !=i+1" class="layover-bar relative text-center">
                  <!-- <div class="line absolute"></div> -->
                  <span
                    class="layover-txt fnt-14 f500 relative">{{layoverTime(booking.flight_trip.flights[0].segments[flightDetailsNumb],booking.flight_trip.flights[0].segments[+flightDetailsNumb+1]) }}
                    layover</span>
                </div>

              </div>

              <!-- <div *ngIf="!flightDetailsReturn" class="pad-left-20 pad-right-20 d-flex align-items-center go-to-home">
              <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 1L1 4L4 7" stroke="#C2A63F" stroke-linecap="round" stroke-linejoin="round" />
              </svg><span class="fnt-14 pad-left-8 f300 pointer" (click)="goToHome()">Go to Home</span>
            </div> -->
            </div>
          </div>
          <div class="display-card-mobile">
            <div class="display-card" *ngIf="flightDetailsReturn">


              <div class=" flight-details align-items-center">
                <div class="d-none d-sm-block d-md-block">
                  <div
                    class="pad-left-20 pad-right-20 bg d-flex justify-content-between align-items-center flight-details-strip fnt-16">
                    <div><span
                        class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
                      <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                          fill="#ffffff" />
                      </svg>
                      <span
                        class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
                      <span class="pad-left-10 pad-right-10">|</span>
                      <span>{{totalTravellers()}} traveller(s)</span>
                      <span class="pad-left-10 pad-right-10">|</span>
                      <span>{{booking.flight_trip.flight_search.return_date|date:'MMM d, y'}}</span>
                      <span class="pad-left-10 pad-right-10">|</span>
                      <span
                        class="cabin-type">
                        {{(booking.flight_trip.flights[1].segments[returnSegmentLength(booking.flight_trip?.flights[1].segments)]?.stops != "0")?(booking.flight_trip.flights[1].segments[returnSegmentLength(booking.flight_trip?.flights[1].segments)]?.stops): 'Non -' }} Stop
                      </span>
                      <!-- <span class="pad-right-10 names">
                      <svg class="mar-right-12 mar-left-10" width="14" height="14" viewBox="0 0 14 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.66667 6.66667C8.50833 6.66667 10 5.175 10 3.33333C10 1.49167 8.50833 0 6.66667 0C4.825 0 3.33333 1.49167 3.33333 3.33333C3.33333 5.175 4.825 6.66667 6.66667 6.66667ZM6.66667 8.33333C4.44167 8.33333 0 9.45 0 11.6667V13.3333H13.3333V11.6667C13.3333 9.45 8.89166 8.33333 6.66667 8.33333Z"
                          fill="#64707C" />
                      </svg>
                    </span> -->
                    </div>
                    <!-- <div class="ft-wt-500"><span class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.total_fare}}
                  </div> -->
                    <div class="d-none d-sm-block" *ngIf="booking.flight_trip.flight_search.supplier_name">
                      <div class="flex flex-column"><span class="fnt-12">Booking Powered by</span>
                        <img *ngIf="booking.flight_trip.flight_search.supplier_name == 'yatra'" class="powered-img"
                          src="assets/yatra.png">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-xl-none d-lg-none d-md-none">
                  <div
                    class="d-flex bg justify-content-between align-items-center flight-details-strip fnt-16">
                    <div>
                      <div>
                        <span
                          class="pad-right-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[flightDetailsOnwReturn].arrival_city_name}}</span>
                        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.3536 4.35355C15.5488 4.15829 15.5488 3.84171 15.3536 3.64645L12.1716 0.464466C11.9763 0.269204 11.6597 0.269204 11.4645 0.464466C11.2692 0.659728 11.2692 0.976311 11.4645 1.17157L14.2929 4L11.4645 6.82843C11.2692 7.02369 11.2692 7.34027 11.4645 7.53553C11.6597 7.7308 11.9763 7.7308 12.1716 7.53553L15.3536 4.35355ZM0 4.5H15V3.5H0V4.5Z"
                            fill="#ffffff" />
                        </svg>
                        <span
                          class="pad-left-10 ft-wt-500">{{booking.flight_trip.flights[0].segments[1].departure_city_name}}</span>
                      </div>
                      <div class="fnt-12">
                        <span>{{totalTravellers()}} traveller(s)</span>
                        <span class="pad-left-10 pad-right-10">|</span>
                        <span>{{booking.flight_trip.flight_search.return_date|date:'MMM d, y'}}</span>
                        <span class="pad-left-10 pad-right-10">|</span>
                        <span
                          class="cabin-type">
                          {{(booking.flight_trip.flights[1].segments[returnSegmentLength(booking.flight_trip?.flights[1].segments)]?.stops != "0")?(booking.flight_trip.flights[1].segments[returnSegmentLength(booking.flight_trip?.flights[1].segments)]?.stops): 'Non -' }} Stop
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class=" flight-details" *ngFor="let flightDetailsNumb of flightDetailsReturn; let i = index">
                  <!-- <div class="row"> -->
                  <div class="pad-left-20 pad-right-20 row m-0 align-items-center mob-no-padding">
                    <div class="col-lg-2 col-sm-2 col-md-2">
                      <div class="airline-details">
                        <!-- <div> -->
                        <img width="30" height="30"
                          src="{{getAirlineImage(booking.flight_trip.flights[1].segments[flightDetailsNumb].airline)}}">
                        <!-- </div> -->
                        <div class="flex align-items-center flex-column">
                          <div class="fnt-14 airline-name text-center">
                            {{getAirlineName(booking.flight_trip.flights[1].segments[flightDetailsNumb].airline)}}</div>
                          <div class="fnt-12 airline-number">
                            {{booking.flight_trip.flights[1].segments[flightDetailsNumb].airline}}-{{booking.flight_trip.flights[1].segments[flightDetailsNumb].flight_number}}
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="air-info flex pad-top-30 col-lg-8 col-sm-8 col-md-8 pad-bot-30">
                      <div class="l-info">
                        <div class="fnt-16 tim pad-bot-7">
                          {{booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_airport}}
                          <b>{{flightS.removeTimeZoneFromString(booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_date)|date:"HH:mm"}}</b>
                        </div>
                        <div class="fnt-14 dat pad-bot-5">
                          {{flightS.removeTimeZoneFromString(booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_date)|date:"EEE, MMM d"}}
                        </div>
                        <div class="fnt-16 ft-wt-500">
                          {{booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_city_name}}
                        </div>
                        <div class="fnt-12 port">
                          {{booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_airport_name}}
                        </div>

                        <div class="fnt-12 port"
                          *ngIf="booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_terminal">
                          Terminal:
                          {{booking.flight_trip.flights[1].segments[flightDetailsNumb].departure_terminal}}
                        </div>
                      </div>
                      <div class="m-info f500 fnt-14 text-center">
                        <div class="pad-bot-2 mar-bot-5 accenttwo">
                          {{flightDuration(booking.flight_trip.flights[1].segments[flightDetailsNumb].duration)}}</div>
                      </div>
                      <div class="l-info text-right">
                        <div class="fnt-16 tim pad-bot-7">
                          <b>{{flightS.removeTimeZoneFromString(booking.flight_trip.flights[1].segments[flightDetailsNumb]['arrival-date'])|date:"HH:mm"}}
                          </b>{{booking.flight_trip.flights[1].segments[flightDetailsNumb].arrival_airport}}
                        </div>
                        <div class="fnt-14 dat pad-bot-5">
                          {{flightS.removeTimeZoneFromString(booking.flight_trip.flights[1].segments[flightDetailsNumb]['arrival-date'])|date:'EEE, MMM d'}}
                        </div>
                        <div class="fnt-16 ft-wt-500">
                          {{booking.flight_trip.flights[1].segments[flightDetailsNumb].arrival_city_name}}
                        </div>
                        <div class="fnt-12 port">
                          {{booking.flight_trip.flights[1].segments[flightDetailsNumb].arrival_airport_name}}
                        </div>
                        <div class="fnt-12 port"
                          *ngIf="booking.flight_trip.flights[1].segments[flightDetailsNumb].arrival_terminal">Terminal:
                          {{booking.flight_trip.flights[1].segments[flightDetailsNumb].arrival_terminal}}
                        </div>
                      </div>


                    </div>
                    <!-- <div class="pad-left-10 names">
                    <div> PNR: {{getPNR(flightDetailsNumb)}}</div>
                    <div>Ticket Number: {{getTicket(flightDetailsNumb)}}</div>
                  </div> -->
                  </div>
                  <!-- </div> -->
                  <div class="bag-bar flex align-center">
                    <span
                      class="ft-wt-500">{{travClass[booking.flight_trip.flight_search.cabin_type]}}</span><span
                      class="pad-left-7">|</span>
                    <span class="bag-indi text-right">
                      <span
                        *ngFor="let baggage of getBaggageInfo(booking.flight_trip.flights[1].segments[flightDetailsNumb],1)|keyvalue">
                        <span class="ft-wt-500 pad-left-8 pad-right-8">{{baggageType[baggage.key]}}</span>
                        <span>
                          Checkin: <span class="ft-wt-500 pad-left-5 pad-right-10">{{baggage.value.cib}}</span>Cabin:
                          <span class="ft-wt-500 pad-left-5">{{baggage.value.cab}}</span>
                        </span>
                      </span>
                    </span>


                  </div>
                  <div class="trav-table mar-left-15 mar-right-15 pad-bot-20">
                    <div class="bg ">
                      <div class="pad-left-10 pad-right-10">
                        <div class="pad-top-13 pad-bot-13 fnt-14 row airline-name">
                          <span class="col-4 text-left">Travellers</span>
                          <span class="col-4 text-center">PNR</span>
                          <span class="col-4 text-right">Ticket Number</span>
                        </div>
                        <hr>
                        <div *ngIf="booking.flight_trip.flight_search.supplier_name == 'yatra'">
                          <div *ngFor="let traveller of booking.flight_trip.pax_info_list;let j =index;">
                            <div class="pad-top-13 pad-bot-13 fnt-14 row ft-wt-500 accenttwo">
                              <span class="col-4 text-left ">{{traveller.title}} {{traveller.first_name}}
                                {{traveller.last_name}}
                                <span  class="custom-tag" *ngIf="traveller.type == 'INF'">infant</span>
                                <span  class="custom-tag" *ngIf="traveller.type == 'CHD'">Child</span>
                              </span>
                              <span
                                class="col-4 text-center ">{{booking.flight_trip.flights[1].segments[flightDetailsNumb].airline_pnr}}</span>
                              <span
                                class="col-4 text-right ">{{booking.flight_trip.flights[1].segments[flightDetailsNumb].gds_pnr ? booking.flight_trip.flights[1].segments[flightDetailsNumb].gds_pnr : booking.flight_trip.flights[1].segments[flightDetailsNumb].airline_pnr}}</span>
                            </div>
                            <hr *ngIf="booking.flight_trip.pax_info_list.length!=j+1">
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div *ngIf="flightDetailsReturn.length !=i+1" class="layover-bar relative text-center">
                    <!-- <div class="line absolute"></div> -->
                    <span
                      class="layover-txt fnt-14 f500 relative">{{layoverTime(booking.flight_trip.flights[1].segments[flightDetailsNumb],booking.flight_trip.flights[1].segments[+flightDetailsNumb+1]) }}
                      layover</span>
                  </div>

                </div>


              </div>
              <!-- <div class="bg fare-breakup flex align-center pad-left-15 fnt-16">Fare Breakup</div>
            <div class="pad-left-15 pad-right-15 back-white"
              *ngFor="let priceElem of booking.flight_trip.pricing_summary| keyvalue">
              <div class="flex justify-content-between fnt-16 pricing-elements" *ngIf="priceElem.value">
                <span class="airline-name">{{priceElem.key|removeUnderscore}}</span><span
                  class="ft-wt-500">{{priceElem.value| indianCurrency}}</span>
              </div>
              <hr *ngIf="priceElem.value">
            </div>
            <div class="pad-left-20 pad-right-20 d-flex align-items-center go-to-home">
              <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 1L1 4L4 7" stroke="#C2A63F" stroke-linecap="round" stroke-linejoin="round" />
              </svg><span class="fnt-14 pad-left-8 f300 pointer" (click)="goToHome()">Go to Home</span>
            </div> -->
            </div>
          </div>
          <div class="bg fare-breakup flex align-center pad-left-15 fnt-16">Fare Breakup</div>
          <!-- <div class="pad-left-15 pad-right-15 back-white"
            *ngFor="let priceElem of booking.flight_trip.pricing_summary| keyvalue">
            <div class="flex justify-content-between fnt-16 pricing-elements" *ngIf="priceElem.value">
              <span class="airline-name">{{priceElem.key|removeUnderscore}}</span><span class="ft-wt-500"><span
                  class="rupee-symbol">₹</span> {{priceElem.value| indianCurrency}}</span>
            </div>
            <hr *ngIf="priceElem.value">
          </div> -->
          <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.base_fare">
            <div class="flex justify-content-between fnt-16 pricing-elements">
              <span class="airline-name">Base Fare</span><span class="ft-wt-500"><span
                  class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.base_fare| indianCurrency}}</span>
            </div>
            <hr>
          </div>
          <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.taxes">
            <div class="flex justify-content-between fnt-16 pricing-elements">
              <span class="airline-name">Taxes</span><span class="ft-wt-500">
                <span class="mar-right-2">+</span>
                <span class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.taxes| indianCurrency}}
                <span class="icon-info taxes-info" triggers="mouseenter:mouseleave" [popover]="taxesTemplate"
                  popoverTitle="Taxes and Surcharges"></span>

              </span>
            </div>

            <ng-template #taxesTemplate>
              <div *ngFor="let pricingElem of booking.flight_trip.pax_pricing_info_list.pricing_elements;let j=index">
                <div class="flex justify-content-between" *ngIf="pricingElem.amount && pricingElem.category != 'BF'">
                  <span>{{pricingElem.code}}</span><span class="pad-left-25"><span
                      class="rupee-symbol">₹</span>{{pricingElem.amount|indianCurrency}}</span>
                </div>
              </div>
            </ng-template>

            <hr>
          </div>
          <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.convenience_fee">
            <div class="flex justify-content-between fnt-16 pricing-elements">
              <span class="airline-name">Convenience Fee</span><span class="ft-wt-500">
                <span class="mar-right-2">+</span>
                <span
                  class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.convenience_fee| indianCurrency}}</span>
            </div>
            <hr>
          </div>
          <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.discount">
            <div class="flex justify-content-between fnt-16 pricing-elements" style="color: var(--primary);">
              <span class="airline-name" style="color: var(--primary);">Coupon Discount </span>
              <span class="ft-wt-500">
                <span class="mar-right-2">-</span>
                <span
                  class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.discount| indianCurrency}}</span>
            </div>
            <hr>
          </div>
          <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.markup">
            <div class="flex justify-content-between fnt-16 pricing-elements">
              <span class="mar-right-2">+</span>
              <span class="airline-name">Markup </span><span class="ft-wt-500"><span
                  class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.markup| indianCurrency}}</span>
            </div>
            <hr>
          </div>
          <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.pricing_summary.total_fare">
            <div class="flex justify-content-between fnt-16 pricing-elements">
              <span class="airline-name">Total Fare </span><span class="ft-wt-500"><span
                  class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.total_fare| indianCurrency}}</span>
            </div>
            <!-- <hr> -->
          </div>
          <!-- <div class="pad-left-15 pad-right-15 flex justify-content-between fnt-14 pad-top-15 pad-bot-15">
            <span class="airline-name ft-wt-500">Amount Paid by card</span>
            <span class="f500"><span
                class="rupee-symbol">₹</span>{{booking.flight_trip.pricing_summary.total_fare | indianCurrency}}</span>
          </div> -->
          <div class="bg fare-breakup flex align-center pad-left-15 fnt-16">Cancellation</div>
          <div class="pad-left-15 pad-right-15 back-white" *ngIf="booking.flight_trip.mini_rules">
            <div class="flex justify-content-between fnt-16 pricing-elements">
              <span class="airline-name">Airlines Cancellation Charges</span><span class="ft-wt-500"><span
                  class="rupee-symbol">₹</span>
                <!-- {{booking.flight_trip.mini_rules.onward.airlines_cancellation_charges}} -->
                {{(booking.flight_trip.mini_rules.onward.airlines_cancellation_charges + ((booking.flight_trip.mini_rules.return | json) != '{}' ?  booking.flight_trip.mini_rules.return.airlines_cancellation_charges : 0))| indianCurrency}}</span>
            </div>
            <hr>
            <div class="flex justify-content-between fnt-16 pricing-elements">
              <span class="airline-name">Partner Cancellation Charges</span><span class="ft-wt-500"><span
                  class="rupee-symbol">₹</span>{{(booking.flight_trip.mini_rules.onward.partner_cancellation_charges + ((booking.flight_trip.mini_rules.return | json) != '{}' ? booking.flight_trip.mini_rules.return.partner_cancellation_charges : 0))| indianCurrency}}</span>
            </div>
            <hr>
            <div class="flex justify-content-between fnt-16 pricing-elements">
              <span class="airline-name">Total Cancellation Charges</span><span class="ft-wt-500"><span
                  class="rupee-symbol">₹</span>
                {{(booking.flight_trip.mini_rules.onward.total_cancellation_charges + ((booking.flight_trip.mini_rules.return | json) != '{}' ? booking.flight_trip.mini_rules.return.total_cancellation_charges : 0)) | indianCurrency}}</span>
            </div>
          </div>
          <div *ngIf="booking.flight_trip.mini_rules">
            <div class="pad-left-15 pad-right-15 fnt-14 pad-top-15 port"><span class="icon-bullet mar-right-5"
                style="font-size: 8px;"></span>Outbound Cancellation
              Rules: {{booking.flight_trip.mini_rules.onward.note}}</div>
            <div class="pad-left-15 pad-right-15 fnt-14 pad-top-10 port"
              *ngIf="booking.flight_trip.mini_rules.return?.note"><span class="icon-bullet mar-right-5"
                style="font-size: 8px;"></span>Inbound Cancellation
              Rules: {{booking.flight_trip.mini_rules.return.note}}</div>
          </div>
          <div class="pad-left-15 pad-right-15 pad-top-15">
            <button *ngIf="booking.flight_trip.booking_status=='booking_confirmed'" mat-button class="primary-btn"
              (click)=cancelBooking(booking)>
              <span class="pad-left-20 pad-right-20"></span>Cancel Booking
            </button>
            <div class="fnt-14" *ngIf="status=='cancellation_requested'">Cancellation Requested</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<app-loader *ngIf="progress"></app-loader>
