<div class="d-flex flex-column booking-container" *ngIf="!showLoader">
  <div class="d-flex align-items-center flex-column confirmation-container">
    <img
      class="confirmation-icon"
      [src]="
        isSuccess
          ? 'assets/transfer-success-icon.png'
          : 'assets/transfer-failure-icon.png'
      "
      alt="tick"
    />
    <span class="confirmation-text">{{
      isSuccess ? "Transfer Initiated Successfully" : "Transfer Request Failed"
    }}</span>
    <span
      class="confirmation-sub-text"
      [ngClass]="{ 'failure-text': !isSuccess }"
      >{{
        isSuccess
          ? " Points will be credited to your "+  transferDetails?.attributes?.points_provider?.name +" account "
          : "Sorry, we couldn’t complete your transfer request. Please try again!"
      }}
    </span>
    <span class="user-details">{{
      isSuccess
        ? "You will receive the transfer details shortly on your mobile " +
          transferDetails?.attributes?.user_points_provider_account?.mobile +
          " & email " +
          transferDetails?.attributes?.user_points_provider_account?.email
        : "Any points deducted will be refunded within a day."
    }}</span>
  </div>
  <div class="booking-details d-flex flex-column">
    <div class="booking-card d-flex align-items-center position-relative">
      <img
        class="merchant-logo img-fluid"
        [src]="
          transferDetails?.attributes?.points_provider?.logo ||
          appConstants.FALLBACK_IMAGE
        "
      />
      <div class="d-flex flex-column flex-grow-1">
        <div class="merchant-title d-flex align-items-center justify-content-between">
          <span class="merchant-points text-white">
            {{ transferDetails?.attributes?.points }}
            {{
              transferDetails?.attributes?.points_provider?.currency_name
            }}
          </span>
          <span [ngClass]="{'try-again-failure-class':!isSuccess}" class="details-text pointer" (click)="handleRedirection(isSuccess)">{{
            isSuccess ? "View Details" : "Try Again"
          }}</span>
      </div>
        <span class="merchant-points">
          {{
            transferDetails?.attributes?.points_provider?.name
          }} ID :
          <span class="text-white">
            {{transferDetails?.attributes?.user_points_provider_account?.account_id}}
          </span>
          </span>
          <span *ngIf="isSuccess" class="merchant-points">
            Transfer Time: {{transferDetails?.attributes?.points_provider?.transfer_type ?? 'N.A.'}}
            </span>
      </div>
      <!-- <div class="flex-fill"></div> -->

    </div>
    <ngb-carousel *ngIf="filteredBanners?.length">
      <ng-template ngbSlide *ngFor="let banner of filteredBanners;">
        <a href="{{ banner?.attributes?.banner_url }}">
          <img class="banner-image" [src]="banner?.attributes?.banner_image" default="./assets/default_image.png">
        </a>
      </ng-template>
    </ngb-carousel>
  </div>
  <div class="back-to-home-container" (click)="navigateToHome()">
    <button
      class="back-to-home-cta d-flex align-items-center justify-content-center"
    >
      Go to home
    </button>
  </div>
</div>
<app-loader *ngIf="showLoader"></app-loader>
