<div class="tours-container relative" *ngIf="!progress">
    <div class="tours-page-heading">
    <h1 class="sp-heading gold mb-20 text-center">Say Hello to new experiences using AURUM Reward Points</h1>
</div>
    <div class="tours-search-bar container mar-top-2">
        <div class="row tours-search-row">
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-md-12 col-lg-5 home-input-fields form-field relative" dropdown #dropdown="bs-dropdown" (click)="openCityDrop()">
                        <div class="form-field__control" dropdownToggle>
                            <input #cityDropdown id="exampleFieldOrigin" type="text"
                                placeholder=" "
                                class='form-field__input arrows text-truncate' [formControl]='cityInput'
                                (input)="cityInputChange()">
                            <label for="exampleFieldOrigin" class="form-field__label">Select your destination</label>
                            <div class="form-field__bar"></div>
                        </div>
                        <div class="dropdown-menu w100" *dropdownMenu>
                            <div class="dropdown location-dropdown" *ngIf="!citiesList.length">
                                <div class="w100">
                                    <div class="option-item ellipsis">
                                        <div class="option-item-inner">
                                            No results found
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='w100' *ngIf="citiesList.length">
                                <div *ngIf="!filterCityList" class="two-col-dropdown">
                                    <div class="option-item ellipsis" *ngFor="let where of citiesList">
                                        <div class="option-item-inner" (mousedown)="setSelectedCity(where)">
                                            {{where.name}}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="filterCityList && (citiesList | filter: cityInput.value : 'name') as result" class="two-col-dropdown">
                                    <div class="option-item ellipsis" *ngFor="let where of result">
                                        <div class="option-item-inner" (mousedown)="setSelectedCity(where)">
                                            {{where.name}}
                                        </div>
                                    </div>
                                    <div class="option-item ellipsis" *ngIf="result.length == 0">
                                        <div class="option-item-inner">
                                            No results found
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-7 home-input-fields form-field relative" dropdown (click)="openActivityDrop()">
                        <div class="form-field__control" dropdownToggle>
                            <input  id="exampleActivityOrigin" type="text" class="form-field__input arrows text-truncate" [formControl]='activityInput' [(ngModel)]="activityInput.value" placeholder=" "
                             type="text" (input)="activityInputChange();"/>
                            <label for="exampleActivityOrigin" class="form-field__label">Search for activities</label>
                            <div class="form-field__bar"></div>
                        </div>
                        <div  class="dropdown-menu w100" *dropdownMenu>
                            <div class="dropdown location-dropdown"
                            *ngIf="!isLoading && !(activityList.length)">
                            <div class="w100">
                                <div class="option-item ellipsis">
                                    <div class="option-item-inner">
                                        No results found
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dropdown location-dropdown" *ngIf="isLoading">
                            <div class="w100">
                                <div class="option-item ellipsis">
                                    <div class="option-item-inner">
                                        Loading...
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dropdown location-dropdown">
                            <div class='w100'>
                                <div *ngIf="!filterActivityList">
                                    <div class="option-item ellipsis d-flex" *ngFor="let where of activityList"
                                        (mousedown)="setSelectedActivity(where)">
                                        <img [src]="where.banner_url?where.banner_url:'assets/default_image.png'"
                                            class="activity-image">
                                        <div class="option-item-inner">
                                            {{where.name}}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="filterActivityList && (activityList | filter: activityInput.value : 'name') as result">
                                    <div class="option-item ellipsis d-flex" *ngFor="let where of result"
                                        (mousedown)="setSelectedActivity(where)">
                                        <img [src]="where.banner_url?where.banner_url:'assets/default_image.png'"
                                            class="activity-image">
                                        <div class="option-item-inner">
                                            {{where.name}}
                                        </div>
                                    </div>
                                    <div class="option-item ellipsis" *ngIf="result.length == 0">
                                        <div class="option-item-inner">
                                            No results found
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-md-12 col-lg-2 d-flex home-input-fields">
                <div class="col-12 align-self-center home-enquire-button no-margin no-padding main-button">
                    <button mat-button class="w100" (click)="search()">Search
                        <span class="mar-left-10 loader btn-loader-default" *ngIf="clickedSearch"></span>
                    </button>
                </div>
            </div>
        </div>

        <div class="tours-view-all d-block d-lg-none">
            <span [routerLink]="'/tours-and-activities/listing/'+selectedCity.name" class="view-all">View All
                Experiences In
                {{selectedCity.name}}<span class="icon-right"></span></span>
        </div>

        <section class="all-mob-dropdowns d-lg-none d-xl-none" [ngClass]="{'active' : dropDownPopOpen}"
            *ngIf="dropDownPopOpen">
            <div class="close-bar flex align-center">
                <div class="close-icon-contain mar-top-15" (click)="dropDownPopOpen=false;removeModalOpen();">
                    <span class="icon-cancel"></span>
                </div>
            </div>
            <!-- City Drop -->

            <div id="origin-drop" *ngIf="inputToShow == 'cityDrop'">
                <div class="flex line-1 justify-center">
                    <div class="img-box">
                        <span class="icon-group fnt-18"></span>
                    </div>
                    <div class="txt-box fnt-16 f500 pad-top-2">Select your destination</div>
                </div>
                <div class="input-contain mar-top-20">
                    <input type="text" class="form-control fnt-14" [formControl]='cityInput'
                        [(ngModel)]="cityInput.value" (input)="cityInputChange()" placeholder="Select your destination">
                </div>
                <div class="option-contain pad-top-10">
                    <div class="dropdown location-dropdown" *ngIf="!citiesList.length">
                        <div class="show dropdown-menu w100">
                            <div class="option-item ellipsis">
                                <div class="option-item-inner">
                                    No results found
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dropdown location-dropdown" *ngIf="citiesList?.length">
                        <div *ngIf="!filterCityList">
                            <div class="option-item ellipsis" *ngFor="let where of citiesList">
                                <div class="option-item-inner" (mousedown)="setSelectedCity(where)">
                                    {{where.name}}
                                </div>
                            </div>
                        </div>

                        <div *ngIf="filterCityList && (citiesList | filter: cityInput.value : 'name') as result">
                            <div class="option-item ellipsis" *ngFor="let where of result">
                                <div class="option-item-inner" (mousedown)="setSelectedCity(where)">
                                    {{where.name}}
                                </div>
                            </div>
                            <div class="option-item ellipsis" *ngIf="result.length == 0">
                                <div class="option-item-inner">
                                    No results found
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="map-done w100 d-block d-lg-none">
                        <button class="w100 primary-btn height-45" (click)="dropDownPopOpen=false;removeModalOpen();"
                            mat-button>Done</button>
                    </div>
                </div>
            </div>

            <!-- City Drop -->

            <!-- Activity Drop -->

            <div id="origin-drop" *ngIf="inputToShow == 'activityDrop'">
                <div class="flex line-1 justify-center">
                    <div class="icon-search">
                    </div>
                    <div class="txt-box fnt-16 f500 pad-top-2">Search for activities</div>
                </div>
                <div class="input-contain mar-top-20">
                    <input type="text" class="form-control fnt-14" [formControl]='activityInput'
                        [(ngModel)]="activityInput.value" (input)="activityInputChange()"
                        [placeholder]="'Search for activities in ' + selectedCity.name">
                </div>
                <div class="mob-drop-heading">All activities in {{selectedCity.name}}</div>
                <div class="option-contain pad-top-10">
                    <div class="dropdown location-dropdown" *ngIf="!activityList.length">
                        <div class="show dropdown-menu w100">
                            <div class="option-item ellipsis">
                                <div class="option-item-inner">
                                    No results found
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dropdown location-dropdown" *ngIf="activityList?.length">
                        <div *ngIf="!filterActivityList">
                            <div class="option-item ellipsis" *ngFor="let where of activityList" style="display: flex;"
                                (mousedown)="setSelectedActivity(where)">
                                <img [src]="where.banner_url?where.banner_url:'assets/default_image.png'"
                                    class="activity-image">
                                <div class="option-item-inner">
                                    {{where.name}}
                                </div>
                            </div>

                        </div>

                        <div
                            *ngIf="filterActivityList && (activityList | filter: activityInput.value : 'name') as result">
                            <div class="option-item ellipsis" *ngFor="let where of result" style="display: flex;"
                                (mousedown)="setSelectedActivity(where)">
                                <img [src]="where.banner_url?where.banner_url:'assets/default_image.png'"
                                    class="activity-image">
                                <div class="option-item-inner">
                                    {{where.name}}
                                </div>
                            </div>
                            <div class="option-item ellipsis" *ngIf="result.length == 0">
                                <div class="option-item-inner">
                                    No results found
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="map-done w100 d-block d-lg-none">
                        <button class="w100 primary-btn height-45" (click)="dropDownPopOpen=false;removeModalOpen();"
                            mat-button>Done</button>
                    </div>
                </div>
            </div>

            <!-- Activity Drop -->


        </section>
    </div>


</div>


<div class="secondary-background  sp-section  dark3 d-none d-sm-block" *ngIf="!progress">
    <div class="header">
        <h1 class="list-heading sp-heading gold center mb-30">Popular Experiences in {{selectedCity.name}}</h1>
        <div [routerLink]="'/tours-and-activities/listing/'+selectedCity.name" class="text-center mb-60 pointer">View All 
            <a class="sp-link arrow-link"></a></div>
        <!-- <span [routerLink]="'/tours-and-activities/listing/'+selectedCity.name" class="view-all pointer">View All <span
                class="icon-right"></span></span> -->
    </div>

    <div class="listing-container">
        <div class="d-inline-flex justify-content-end dots-spinner" *ngIf="isLoading">
            <div class="dot-flashing" style="margin: auto"></div>
        </div>
        <div *ngIf="!isLoading && !activityList?.length" class="no-results">
            No results found.
        </div>

        <ng-container *ngFor="let activity of activityList; let index=index">
            <div *ngIf="index<4" class="col-lg-3 col-md-3 col-sm-12">
                <app-activity-card [activity]="activity" [exchangeRates]="exchangeRates"></app-activity-card>
            </div>
        </ng-container>

    </div>
</div>

<app-loader *ngIf="progress"></app-loader>