<div *ngIf="!progress; else progressblock">
    <div *ngIf="!bookings?.length" class="no-rooms">
        No bookings found !
    </div>

    <div class="nat-geo-item d-block d-sm-none"
        *ngFor="let booking of bookings | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }"
        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
        <div class="d-flex justify-content-between align-items-center pointer">
            <div class="d-flex justify-content-start align-items-center">
                <div class="mar-right-15">
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17.5" r="17.5" fill="#C2A63F" />
                        <g clip-path="url(#clip0)">
                            <path d="M16.0531 22.2009H14.9863L15.4531 23.9068H16.5197L16.0531 22.2009Z" fill="white" />
                            <path d="M20.0606 22.2009H18.994L18.5273 23.9068H19.5941L20.0606 22.2009Z" fill="white" />
                            <path d="M17.0088 22.2009H18.0377V23.9068H17.0088V22.2009Z" fill="white" />
                            <path
                                d="M15.845 21.1721C15.1659 19.102 14.5169 16.2951 14.5169 13.9236C14.5169 12.1324 14.7883 10.4407 15.2811 9.16026C15.4133 8.81657 15.5596 8.51041 15.7177 8.24268C13.3108 9.00922 11.5625 11.2659 11.5625 13.9236C11.5625 15.9894 12.8387 18.8151 14.986 21.1721H15.845Z"
                                fill="white" />
                            <path
                                d="M20.5293 13.9236C20.5293 16.2951 19.8803 19.1018 19.2012 21.1721H20.0602C22.2076 18.8151 23.4837 15.9894 23.4837 13.9236C23.4837 11.2659 21.7353 9.00922 19.3284 8.24268C19.4866 8.51041 19.6329 8.81657 19.7651 9.16026C20.2579 10.4407 20.5293 12.1324 20.5293 13.9236Z"
                                fill="white" />
                            <path
                                d="M16.93 21.1721H18.1166C18.8065 19.1598 19.5005 16.3 19.5005 13.9236C19.5005 12.2557 19.2537 10.6952 18.805 9.52975C18.4332 8.56331 17.9419 7.96289 17.5233 7.96289C17.1045 7.96289 16.6132 8.56331 16.2414 9.52975C15.7929 10.6952 15.5459 12.2556 15.5459 13.9235C15.5459 16.3002 16.24 19.1598 16.93 21.1721Z"
                                fill="white" />
                            <path
                                d="M15.8398 25.7096C15.8398 26.4006 16.402 26.9629 17.0932 26.9629H17.952C18.6432 26.9629 19.2053 26.4006 19.2053 25.7096V24.9357H15.8398V25.7096Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect x="8" y="8" width="19" height="19" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </div>
                <div >
                    <div class="nat-geo-item-title">
                        {{booking?.attributes.booking_data.golf_course_details.name}}</div>
                    <div class="nat-geo-item-details">
                        {{booking?.attributes.booking_data.golf_course_details.address}}
                    </div>
                </div>
            </div>
            <div class="status-text d-flex">
                <div>
                    <app-status-label class="status-text"
                        [status]="getStatus(booking.attributes?.booking_data?.booking_date_time, booking.attributes.status)">
                    </app-status-label>
                </div>
            </div>
        </div>
    </div>
    <div class="nat-geo-item d-none d-sm-block"
        *ngFor="let booking of bookings  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNo }">
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-start align-items-center w80">
                    <div class="mar-right-15">
                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="17.5" cy="17.5" r="17.5" fill="#C2A63F" />
                            <g clip-path="url(#clip0)">
                                <path d="M16.0531 22.2009H14.9863L15.4531 23.9068H16.5197L16.0531 22.2009Z"
                                    fill="white" />
                                <path d="M20.0606 22.2009H18.994L18.5273 23.9068H19.5941L20.0606 22.2009Z"
                                    fill="white" />
                                <path d="M17.0088 22.2009H18.0377V23.9068H17.0088V22.2009Z" fill="white" />
                                <path
                                    d="M15.845 21.1721C15.1659 19.102 14.5169 16.2951 14.5169 13.9236C14.5169 12.1324 14.7883 10.4407 15.2811 9.16026C15.4133 8.81657 15.5596 8.51041 15.7177 8.24268C13.3108 9.00922 11.5625 11.2659 11.5625 13.9236C11.5625 15.9894 12.8387 18.8151 14.986 21.1721H15.845Z"
                                    fill="white" />
                                <path
                                    d="M20.5293 13.9236C20.5293 16.2951 19.8803 19.1018 19.2012 21.1721H20.0602C22.2076 18.8151 23.4837 15.9894 23.4837 13.9236C23.4837 11.2659 21.7353 9.00922 19.3284 8.24268C19.4866 8.51041 19.6329 8.81657 19.7651 9.16026C20.2579 10.4407 20.5293 12.1324 20.5293 13.9236Z"
                                    fill="white" />
                                <path
                                    d="M16.93 21.1721H18.1166C18.8065 19.1598 19.5005 16.3 19.5005 13.9236C19.5005 12.2557 19.2537 10.6952 18.805 9.52975C18.4332 8.56331 17.9419 7.96289 17.5233 7.96289C17.1045 7.96289 16.6132 8.56331 16.2414 9.52975C15.7929 10.6952 15.5459 12.2556 15.5459 13.9235C15.5459 16.3002 16.24 19.1598 16.93 21.1721Z"
                                    fill="white" />
                                <path
                                    d="M15.8398 25.7096C15.8398 26.4006 16.402 26.9629 17.0932 26.9629H17.952C18.6432 26.9629 19.2053 26.4006 19.2053 25.7096V24.9357H15.8398V25.7096Z"
                                    fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect x="8" y="8" width="19" height="19" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <div>
                        <div>
                            <span class="hotel_name">
                                {{booking?.attributes.booking_data.golf_course_details.name}}
                            </span>
                        </div>
                        <div>
                            <span class="from-to">
                                {{booking?.attributes.booking_data.golf_course_details.address}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="status-text">
                    <div>
                        <app-status-label class="status-text"
                            [status]="getStatus(booking.attributes?.booking_data?.booking_date_time, booking.attributes.status)">
                        </app-status-label>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="bg">
            <div class="d-flex justify-content-between">
                <div class="booking-id-detail">
                    <div>
                        <span>Booking ID : </span>
                        <span class="id">{{booking.attributes?.booking_unique_id}}</span>
                    </div>
                </div>
                <div>
                    <span class="cancel-booking pointer fnt-14" *ngIf="booking.attributes.status == 'booking_confirmed'"
                        (click)="cancelBooking(booking,
                        booking?.attributes.booking_data.booking_date_time,booking?.attributes.amount)">Request Cancellation</span>
                    <span *ngIf="booking.attributes.status == 'booking_confirmed'"
                        class="pad-left-8 pad-right-8 pipe">|</span>

                    <span class="cancel-ticket pointer"
                        (click)="openBookingDetails(booking.attributes.booking_unique_id)">
                        View Details
                    </span>
                    <span>
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 10.5L5.5 6L1.5 1.5" stroke="#C2A63F" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <hr>
        <div class="adfasdas">
            <div class="desktop-body d-flex align-items-center justify-content-between">
                <div class="check-in-out">
                    Date
                </div>
                <div class="check-in-out-txt">
                    {{booking?.attributes.booking_data.booking_date_time|date:'EEEE, MMM d, y'}}
                </div>
            </div>
            <hr>
            <div class="desktop-body d-flex align-items-center justify-content-between">
                <div class="check-in-out">
                    Time
                </div>
                <div class="check-in-out-txt">
                    {{booking?.attributes.booking_data.booking_date_time|date:'hh:mm a'}}
                </div>
            </div>
            <hr>
            <div class="desktop-body d-flex align-items-center justify-content-between">
                <div class="check-in-out">
                    Number of people
                </div>
                <div class="check-in-out-txt"><span *ngIf="booking?.attributes.booking_data.user_pack.gentlemen">
                    {{booking?.attributes.booking_data.user_pack.gentlemen}} Gentleman
                  </span>
                  <span *ngIf="booking?.attributes.booking_data.user_pack.ladies">
                    {{booking?.attributes.booking_data.user_pack.ladies}} Ladies
                  </span>
                  <span *ngIf="booking?.attributes.booking_data.user_pack.kids">
                    {{booking?.attributes.booking_data.user_pack.kids}} Kids
                  </span>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center w100 my-5">
        <pagination-controls *ngIf="bookings?.length>10" responsive="true" (pageChange)="pageNo = $event; moveUp();">
        </pagination-controls>
    </div>
</div>
<ng-template #progressblock>
<div class="d-flex justify-content-center w100 my-5">
    <div class="dot-flashing" *ngIf="progress"></div>
</div>
</ng-template>