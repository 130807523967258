<!-- <div class="container-fluid">
    <div class="row background500 vh-100">
        <div class="col-12 vh-100 text-white">
            <div class="top-text">
                <h5 class="heading fnt-18">Oops! Something blew up! But, fret not. Top people are <br/> now aware. Check back after sometime.</h5>
                <a href="/" class="btn btn-outline-light my-3 d-flex align-items-center justify-content-around take-me-home">
                    <div class="icon-left-arrow mar-top-6"></div>
                    <div class="pl-2">Take me home</div>
                </a>
            </div>
            <div class="bottom-text px-4">
                <p class="font-italic heading">
                    "Did you ever hear the philosophy that once a man admits that he's wrong, he is immediately forgiven for all wrongdoings?"
                </p>
            </div>
        </div>
    </div>
</div>   -->
<div class="sp-section dark4 center">
    <div class="row background404 w100">
        <div class="container text-white d-flex justify-content-start align-center row">
            <div class="col-md-8 col-sm-12 mb-40">
                <h1 class="sp-heading accenttwo mb-40">Oops! Something blew up! But, fret not. Top people are now aware. Check back after sometime.</h1>
                <h4 class="sp-heading gold f300">Auto-redirecting to AURUM Rewards homepage in {{autoRedirectTime}} seconds...</h4>
            </div>
            <div class="col-md-4 col-sm-12">
                <img src="/assets/404.png" class="w100">
            </div>
        </div>
    </div>
</div>