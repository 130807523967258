<div class="d-none d-sm-block">
  <div class="dining-home-top-container relative">
    <div class="home-page-heading absolute">
      <h1 class="sp-heading gold mb-20 text-center">Redeem your points to explore
with National Geographic Tours</h1>
    </div>

    <div class="home-search-bar container">
      <div class="row home-search-row">
        <div class="col-lg-10">
          <div class="row">
            <div class="col-md-12 col-lg-4 home-input-fields" dropdown [insideClick]="true">
              <div class="form-field relative" dropdownToggle>
                <div class="form-field__control ">
                  <input id="exampleFieldOrigin" readonly type="text" class="form-field__input arrows text-truncate"
                    [(ngModel)]="destination" placeholder=" ">
                  <label for="exampleFieldOrigin" class="form-field__label">Select your destination</label>
                  <div class="form-field__bar"></div>
                </div>
              </div>
              <div *dropdownMenu class="dropdown-menu m-0 w100">
                <div class="drop-inner">
                  <div class="block loc-results">
                    <div class="option-item ellipsis" *ngFor="let destPlace of filters?.data.regions">
                      <label class="w100 d-flex align-items-center font-14 filter-label option-item-inner">

                        <div class="filter-label-text">{{destPlace.region}}</div>
                        <input (change)="changeCheckbox($event,destPlace,country)" type="checkbox">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 home-input-fields form-field relative" dropdown>
              <div class="form-field__control" dropdownToggle>
                <input id="exampleFieldTheme" readonly type="text" class="form-field__input arrows text-truncate"
                  [(ngModel)]="theme" placeholder=" ">
                <label for="exampleFieldTheme" class="form-field__label">Choose your expedition theme</label>
                <div class="form-field__bar"></div>
              </div>
              <div class="dropdown-menu w100" *dropdownMenu>
                <div class="block loc-results">
                  <div class="option-item ellipsis" *ngFor="let trip_type of filters?.data.trip_types">
                    <div class="option-item-inner"
                      (mousedown)="theme = trip_type.trip_type;enquiryFilters.theme=trip_type.id; chooseOneCriteria = '';">
                      {{trip_type.trip_type}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 home-input-fields form-field relative" dropdown>
              <div class="form-field__control" dropdownToggle>
                <input id="exampleFieldDDate" type="text" #monthDropdown readonly [(ngModel)]="month" placeholder=" "
                  class='form-field__input arrows pointer'>
                <label for="exampleFieldDDate" class="form-field__label">Departure Month</label>
                <div class="form-field__bar"></div>
              </div>
              <div class='dropdown-menu w100 ft-wt-400' *dropdownMenu>
                <div class="block loc-results">
                  <div class="option-item ellipsis" *ngFor="let selectMonth of filters?.data.departure_months">
                    <div class="option-item-inner" (mousedown)="month = selectMonth;enquiryFilters.month=month">
                      {{selectMonth}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="chooseOneCriteria">
            <span class="fnt-10 error-msg">{{chooseOneCriteria}}</span>
          </div>
        </div>
        <div class="col-md-12 col-lg-2 p-0">
          <div class="col-12 align-self-center search-button-pad h100">
            <button class="col-12 search-button ft-wt-400" (click)="goToListing()" mat-button>Search
              <span class="mar-left-10 loader btn-loader-default" *ngIf="clickedSearch"></span>
            </button>
          </div>
        </div>
        <div *ngIf="submitted" class="invalid-feedback">Please fill atleast one field</div>
      </div>
      <div class="search-bottom-text float-left">Don't know where to go ? <span (click)="seeAllDestination()"
          class="see-destination color-white">See all Destinations</span></div>
    </div>
  </div>
</div>

<div class="d-block d-sm-none">
  <div class="dining-home-top-container">
    <div class="home-page-heading d-flex align-items-center justify-content-center">
      <h1 class="sp-heading gold mb-20 text-center">
        Redeem your points to explore
with National Geographic Tours
      </h1>
    </div>

  </div>
  <div class="home-search-bar">
    <p class="destination-p">What's your destination?</p>
    <div class="col-md-12 col-lg-3 input-field pointer form-field relative" dropdown [insideClick]="true">
      <div class="form-field relative" dropdownToggle>
        <div class="form-field__control">
          <input id="exampleFieldOrigin" readonly type="text" class="form-field__input arrows text-truncate"
            [(ngModel)]="destination" placeholder=" ">
          <label for="exampleFieldOrigin" class="form-field__label">Select your destination</label>
          <div class="form-field__bar"></div>
        </div>
      </div>
      <div *dropdownMenu class="dropdown-menu m-0 w100">
        <div class="drop-inner">
          <div class="block loc-results">
            <div class="option-item ellipsis" *ngFor="let destPlace of filters?.data.regions">
              <label class="w100 d-flex align-items-center font-14 filter-label option-item-inner">

                <div class="filter-label-text">{{destPlace.region}}</div>
                <input (change)="changeCheckbox($event,destPlace,country)" type="checkbox">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-3 input-field pointer form-field relative" dropdown>
      <div class="form-field__control" dropdownToggle>
        <input id="exampleFieldTheme" readonly type="text" class="form-field__input arrows text-truncate"
          [(ngModel)]="theme" placeholder=" ">
        <label for="exampleFieldTheme" class="form-field__label">Choose your expedition theme</label>
        <div class="form-field__bar"></div>
      </div>
      <div class="dropdown-menu w100" *dropdownMenu>
        <div class="block loc-results">
          <div class="option-item ellipsis" *ngFor="let trip_type of filters?.data.trip_types">
            <div class="option-item-inner" (mousedown)="theme = trip_type.trip_type;enquiryFilters.theme=trip_type.id">
              {{trip_type.trip_type}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-3 input-field pointer form-field relative" dropdown>
      <div class="form-field__control" dropdownToggle>
        <input id="exampleFieldDDate" type="text" #monthDropdown readonly [(ngModel)]="month" placeholder=" "
          class='form-field__input arrows pointer'>
        <label for="exampleFieldDDate" class="form-field__label">Departure Month</label>
        <div class="form-field__bar"></div>
      </div>
      <div class='dropdown-menu w100 ft-wt-400' *dropdownMenu>
        <div class="block loc-results">
          <div class="option-item ellipsis" *ngFor="let selectMonth of filters?.data.departure_months">
            <div class="option-item-inner" (mousedown)="month = selectMonth;enquiryFilters.month=month">
              {{selectMonth}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="chooseOneCriteria">
      <span class="fnt-10 error-msg">{{chooseOneCriteria}}</span>
    </div>
    <div class="col-md-12 col-lg-3 p-0">
      <div class="col-12 align-self-center home-top-button p-0">
        <button class="col-12 button-height" (click)="goToListing()" mat-button>Search
          <span class="mar-left-10 loader btn-loader-default" *ngIf="clickedSearch"></span>
        </button>
      </div>
    </div>
    <div class="search-bottom-text">Don't know where to go ? <a (click)="seeAllDestination()"
        class="see-destination">See all Destinations</a></div>
  </div>
</div>

<!-- <app-loader *ngIf="progress"></app-loader> -->