import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './modules/material/material.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemoveUnderscorePipe } from './pipes/remove-underscore/remove-underscore.pipe';
import { Ng5SliderModule } from 'ng5-slider';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { LoaderComponent } from './components/loader/loader.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { IndianCurrency } from './pipes/indian-currency.pipe';
import { CreditCardMaskPipe } from './pipes/credit-card-mask.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { NgxPaginationModule } from "ngx-pagination";
import { PaymentComponent } from './components/payment/payment.component';
import { NgbDatepickerModule, NgbModule, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover'
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CreditCardDirective } from './directives/credit-card.directive';
import { StatusLabelComponent } from './../booking-history/status-label/status-label.component';
import { NavIconComponent } from './components/nav-icon.component';
import { IncrementDecrementPipe } from './pipes/increment-decrement.pipe';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { Time24to12Pipe } from './pipes/time24to12.pipe';
import { CouponCodeComponent } from './components/coupon-code/coupon-code.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { PromoTncComponent } from './components/promo-tnc/promo-tnc.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {NgxPrintModule} from 'ngx-print';
import { NgxDaterangepickerMd } from 'src/app/shared/components/daterangepicker';
import { ActivityCardComponent } from 'src/app/tours-activities/activity-card/activity-card.component';
import { NatgeoHomeTopPartComponent } from 'src/app/nat-geo/pages/home/home-top-part/natgeo-home-top-part.component';
import {GiftcardHomeTopPartComponent} from 'src/app/giftcards/home/home-top-part/giftcard-home-top-part.component';
import {GiftcardSearchBarComponent} from 'src/app/giftcards/search-bar/giftcard-search-bar.component';
import { NoDataComponent } from 'src/app/shared/components/no-data/no-data.component';
import {GolfHomeComponent} from 'src/app/golf/pages/home/golf-home.component';
import {GolfHomeBarComponent} from 'src/app/golf/pages/home/home-bar/golf-home-bar.component';
import {CustomDropdownComponent} from 'src/app/hotel/components/dropdown/custom-dropdown.component';
import {CustomDropdownItemsComponent} from 'src/app/hotel//components/dropdown/custom-dropdown.component';
import { CleanHtmlPipe } from './pipes/clean-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { AmenitiesPopupComponent } from 'src/app/hotel/components/amenities-popup/amenities-popup.component';
import { GiftCardComponent } from 'src/app/giftcards/gift-card/gift-card.component';
import { EventsConcertsHomeComponent } from  'src/app/events-concerts/home/home.component';
import { EventCardComponent } from 'src/app/events-concerts/event-card/event-card.component';
import { HomeThemePartComponent } from 'src/app/nat-geo/pages/home/home-theme-part/home-theme-part.component';
import { SbicLoginComponent } from 'src/app/shared/components/sbic-login/sbic-login.component';
import { ReadMoreComponent } from '../points-transfer/read-more.component';

// import { InfoLinksComponent } from './components/info-links/info-links.component';

@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        RemoveUnderscorePipe,
        LoaderComponent,
        IndianCurrency,
        CreditCardMaskPipe,
        FilterPipe,
        PaymentComponent,
        CreditCardDirective,
        StatusLabelComponent,
        NavIconComponent,
        IncrementDecrementPipe,
        ImagePreloadDirective,
        Time24to12Pipe,
        CouponCodeComponent,
        NumbersOnlyDirective,
        PromoTncComponent,
        ReplacePipe,
        MinuteSecondsPipe,
        ActivityCardComponent,
        NatgeoHomeTopPartComponent,
        GiftcardHomeTopPartComponent,
        GiftcardSearchBarComponent,
        NoDataComponent,
        GolfHomeComponent,
        GolfHomeBarComponent,
        CustomDropdownComponent,
        CustomDropdownItemsComponent,
        CleanHtmlPipe,
        SafeUrlPipe,
        AmenitiesPopupComponent,
        GiftCardComponent,
        EventsConcertsHomeComponent,
        EventCardComponent,
        HomeThemePartComponent,
        SbicLoginComponent,
        // InfoLinksComponent,
        ReadMoreComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        Ng5SliderModule,
        ToastrModule.forRoot({
            // closeButton: true,
            preventDuplicates: true,
            positionClass: 'inline',
            // disableTimeOut: true
        }),
        ToastContainerModule,
        NgSelectModule,
        NgxPaginationModule,
        NgbDatepickerModule,
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        AccordionModule.forRoot(),
        NgbModule,
        BsDropdownModule.forRoot(),
        NgxPrintModule,
        NgxDaterangepickerMd.forRoot({
            firstDay: 0
        }),
    ],
    exports: [
        MaterialModule,
        FooterComponent,
        HeaderComponent,
        RemoveUnderscorePipe,
        FormsModule,
        ReactiveFormsModule,
        Ng5SliderModule,
        LoaderComponent,
        NgSelectModule,
        IndianCurrency,
        CreditCardMaskPipe,
        FilterPipe,
        NgxPaginationModule,
        PaymentComponent,
        NgbModule,
        ModalModule,
        PopoverModule,
        AccordionModule,
        CreditCardDirective,
        StatusLabelComponent,
        NavIconComponent,
        IncrementDecrementPipe,
        ImagePreloadDirective,
        Time24to12Pipe,
        CouponCodeComponent,
        NumbersOnlyDirective,
        PromoTncComponent,
        ReplacePipe,
        MinuteSecondsPipe,
        NgxPrintModule,
        BsDropdownModule,
        NgxDaterangepickerMd,
        ActivityCardComponent,
        NatgeoHomeTopPartComponent,
        GiftcardHomeTopPartComponent,
        GiftcardSearchBarComponent,
        NoDataComponent,
        GolfHomeComponent,
        GolfHomeBarComponent,
        CustomDropdownComponent,
        CustomDropdownItemsComponent,
        CleanHtmlPipe,
        NgxDaterangepickerMd,
        SafeUrlPipe,
        GiftCardComponent,
        EventsConcertsHomeComponent,
        EventCardComponent,
        HomeThemePartComponent,
        SbicLoginComponent,
        ToastrModule,
        ToastContainerModule,
        ReadMoreComponent
        // InfoLinksComponent
    ],
    providers: []
})
export class SharedModule { }
